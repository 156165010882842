import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";


export const RiskAssessmentDialogDataWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.risk-assesment-dialog-data-wrap": {
    ".stack-wrap": {
      ".stack-header": {
        ".title": {
          width: '100%',
          ".MuiTypography-root": {
            fontSize: theme.typography.body2.fontSize
          }
        }
      }
    },
    ".basic-list-wrap": {
      ".list": {
        padding: "0 8px",
        ".MuiTypography-root": {
          "&.title": {
            width: '100%',
          }
        }
      }
    },
    ".accordion-graph-wrap": {
      height: 248,
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
    }
  },
}));
