import {
    TableCell,
    TableRow,
    useTheme,
} from "@mui/material";
import { Box, Button, DataTable, Icon, Checkbox, Menu, Typography, Input } from "@ntpkunity/controls";
import { FinanceStructureTableWrap } from "./finance-structure-table.style";
import { useSelector } from "react-redux";
import { useState } from "react";

export const FinanceStructureTable = ({ financeStructures, onUpdateStructure, onRemoveStructure, selectedStructureKey, onSelectStructure }) => {
    const theme = useTheme();

    const [editMode, setEditMode] = useState<string | null>(null);
    const { applicantDetails } = useSelector((state: any) => state?.applicantDetails);

    console.log(applicantDetails)

    const formatNumberWithCommas = (number) => {
        if (typeof number === "number") {
            return new Intl.NumberFormat("en-US").format(number);
        }
        return number;
    };

    const editableFields = [
        "terms",
        "interest_rate",
        "down_payment_value",
        "dealer_fees_total",
        "insurance_total",
        "tax_amount"
    ];

    // const fields = [
    //     { label: "Finance Type", key: "finance_type", value: applicantDetails?.finance_type || "--", textLeft: true },
    //     { label: "Financed Amount", key: "finance_amount", value: formatNumberWithCommas(applicantDetails?.finance_amount || "--"), startAdornment: "$" },
    //     { label: "Periodic Payment", key: "rental_amount", value: formatNumberWithCommas(applicantDetails?.estimated_monthly_payment || applicantDetails?.rental_amount || "--"), startAdornment: "$" },
    //     { label: "Terms", key: "terms", value: applicantDetails?.order_submissions?.[0]?.decision?.terms ?? "--" },
    //     { label: "Frequency", key: "rental_frequency", value: applicantDetails?.rental_frequency || "--", textLeft: true },
    //     { label: "Asset Cost", key: "asset_cost", value: formatNumberWithCommas(applicantDetails?.selling_price || "--"), startAdornment: "$" },
    //     { label: "Down Payment", key: "down_payment_value", value: formatNumberWithCommas(applicantDetails?.down_payment_value || "--"), startAdornment: "$" },
    //     { label: "Tax on Asset Cost", key: "tax_amount", value: formatNumberWithCommas(applicantDetails?.tax_amount || "--"), startAdornment: "$" },
    //     { label: "Net Trade-in", key: "trade_in_value", value: formatNumberWithCommas(applicantDetails?.order_tradein?.trade_in_amount || "--"), startAdornment: "$" },
    //     { label: "Charges", key: "charges", value: formatNumberWithCommas(applicantDetails?.charges || "--"), startAdornment: "$" },
    //     { label: "Rebate", key: "rebates", value: formatNumberWithCommas(applicantDetails?.rebate || "--"), startAdornment: "$" },
    //     { label: "Discount", key: "discount", value: formatNumberWithCommas(applicantDetails?.estimated_monthly_payment || "--"), startAdornment: "$" },
    //     { label: "Add-ons", key: "gap_total", value: formatNumberWithCommas(applicantDetails?.order_options?.reduce((acc, option) => acc + (option.applied_price || 0), 0) || "--"), startAdornment: "$" },
    //     { label: "Insurance", key: "insurance_total", value: formatNumberWithCommas(applicantDetails?.order_fnI?.reduce((acc, item) => acc + (item.applied_price || 0), 0) || "--"), startAdornment: "$" },
    //     { label: "Interest Rate", key: "interest_rate", value: formatNumberWithCommas(applicantDetails?.apr || "--"), startAdornment: "%" },
    //     { label: "Dealer Fees", key: "dealer_fees_total", value: formatNumberWithCommas(applicantDetails?.order_fees?.reduce((acc, fee) => acc + (fee.applied_price || 0), 0) || "--"), startAdornment: "$" },
    // ];

    const fields = [
        { label: "Finance Type", key: "finance_type", value: applicantDetails?.finance_type || "--", textLeft: true },
        { label: "Financed Amount", key: "finance_amount", value: formatNumberWithCommas(applicantDetails?.finance_amount ?? "--"), startAdornment: "$" },
        {
            label: "Periodic Payment",
            key: "rental_amount",
            value: formatNumberWithCommas(
                applicantDetails?.order_repayment_plan?.[0]?.rental_amount ??
                applicantDetails?.estimated_monthly_payment ??
                "--"
            ),
            startAdornment: "$"
        },
        {
            label: "Terms",
            key: "terms",
            value: applicantDetails?.order_submissions?.[0]?.decision?.terms ??
                applicantDetails?.no_of_regular_payments ??
                "--"
        },
        { label: "Frequency", key: "rental_frequency", value: applicantDetails?.rental_frequency || "--", textLeft: true },
        { label: "Asset Cost", key: "asset_cost", value: formatNumberWithCommas(applicantDetails?.selling_price ?? "--"), startAdornment: "$" },
        {
            label: "Down Payment",
            key: "down_payment_value",
            value: formatNumberWithCommas(
                applicantDetails?.down_payment_value ??
                applicantDetails?.down_payment ??
                "--"
            ),
            startAdornment: "$"
        },
        { label: "Tax on Asset Cost", key: "tax_amount", value: formatNumberWithCommas(applicantDetails?.tax_amount ?? "--"), startAdornment: "$" },
        {
            label: "Net Trade-in",
            key: "trade_in_value",
            value: formatNumberWithCommas(applicantDetails?.order_tradein?.trade_in_amount ?? "--"),
            startAdornment: "$"
        },
        { label: "Charges", key: "charges", value: formatNumberWithCommas(applicantDetails?.charges ?? "--"), startAdornment: "$" },
        { label: "Rebate", key: "rebates", value: formatNumberWithCommas(applicantDetails?.rebate ?? "--"), startAdornment: "$" },
        { label: "Discount", key: "discount", value: formatNumberWithCommas(applicantDetails?.discount ?? "--"), startAdornment: "$" },
        {
            label: "Add-ons",
            key: "gap_total",
            value: formatNumberWithCommas(
                applicantDetails?.order_options?.reduce((acc, option) => acc + (option.applied_price || 0), 0) ?? "--"
            ),
            startAdornment: "$"
        },
        {
            label: "Insurance",
            key: "insurance_total",
            value: formatNumberWithCommas(
                applicantDetails?.order_fnI?.reduce((acc, item) => acc + (item.applied_price || 0), 0) ?? "--"
            ),
            startAdornment: "$"
        },
        { label: "Interest Rate", key: "interest_rate", value: formatNumberWithCommas(applicantDetails?.apr ?? "--"), startAdornment: "%" },
        {
            label: "Dealer Fees",
            key: "dealer_fees_total",
            value: formatNumberWithCommas(
                applicantDetails?.order_fees?.reduce((acc, fee) => acc + (fee.applied_price || 0), 0) ?? "--"
            ),
            startAdornment: "$"
        },
    ];

    return (
        <FinanceStructureTableWrap theme={theme} className="finance-structure-table-wrap">
            <DataTable
                theme={theme}
                theadChildren={
                    <TableRow>
                        <TableCell className="sticky"></TableCell>
                        {financeStructures.map(({ key, label, pin }) => (
                            <TableCell key={key} className="text-body1 fw-medium">
                                <Box theme={theme} className="table-drag-drop-order">
                                    <Box theme={theme} className="table-header-drap-with-checkbox">
                                        <Button
                                            theme={theme}
                                            defaultBtn
                                            iconText={<Icon name="DragIcon" />}
                                            onClick={() => { }}
                                        />
                                        <Box theme={theme} className="header-check">
                                            <Checkbox
                                                theme={theme}
                                                label={label}
                                                checked={selectedStructureKey === key}
                                                onChange={() => {
                                                    if (selectedStructureKey !== key) {
                                                        onSelectStructure(key);
                                                    }
                                                }} 
                                            />

                                        </Box>
                                    </Box>
                                    <Box theme={theme} className="table-header-menu">
                                        {pin && (
                                            <Box theme={theme} className="column-pin">
                                                <Button
                                                    theme={theme}
                                                    defaultBtn
                                                    iconText={<Icon name="PinUnpin" />}
                                                    onClick={() => { }}
                                                />
                                            </Box>
                                        )}
                                        {key.startsWith("Underwriter") && (
                                            <Menu
                                                theme={theme}
                                                options={[
                                                    { optionText: "Edit", optionkey: "edit" },
                                                    { optionText: "Delete", optionkey: "delete", disabled: selectedStructureKey === key },
                                                ]}
                                                handleOptionClick={(event, actionKey) => {
                                                    if (actionKey === "edit") {
                                                        setEditMode(key);
                                                    } else if (actionKey === "delete" && selectedStructureKey !== key) {
                                                        onRemoveStructure(key);
                                                    }
                                                }}
                                                render={(cb) => (
                                                    <Button
                                                        defaultBtn
                                                        theme={theme}
                                                        iconText={<Icon name="MoreIcon" />}
                                                        onClick={cb}
                                                    />
                                                )}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </TableCell>
                        ))}
                    </TableRow>
                }
                tbodyChildren={
                    fields.map((field, index) => (
                        <TableRow key={index}>
                            <TableCell className="sticky">
                                <Typography
                                    theme={theme}
                                    variant="body2"
                                    component="span"
                                    className="left-text text-body2 fw-medium text-black"
                                >
                                    {field.label}
                                </Typography>
                            </TableCell>

                            {financeStructures.map((structure) => (
                                <TableCell key={structure.key}>
                                    <Input
                                        theme={theme}
                                        type="text"
                                        value={formatNumberWithCommas(structure.data[field.key] || field.value)}
                                        startAdornment={field.startAdornment || ""}
                                        onChange={(e) => onUpdateStructure(structure.key, { ...structure.data, [field.key]: e })}
                                        inputClass={field.textLeft ? "text-left" : "text-right"}
                                        fullWidth
                                        readOnly={!(editMode === structure.key && editableFields.includes(field.key))}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))
                }
            />
        </FinanceStructureTableWrap>
    );
};

export default FinanceStructureTable;
