import { useTheme } from "@mui/material";
import { ThirdPartyResponseListWrap } from "./third-party-response-list.style";
import { Icon, Box, Typography, Accordion, Label } from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { PdfList } from "@src/components";
import Experian_Report from "@src/assets/docs/Experian_Report.pdf";
import Transunion_Report from "@src/assets/docs/TransUnion.pdf.pdf";

export const ThirdPartyResponseList = () => {
  const theme = useTheme();

  const getTimestampWithGap = (monthsGap) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + monthsGap);
    return currentDate.toISOString().split("T")[0];
  };

  return (
    <>
      <ThirdPartyResponseListWrap
        theme={theme}
        className="third-party-response-list-wrap"
      >
        <Accordion
          theme={theme}
          items={[
            // {
            //   content: (
            //     <>
            //       <PdfList
            //         title={
            //           <a
            //             href={Experian_Report}
            //             target="_blank"
            //             // rel="noopener noreferrer"
            //           >
            //             Experian Report.pdf
            //           </a>
            //         } // Replace with the actual URL of the PDF
            //         subTitle={getTimestampWithGap(2)}
            //         pdfIcon={false}
            //       />
            //     </>
            //   ),
            //   title: (
            //     <>
            //       Experian Report
            //       <Label theme={theme} text="Active" textTransform="none" />
            //     </>
            //   ),
            //   key: "experian-report",
            //   isExpanded: true,
            //   id: "experian-report",
            //   ariaControls: "experian-report",
            // },
            {
              content: (
                <>
                  <PdfList
                    title={
                      <a
                        href={Transunion_Report}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        TransUnion Report.pdf
                      </a>
                    } // Replace with the actual URL of the PDF'TransUnion Report.pdf'}
                    subTitle={getTimestampWithGap(3)}
                    pdfIcon={false}
                  />
                </>
              ),
              title: (
                <>
                  Third party credit report
                  <Label
                    theme={theme}
                    text="Active"
                    state="primary"
                    textTransform="none"
                  />
                </>
              ),
              key: "third-party-credit-report",
              isExpanded: false,
              id: "third-party-credit-report",
              ariaControls: "third-party-credit-report",
            },
            {
              content: (
                <>
                  <PdfList
                    title={
                      <a
                        href="https://example.com/TransUnion_Report.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Fraud Check.pdf
                      </a>
                    } // Replace with the actual URL of the PDF'TransUnion Report.pdf'}
                    subTitle={getTimestampWithGap(3)}
                    pdfIcon={false}
                  />
                </>
              ),
              title: (
                <>
                  Fraud Check
                  <Label
                    theme={theme}
                    text="Active"
                    state="primary"
                    textTransform="none"
                  />
                </>
              ),
              key: "fraud-check-report",
              isExpanded: false,
              id: "fraud-check-report",
              ariaControls: "fraud-check-report",
            },
            {
              content: (
                <>
                  <PdfList
                    title={
                      <a
                        href="https://example.com/Equifex_Report.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vehicle Verification.pdf
                      </a>
                    }
                    subTitle={getTimestampWithGap(4)}
                    pdfIcon={false}
                  />
                </>
              ),
              title: (
                <>
                  Vehicle Verification
                  <Label
                    theme={theme}
                    text="Active"
                    state="primary"
                    textTransform="none"
                  />
                </>
              ),
              key: "vehicle-verification-report",
              isExpanded: false,
              id: "vehicle-verification-report",
              ariaControls: "vehicle-verification-report",
            },
          ]}
          shouldCollapse
        />
      </ThirdPartyResponseListWrap>
    </>
  );
};
