import { useTheme } from "@mui/material";
import { Box, Button, Label, Tooltip } from "@ntpkunity/controls";
import { Alert, PageHeader, Stack } from "@src/components";
import { AddDecisionDialog } from "./add-decision-popup/add-decision-dialog.component";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardPageHeaderStatus from "./dashboard-page-header-status.component";
import { OrderStatus } from "@src/helpers/enum";
import { DashboardPageHeaderWrap } from "./dashboard-page-header.style";

export const DashboardPageHeader = ({ businessData }) => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);

  const { applicantDetails } = useSelector(
    (state: any) => state.applicantDetails
  );

  const { assignedRowId, workQueues } = useSelector(
    (state: any) => state.workQueue
  );

  const assignedStatus =
    workQueues?.find((item) => item.id === assignedRowId)?.status || "N/A";

  localStorage.setItem("reference_number", applicantDetails?.status);

  const handleOpenDialog = () => {
    setOpened(true);
  };

  const handleCloseDialog = () => {
    setOpened(false);
  };

  return (
    <>
      <DashboardPageHeaderWrap
        theme={theme}
        className="dashboard-page-header-wrap"
      >
        <Stack paddingMd={2} paddingXs={2}>
          <PageHeader
            title={applicantDetails?.reference_number}
            hasLabel={
              <DashboardPageHeaderStatus
                status={assignedStatus as OrderStatus}
              />
            }
            actionArea={
              <>
                <Button
                  theme={theme}
                  primary
                  text={"Add Decision"}
                  type="button"
                  onClick={handleOpenDialog}
                />
              </>
            }
            hasStatus={
              <>
                {businessData?.alerts?.map((alert, i) => (
                  <Tooltip
                    theme={theme}
                    title={alert?.rule}
                    placement={"bottom"}
                    key={i}
                  >
                    <Alert text={alert?.title} error />
                  </Tooltip>
                ))}
              </>
            }
          />
        </Stack>
      </DashboardPageHeaderWrap>
      <AddDecisionDialog open={open} closeDialog={handleCloseDialog} />
    </>
  );
};
