import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageHeaderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-header-wrap": {
    display: "flex",
    alignItems: "center",
    padding: "4px 0px",
    backgroundColor: theme.palette.common.white,
    gap: 24,
    flexWrap: "wrap",
    ".title-area, .input-area": {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
      gap: 16,
    },
    ".title-area": {
      ".icon-area": {
        flexShrink: 0,
      },
      ".has-label": {
        ".label-wrap": {
          ".label-bg": {
            padding: "8px 16px",
            "&.bg-primary": {
              bakgroundColor: theme.palette.primary.dark,
            },
            ".label-text": {
              fontSize: theme.typography.body2.fontSize,
              lineHeight: theme.typography.body1.lineHeight,
              fontWeight: theme.typography.fontWeightMedium,
            },
          },
        },
      },
    },
    ".input-area": {
      ".u-form-group": {
        width: "100%",
        ".u-form-control": {
          paddingLeft: 0,
          fontSize: theme.typography.h2.fontSize,
          lineHeight: theme.typography.h2.lineHeight,
          fontWeight: theme.typography.fontWeightBold,
          ".MuiInputBase-input": {
            padding: 0,
            "&::placeholder": {
              opacity: 0.26,
            },
          },
          fieldset: {
            border: "none",
          },
          [theme.breakpoints.down("md")]: {
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
          },
        },
      },
    },
    ".action-area": {
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
      gap: 24,
      ".action-item": {
        display: "flex",
        alignItems: "center",
        gap: 16,
        position: "relative",
        [theme.breakpoints.down("md")]: {
          gap: 8,
          flexGrow: 1,
        },
        ".u-form-group": {
          minWidth: 110,
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        },
      },
      ".status-wrap": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        position: "relative",
        borderRight: "1px solid" + theme.palette.divider,
        paddingRight: 16,
        "&.seperator": {
          marginRight: 24,
          "&::after": {
            display: "block",
            content: '" "',
            borderRightStyle: "solid",
            borderRightWidth: 1,
            borderRightColor: theme.palette.divider,
            height: "calc(100% - 8px)",
            position: "absolute",
            right: -24,
          },
        },
        ".styled-tooltip": {
          ".MuiTooltip-popper": {
            ".MuiTooltip-tooltip": {
              maxWidth: 146,
              textAlign: 'center',
              lineHeight: theme.typography.body2.lineHeight
            }
          }
        },
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        gap: 16,
        ".btn": {
          "&.btn-primary, &.btn-secondary": {
            padding: "12px 16px",
            fontSize: theme.typography.body2.fontSize,
            lineHeight: "16px",
          },
          "&.with-icon": {
            "&.icon-only": {
              padding: 12,
              svg: {
                width: 16,
                height: 16,
              },
            },
          },
          "&:last-of-type": {
            width: "100%",
          },
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      padding: 24,
      gap: 16,
      position: "fixed",
      width: "100%",
      zIndex: 1201,
      top: 76,
    },
  },
}));
