import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  CircleLoader,
  Grid,
  Icon,
  Label,
} from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { RiskAssessmentGraph } from "../risk-assessment-graph/risk-assessment-graph";
import { AlertStatusText, BasicStack } from "@src/components/dashboard";
import { Stack } from "@src/components/stack";
import { useEffect, useState, useRef } from "react";
import { RiskAssessmentDialog } from "../risk-assessment-dialog/risk-assessment-dialog.component";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchWorkflowDetails } from "@src/services/configureApplicationService";
import { RiskAssessmentStackWrap } from "./risk-assessment-stack.style";
import { updateRatios } from "@src/components/redux/actions/riskAssessmentActions";

export const RiskAssessmentStack = ({ exposureData }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const [fraudScores, setFraudScores] = useState([]) as any;
  const [ratios, setRatios] = useState({
    ltiRatio: null,
    ltvRatio: null,
    dbrRatio: null,
    creditUtilization: null,
  }) as any;

  const dispatch = useDispatch();

  const { riskAssessmentData, error } = useSelector(
    (state: any) => state.riskAssessment
  );

  const { applicantDetails, loading } = useSelector(
    (state: any) => state?.applicantDetails
  );

  const creditCheckScore = useSelector(
    (state: any) => state.workQueue.credit_check_score
  );
  const { running_workflow_instance_id } = useSelector(
    (state: any) => state.workQueue
  );

  useEffect(() => {
    const fetchData = async (activityName: string) => {
      try {
        const base_url = process.env.REACT_APP_API_URL;
        const response = await axios.get(
          `${base_url}/cde/get_activity_workflow_data`,
          {
            params: {
              workflowInstanceId: running_workflow_instance_id,
              activity_name: activityName,
            },
          }
        );
        return response.data;
      } catch (err) {
        console.error(`Error fetching data for ${activityName}:`, err);
        return null;
      }
    };

    const fetchAllData = async () => {
      if (running_workflow_instance_id) {
        const fraudData = await fetchData("FraudCheck_Response");
        setFraudScores(fraudData?.fraud?.scores || []);

        const ltiData = await fetchData("LTI");
        const ltvData = await fetchData("LTV");
        const dbrData = await fetchData("DBR");
        const creditUtilizationData = await fetchData(
          "CreditUtilization_Response"
        );

        const newRatios = {
          ltiRatio:
            ltiData !== null && !isNaN(ltiData)
              ? Number(ltiData).toFixed(2)
              : "0.00",
          ltvRatio:
            ltvData !== null && !isNaN(ltvData)
              ? Number(ltvData).toFixed(2)
              : "0.00",
          dbrRatio:
            dbrData !== null && !isNaN(dbrData)
              ? Number(dbrData).toFixed(2)
              : "0.00",
          creditUtilization:
            creditUtilizationData !== null && !isNaN(creditUtilizationData)
              ? Number(creditUtilizationData).toFixed(2)
              : "0.00",
        };
        setRatios(newRatios);
        dispatch(updateRatios(newRatios));
      }
    };

    fetchAllData();
  }, [running_workflow_instance_id]);

  const isFetching = useRef(false);

  useEffect(() => {
    const loadWorkflow = async () => {
      if (!running_workflow_instance_id || isFetching.current) return;

      isFetching.current = true;
      try {
        const data = await fetchWorkflowDetails(running_workflow_instance_id);
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
      }
      isFetching.current = false;
    };

    loadWorkflow();

    return () => {
      isFetching.current = false;
    };
  }, [running_workflow_instance_id]);

  // useEffect(() => {
  //   if (!riskAssessmentData) {
  //     dispatch(fetchRiskAssessmentRequest(customerReferenceId));
  //   }
  // }, [dispatch, riskAssessmentData]);

  // const {
  //   credit_rating,
  //   net_finance_amount,
  //   estimated_monthly_payment,
  //   due_at_signing,
  // } = riskAssessmentData?.primaryApplicant ?? {};

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const annualEmploymentIncome =
    Number(
      applicantDetails?.customer_info?.employment_info?.annual_employment_income
    ) || 0;

  const annualOtherIncome =
    Number(
      applicantDetails?.customer_info?.employment_info?.annual_other_income
    ) || 0;

  const totalAnnualIncome = annualEmploymentIncome + annualOtherIncome;

  const monthlyIncome =
    totalAnnualIncome && !isNaN(totalAnnualIncome)
      ? (totalAnnualIncome / 12).toFixed(2)
      : "0.00";

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(value);
  };

  // const monthlyIncome =
  //   totalAnnualIncome && !isNaN(totalAnnualIncome) ? totalAnnualIncome / 12 : 0;

  return (
    <>
      <Stack
        title={"Risk Assessment Matrices"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={<Icon name="ArrowLinkIcon" />}
            onClick={handleOpenDialog}
          />
        }
      >
        <RiskAssessmentStackWrap
          theme={theme}
          className="risk-assessment-stack-wrap"
        >
          <Box theme={theme} className="scrollable-container">
            {loading ? (
              <CircleLoader theme={theme} size="sm" />
            ) : error ? (
              <div>Error: {error}</div>
            ) : (
              <>
                <Box theme={theme} className="risk-assessment-alerts">
                  <RiskAssessmentGraph creditCheckScore={creditCheckScore} />
                  {fraudScores.map((item, index) => (
                    <AlertStatusText
                      key={index}
                      text={"Fraud Check"}
                      statusText={`Score: ${item.score}`}
                      success
                      fullWidth
                    />
                  ))}
                  <BasicStack
                    text={"Monthly Income:"}
                    // subTitle={`${
                    //   applicantDetails?.customer_info?.employment_info
                    //     ?.annual_employment_income
                    //     ? `$${(
                    //         (applicantDetails?.customer_info?.employment_info
                    //           ?.annual_employment_income +
                    //           applicantDetails?.customer_info?.employment_info
                    //             ?.annual_other_income) /
                    //         12
                    //       ).toLocaleString()}`
                    //     : "N/A"
                    // }`}
                    subTitle={formatCurrency(monthlyIncome)}
                    fullWidth
                    textJustify
                  />
                  <BasicStack
                    text={"Monthly Debt Payment"}
                    subTitle={
                      applicantDetails?.estimated_monthly_payment
                        ? formatCurrency(
                            applicantDetails.estimated_monthly_payment
                          )
                        : "N/A"
                    }
                    fullWidth
                    textJustify
                  />
                  <BasicStack
                    text={"Current Exposure:"}
                    subTitle={
                      exposureData?.total_exposure
                        ? formatCurrency(exposureData.total_exposure)
                        : "N/A"
                    }
                    fullWidth
                    textJustify
                  />
                  <Alert
                    text={
                      <span style={{ fontWeight: "normal" }}>
                        Credit Utilisation:
                      </span>
                    }
                    value={
                      <span style={{ fontWeight: "normal" }}>
                        {ratios.creditUtilization !== null
                          ? `${ratios.creditUtilization}%`
                          : "N/A"}
                      </span>
                    }
                    success
                    fullWidth
                  />

                  <Alert
                    text={
                      <span style={{ fontWeight: "normal" }}>LTI Ratio:</span>
                    }
                    value={
                      <span style={{ fontWeight: "normal" }}>
                        {ratios.ltiRatio !== null && !isNaN(ratios.ltiRatio)
                          ? `${ratios.ltiRatio}`
                          : "N/A"}
                      </span>
                    }
                    error
                    fullWidth
                  />

                  <Alert
                    text={
                      <span style={{ fontWeight: "normal" }}>LTV Ratio:</span>
                    }
                    value={
                      <span style={{ fontWeight: "normal" }}>
                        {ratios.ltvRatio !== null && !isNaN(ratios.ltvRatio)
                          ? `${ratios.ltvRatio}%`
                          : "N/A"}
                      </span>
                    }
                    error
                    fullWidth
                  />

                  <Alert
                    text={
                      <span style={{ fontWeight: "normal" }}>DBR Ratio:</span>
                    }
                    value={
                      <span style={{ fontWeight: "normal" }}>
                        {ratios.dbrRatio !== null && !isNaN(ratios.dbrRatio)
                          ? `${ratios.dbrRatio}%`
                          : "N/A"}
                      </span>
                    }
                    error
                    fullWidth
                  />
                </Box>
              </>
            )}
          </Box>
        </RiskAssessmentStackWrap>
      </Stack>

      <RiskAssessmentDialog
        open={isOpen}
        closeDialog={handleCloseDialog}
        applicantDetails={applicantDetails}
        exposureData={exposureData}
      />
    </>
  );
};
