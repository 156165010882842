import { useTheme } from "@mui/material";
import { AddStip } from "../../add-stip/add-stip.component"

export const AddStipulation = () => {
  const theme = useTheme();
  

  return (
    <>
      <AddStip />
    </>
  );
};