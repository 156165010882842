import { useTheme } from "@mui/material"
import { Box, Icon } from "@ntpkunity/controls";
import { ArrowDownWrap } from "./arrow-down.style";
import chevronDown from "@assets/icons/chevron-down.svg"

export const ArrowDown = ({onClick}: {onClick?: () => void}) => {
    const theme = useTheme();

    return (
        <>
            <ArrowDownWrap theme={theme} className="arrow-down" onClick={onClick}>
                <img src={chevronDown} />
            </ArrowDownWrap>
        </>
    )
}