import { useTheme } from "@mui/material";
import { DashboardPageHeader } from "@src/components/dashboard";
import { DashboardFundingRequest } from "@src/components/dashboard/dashboard-funding-request/dashboard-funding-request.component";
import { DashboardStacks } from "@src/components/dashboard/dashboard-stack/dashboard-stack";
import { retrieveFundingRequest } from "@src/components/redux/actions/fundingActions";
import { LayoutWithSideNavComponent } from "@src/layouts";
import {
  fetchBookmarks,
  rentalCalculation,
} from "@src/services/configureApplicationService";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const DashboardFundingRequestPage = () => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const [businessData, setBusinessData] = useState({});
  const [repaymentPlan, setRepaymentPlan] = useState([]);
  const [incomingPosting, setIncomingPosting] = useState([]);

  const { applicantDetails, dialogLoading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  const { workQueueDetail } = useSelector((state: any) => state.workQueue);

  const { fundingRequestDetail } = useSelector(
    (state: any) => state.fundingRequests
  );

  const isFetching = useRef(false);

  useEffect(() => {
    const loadBookmarks = async () => {
      if (fundingRequestDetail) {
        if (!applicantDetails?.reference_number || isFetching.current) return;

        isFetching.current = true;
        try {
          const rentalData = await rentalCalculation(
            fundingRequestDetail.data.id,
            workQueueDetail.data.x_api_key
          );
          if (rentalData) {
            setRepaymentPlan(rentalData.repaymentPlan || []);
            setIncomingPosting(rentalData.IncomingPosting || []);
          }

          const data = await fetchBookmarks(applicantDetails.reference_number);
          setBusinessData(data?.data);
        } catch (error) {
          console.error("Error fetching bookmarks:", error);
        }
        isFetching.current = false;
      }
    };

    loadBookmarks();

    return () => {
      isFetching.current = false;
    };
  }, [applicantDetails?.reference_number, fundingRequestDetail]);

  useEffect(() => {
    if (applicantDetails?.reference_number) {
      dispatch(retrieveFundingRequest(applicantDetails?.reference_number));
    }
  }, [dispatch, applicantDetails]);

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <DashboardPageHeader businessData={businessData} />
      <DashboardFundingRequest businessData={businessData} repaymentPlan={repaymentPlan} incomingPosting={incomingPosting} />
    </LayoutWithSideNavComponent>
  );
};
