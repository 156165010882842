export const FETCH_MY_NOTES_REQUEST = "FETCH_MY_NOTES_REQUEST";
export const FETCH_MY_NOTES_SUCCESS = "FETCH_MY_NOTES_SUCCESS";
export const FETCH_MY_NOTES_FAILURE = "FETCH_MY_NOTES_FAILURE";

export const CREATE_NOTE_REQUEST= "CREATE_NOTE_REQUEST";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_FAILURE = "CREATE_NOTE_FAILURE";

export const OPEN_NOTES_POPUP = "OPEN_NOTES_POPUP";
export const CLOSE_NOTES_POPUP = "CLOSE_NOTES_POPUP";

export const fetchMyNotesRequest = (reference_number  ) => ({
  type: FETCH_MY_NOTES_REQUEST,
  payload: reference_number,
});

export const fetchMyNotesSuccess = (notes) => ({
  type: FETCH_MY_NOTES_SUCCESS,
  payload: notes,
});

export const fetchMyNotesFailure = (error) => ({
  type: FETCH_MY_NOTES_FAILURE,
  payload: error,
});


export const createNoteRequest = (note) => ({
  type: CREATE_NOTE_REQUEST,
  payload: note,
});

export const createNoteSuccess = (note) => ({
  type: CREATE_NOTE_SUCCESS,
  payload: note,
});

export const createNoteFailure = (error) => ({
  type: CREATE_NOTE_FAILURE,
  payload: error,
});

export const openNotesPopup = () => ({
  type: OPEN_NOTES_POPUP,
});

export const closeNotesPopup = () => ({
  type: CLOSE_NOTES_POPUP,
});
