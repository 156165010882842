import { useTheme } from "@mui/material";
import { BusinessRulesAlertsWrap, CustomizedTooltipWrap } from "./business-rules.style";
import { Alert } from "@src/components";
import { Tooltip, Box, Button, Typography, Textarea } from "@ntpkunity/controls";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateComment, saveRule, resetRule } from "@src/components/redux/actions/buisinessRulesActions";

export const BusinessRulesAlerts = ({ businessData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [tooltipOpenIndex, setTooltipOpenIndex] = useState(null);

  const { comments, savedRules } = useSelector((state: any) => state.businessRules);

  const handleCommentChange = (index, value) => {
    dispatch(updateComment(index, value));
  };

  const handleSaveComment = (index) => {
    if (!comments[index]?.trim()) {
      alert("Please add a comment before waiving the rule.");
      return;
    }
    dispatch(saveRule(index));
    setTooltipOpenIndex(null);
  };

  const handleResetComment = (index) => {
    dispatch(resetRule(index));
  };

  return (
    <>
      <BusinessRulesAlertsWrap theme={theme} className="business-rules-alerts-wrap">
        {businessData?.business_rules?.map((business, i) => (
          <Alert
            key={`${i}-${savedRules[i]}`} 
            text={business.title}
            description={
              <>
                <CustomizedTooltipWrap theme={theme} className="customized-tooltip-wrap">
                  <Tooltip
                    theme={theme}
                    title={
                      <>
                        <Box theme={theme} display={"flex"} flexDirection={"column"} gap={"10px"}>
                          <Box theme={theme} p={1}>
                            <Typography theme={theme} variant="body1" component={"span"} className="fw-medium text-black">
                              Add Comment
                            </Typography>
                          </Box>
                          <Box theme={theme}>
                            <Textarea
                              theme={theme}
                              id={`comment-${i}`}
                              type="text"
                              label="Comments"
                              textCount={comments[i]?.length || 0}
                              totalTextCount={60}
                              rows={3}
                              fullWidth
                              value={comments[i] || ""}
                              onChange={(e) => handleCommentChange(i, e)}
                              disabled={savedRules[i]}
                            />
                          </Box>
                          <Box theme={theme} textAlign={"center"} pb={1}>
                            <Button
                              theme={theme}
                              primary
                              text={"Waive Business Rule"}
                              onClick={() => handleSaveComment(i)}
                              disabled={savedRules[i]}
                              style={{
                                opacity: savedRules[i] ? 0.5 : 1,
                                cursor: savedRules[i] ? "not-allowed" : "pointer",
                              }}
                            />
                            {savedRules[i] && (
                              <Button
                                theme={theme}
                                secondary
                                text={"Reset"}
                                onClick={() => handleResetComment(i)}
                              />
                            )}
                          </Box>
                        </Box>
                      </>
                    }
                    placement="top"
                    open={tooltipOpenIndex === i}
                    onClose={() => setTooltipOpenIndex(null)}
                  >
                    <a
                      href="#"
                      className={`text-danger ${savedRules[i] ? "disabled-link" : ""}`}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!savedRules[i]) {
                          setTooltipOpenIndex(i);
                        }
                      }}
                      style={{
                        pointerEvents: savedRules[i] ? "none" : "auto",
                        opacity: savedRules[i] ? 0.5 : 1,
                        cursor: savedRules[i] ? "not-allowed" : "pointer",
                      }}
                    >
                      {savedRules[i] ? "Waived" : "Waive"}
                    </a>
                  </Tooltip>
                </CustomizedTooltipWrap>
              </>
            }
            error
            fullWidth
          />
        ))}
      </BusinessRulesAlertsWrap>
    </>
  );
};