import { useTheme } from "@mui/material";
import { Box, Grid, Typography, Label } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import { CreditScoreCounter } from "../credit-score-counter/credit-score-counter.component";
import { RiskAssessmentDialogDataWrap } from "./risk-assessment-dialog-data.style";
import { useSelector } from "react-redux";
import formatNumber from "@src/utilities/fomatNumber";

export const RiskAssessmentDialogData = ({
  applicantDetails,
  riskAssessmentDialogueData,
  exposureData,
}) => {
  const theme = useTheme();
  const { data } = useSelector((state: any) => state?.financeStructure);
  const ratios = useSelector((state: any) => state.riskAssessment.ratios);

  const formatCurrency = (value) => {
    if (value === null || value === undefined || isNaN(value)) return "N/A";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(value);
  };

  const formatPercentage = (value, key) => {
    if (value === null || value === undefined || isNaN(value)) return "N/A";
    return key === "ltiRatio" ? formatNumber(value) : `${formatNumber(value)}%`;
  };

  const {
    creditUtilisationRatio = "N/A",
    dtiRatio = "N/A",
    ltvRatio = "N/A",
    ltiRatio = "N/A",
    totalMonthlyInstallment = "N/A",
    totalMonthlyIncome = "N/A",
    creditCardDebt = "N/A",
    applications = `${exposureData?.applications_count ?? "0"}`,
    contracts = `${exposureData?.contracts_count ?? "0"}`,
    activeExposure = formatCurrency(exposureData?.active_exposure ?? 0),
    pipelineExposure = formatCurrency(exposureData?.pipeline_exposure ?? 0),
    activePipelineExposure = formatCurrency(exposureData?.total_exposure ?? 0),
    periodicPayment = formatCurrency(
      (exposureData?.periodic_payment_pipeline_exposure ?? 0) +
        (exposureData?.periodic_payment_active_exposure ?? 0)
    ),
  } = riskAssessmentDialogueData ?? {};

  const annualEmploymentIncome =
    Number(
      applicantDetails?.customer_info?.employment_info?.annual_employment_income
    ) || 0;

  const annualOtherIncome =
    Number(
      applicantDetails?.customer_info?.employment_info?.annual_other_income
    ) || 0;

  const totalAnnualIncome = annualEmploymentIncome + annualOtherIncome;

  const monthlyIncome = formatCurrency(totalAnnualIncome / 12);

  return (
    <>
      <RiskAssessmentDialogDataWrap
        theme={theme}
        className="risk-assesment-dialog-data-wrap"
      >
        <Grid
          theme={theme}
          container
          rowSpacing={0}
          columnSpacing={2}
          sx={{ mt: 0 }}
        >
          <Grid item xs={6} theme={theme}>
            <Stack paddingMd={0} paddingXs={0}>
              <Box
                theme={theme}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                p={1}
              >
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component={"span"}
                  className="fw-sbold text-body2"
                  display={"block"}
                >
                  Basic Details
                </Typography>
                <Label
                  theme={theme}
                  text={`Risk Tier 2`}
                  state="primary"
                  textTransform="none"
                />
              </Box>
              <BasicList
                noWrap
                options={[
                  {
                    title: "Credit Utilisation Ratio:",
                    subtitle: formatPercentage(
                      ratios.creditUtilization,
                      "creditUtilization"
                    ),
                    textRight: true,
                  },
                  {
                    title: "LTI Ratio:",
                    subtitle: formatPercentage(ratios.ltiRatio, "ltiRatio"),
                    textRight: true,
                  },
                  {
                    title: "LTV Ratio:",
                    subtitle: formatPercentage(ratios.ltvRatio, "ltvRatio"),
                    textRight: true,
                  },
                  {
                    title: "DBR Ratio:",
                    subtitle: formatPercentage(ratios.dbrRatio, "dbrRatio"),
                    textRight: true,
                  },
                ]}
              />

              <Box theme={theme} pt={2}>
                <CreditScoreCounter />
              </Box>
              <Typography
                theme={theme}
                variant="subtitle2"
                component={"span"}
                className="fw-sbold text-body2"
                display={"block"}
                p={1}
                mt={2}
              >
                Monthly Expenses
              </Typography>
              <BasicList
                noWrap
                options={[
                  {
                    title: "Total Monthly Installment:",
                    subtitle: formatCurrency(
                      applicantDetails?.estimated_monthly_payment ?? 0
                    ),
                    textRight: true,
                  },
                  {
                    title: "Total Monthly Income After Tax:",
                    subtitle: `${monthlyIncome}`,
                    textRight: true,
                  },
                  // {
                  //   title: "Debt of Credit Card:",
                  //   subtitle: `$${creditCardDebt}`,
                  //   textRight: true,
                  // },
                ]}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} theme={theme}>
            <Stack paddingMd={0} paddingXs={0}>
              <Typography
                theme={theme}
                variant="subtitle2"
                component={"span"}
                className="fw-sbold text-body2"
                display={"block"}
                p={1}
              >
                Total Exposure
              </Typography>
              <BasicList
                noWrap
                options={[
                  {
                    title: "Applications:",
                    subtitle: `${applications}`,
                    textRight: true,
                  },
                  {
                    title: "Contracts:",
                    subtitle: `${contracts}`,
                    textRight: true,
                  },
                  {
                    title: "Active Exposure:",
                    subtitle: `${activeExposure}`,
                    textRight: true,
                  },
                  {
                    title: "Pipeline Exposure:",
                    subtitle: `${pipelineExposure}`,
                    textRight: true,
                  },
                  {
                    title: "Active + Pipeline Exposure:",
                    subtitle: `${activePipelineExposure}`,
                    textRight: true,
                  },
                  {
                    title: "Periodic Payment:",
                    subtitle: periodicPayment,
                    textRight: true,
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>
      </RiskAssessmentDialogDataWrap>
    </>
  );
};
