import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const RiskAssessmentStackWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.risk-assessment-stack-wrap": {
        position: 'relative',
        '.scrollable-container': {
            maxHeight: 'calc(100vh - 332px)',
            overflowX: 'hidden',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            marginTop: 16,
            "@media(min-width: 1599.95px)": {
                maxHeight: 'calc(100vh - 350px)',
            },
            "@media(min-width: 2000.95px)": {
                maxHeight: 'calc(100vh - 350px)',
            },
            ".risk-assessment-alerts": {
                display: 'flex',
                flexDirection: 'column',
                gap: 8,

            }
        },
    },
}));
