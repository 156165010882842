import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  ADD_DECISION_REQUEST,
  FETCH_DECISION,
  addDecisionSuccess,
  addDecisionFailure,
  fetchDecisionSuccess,
  fetchDecisionFailure,
} from "../actions/decisionActions";

function* addDecisionSaga(action) {
  try {
    const { id, payload } = action;
    const apiUrl = `${process.env.REACT_APP_API_URL}/cde/application_queue/decision/${id}`;
    const response = yield call(axios.post, apiUrl, payload);

    if (response?.data) {
      yield put(addDecisionSuccess(response.data));
    } else {
      throw new Error("Empty response from API");
    }
  } catch (error) {
    console.error("API Call Failed:", error);
    yield put(
      addDecisionFailure(error.response?.data?.message || error.message)
    );
  }
}

export function* watchAddDecisionSaga() {
  yield takeLatest(ADD_DECISION_REQUEST, addDecisionSaga);
}

function* fetchDecision(action) {
  const { payload } = action;
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/cde/application_queue/decision/${payload}`;
    const response = yield call(axios.get, apiUrl);

    if (response?.data) {
      const stipulations = response.data?.stipulations?.map((stip) => ({
        stipulationType: stip.code,
        recommendation: stip.description,
        comments: stip.comments,
      }));
      yield put(
        fetchDecisionSuccess({ ...response.data, stipulations: stipulations })
      );
    } else {
      throw new Error("Empty response from API");
    }
  } catch (error) {
    console.error("API Call Failed:", error);
    yield put(
      fetchDecisionFailure(error.response?.data?.message || error.message)
    );
  }
}

export function* watchfetchDecisionSaga() {
  yield takeLatest(FETCH_DECISION, fetchDecision);
}
