import { TableCell, TableRow, useTheme } from "@mui/material";
import { Icon, Box, Typography } from "@ntpkunity/controls";
import { useState } from "react";
import React from 'react';

export const ReceivablesChildRows = ({ open, index }) => {
    const theme = useTheme();

    const childRows = [
        {
            key: "DocumentType",
            label: "Dealer Fee",
            amount: "100.00",
            partnerName: "",
            partnerRole: "",
        },
        {
            key: "DocumentName",
            label: "Processing Fee",
            amount: "50.00",
            partnerName: "",
            partnerRole: "",
            accordion: false,
        },
        {
            key: "UploadedBy",
            label: "Documentation Fee",
            amount: "30.00",
            partnerName: "",
            partnerRole: "",
            accordion: false,
        }
    ];

    // Only render child rows if 'open' is true
    if (!open) {
        return null; // Don't render anything if 'open' is false
    }

    return (
        <>
            {childRows.map((row, index) => (
                <React.Fragment key={row.key}>
                    <TableRow className="child-row">
                        <TableCell className="w-300 text-dark-grey">
                            <Box theme={theme} pl={2}>
                                {index + 1}. {row.label}
                            </Box>
                        </TableCell>
                        <TableCell className="w-180 text-dark-grey">
                            <Box theme={theme} display={'flex'} justifyContent={'space-between'}>
                                <Typography theme={theme} variant="body2" component={'span'} className="text-dark-grey">$</Typography>
                                {row.amount}
                            </Box>
                        </TableCell>
                        <TableCell className="w-250">{row.partnerName}</TableCell>
                        <TableCell className="w-250">{row.partnerRole}</TableCell>
                    </TableRow>
                </React.Fragment>
            ))}
        </>
    );
};
