import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FinanceStructureTableWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.finance-structure-table-wrap": {
        ".sticky": {
            position: 'sticky',
            left: 0,
            zIndex: 1202,
            width: 260,
            backgroundColor: theme.palette.common.white + '!important',
        },
        ".u-table-wrap": {
            ".scroll": {
                display: 'none'
            },
            ".u-table-container": {
                border: 'none',
                maxHeight: "calc(100vh - 308px)",
                scrollbarWidth: "none",
                ".u-table": {
                    ".u-table-head": {
                        tr: {
                            td: {
                                minWidth: 260,
                                maxWidth: 260,
                                padding: '12px 16px',
                                borderRight: '1px solid',
                                borderColor: '#F5F5F7',
                                ".table-drag-drop-order": {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: 8,
                                    ".table-header-drap-with-checkbox": {
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 8,
                                        ".custom-checkbox-wrap": {
                                            ".u-custom-control-label": {
                                                marginBottom: 0
                                            }
                                        }
                                    },

                                    ".table-header-menu": {
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 8,
                                        ".column-pin": {
                                            ".MuiButtonBase-root.btn.with-icon.btn-default": {
                                                svg: {
                                                    width: 24,
                                                    height: 24,
                                                    path: {
                                                        stroke: 'none'
                                                    }
                                                }
                                            }
                                        }
                                    },

                                    ".MuiButtonBase-root.btn.with-icon.btn-default": {
                                        padding: 0,
                                        svg: {
                                            width: 24,
                                            height: 24,
                                        }
                                    }
                                },
                                "&:last-child": {
                                    borderRight: 0,
                                },
                            }
                        }
                    },
                    ".u-table-body": {
                        tr: {
                            backgroundColor: 'transparent',
                            borderRadius: 8,
                            td: {
                                minWidth: 260,
                                maxWidth: 260,
                                padding: '12px 16px',
                                borderRight: '1px solid',
                                borderBottom: 0,
                                borderColor: '#F5F5F7',
                                ".u-form-group": {
                                    marginBottom: 0,
                                    ".u-form-control": {
                                        height: 'auto',
                                        "&.MuiInputBase-readOnly": {
                                            color: 'rgba(71, 74, 89, 1)',
                                            ".MuiInputBase-readOnly": {
                                                color: 'rgba(71, 74, 89, 1)',
                                                '-webkit-text-fill-color': 'rgba(71, 74, 89, 1)'
                                            }
                                        },
                                        "&.text-right": {
                                            ".MuiInputBase-input": {
                                                textAlign: "right",
                                                maskImage: "none",
                                            },
                                        },
                                        
                                        ".MuiInputBase-input": {
                                            fontSize: theme.typography.body2.fontSize,
                                            fontWeight: theme.typography.body2.fontWeight,
                                            lineHeight: theme.typography.body2.lineHeight,
                                            color: "#474A59",
                                            padding: 8,

                                        },
                                        "&.MuiInputBase-adornedStart": {
                                            paddingLeft: 8,
                                            ".adornment-icon": {
                                                "&.start-icon": {
                                                    marginRight: 0,
                                                    fontSize: theme.typography.body2.fontSize,
                                                    fontWeight: theme.typography.body2.fontWeight,
                                                    lineHeight: theme.typography.body2.lineHeight,
                                                    color: "#474A59",
                                                },
                                            },
                                        },
                                        fieldset: {
                                            border: "none",
                                        },
                                        "&.Mui-disabled": {
                                            ".adornment-icon": {
                                                opacity: 0.5,
                                            },
                                        },
                                    },
                                },
                                "&:last-child": {
                                    borderRight: 0,
                                },
                            },
                            "&:nth-of-type(odd), &:nth-of-type(even )": {
                                td: {
                                    backgroundColor: 'transparent',
                                },
                            },
                            "&:hover": {
                                backgroundColor: '#F8F8F9 !important',
                                td: {
                                    "&.sticky": {
                                        backgroundColor: '#F8F8F9 !important',
                                    },
                                    "&:nth-child(1)": {
                                        borderTopLeftRadius: 8,
                                        borderBottomLeftRadius: 8,
                                    },
                                    "&:last-child": {
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                    },
                                }
                            }
                        }
                    }
                }
            }
        }
    },
}));
