export const FETCH_FINANCE_STRUCTURE_REQUEST = "FETCH_FINANCE_STRUCTURE_REQUEST";
export const FETCH_FINANCE_STRUCTURE_SUCCESS = "FETCH_FINANCE_STRUCTURE_SUCCESS";
export const FETCH_FINANCE_STRUCTURE_FAILURE = "FETCH_FINANCE_STRUCTURE_FAILURE";

export const UPDATE_FINANCE_STRUCTURE_REQUEST = "UPDATE_FINANCE_STRUCTURE_REQUEST";
export const UPDATE_FINANCE_STRUCTURE_SUCCESS = "UPDATE_FINANCE_STRUCTURE_SUCCESS";
export const UPDATE_FINANCE_STRUCTURE_FAILURE = "UPDATE_FINANCE_STRUCTURE_FAILURE";

export const SET_SELECTED_STRUCTURE = "SET_SELECTED_STRUCTURE";
export const ADD_FINANCE_STRUCTURE = "ADD_FINANCE_STRUCTURE";
export const UPDATE_FINANCE_STRUCTURES = "UPDATE_FINANCE_STRUCTURES";

export const DELETE_FINANCE_STRUCTURE = "DELETE_FINANCE_STRUCTURE";


export const fetchFinanceStructureRequest = (reference_number) => ({
    type: FETCH_FINANCE_STRUCTURE_REQUEST,
    payload: reference_number
});

export const fetchFinanceStructureSuccess = (data) => ({
    type: FETCH_FINANCE_STRUCTURE_SUCCESS,
    payload: data,
});

export const fetchFinanceStructureFailure = (error) => ({
    type: FETCH_FINANCE_STRUCTURE_FAILURE,
    payload: error,
});

export const updateFinanceStructureRequest = (data) => ({
    type: UPDATE_FINANCE_STRUCTURE_REQUEST,
    payload: data,
});

export const updateFinanceStructureSuccess = (data) => ({
    type: UPDATE_FINANCE_STRUCTURE_SUCCESS,
    payload: data,
});

export const updateFinanceStructureFailure = (error) => ({
    type: UPDATE_FINANCE_STRUCTURE_FAILURE,
    payload: error,
});

export const setSelectedStructure = (structure) => ({
    type: SET_SELECTED_STRUCTURE,
    payload: structure,
});

export const addFinanceStructure = (structure) => ({
    type: ADD_FINANCE_STRUCTURE,
    payload: structure,
});

export const deleteFinanceStructure = (key) => ({
    type: DELETE_FINANCE_STRUCTURE,
    payload: key,
})
