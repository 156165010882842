import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const FinalDecisionWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.final-decision-wrap": {
        '.scrollable-container': {
            height: 'calc(100vh - 365px)',
            overflowX: 'hidden',
            overflowY: 'auto',
            scrollbarWidth: 'none',
        },
    }
}));
