const base_url = process.env.REACT_APP_API_URL;

interface Bookmark {
  id: number;
  name: string;
  value: string;
}

export const CHECK_ENDPOINTS = {
  FETCH_BOOKMARKS: "/check/api/v1/business-rules-alert",
};

export const CDE_ENDPOINTS = {
  FETCH_EXPOSURE_DETAIL: "/cde/get-exposure-details",
  FETCH_WORKFLOW_DETAIL: "/cde/get_workflow_data",
  POST_DECISION: "/cde/application_queue/decision/:id",
  RENTAL_CALCULATION: "/cde/rental_calculation/",
};

export const DMS_ENDPOINTS = {
  FETCH_ALL_DOCUMENTS: "/dms/configure/all-document-packages-by-reference-id",
  FETCH_CUSTOMER_DOCUMENTS: "/dms/customer/document",
};

export const fetchBookmarks = async (id: string): Promise<any> => {
  try {
    const apiUrl = `${base_url}${CHECK_ENDPOINTS.FETCH_BOOKMARKS}`;

    const requestBody = {
      workflow_instance_id: id,
    };

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY ?? "",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    return await response.json();
  } catch (error: any) {
    console.error("Failed to fetch bookmarks:", error);
    return { error: error.message };
  }
};

export const fetchExposureDetails = async (payload: {
  customer_id_details: {
    customer_id_type: string;
    customer_id_number: string;
  };
  customer_personal_details: {
    customer_name: string;
    customer_dob: string;
    customer_address: string;
  };
}): Promise<any> => {
  try {
    const apiUrl = `${base_url}${CDE_ENDPOINTS.FETCH_EXPOSURE_DETAIL}`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY ?? "",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    return await response.json();
  } catch (error: any) {
    console.error("Failed to fetch exposure details:", error);
    return { error: error.message };
  }
};

export const fetchWorkflowDetails = async (id: string): Promise<any> => {
  try {
    const apiUrl = `${base_url}${
      CDE_ENDPOINTS.FETCH_WORKFLOW_DETAIL
    }?workflowInstanceId=${encodeURIComponent(id)}`;

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY ?? "",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    return await response.json();
  } catch (error: any) {
    console.error("Failed to fetch workflow activity details:", error);
    return { error: error.message };
  }
};

export const postDecision = async (id: string, payload): Promise<any> => {
  try {
    const apiUrl = `${base_url}${CDE_ENDPOINTS.POST_DECISION}`.replace(
      ":id",
      id
    );

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY ?? "",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    return await response.json();
  } catch (error: any) {
    console.error("Failed to fetch workflow activity details:", error);
    return { error: error.message };
  }
};

export const fetchAllDocuments = async (
  id: string,
  ref_id: string,
  api_key: string
): Promise<any> => {
  try {
    const apiUrl = `${base_url}${DMS_ENDPOINTS.FETCH_ALL_DOCUMENTS}?reference_id=${ref_id}&external_order_id=${id}&document_type=General&document_type=Contract`;

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": api_key ?? "",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    return await response.json();
  } catch (error: any) {
    console.error("Failed to fetch bookmarks:", error);
    return { error: error.message };
  }
};

export const fetchCustomerDocuments = async (
  customer_id: string,
  api_key: string
): Promise<any> => {
  try {
    const apiUrl = `${base_url}${DMS_ENDPOINTS.FETCH_CUSTOMER_DOCUMENTS}?customer_reference_id=${customer_id}`;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": api_key ?? "",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    return await response.json();
  } catch (error: any) {
    console.error("Failed to fetch bookmarks:", error);
    return { error: error.message };
  }
};

export const rentalCalculation = async (
  id: number,
  x_api_key: string
): Promise<any> => {
  try {
    const apiUrl = `${base_url}${CDE_ENDPOINTS.RENTAL_CALCULATION}`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": x_api_key ?? "",
      },
      body: JSON.stringify({ application_id: id }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    return await response.json();
  } catch (error: any) {
    console.error("Failed to fetch workflow activity details:", error);
    return { error: error.message };
  }
};
