import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const DashboardPageHeaderWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.dashboard-page-header-wrap": {
        ".status-indicator": {
            padding: '4px 8px',
            borderRadius: 8,
            backgroundColor: theme.palette.primary.light,
            "@media only screen and (min-width: 1599.95px)": {
                padding: "8px 16px",
            },
            ".MuiTypography-root": {
                lineHeight: theme.typography.body1.lineHeight,
                color: theme.palette.primary.main
            },
            "&.ind-danger": {
                backgroundColor: theme.palette.error.light,
                ".MuiTypography-root": {
                    color: theme.palette.error.main
                }
            },
            "&.ind-success": {
                backgroundColor: theme.palette.success.light,
                ".MuiTypography-root": {
                    color: theme.palette.success.main
                }
            },
            "&.ind-warning": {
                backgroundColor: theme.palette.warning.light,
                ".MuiTypography-root": {
                    color: theme.palette.warning.main
                }
            }
        }
    },
}));
