import { useTheme } from "@mui/material";
import { Autocomplete, Dialog, Box, Icon, Checkbox, Avatar, Typography, Button } from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { SuggestionListWrap } from "./assign-member-dialog.style";
import avatarIcon from "../../../../assets/icons/avatar.png";
import React, { useState } from "react";

interface AssignToMemberDialogProps {
    open: boolean;
    onClose: () => void;
    onSelectMember?: (member: any) => void;
}

export const AssignToMemberDialog: React.FC<AssignToMemberDialogProps> = ({ open, onClose, onSelectMember }) => {
    const theme = useTheme();
    const [selectedMember, setSelectedMember] = useState<any>(null);



    if (!open) return null;

    const teamMembers = [
        { id: 1, title: "James Anderson", year: 1994, name: "James Anderson", jobTitle: "Credit Analysis Manager 2" },
        { id: 2, title: "John Williams", year: 1972, name: "John Williams", jobTitle: "Credit Analysis Manager 1" },
        { id: 3, title: "Joseph Wilson", year: 2008, name: "Joseph Wilson", jobTitle: "Credit Analysis Manager 4" },
        { id: 4, title: "Joshua Allen", year: 2008, name: "Joshua Allen", jobTitle: "Credit Analysis Manager 3" },
    ];

    const handleMemberSelect = (event, newValue) => {
        setSelectedMember(newValue);
        if (onSelectMember) {
            onSelectMember(newValue);
        }
    };

    return (
        <SuggestionListWrap theme={theme} className="suggestion-list-wrap">
            <Popup>
                <Dialog
                    theme={theme}
                    size="md"
                    open={open}
                    title="Assign request to Team Member"
                    onCloseDialog={onClose}
                    disablePortal
                    customFooter={
                        <Button
                            theme={theme}
                            primary
                            text="Assign task"
                            onClick={() => { }}
                        />
                    }
                >
                    <Box theme={theme} p={2} height="288px">
                        <Autocomplete
                            disablePortal
                            options={teamMembers}
                            getOptionLabel={(option) => `${option.name} (${option.jobTitle}) - ${option.year}`}
                            placeholder="Search..."
                            value={selectedMember}
                            onChange={handleMemberSelect}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        <Box theme={theme} className="suggestion-list">
                                            <Box theme={theme} className="title">
                                                <Checkbox
                                                    theme={theme}
                                                    label=""
                                                    checked={selectedMember?.id === option.id} />
                                                <Avatar theme={theme}><img src={avatarIcon} alt="avatar" /></Avatar>
                                                <Typography theme={theme} variant="caption" component="small" className="fw-medium">
                                                    {option.name}
                                                </Typography>
                                            </Box>
                                            <Typography theme={theme} variant="caption" component="small" className="text-dark-grey">
                                                {option.jobTitle} (Year: {option.year})
                                            </Typography>
                                        </Box>
                                    </li>
                                );
                            }} theme={theme} items={teamMembers} />
                    </Box>
                </Dialog>
            </Popup>
        </SuggestionListWrap>
    );
};
