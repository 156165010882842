import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_WORKQUEUE_REQUEST,
  fetchWorkqueueSuccess,
  fetchWorkqueueFailure,
  retrieveWorkqueueFailure,
  retrieveWorkqueueSuccess,
  RETRIEVE_WORKQUEUE_REQUEST
} from "../actions/workQueueActions";


function* fetchWorkQueuesSaga(action) {
  try {
    const { apiKey, pageNumber, pageSize, filters, selectedTab } = action.payload;

    const baseApiUrl =
      selectedTab === "Funding Requests"
        ? `${process.env.REACT_APP_API_URL}/cde/funding_application_queue`
        : `${process.env.REACT_APP_API_URL}/cde/application_queue`;

    let apiUrl = `${baseApiUrl}?page_number=${pageNumber}&records_per_page=${pageSize}`;

    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          apiUrl += `&${key}=${value}`;
        }
      });
    }

    const response = yield call(axios.get, apiUrl, {
      headers: { "x-api-key": apiKey },
    });

    if (response.status === 200) {
      const { data, pagination } = response.data;
      yield put(fetchWorkqueueSuccess({ data, pagination }));
    } else {
      throw new Error(`API Error: ${response.status}`);
    }
  } catch (error) {
    console.error("Workqueues Saga Error:", error);
    yield put(fetchWorkqueueFailure(error.response?.data?.message || "API Request Failed"));
  }
}

function* retrieveWorkQueuesSaga(action) {
  try {
    const applicantReferenceNum = action.payload;

    const apiUrl = `${process.env.REACT_APP_API_URL}/cde/application_queue?application_number=${applicantReferenceNum}`;

    const response = yield call(axios.get, apiUrl, {
      headers: { "x-api-key": `${process.env.REACT_APP_API_KEY}` },
    });

    if (response.status === 200) {
      const { data } = response.data;
      if (Array.isArray(data) && data.length > 0) {
        yield put(retrieveWorkqueueSuccess({ data: data[0] }));
      } else {
        throw new Error("API Error: Data is empty or not an array");
      }
    } else {
      throw new Error(`API Error: ${response.status}`);
    }
  } catch (error) {
    console.error("Workqueues Saga Error:", error);
    yield put(retrieveWorkqueueFailure(error.response?.data?.message || "API Request Failed"));
  }
}

export function* watchWorkQueuesSaga() {
  yield takeLatest(FETCH_WORKQUEUE_REQUEST, fetchWorkQueuesSaga);
  yield takeLatest(RETRIEVE_WORKQUEUE_REQUEST, retrieveWorkQueuesSaga);
}