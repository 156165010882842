import { useTheme } from "@mui/material"
import { RiskAssessmentGraphWrap } from "./risk-assessment-graph.style"
import { Image, Box, Typography, Label } from "@ntpkunity/controls"
import graph from "@src/assets/icons/assessment-graph.svg"
import { BasicList, Stack } from "@src/components"

export const RiskAssessmentGraph = () => {
    const theme = useTheme()

    return (
        <>
            <RiskAssessmentGraphWrap theme={theme} className="risk-assessment-graph-wrap">
                <Stack
                    paddingMd={0}
                    paddingXs={0}
                    title={"Basic Details"}
                    actionArea={
                        <Label
                            theme={theme}
                            text="Risk Tier 2"
                            state="primary"
                            textTransform="none"
                        />
                    }
                >
                    <Box theme={theme} className="graph">
                        <Image theme={theme} src={graph} alt={graph} />
                        <Box theme={theme} className="graph-text">
                            <Typography theme={theme} variant="caption" component={"small"} className="credit-score">Credit Score</Typography>
                            <Box theme={theme} display={'flex'}>
                                <Typography theme={theme} variant="h4" component={"h4"} className="text-primary">{'506'}</Typography>
                                <Typography theme={theme} variant="h4" component={"h4"} className="number">/<span>710</span></Typography>
                            </Box>
                        </Box>
                        <Box theme={theme} mt={2}>
                            <Label
                                theme={theme}
                                text="Excellent"
                                state="primary"
                                textTransform="none"
                            />
                        </Box>
                    </Box>
                    <BasicList options={[
                        {
                            title: "Credit Utilisation Ratio:",
                            subtitle: '30%',
                            textRight: true
                        },
                    ]}
                    />
                </Stack>
            </RiskAssessmentGraphWrap>
        </>
    )
}