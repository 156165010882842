import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const ComplianceCheckListTableWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.compliance-checklist-table-wrap": {
        ".data-table-wrap": {
            ".u-table-wrap": {
                ".scroll": {
                    ".scroll-hide": {
                        height: 104,
                        borderRadius: '0 20px 8px 0',
                        "@media(min-width: 1599.95px)": {
                            height: 114,
                        }
                    }
                },
                ".u-table": {
                    ".u-table-head": {
                        tr: {
                            td: {
                                "&.w-172": {
                                    minWidth: 172,
                                },
                                "&.w-244": {
                                    minWidth: 244
                                },
                                maxWidth: 400,
                            }
                        }
                    },
                    ".u-table-body": {
                        tr: {
                            td: {
                                "&.w-172": {
                                    minWidth: 172
                                },
                                "&.w-244": {
                                    minWidth: 244
                                },
                                maxWidth: 400,
                                ".custom-checkbox-wrap": {
                                    ".u-custom-control-label": {
                                        margin: 0
                                    }
                                },
                            }
                        }
                    }
                }
            }
        }
    },
}));
