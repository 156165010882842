import {
    styled,
    TableCell,
    TablePagination,
    TableRow,
    useTheme,
} from "@mui/material";
import { useState } from "react"
import { Box, Button, DataTable, Textarea, Icon, Autocomplete, Select } from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import dragIcon from "@assets/icons/dragIcon.svg"
import sendIcon from "@assets/icons/icon-send.svg"
import voiceIcon from "@assets/icons/Icon-voice.svg"
import textIcon from "@assets/icons/Icon-text.svg"
import addIcon from "@assets/icons/Icon-add.svg"


export const BusinessRulesConditionTable = ({}) => {
    const theme = useTheme();
    const [selectedMember, setSelectedMember] = useState<any>(null);
    const [selectedScope, setSelectedScope] = useState<any>('null');

    const top100Films = [
        {
          label: 'The Lord of the Rings: The Return of the King',
          id: 2003,
        },
        { label: 'The Good, the Bad and the Ugly', id: 1966 },
        { label: 'Fight Club', id: 1999 },
        {
          label: 'The Lord of the Rings: The Fellowship of the Ring',
          id: 2001,
        },
        {
          label: 'Star Wars: Episode V - The Empire Strikes Back',
          id: 1980,
        },
        { label: 'Forrest Gump', id: 1994 },
        { label: 'Inception', id: 2010 },
        {
          label: 'The Lord of the Rings: The Two Towers',
          id: 2002,
        },
        { label: "One Flew Over the Cuckoo's Nest", id: 1975 },
        { label: 'Goodfellas', id: 1990 },
        { label: 'The Matrix', id: 1999 },
        { label: 'Seven Samurai', id: 1954 },
        {
          label: 'Star Wars: Episode IV - A New Hope',
          id: 1977,
        },
        { label: 'City of God', id: 2002 },
        { label: 'Se7en', id: 1995 },
        { label: 'The Silence of the Lambs', id: 1991 },
        { label: "It's a Wonderful Life", id: 1946 },
        { label: 'Life Is Beautiful', id: 1997 },
        { label: 'The Usual Suspects', id: 1995 },
        { label: 'Léon: The Professional', id: 1994 },
        { label: 'Spirited Away', id: 2001 },
        { label: 'Saving Private Ryan', id: 1998 },
        { label: 'Once Upon a Time in the West', id: 1968 },
        { label: 'American History X', id: 1998 },
        { label: 'Interstellar', id: 2014 },
        { label: 'Casablanca', id: 1942 },
        { label: 'City Lights', id: 1931 },
        { label: 'Psycho', id: 1960 },
        { label: 'The Green Mile', id: 1999 },
        { label: 'The Intouchables', id: 2011 },
        { label: 'Modern Times', id: 1936 },
        { label: 'Raiders of the Lost Ark', id: 1981 },
        { label: 'Rear Window', id: 1954 },
        { label: 'The Pianist', id: 2002 },
        { label: 'The Departed', id: 2006 },
        { label: 'Terminator 2: Judgment Day', id: 1991 },
        { label: 'Back to the Future', id: 1985 },
        { label: 'Whiplash', id: 2014 },
        { label: 'Gladiator', id: 2000 },
        { label: 'Memento', id: 2000 },
        { label: 'The Prestige', id: 2006 },
        { label: 'The Lion King', id: 1994 },
        { label: 'Apocalypse Now', id: 1979 },
        { label: 'Alien', id: 1979 },
        { label: 'Sunset Boulevard', id: 1950 },
        {
          label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
          id: 1964,
        },
        { label: 'The Great Dictator', id: 1940 },
        { label: 'Cinema Paradiso', id: 1988 },
        { label: 'The Lives of Others', id: 2006 },
        { label: 'Grave of the Fireflies', id: 1988 },
        { label: 'Paths of Glory', id: 1957 },
        { label: 'Django Unchained', id: 2012 },
        { label: 'The Shining', id: 1980 },
        { label: 'WALL·E', id: 2008 },
        { label: 'American Beauty', id: 1999 },
        { label: 'The Dark Knight Rises', id: 2012 },
        { label: 'Princess Mononoke', id: 1997 },
        { label: 'Aliens', id: 1986 },
        { label: 'Oldboy', id: 2003 },
        { label: 'Once Upon a Time in America', id: 1984 },
        { label: 'Witness for the Prosecution', id: 1957 },
        { label: 'Das Boot', id: 1981 },
        { label: 'Citizen Kane', id: 1941 },
        { label: 'North by Northwest', id: 1959 },
        { label: 'Vertigo', id: 1958 },
        {
          label: 'Star Wars: Episode VI - Return of the Jedi',
          id: 1983,
        },
        { label: 'Reservoir Dogs', id: 1992 },
        { label: 'Braveheart', id: 1995 },
        { label: 'M', id: 1931 },
        { label: 'Requiem for a Dream', id: 2000 },
        { label: 'Amélie', id: 2001 },
        { label: 'A Clockwork Orange', id: 1971 },
        { label: 'Like Stars on Earth', id: 2007 },
        { label: 'Taxi Driver', id: 1976 },
        { label: 'Lawrence of Arabia', id: 1962 },
        { label: 'Double Indemnity', id: 1944 },
        {
          label: 'Eternal Sunshine of the Spotless Mind',
          id: 2004,
        },
        { label: 'Amadeus', id: 1984 },
        { label: 'To Kill a Mockingbird', id: 1962 },
        { label: 'Toy Story 3', id: 2010 },
        { label: 'Logan', id: 2017 },
        { label: 'Full Metal Jacket', id: 1987 },
        { label: 'Dangal', id: 2016 },
        { label: 'The Sting', id: 1973 },
        { label: '2001: A Space Odyssey', id: 1968 },
        { label: "Singin' in the Rain", id: 1952 },
        { label: 'Toy Story', id: 1995 },
        { label: 'Bicycle Thieves', id: 1948 },
        { label: 'The Kid', id: 1921 },
        { label: 'Inglourious Basterds', id: 2009 },
        { label: 'Snatch', id: 2000 },
        { label: '3 Idiots', id: 2009 },
        { label: 'Monty Python and the Holy Grail', id: 1975 },
      ];

    const handleMemberSelect = (event, newValue) => {
        setSelectedMember(newValue);
    };

    const handleSelectedScop = (event) => {
        setSelectedScope(event.target.value);
    };

    return (
        <>
            <Box theme={theme} className="chat-box" mb={2}>
                <Textarea
                    theme={theme}
                    type="text"
                    placeholder="Build Rule with AI."
                    id=""
                    fullWidth
                />
                <Box theme={theme} className="chat-options">
                    <Box theme={theme} className="chat-filters">
                        <Button
                            theme={theme}
                            defaultBtn
                            iconText={
                                <>
                                    <img src={voiceIcon} />
                                </>
                            }
                        />
                        <Button
                            theme={theme}
                            defaultBtn
                            iconText={
                                <>
                                    <img src={sendIcon} />
                                </>
                            }
                        />
                    </Box>
                </Box>
            </Box>
            <DataTableWrap theme={theme} className="data-table-wrap table-border">
                <DataTable
                    theme={theme}
                    theadChildren={
                        <>
                            <TableRow>
                                <TableCell className="action-cell fixed-cell w-42"></TableCell>
                                <TableCell className="text-body2 fw-sbold w-200">Scope</TableCell>
                                <TableCell className="text-body2 fw-sbold w-285">Parameter</TableCell>
                                <TableCell className="text-body2 fw-sbold w-px-100">Operator</TableCell>
                                <TableCell className="text-body2 fw-sbold w-285">Value</TableCell>
                                <TableCell className="w-44 p-12"></TableCell>
                                <TableCell className="w-44 p-12"></TableCell>
                            </TableRow>
                        </>
                    }
                    tbodyChildren={
                        <>
                            {Array.from({ length: 6 }).map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell className="action-cell fixed-cell w-42">
                                        <Button theme={theme} defaultBtn iconText={<><img src={dragIcon} /></>} />
                                    </TableCell>
                                    <TableCell className="w-200">
                                        <Box theme={theme} className="custom-dropdown custom-select">
                                            <Select
                                                theme={theme}
                                                disablePortal={false}
                                                onChange={handleSelectedScop}
                                                fullWidth
                                                items={[
                                                    { text: "And", value: "and" },
                                                    { text: "Or", value: "or" },
                                                ]}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell className="autocomplete-input w-285">
                                        <Autocomplete
                                            disablePortal
                                            placeholder="Add Parameter"
                                            value={selectedMember}
                                            onChange={handleMemberSelect} 
                                            theme={theme} 
                                            items={top100Films} 
                                            popupIcon={false}
                                        />
                                    </TableCell>
                                    <TableCell className="w-px-100">
                                        <Box theme={theme} className="custom-dropdown custom-select">
                                            <Select
                                                theme={theme}
                                                disablePortal={false}
                                                onChange={handleSelectedScop}
                                                items={[
                                                    { text: ">", value: "greater_than" },
                                                    { text: "<", value: "less_than" },
                                                    { text: "=", value: "equal" },
                                                ]}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell className="autocomplete-input w-285">
                                        <Autocomplete
                                            disablePortal
                                            placeholder="Add Value"
                                            value={selectedMember}
                                            onChange={handleMemberSelect} 
                                            theme={theme} 
                                            items={top100Films} 
                                            popupIcon={false}
                                        />
                                    </TableCell>
                                    <TableCell className="w-44 p-12">
                                        <Button 
                                            theme={theme}
                                            defaultBtn
                                            iconText={<><Icon name="AddIcon" /></>}
                                        />
                                    </TableCell>
                                    <TableCell className="w-44 p-12">
                                        <Button 
                                            theme={theme}
                                            defaultBtn
                                            iconText={<><Icon name="IconDelete" /></>}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    }
                />
            </DataTableWrap>
            <Box theme={theme} mt={2}>
                <Button theme={theme} primary text={"Save"} />
            </Box>
        </>

    );
};

export default BusinessRulesConditionTable;
