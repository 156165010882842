import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  DuoTab,
  Grid,
  Icon,
  Select,
  Image,
  Typography,
  CircleLoader,
} from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { useSelector } from "react-redux";
import { OwnershipDocumentWrap } from "./ownership-document.style";
import { Alert, PdfList } from "@src/components";
import pdfSample from "@assets/images/pdf-sample.png";
import { useEffect, useState } from "react";
import { TEMPLATE_ENDPOINTS } from "../../../documents-list/document-list.component";
import { AlertStatusText } from "@src/components/dashboard";
import { OwnershipDocumentProps } from "./ownership-document.props";

interface Document {
  id: number;
  created_at: string;
  updated_at: string;
  identifier: string;
  template?: string;
  document_url?: string;
  document_type: string;
}

interface DocumentNested {
  id: number;
  title?: string;
  template?: string;
  created_at: string;
  document_url?: string;
  document_type: string;
}

interface CustomerDocument {
  id: number;
  created_at: string;
  updated_at: string;
  identifier: string;
  document_name?: string;
  document_url?: string;
  document_type: string;
}

interface CustomerDocumentNested {
  id: number;
  document_name?: string;
  template?: string;
  created_at: string;
  document_url?: string;
  document_type: string;
}

function truncateFileName(fileName: String) {
  if (!fileName) return fileName;
  const dotIndex = fileName.lastIndexOf(".");
  if (dotIndex === -1) return fileName;
  const namePart = fileName.substring(0, dotIndex);
  const extension = fileName.substring(dotIndex);

  const truncatedName =
    namePart.length > 18 ? `${namePart.substring(0, 18)}...` : namePart;
  return truncatedName + extension;
}

export const OwnershipDocument = ({ title, documents, customerData }) => {
  const theme = useTheme();

  const [allDocuments, setAllDocuments] = useState<Document[]>([]);
  const [customerDocuments, setCustomerDocuments] = useState<
    CustomerDocument[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const { applicantDetails } = useSelector(
    (state: any) => state.applicantDetails
  );

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    if (documents && Array.isArray(documents)) {
      setAllDocuments(documents);
    }
  }, [documents]);

  useEffect(() => {
    if (customerData && Array.isArray(customerData)) {
      setCustomerDocuments(customerData);
    }
  }, [customerData]);

  const handleDownloadTemplate = async (template_name: string) => {
    if (!template_name) {
      console.error("Template name is required");
      return;
    }

    try {
      const response = await fetch(
        `https://flex-reporting-dev.netsolapp.io/documents/${TEMPLATE_ENDPOINTS.GETPRESIGNED_URL}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY ?? "",
          },
          body: JSON.stringify({
            key: template_name,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch template: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.template_url) {
        window.open(data.template_url, "_blank");
      } else {
        console.error("Invalid response: template_url not found");
      }
    } catch (error) {
      console.error("Error downloading template:", error);
    }
  };

  return (
    <>
      <OwnershipDocumentWrap theme={theme} className="ownership-document-wrap">
        <Box theme={theme} className="custom-dropdown" pb={3}>
          <Typography
            theme={theme}
            className="text-muted"
            variant="body2"
            component={"span"}
            display={"block"}
          >
            Document Type:
          </Typography>
          <Select
            theme={theme}
            disablePortal={false}
            items={[
              { text: "All", value: "all" },
              { text: "Funding Contracts", value: "FundingContracts" },
            ]}
            onChange={handleCategoryChange}
            value={selectedCategory}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </Box>
        {title && (
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            className="text-muted fw-medium"
            display={"block"}
            pt={0.5}
            pb={0.5}
            mb={0.5}
          >
            {selectedCategory === "all" ? "All" : "Funding Contracts"}
          </Typography>
        )}

        <Grid theme={theme} container spacing={2}>
          {selectedCategory === "all" &&
            applicantDetails?.customer_info?.customer_license &&
            applicantDetails?.customer_info?.customer_license.length !== 0 &&
            applicantDetails?.customer_info?.customer_license[0]?.back_image !==
              null && (
              <Grid item xl={3} lg={4} md={6} theme={theme}>
                <Stack
                  title={truncateFileName("License back image").toString()}
                  paddingXs={1}
                  paddingMd={1}
                  bgLight
                  actionArea={<>{/* <AlertStatusText success /> */}</>}
                >
                  <Box theme={theme} className="pdf-card">
                    <Box theme={theme} className="pdf-preview">
                      {/* <img src={pdfSample} alt="PDF Preview" /> */}
                      <Image src={pdfSample} theme={theme} aspectRatio="16:9" />
                    </Box>
                    <Box theme={theme} className="pdf-card-footer">
                      <PdfList
                        title={
                          <>
                            {truncateFileName("License back image").toString()}
                          </>
                        }
                        onClick={() => {
                          handleDownloadTemplate(
                            applicantDetails?.customer_info?.customer_license[0]
                              ?.back_image || "default.pdf"
                          );
                        }}
                        subTitle={
                          applicantDetails?.customer_info?.customer_license[0]?.created_at?.split(
                            "T"
                          )[0]
                        }
                        variant="light"
                        jpgIcon={true}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Grid>
            )}

          {selectedCategory === "all" &&
            applicantDetails?.customer_info?.customer_license &&
            applicantDetails?.customer_info?.customer_license.length !== 0 &&
            applicantDetails?.customer_info?.customer_license[0]
              ?.front_image !== null && (
              <Grid item xl={3} lg={4} md={6} theme={theme}>
                <Stack
                  title={truncateFileName("License back image").toString()}
                  paddingXs={1}
                  paddingMd={1}
                  bgLight
                  actionArea={<>{/* <AlertStatusText success /> */}</>}
                >
                  <Box theme={theme} className="pdf-card">
                    <Box theme={theme} className="pdf-preview">
                      {/* <img src={pdfSample} alt="PDF Preview" /> */}
                      <Image src={pdfSample} theme={theme} aspectRatio="16:9" />
                    </Box>
                    <Box theme={theme} className="pdf-card-footer">
                      <PdfList
                        title={
                          <>
                            {truncateFileName("License front image").toString()}
                          </>
                        }
                        onClick={() => {
                          handleDownloadTemplate(
                            applicantDetails?.customer_info?.customer_license[0]
                              ?.front_image || "default.pdf"
                          );
                        }}
                        subTitle={
                          applicantDetails?.customer_info?.customer_license[0]?.created_at?.split(
                            "T"
                          )[0]
                        }
                        variant="light"
                        jpgIcon={true}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Grid>
            )}

          {applicantDetails?.Insurance_Inforation !== null &&
            applicantDetails?.Insurance_Inforation?.insurance_documents !==
              null && (
              <Grid item xl={3} lg={4} md={6} theme={theme}>
                <Stack
                  title={truncateFileName(
                    applicantDetails?.Insurance_Inforation
                      ?.insurance_documents[0]?.name
                  )?.toString()}
                  paddingXs={1}
                  paddingMd={1}
                  bgLight
                  actionArea={<>{/* <AlertStatusText success /> */}</>}
                >
                  <Box theme={theme} className="pdf-card">
                    <Box theme={theme} className="pdf-preview">
                      {/* <img src={pdfSample} alt="PDF Preview" /> */}
                      <Image src={pdfSample} theme={theme} aspectRatio="16:9" />
                    </Box>
                    <Box theme={theme} className="pdf-card-footer">
                      <PdfList
                        title={
                          <>
                            {truncateFileName(
                              applicantDetails?.Insurance_Inforation
                                ?.insurance_documents[0]?.name
                            )?.toString()}
                          </>
                        }
                        onClick={() => {
                          handleDownloadTemplate(
                            applicantDetails?.Insurance_Inforation
                              ?.insurance_documents[0]?.document_uri ||
                              "default.pdf"
                          );
                        }}
                        subTitle={
                          applicantDetails?.Insurance_Inforation?.insurance_documents[0]?.created_at?.split(
                            "T"
                          )[0]
                        }
                        variant="light"
                        jpgIcon={
                          !applicantDetails?.Insurance_Inforation?.insurance_documents[0]?.name
                            ?.toLowerCase()
                            .endsWith(".pdf")
                        }
                        pdfIcon={applicantDetails?.Insurance_Inforation?.insurance_documents[0]?.name
                          ?.toLowerCase()
                          .endsWith(".pdf")}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Grid>
            )}

          {allDocuments && allDocuments.length > 0 ? (
            allDocuments
              .filter((doc) => doc?.template)
              .map((document: DocumentNested, i) => {
                return (
                  <Grid item xl={3} lg={4} md={6} key={i} theme={theme}>
                    <Stack
                      title={truncateFileName(
                        document?.title || "test.pdf"
                      ).toString()}
                      paddingXs={1}
                      paddingMd={1}
                      bgLight
                      actionArea={<>{/* <AlertStatusText success /> */}</>}
                    >
                      <Box theme={theme} className="pdf-card">
                        <Box theme={theme} className="pdf-preview">
                          {/* <img src={pdfSample} alt="PDF Preview" /> */}
                          <Image
                            src={pdfSample}
                            theme={theme}
                            aspectRatio="16:9"
                          />
                        </Box>
                        <Box theme={theme} className="pdf-card-footer">
                          <PdfList
                            title={
                              <>
                                {truncateFileName(
                                  document?.title || "test.pdf"
                                ).toString()}
                              </>
                            }
                            onClick={() => {
                              handleDownloadTemplate(
                                document?.template || "default.pdf"
                              );
                            }}
                            subTitle={document?.created_at?.split("T")[0]}
                            variant="light"
                            pdfIcon={true}
                          />
                        </Box>
                      </Box>
                    </Stack>
                  </Grid>
                );
              })
          ) : (
            <CircleLoader theme={theme} size="sm" />
          )}

          {selectedCategory === "all" &&
            customerDocuments &&
            customerDocuments.length > 0 &&
            customerDocuments
              ?.filter((doc) => doc.document_url !== null)
              .map((document: CustomerDocumentNested, i) => {
                const isPdf = document?.document_url
                  ? document?.document_url?.toLowerCase().endsWith(".pdf")
                  : document?.document_name?.toLowerCase().endsWith(".pdf");
                return (
                  <Grid item xl={3} lg={4} md={6} key={i} theme={theme}>
                    <Stack
                      title={
                        document?.document_name !== ""
                          ? truncateFileName(
                              document?.document_name || "test.pdf"
                            ).toString()
                          : truncateFileName(
                              document?.document_url || "test.pdf"
                            ).toString()
                      }
                      paddingXs={1}
                      paddingMd={1}
                      bgLight
                      actionArea={<>{/* <AlertStatusText success /> */}</>}
                    >
                      <Box theme={theme} className="pdf-card">
                        <Box theme={theme} className="pdf-preview">
                          {/* <img src={pdfSample} alt="PDF Preview" /> */}
                          <Image
                            src={pdfSample}
                            theme={theme}
                            aspectRatio="16:9"
                          />
                        </Box>
                        <Box theme={theme} className="pdf-card-footer">
                          <PdfList
                            title={
                              <>
                                {document?.document_name !== ""
                                  ? truncateFileName(
                                      document?.document_name || "test.pdf"
                                    ).toString()
                                  : truncateFileName(
                                      document?.document_url || "test.pdf"
                                    ).toString()}
                              </>
                            }
                            onClick={() => {
                              document?.document_name !== ""
                                ? handleDownloadTemplate(
                                    document?.document_name || "default.pdf"
                                  )
                                : handleDownloadTemplate(
                                    document?.document_url || "default.pdf"
                                  );
                            }}
                            subTitle={document?.created_at?.split("T")[0]}
                            variant="light"
                            pdfIcon={isPdf}
                            jpgIcon={!isPdf}
                          />
                        </Box>
                      </Box>
                    </Stack>
                  </Grid>
                );
              })}
        </Grid>
      </OwnershipDocumentWrap>
    </>
  );
};
