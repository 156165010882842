
import { useTheme } from "@mui/material";
import { Box, Button, Grid, Icon, Label } from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ComplianceCheckListTabs } from "../compliance-checklist-tabs/compliance-checklist-tabs.component";
import { ComplianceChecklistDialog } from "../compliance-checklist-popup/compliance-checklist-dialog.component";

export const ComplianceCheckList = ({repaymentPlan, incomingPosting}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseDialog = () => {
    setIsOpen(false);
  };
  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Stack paddingXs={2} paddingMd={2}>
        <ComplianceCheckListTabs incomingPosting={incomingPosting} repaymentPlan={repaymentPlan} />
      </Stack>
      <ComplianceChecklistDialog open={isOpen} closeDialog={handleCloseDialog} />
    </>
  );
};
