import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";
import { Padding } from "@mui/icons-material";
import cross from "@src/assets/icons/ai-cross.svg"
import frameIcon from "@assets/icons/expandedFrame.svg"

export const CDE_THEME_WRAP = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.global-theme-wrap": {
    color: theme.palette.grey[900],
    ".primary-alert": {
      svg: {
        path: {
          stroke: theme.palette.primary.main,
        },
      },
    },
    ".link": {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    ".no-padding": {
      "&.btn.with-icon.icon-only": {
        padding: 0
      },
    },
    ".fw-medium": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    ".fw-sbold": {
      fontWeight: theme.customVariables.fontWeightSemiBold + '!important',
    },
    ".fw-bold": {
      fontWeight: theme.typography.fontWeightBold,
    },
    ".text-dark": {
      color: theme.palette.grey[400],
    },
    ".text-dark-grey": {
      color: CustomColors.darkGrey + '!important'
    },
    ".text-black": {
      color: theme.palette.grey[900],
    },
    ".text-muted": {
      color: theme.palette.grey[600],
    },
    ".text-primary": {
      color: theme.palette.primary.main,
    },
    ".text-secondary": {
      color: theme.palette.text.secondary,
    },
    ".text-center": {
      textAlign: "center",
    },
    ".text-white": {
      color: theme.palette.common.white,
    },
    ".text-danger": {
      color: theme.palette.error.main,
    },
    ".text-success": {
      color: theme.palette.success.main,
    },
    ".bg-white": {
      backgroundColor: theme.palette.common.white + '!important'
    },
    ".decoration-none": {
      textDecoration: "none",
    },
    ".text-uppercase": {
      textTransform: "uppercase",
    },
    ".text-nowrap": {
      whiteSpace: 'nowrap'
    },
    ".white-space-normal": {
      whiteSpace: 'normal !important'
    },
    ".text-pre-line": {
      whiteSpace: "pre-line",
    },
    ".text-tiny": {
      fontSize: 10,
      lineHeight: "12px",
    },
    ".line-height18": {
      lineHeight: "18px",
    },
    ".w-100": {
      width: "100%",
    },
    ".mw-100": {
      maxWidth: "100%",
    },
    ".w-m-100": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    ".dFlex": {
      display: "flex",
    },
    ".alignItemsCenter": {
      alignItems: "center",
    },
    ".cp": {
      cursor: "pointer",
    },
    ".justifyContentBetween": {
      justifyContent: "space-between",
    },
    svg: {
      "&.text-primary": {
        path: {
          stroke: theme.palette.primary.main,
        },
      },
    },
    ".light-yellow": {
      backgroundColor: CustomColors.yellowLight,
    },
    ".light-purple": {
      backgroundColor: CustomColors.purpleLight,
    },
    ".light-ferozi": {
      backgroundColor: CustomColors.feroziLight,
    },
    ".light-grey": {
      backgroundColor: CustomColors.lightGrey,
    },
    ".pink-light": {
      backgroundColor: CustomColors.pinkLight,
    },
    ".light-green": {
      backgroundColor: CustomColors.greenLight,
    },
    ".bg-light": {
      ".u-form-group": {
        ".u-input-label": {
          backgroundColor: "#F7F7F7",
        },
        "&.u-date-picker": {
          ".u-form-control": {
            ".MuiInputLabel-formControl": {
              backgroundColor: "#F7F7F7",
            },
          },
        },
      },
    },

    ".u-form-group": {
      ".u-help-text": {
        textAlign: 'right'
      }
    },

    ".custom-dropdown": {
      display: "flex",
      alignItems: "center",
      flexWrap: 'wrap',
      gap: 4,
      ".u-form-group": {
        height: 20,
        ".u-form-control": {
          "&.u-select": {
            height: 20,
            ".MuiInputBase-input": {
              padding: "0px 24px 0px 0px",
              color: theme.palette.grey[900],
              fontWeight: theme.customVariables.fontWeightSemiBold,
              fontSize: theme.typography.body2.fontSize,
              lineHeight: theme.typography.body2.lineHeight,
            },
            ".MuiSelect-icon": {
              right: 0,
              path: {
                stroke: "#6C6E7A",
              },
            },
            fieldset: {
              border: "none",
            },
          },
        },
      },
      "&.custom-select": {
        display: 'block',
        ".u-form-group": {
          height: 20,
          ".u-form-control": {
            "&.u-select": {
              height: 20,
              ".MuiInputBase-input": {
                padding: "0px 24px 0px 0px",
                color: CustomColors.darkGrey,
                fontWeight: theme.typography.body2.fontWeight,
                fontSize: theme.typography.body2.fontSize,
                lineHeight: theme.typography.body2.lineHeight,
              },
              ".MuiSelect-icon": {
                // right: 0,
                path: {
                  stroke: "#6C6E7A",
                },
              },
              fieldset: {
                border: "none",
              },
            },
          },
        },
      }
    },


    ".desktop-disable": {
      [theme.breakpoints.up("md")]: {
        ".u-accordian-wrap": {
          ".u-accordion-header": {
            cursor: "text",
            ".MuiAccordionSummary-expandIconWrapper": {
              display: "none",
            },
          },
          ".MuiCollapse-root.MuiCollapse-vertical ": {
            minHeight: "auto !important",
            height: "auto !important",
            visibility: "visible !important",
          },
        },
      },
    },
    ".stack-mobile": {
      [theme.breakpoints.up("md")]: {
        padding: 0,
        backgroundColor: "transparent",
        border: "none",
        ".stack-header": {
          display: "none",
        },
      },
    },
    ".MuiSkeleton-root": {
      transformOrigin: "0 0",
      transform: "scale(1, 1)",
      borderRadius: theme.shape.borderRadius,
    },
    ".MuiDivider-root.divider": {
      borderColor: theme.palette.divider,
      margin: "24px 0",
    },

    ".label-wrap": {
      borderRadius: 8,
      ".label-bg": {
        padding: "4px 8px",
        "&.bg-primary": {
          backgroundColor: theme.palette.primary.light + '!important',
          '.label-text': {
            color: theme.palette.primary.main + '!important'
          }
        },
        "&.bg-success": {
          backgroundColor: theme.palette.common.white,
          '.label-text': {
            color: theme.palette.primary.main + '!important'
          }
        },
        "&.bg-info": {
          backgroundColor: theme.palette.grey[50],
          '.label-text': {
            color: theme.palette.grey[300] + '!important'
          }
        },
        ".label-text": {
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body1.lineHeight,
          fontWeight: theme.typography.fontWeightMedium,
          "@media only screen and (min-width: 1599.5px)": {
            fontSize: theme.typography.body1.fontSize,
          },
        },
      },
    },
    a: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      '&.text-link': {
        fontSize: theme.typography.body2.fontSize,
      },
      "@media(max-width: 1599.95px)": {
        fontSize: theme.typography.body2.fontSize
      }
    },

    ".selection-button-wrap": {
      ".ctrl-btn": {
        ".MuiTypography-root": {
          fontSize: theme.typography.caption.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          "@media(min-width: 1599.95px)": {
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
          }
        },
        "&.state-unavailable": {
          backgroundColor: "#DADBDE",
          color: "#474A59"
        }
      }
    },

    ".state-unavailable": {
      backgroundColor: "#DADBDE",
      color: CustomColors.bodyColor
    },

    ".stack-wrap": {
      "&.recommended-finance-structure": {
        ".basic-list-wrap": {
          paddingTop: 0,
          gap: 8,
          ".list": {
            gap: 16,
            ".MuiTypography-root": {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: CustomColors.bodyColor,
              "&.title": {
                minWidth: '50%',
                padding: 8,
                fontSize: theme.typography.body2.fontSize,
                fontWeight: theme.typography.fontWeightMedium,
              }
            },
            ".action-area": {
              ".u-form-group": {
                ".u-form-control": {
                  paddingLeft: 8,
                  fontSize: theme.typography.body2.fontSize,
                  ".adornment-icon": {
                    marginRight: 0,
                    color: "#474A59",
                  },
                  ".MuiInputBase-input": {
                    color: "#474A59",
                    paddingLeft: 4
                  }
                }
              },
            }
          }
        }
      }
    },

    ".TabsUnstyled-root": {
      "&.TabsUnstyled-horizontal": {
        ".tab-list-underline": {
          marginBottom: 0,
          border: 'none',
          backgroundColor: '#F5F5F7',
          borderRadius: 8,
          padding: 4,
          ".underline-varient-btn": {
            padding: '8px 12px',
            color: '#474A59',
            fontWeight: theme.typography.fontWeightMedium,
            "&.Mui-selected": {
              color: '#12131A',
              fontWeight: theme.customVariables.fontWeightSemiBold,
            }
          }
        },
        '.tab-panel-underline': {
          padding: 16
        }
      }
    },

    ".collapse-wrap": {
      ".u-accordian-wrap": {
        marginBottom: 0,
      },
      ".u-accordion-header": {
        padding: 0,
        minHeight: "auto",
        ".MuiAccordionSummary-content": {
          margin: 0,
          alignItems: "center",
          ".main-title": {
            fontSize: theme.typography.h4.fontSize,
            lineHeight: theme.typography.h4.lineHeight,
            ".title-with-icon": {
              width: 24,
              height: 24,
            },
          },
          ".action-btn": {
            ".btn": {
              "&.icon-only": {
                padding: 0,
              },
            },
          },
          [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
            gap: 16,
            ".action-btn": {
              width: "100%",
            },
          },
        },
        ".MuiAccordionSummary-expandIconWrapper": {
          marginLeft: 16,
        },
        [theme.breakpoints.down("md")]: {
          alignItems: "flex-start",
        },
      },
      ".u-accordian-body": {
        padding: "24px 0px 0 0",
        border: "none",
      },
      "&.list-collapse": {
        ".u-accordion-header": {

          ".main-title": {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            fontWeight: theme.typography.fontWeightRegular,
            display: "flex",
            alignItems: "flex-start",
            gap: 16,
            svg: {
              flexShrink: 0,
            },
          },
          [theme.breakpoints.down("md")]: {
            padding: "0 16px",
          },
        },
        ".u-accordian-body": {
          padding: "16px 0px 0 0",
        },
        ".MuiAccordionSummary-content": {
          [theme.breakpoints.down("md")]: {
            flexWrap: "nowrap",
            alignItems: "flex-start",
            ".action-btn": {
              width: "auto",
            },
          },
        },
        [theme.breakpoints.down("md")]: {
          padding: "24px 8px",
        },
        "&.with-border": {
          ".u-accordian-wrap": {
            borderBottom: "1px solid" + theme.palette.divider,
            paddingBottom: 16,
            marginBottom: 16,
            "&:last-child": {
              border: "none",
              paddingBottom: 0,
              marginBottom: 0,
            },
          },
        },
      },
    },
    ".accordion-box": {
      borderRadius: theme.shape.borderRadius,
      ".u-accordian-wrap": {
        padding: 8,
        marginBottom: 4,
        "&:last-child": {
          marginBottom: 0,
        },
        ".u-accordion-header": {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: "#f7f7f7",
          padding: "12px 16px",
          ".main-title": {
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            fontWeight: theme.customVariables.fontWeightSemiBold,

            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          },
        },
        ".u-accordian-body": {
          padding: "0 8px",
        },
        [theme.breakpoints.down("md")]: {
          padding: "0 8px",
        },
      },
    },
    ".accordian-mini-wrap": {
      ".u-accordian-wrap": {
        padding: 0,
        marginBottom: 16,
        "&:last-child": {
          marginBottom: 0,
        },
        ".u-accordion-header": {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: "#f7f7f7",
          padding: "8px",
          ".main-title": {
            fontSize: theme.typography.caption.fontSize,
            lineHeight: theme.typography.caption.lineHeight,
            fontWeight: theme.typography.fontWeightMedium,

            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            svg: {
              width: 16.5,
              height: 16.5
            }
          }
        },
        ".u-accordian-body": {
          padding: "0 8px",
        },
        [theme.breakpoints.down("md")]: {
          padding: "0 8px",
        },
      },
    },
    ".accordian-table-wrap": {
      ".u-accordian-wrap": {
        padding: 0,
        marginBottom: 16,
        "&:last-child": {
          marginBottom: 0,
        },
        ".u-accordion-header": {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: "transparent",
          minHeight: 'auto',
          padding: "0px",
          ".MuiAccordionSummary-content": {
            margin: 0,
            flexGrow: 0,
            ".main-title": {
              fontSize: theme.typography.caption.fontSize,
              lineHeight: theme.typography.caption.lineHeight,
              fontWeight: theme.typography.fontWeightMedium,

              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            },
          },

          ".MuiAccordionSummary-expandIconWrapper": {
            svg: {
              width: 16.5,
              height: 16.5
            }
          }
        },
        ".u-accordian-body": {
          padding: "0 8px",
        },
        [theme.breakpoints.down("md")]: {
          padding: "0 8px",
        },
      },
    },
    ".accordion-graph-wrap": {
      ".u-accordian-wrap": {
        backgroundColor: '#F5F5F7',
        borderRadius: 8,
        marginBottom: 8,
        transition: '0.3s',
        "&:last-child": {
          marginBottom: 0,
        },
        ".u-accordion-header": {
          padding: 0,
          minHeight: 40,
          position: 'relative',
          ".MuiAccordionSummary-content": {
            margin: 0,
            ".main-title": {
              width: '100%'
            }
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            position: 'absolute',
            right: 8,
            top: 10,
            svg: {
              display: 'none'
            },
            "&:before": {
              content: `url(${frameIcon})`,
              display: 'block',
              width: '24px;',
              height: '24px;'
            },
            "&.Mui-expanded": {
              right: 10,
              top: 5,
              "&:before": {
                content: "''",
                width: 0,
                height: 0
              },
              "&:after": {
                content: `url(${cross})`,
                display: 'block',
                width: '24px;',
                height: '24px;',
                margin: '8px 0px 0px 4px'
              },
            }
          },
        },
        "&.Mui-expanded": {
          padding: 8
        },
        ".u-accordian-body": {
          border: 'none',
          marginTop: 8,
          padding: 16,
          img: {
            width: '100%'
          }
        },
      },
    },

    ".styled-tooltip": {
      ".MuiTooltip-popper": {
        ".MuiTooltip-tooltip": {
          backgroundColor: '#474A59',
          marginTop: 4,
          fontSize: theme.typography.body2.fontSize,
          ".MuiTooltip-arrow": {
            display: 'none'
          }
        }
      }
    },

    ".scrollable-tabs-wrap": {
      ".u-scrollable-tabs": {
        ".tab-list-underline": {
          border: 'none',
          ".MuiTabs-scroller": {
            ".MuiTabs-flexContainer": {
              gap: 24,
              borderRadius: 8,
              padding: '4px 16px',
              backgroundColor: '#F5F5F7',
              ".MuiButtonBase-root": {
                padding: '8px 0',
                fontWeight: theme.typography.fontWeightMedium,
                color: CustomColors.darkGrey,
                "&.Mui-selected": {
                  color: CustomColors.bodyColor,
                  fontWeight: theme.customVariables.fontWeightSemiBold
                },
                "&:hover": {
                  "&::before": {
                    backgroundColor: 'transparent'
                  }
                }
              }
            }
          }
        },
        ".u-tab-panel": {

        }
      }
    },

    ".gradient-text": {
      background: 'linear-gradient(100deg, #232BF6 -4%, #FF3B30 120%)',
      backgroundClip: 'text',
      '-webkitBackgroundClip': 'text',
      '-webkitTextFillColor': 'transparent',
    },

    "@media only screen and (min-width: 1599.5px)": {
      ".text-body1": {
        fontSize: theme.typography.body2.fontSize,
      },
      ".text-body2": {
        fontSize: theme.typography.body2.fontSize,
      },
      ".caption-text": {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight
      },
      ".text-h4": {
        fontSize: theme.typography.h4.fontSize
      }
    },
    ".text-body2": {
      ".stack-header": {
        ".title": {
          ".MuiTypography-root": {
            fontSize: theme.typography.body2.fontSize + '!important',
            fontWeight: theme.typography.fontWeightMedium + '!important'
          }
        }
      }
    },
    "@media only screen and (min-width: 1599.4px)": {
      ".label-wrap": {
        ".label-bg": {
          padding: '8px 16px',
        },
      },
    },

    ".u-custom-autocomplete": {
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
}));
