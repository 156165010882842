import { useTheme } from "@mui/material"
import { AddStickyNoteWrap } from "./add-sticky-note.style"
import img from "@src/assets/icons/addNotesIcon.svg"
import { Typography, Box, Button, Icon } from "@ntpkunity/controls";
import { clsx } from "clsx"
import { useDispatch } from "react-redux";
import { openNotesPopup } from "@src/components/redux/actions/myNotesActions";

export const AddStickyNote = ({
  handleAddStickyNote,
  title
}: {
  handleAddStickyNote: () => void;
  title?: string
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // const handleAddNoteClick = () => {
  //     dispatch(openNotesPopup());
  // };

  return (
    <>
      <AddStickyNoteWrap
        theme={theme}
        className="add-sticky-note-wrap"
        onClick={handleAddStickyNote}
      >
        <Icon name="AddIcon" />
        <Typography
          theme={theme}
          variant="body2"
          component={"span"}
          className="add-note"
        >
          {title}
        </Typography>
      </AddStickyNoteWrap>
    </>
  );
};