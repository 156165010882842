import { useTheme } from "@mui/material";
import { StickyNotesListWrap } from "./sticky-notes-list.style";
import { StickyNotes } from "../sticky-notes/sticky-notes.component";
import { clsx } from 'clsx'

export const StickyNotesList = ({ stickyNotesData }) => {
    const theme = useTheme();

    const extractColor = (comment) => {
        const match = comment.match(/#color=([\w-]+)/);
        return match ? match[1] : "light-yellow";
    };

    const extractTitle = (comment) => {
        const match = comment.match(/#priority=([\w\s-]+)/);
        // return match ? "Broker" : match[1];
        return match ? match[1] : "Sticky Note";
    };

    return (
        <>
            <StickyNotesListWrap theme={theme} className="sticky-notes-list-wrap"   >
                {stickyNotesData.map((note, index) => {
                    const parsedComment = note.comment.replace(/#\w+=\S+/g, "").trim();
                    const color = extractColor(note.comment);
                    const title = extractTitle(note.comment);

                    return (
                        <StickyNotes
                            key={index}
                            title={title}
                            subTitle={parsedComment}
                            color={color}
                />
                    );
                })}
            </StickyNotesListWrap>
        </>
    );
};