import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const RiskAssessmentDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.asset-detail-dialog-wrap": {
    ".dialog-wrap": {
      ".MuiDialog-root": {
        "&.u-dialog-xl": {
          ".MuiDialog-paper": {
            maxWidth: "1462px",
            "@media only screen and (min-width: 1800px)": {
              maxWidth: '1555px'
            }
          },
        },
      }
    }
  },
}));
