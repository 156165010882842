import { useTheme } from "@mui/material";
import { Accordion, Box, DuoTab, Dialog, Typography, Label, Icon } from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { VersionResponseStackWrap } from "./version-response-stack.style"
import { useSelector } from "react-redux";
import { Alert, Stack } from "@src/components"
import { VersionResponseStackProps } from "./version-response-stack.pops"
import successIcon from "@assets/icons/success-icon.svg"

export const VersionResponseStack = ({ title, subTitle, version, variant, actionArea }:VersionResponseStackProps) => {
    const theme = useTheme();

    return (
        <>
            <VersionResponseStackWrap theme={theme} className="version-response-stack-wrap">
                <Stack bgLight paddingMd={2} paddingXs={2}>
                    <Box theme={theme} display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={1}>
                        <Box theme={theme} display={'flex'} gap={0.5}>
                            {title && 
                                <Typography theme={theme} variant="caption" component={'span'} className="text-muted line-height18 text-nowrap">{title}</Typography>
                            }
                            {subTitle &&
                                <Typography theme={theme} variant="caption" component={'span'} className="fw-medium line-height18">{subTitle}</Typography>
                            }
                        </Box>
                        {variant &&
                            <img src={successIcon} />
                        }
                        {actionArea}
                    </Box>
                    {version &&
                        <Typography theme={theme} variant="caption" component={'span'} className="text-muted text-tiny">Version {version}</Typography>
                    }
                </Stack>
            </VersionResponseStackWrap>
        </>
    );
};
