// api.ts
import axios from "axios";

const base_url = process.env.REACT_APP_API_URL;

export type ModuleName =
    | "swot"
    | "analyzer"
    | "asset_researcher"
    | "applicant_researcher"
    | "financial_researcher"
    | "credit_memo_researcher";

export interface ModuleResponse {
    data: any;
}

const moduleCache: Record<string, ModuleResponse> = {};

/**
 * Fetches data for the provided workflowInstanceId and module.
 */
export const fetchModuleData = async (
    workflowInstanceId: string,
    moduleName: ModuleName
): Promise<ModuleResponse> => {
    const cacheKey = `${workflowInstanceId}_${moduleName}`;
    if (moduleCache[cacheKey]) {
        return moduleCache[cacheKey];
    }
    try {
        const response = await axios.get(`${base_url}/check/api/v1/get-data/`, {
            params: {
                id: workflowInstanceId,
                module: moduleName,
            },
            headers: {
                Accept: "application/json",
            },
        });
        moduleCache[cacheKey] = response.data;
        return response.data;
    } catch (error) {
        console.error(`Error fetching ${moduleName} data:`, error);
        throw error;
    }
};
