import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const StickyNotesWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.sticky-notes-wrap": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignSelf: 'stretch',
    borderRadius: theme.shape.borderRadius,
    padding: 8,
    gap: 8,
    backgroundColor: theme.palette.grey[50],
    "&.light-yellow": {
      backgroundColor: CustomColors.yellowLight,
      ".notes-head": {
        backgroundColor: CustomColors.yellow
      }
    },
    "&.light-ferozi": {
      backgroundColor: CustomColors.feroziLight,
      ".notes-head": {
        backgroundColor: CustomColors.ferozi
      }
    },
    "&.light-purple": {
      backgroundColor: CustomColors.purpleLight,
      ".notes-head": {
        backgroundColor: CustomColors.purple
      }
    },
    ".notes-head":{
        backgroundColor: theme.palette.grey[400],
        padding: '4px 8px',
        borderRadius: theme.shape.borderRadius,
        textAlign: 'center',
        width: '100%',
        ".title": {
          "@media only screen and (max-width: 1599.95px)": {
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.fontWeightMedium
          },
        },
    },
    ".notes-body": {
        display: 'flex',
        minHeight: 60,
        maxHeight: 170,
        padding: '0px 4px',
        alignItems: 'flex-start',
        gap: '10px',
        alignSelf: 'stretch',
      ".subTitle": {
        padding: '0 4px',
        display: '-webkit-box',
        transform: 'rotate(0.686deg)',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': '7',
        flex: '1 0 0',
        overflow: 'hidden',
        "@media only screen and (max-width: 1599.95px)": {
          fontSize: theme.typography.body2.fontSize
        },
      },
    },
    
    ".notes-footer": {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        padding: '4px 0',
        gap: 8,
        ".MuiButtonBase-root": {
            "&.icon-only": {
                padding: 0
            }
        }
    }
  },
}));
