import { useTheme } from "@mui/material";
import { Select, Grid, Textarea, Box, Button, Icon } from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { AddStip } from "../add-stip/add-stip.component";
import { addDecisionRequest, fetchDecisionRequest } from "@src/components/redux/actions/decisionActions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deleteStip } from "@src/components/redux/actions/stipActions";
import { useParams } from "react-router-dom";
import { retrieveWorkqueueRequest } from "@src/components/redux/actions/workQueueActions";
import { AddStipulation } from "./add-stipulation/add-stipulation.component"
import { FinalDecision } from "./final-decision/final-decision.component"

export const Decision = ({ setActiveTab }) => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { reference_number } = useParams();

  const assignedRowId = useSelector((state: any) => state.workQueue.assignedRowId);
  const workQueues = useSelector((state: any) => state.workQueue.workQueues);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const currentRecord = workQueues.find(
      (item) => item.reference_number === reference_number
    );

    if (currentRecord) {
      const status = currentRecord.status?.toLowerCase();
      if (status === "approved" || status === "declined") {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [workQueues, reference_number]);

  const stipulations = useSelector((state: any) => state.stip.stips);

  const [formState, setFormState] = useState({
    credit_decision_status: "",
    commentType: "",
    comments: "",
  });

  const handleInputChange = (key, value) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  const handleAddDecision = () => {
    if (!formState.credit_decision_status) {
      alert("Please select a decision type and provide a comment.");
      return;
    }

    const payload = {
      status: formState.credit_decision_status,
      comments: formState.comments || "",
      stipulations: stipulations.map((stip) => ({
        stipulation_code: stip.stipulationType,
        stipulation_description: stip.recommendation,
        stipulation_comments: stip.comments || "",
      })),
    };

    dispatch(addDecisionRequest(assignedRowId, payload));
    dispatch(retrieveWorkqueueRequest(reference_number));
    setTimeout(() => setActiveTab(1), 1000);
    setTimeout(() => {
      dispatch(fetchDecisionRequest(assignedRowId));
    }, 1000);

    setTimeout(() => setActiveTab(1), 1500);
  };

  return (
    <>
      <Grid theme={theme} container rowSpacing={0} columnSpacing={0} mt={0}>
        <Grid item xs={12} md={5} theme={theme}>
          <AddStipulation />
        </Grid>
        <Grid item xs={12} md={7} theme={theme}>
          <FinalDecision setActiveTab={setActiveTab} />
        </Grid>
      </Grid>
    </>
  );
};