import { useTheme } from "@mui/material";
import { MyNotesWrap } from "./my-notes.style";
import { Stack } from "@src/components/stack";
import {
  Button,
  Icon,
  Box,
  Typography,
  Select,
  Textarea,
  Checkbox,
  DateRange,
  CircleLoader,
  Input,
} from "@ntpkunity/controls";
import { MyNotesList } from "../my-notes-list/my-notes-list.component";
import { useEffect, useState } from "react";
import { StickyNotesPopup } from "@src/components/dashboard/sticky-notes-stack/sticky-notes-popup/sticky-notes-popup.component";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import {
  createNoteRequest,
  fetchMyNotesRequest,
} from "@src/components/redux/actions/myNotesActions";
import { useParams } from "react-router-dom";

export const MyNotes = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { reference_number } = useParams();

  const { myNotesData, loading, error } = useSelector(
    (state: any) => state.myNotes
  );

  const stickyNotesData = myNotesData.filter((note) =>
    note.comment.includes("#isSticky=true")
  );

  useEffect(() => {
    if (reference_number) {
      dispatch(fetchMyNotesRequest(reference_number));
    }
  }, [reference_number, dispatch]);

  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("general");
  const [note, setNote] = useState<any>({
    title: "",
    comment: "",
    isSticky: false,
    priority: "mid",
    color: "light-yellow",
  });

  const handleInputChange = (field, value) => {
    setNote((prev) => ({ ...prev, [field]: value }));
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handlePriorityChange = (event) => {
    setNote((prev) => ({ ...prev, priority: event.target.value }));
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleStickyChange = () => {
    setNote((prev) => ({ ...prev, isSticky: !prev.isSticky }));
  };

  const handleColorChange = (color) => {
    setNote((prev) => ({ ...prev, color }));
  };
  useEffect(() => {
    if (reference_number) { 
      dispatch(fetchMyNotesRequest(reference_number));
    }
  }, [reference_number, dispatch]);

  const handleSaveNote = () => {
    if (!note.comment.trim()) return;

    const formattedComment = `${note.comment} #title=${note.title || "N/A"
      } #priority=${note.priority || "N/A"} #color=${note.color || "N/A"
      } #isSticky=${note.isSticky}
    `.trim();

    dispatch(
      createNoteRequest({
        comment: formattedComment,
        category: "General",
        order_reference_id: reference_number,
      })
    );

    setNote({
      title: "",
      comment: "",
      priority: "mid",
      isSticky: false,
      color: "light-yellow",
    });

    setTimeout(() => {
      dispatch(fetchMyNotesRequest(reference_number));
    }, 1000);
  };

  function parseComment(comment) {
    return comment.replace(/#\w+=\S+/g, "").trim();
  }

  return (
    <>
      <MyNotesWrap theme={theme} className="my-notes-wrap">
        <Stack bgLight paddingMd={2} paddingXs={2} fullWidth>
          <Stack
            paddingMd={2}
            paddingXs={2}
            className="my-notes-stack"
            title="My Notes"
            fullWidth
          >
            {/* Select Priority */}
            <Box theme={theme} className="custom-dropdown">
              <Typography
                theme={theme}
                className="text-muted"
                variant="body2"
                component={"span"}
                display={"block"}
              >
                Select Priority:
              </Typography>
              <Select
                theme={theme}
                disablePortal={false}
                items={[
                  { text: "High Priority", value: "high" },
                  { text: "Mid Priority", value: "mid" },
                  { text: "Low Priority", value: "low" },
                ]}
                onChange={handlePriorityChange}
                value={note.priority}
              />
            </Box>

            {/* Sticky Notes Input Section */}
            <Box
              theme={theme}
              className={clsx("add-sticky-notes", { clicked: open === true })}
            >
              <Box theme={theme} className="add-title">
                <Box theme={theme} className="input-title">
                  <Input theme={theme} type="text" placeholder="Add Title" onChange={(e) => handleInputChange("title", e)}
                    value={note.title} />
                </Box>

                {/* Select Category */}
                <Box theme={theme} className="custom-dropdown">
                  <Typography
                    theme={theme}
                    className="text-muted"
                    variant="body2"
                    component={"span"}
                    display={"block"}
                  >
                    Select Category:
                  </Typography>
                  <Select
                    theme={theme}
                    disablePortal={false}
                    items={[
                      { text: "Internal Note", value: "internal" },
                      { text: "General Note", value: "general" },
                      { text: "Decision Note", value: "decision" },
                      { text: "Dealer Note", value: "dealer" },
                    ]}
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                  />
                </Box>
              </Box>

              {/* Textarea for Note */}
              <Textarea
                theme={theme}
                type="text"
                rows={2}
                placeholder="Type note here.."
                fullWidth
                onFocus={handleClick}
                onChange={(e) => handleInputChange("comment", e)}
                value={note.comment}
                id={""}
              />
            </Box>

            {/* Sticky Checkbox and Color Selection */}
            <Box theme={theme} display={"flex"} alignItems={"center"} gap={5}>
              <Checkbox
                theme={theme}
                label={"Mark as Sticky Note"}
                checkBoxChecked={note.isSticky}
                onChange={handleStickyChange}
              />

              {/* Color Picker */}
              {note.isSticky && <Box theme={theme} className="select-priority">
                <Typography
                  theme={theme}
                  variant="body2"
                  component={"span"}
                  className="select fw-sbold"
                >
                  Select Color:
                </Typography>
                <Box theme={theme} className="priority">
                  {["light-yellow", "light-purple", "light-ferozi"].map(
                    (color) => (
                      <Box
                        key={color}
                        theme={theme}
                        className={clsx("clr-picker", color, {
                          selected: note.color === color,
                        })}
                        onClick={() => handleColorChange(color)}
                      />
                    )
                  )}
                </Box>
              </Box>}
            </Box>

            {/* Save Button */}
            <Button
              theme={theme}
              primary
              text={"Save Note"}
              onClick={handleSaveNote}
              //   disabled={creating}
            >
              {/* {creating ? (
                <CircleLoader theme={theme} size="sm" />
              ) : (
                "Save Note"
              )} */}
            </Button>
          </Stack>

          {/* Notes List with Loading/Error Handling */}
          <Box theme={theme} className="scrollable-container">
            <Box theme={theme} className="notes-listing">
              {loading ? (
                <CircleLoader theme={theme} size="sm" />
              ) : (
                  <MyNotesList myNotesData={myNotesData.map(note => ({
                    ...note,
                    comment: parseComment(note.comment)
                  }))} />

              )}
            </Box>
          </Box>
        </Stack>
      </MyNotesWrap>
    </>
  );
};
