import { useTheme } from "@mui/material";
import { PageHeader, Stack } from "@src/components";
import { ButtonGroup } from "@src/components/button-group";
import { WorkqueueFilters } from "@src/components/work-queue";
import { LayoutWithSideNavComponent } from "@src/layouts";
import { PageContent } from "./business-rules-page.style";
import { BusinessRulesTableStack, CreateNewBusinessRule } from "@src/components/configurations/index";
import { Icon } from "@ntpkunity/controls"
import { useState } from "react";

export const BusinessRulesPage = () => {
    const theme = useTheme();
    const [open, setOpened] = useState(false);

    const handleOpenDialog = () => setOpened(true);
    const handleCloseDialog = () => setOpened(false);

    return (
        <LayoutWithSideNavComponent theme={theme}>
            <PageContent theme={theme} className="page-content">
                <Stack paddingXs={2} paddingMd={2}>
                    <PageHeader
                        title="Business Rules"
                        actionArea={
                            <>
                                <a href="javascript:void(0)" className="text-primary primary-alert dFlex alignItemsCenter" onClick={handleOpenDialog}>
                                    <Icon name="AddIcon" /> Add Business Rule
                                </a>
                            </>
                        }
                    />
                </Stack>
                <CreateNewBusinessRule />
                <BusinessRulesTableStack />
            </PageContent>
        </LayoutWithSideNavComponent>
    );
};