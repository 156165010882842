import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { keyframes, lineHeight } from "@mui/system";

export const FinanceStructureModifyDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.finance-structure-modify-popup-wrap": {
    padding: 8,
    ".dialog-wrap": {
      ".MuiDialog-root": {
        "&.u-dialog-md": {
          ".MuiDialog-paper": {
            maxWidth: "1104px",
          },
        },
      }
    },
  },
}));
