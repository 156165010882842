import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import img from "@src/assets/icons/ai-expand-icon.svg"
import cross from "@src/assets/icons/ai-cross.svg"
import { CustomColors } from "@src/styles";

export const AiAssistantListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.ai-accordion-wrap": {
    marginTop: 16,
    height: 'calc(100vh - 413px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    ".u-accordian-wrap": {
        backgroundColor: '#F5F5F7',
        borderRadius: 8,
        padding: 8,
        marginBottom: 8,
      "&:last-child": {
        marginBottom: 0,
      },
      ".u-accordion-header": {
        padding: 0,
        minHeight: 40,
        ".MuiAccordionSummary-content": {
          margin: 0
        },
        ".MuiAccordionSummary-expandIconWrapper": {
          svg: {
            display: 'none'
          },
          "&:before": {
            content: `url(${img})`,
            display: 'block',
            width: '24px;',
            height: '24px;'
          },
          "&.Mui-expanded": {
            "&:before": {
              content: "''",
              width: 0,
              height: 0
            },
            "&:after": {
              content: `url(${cross})`,
              display: 'block',
              width: '24px;',
              height: '24px;',
              margin: '8px 0px 0px 4px'
            },
          }
        },
        
      },
      ".u-accordian-body": {
        border: 'none',
        marginTop: 8,
        padding: 0
      },
    },
    ".accordion-box": {
      "&.ai-accordion-child": {
        ".u-accordian-wrap": {
          backgroundColor: '#F5F5F7',
          borderRadius: 8,
          padding: 0,
          marginBottom: 8,
        "&:last-child": {
          marginBottom: 0,
        },
        ".u-accordion-header": {
          padding: '8px 12px',
          minHeight: 0,
          ".MuiAccordionSummary-content": {
            margin: 0,
            ".main-title": {
              fontSize: theme.typography.caption.fontSize,
              lineHeight: theme.typography.caption.lineHeight,
              fontWeight: theme.typography.fontWeightMedium,
              color: '#474A59',
            }
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            svg: {
              display: 'block'
            },
            "&:before": {
              content: 'inherit'
            },
            "&.Mui-expanded": {
              "&:before": {
                content: 'inherit'
              },
              "&:after": {
                content: 'inherit'
              },
            }
          },
          
        },
        ".u-accordian-body": {
          border: 'none',
          marginTop: 8,
          padding: 0,
        },
      },
      }
    }
  }
}));