import { TableCell, TableRow, useTheme } from "@mui/material";
import { Typography, Grid, Dialog, Box, DuoTab, Icon, Textarea, DataTable, Button, Input, Checkbox } from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { Stack } from "@src/components/stack";
import { DataTableWrap } from "@src/styles/data-table-wrap";
import { ComplianceCheckListTableWrap } from "./compliance-checklist-table.style";

export const ComplianceCheckListTable = () => {
    const theme = useTheme();

    const columns = [
        { key: "DocumentType", label: "Serial Number", className: 'w-172 text-body1 fw-medium' },
        { key: "DocumentName", label: "Checklist", className: 'w-244 text-body1 fw-medium' },
        { key: "UploadedBy", label: "Verified", className: 'w-172 text-body1 fw-medium' },
        { key: "Timestamp", label: "Waived", className: 'w-172 text-body1 fw-medium' },
        { key: "Timestamp", label: "Comment", className: 'text-body1 fw-medium' },
    ];

    return (
        <>
            <ComplianceCheckListTableWrap theme={theme} className="compliance-checklist-table-wrap">
                <DataTableWrap theme={theme} className="data-table-wrap">

                    <DataTable
                        theme={theme}
                        theadChildren={
                            <>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.key} className={column.className}>
                                            <Box theme={theme} className="table-order">
                                                {column.label}
                                                <Button
                                                    theme={theme}
                                                    defaultBtn
                                                    iconText={<Icon name="AscDescIcon" />}
                                                    onClick={() => { }}
                                                />
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableRow>

                                <TableRow className="filters-row">
                                    {[...Array(5)].map((_, index) => (
                                        <TableCell key={index} className='w-172'>
                                            <Box theme={theme} className="table-filter">
                                                <Input
                                                    theme={theme}
                                                    fullWidth={true}
                                                    type={"text"}
                                                    startAdornment=""
                                                    endAdornment=""
                                                    id=""
                                                    placeholder={"Search..."}
                                                />
                                                <Button
                                                    theme={theme}
                                                    defaultBtn
                                                    iconText={<Icon name="IconFilter" />}
                                                    onClick={() => {
                                                    }}
                                                />
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </>
                        }
                        tbodyChildren={
                            <>
                                {[...Array(10)].map((_, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='w-172'>00{index + 1}</TableCell>
                                        <TableCell className='w-244'>Verified Contract Document</TableCell>
                                        <TableCell className='w-172'>
                                            <Box theme={theme} display={'flex'} justifyContent={'center'}>
                                                <Checkbox theme={theme} label={undefined} />
                                            </Box>
                                        </TableCell>
                                        <TableCell className='w-172'>
                                            <Box theme={theme} display={'flex'} justifyContent={'center'}>
                                                <Checkbox theme={theme} label={undefined} />
                                            </Box>
                                        </TableCell>
                                        <TableCell>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</TableCell>
                                    </TableRow>
                                ))}
                            </>
                        }
                    />

                </DataTableWrap>
            </ComplianceCheckListTableWrap>
        </>
    )
}