import { useTheme } from "@mui/material";
import { MyNotesWrap } from "./my-notes.style";
import { Stack } from "@src/components/stack";
import {
  Button,
  Icon,
  Box,
  Typography,
  Select,
  Textarea,
  Checkbox,
  DateRange,
  CircleLoader,
  Input,
} from "@ntpkunity/controls";
import { MyNotesList } from "../my-notes-list/my-notes-list.component";
import { useEffect, useState } from "react";
import { StickyNotesPopup } from "@src/components/dashboard/sticky-notes-stack/sticky-notes-popup/sticky-notes-popup.component";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import {
  createNoteRequest,
  fetchMyNotesRequest,
} from "@src/components/redux/actions/myNotesActions";
import { useParams } from "react-router-dom";
import calendarIcon from "@assets/icons/calendarIcon.svg";
import upAndDownIcon from "@assets/icons/upDownArrow.svg";
import resetIcon from "@assets/icons/resetIcon.svg";
import searchIcon from "@assets/icons/searchIcon.svg";
import refreshIcon from "@assets/icons/refreshIcon.svg";

export const MyNotes = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { reference_number } = useParams();

  const { myNotesData, loading, error } = useSelector(
    (state: any) => state.myNotes
  );

  useEffect(() => {
    if (reference_number) {
      dispatch(fetchMyNotesRequest(reference_number));
    }
  }, [reference_number, dispatch]);

  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("general");
  const [note, setNote] = useState<any>({
    title: "",
    comment: "",
    isSticky: false,
    priority: "mid",
    color: "light-yellow",
  });

  const { applicantDetails } = useSelector(
    (state: any) => state.applicantDetails
  );

  const handleInputChange = (field, value) => {
    setNote((prev) => ({ ...prev, [field]: value }));
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handlePriorityChange = (event) => {
    setNote((prev) => ({ ...prev, priority: event.target.value }));
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleStickyChange = () => {
    setNote((prev) => ({ ...prev, isSticky: !prev.isSticky }));
  };

  const handleColorChange = (color) => {
    setNote((prev) => ({ ...prev, color }));
  };
  useEffect(() => {
    if (reference_number) {
      dispatch(fetchMyNotesRequest(reference_number));
    }
  }, [reference_number, dispatch]);

  const handleSaveNote = () => {
    if (!note.comment.trim()) return;

    const isStickyFlag = note.isSticky ? "true" : "false";

    const formattedComment = `${note.comment} #title=${note.title || "--"
      } #priority=${note.priority || "--"} #color=${note.color || "--"
      } #isSticky=${isStickyFlag}`;

    dispatch(
      createNoteRequest({
        comment: formattedComment,
        category: "General",
        order_reference_id: reference_number,
        // reference_id: applicantDetails.customer_info.reference_id
      })
    );

    setNote({
      title: "",
      comment: "",
      priority: "mid",
      isSticky: false,
      color: "light-yellow",
    });

    setTimeout(() => {
      dispatch(fetchMyNotesRequest(reference_number));
    }, 1000);
  };

  const extractMetadata = (comment) => {
    const titleMatch = comment.match(/#title=([^#]+)/);
    const priorityMatch = comment.match(/#priority=([^#]+)/);
    const colorMatch = comment.match(/#color=([^#]+)/);
    const isStickyMatch = comment.match(/#isSticky=([^#]+)/);

    return {
      title: titleMatch ? titleMatch[1] : "--",
      priority: priorityMatch ? priorityMatch[1] : "mid",
      color: colorMatch ? colorMatch[1] : "light-yellow",
      isSticky: isStickyMatch
        ? isStickyMatch[1] === "true"
          ? true
          : false
        : false,
      parsedComment: comment
        .replace(/#(title|priority|color|isSticky)=[^#]+/g, "")
        .trim(),
      comment: comment,
    };
  };

  return (
    <>
      <MyNotesWrap theme={theme} className="my-notes-wrap">
        <Stack bgLight paddingMd={2} paddingXs={2} fullWidth>
          <Stack
            paddingMd={2}
            paddingXs={2}
            className="my-notes-stack"
            title="Add Notes"
            fullWidth
          >
            {/* Select Priority */}
            <Box theme={theme} className="custom-dropdown">
              <Typography
                theme={theme}
                className="text-muted"
                variant="body2"
                component={"span"}
                display={"block"}
              >
                Select Priority:
              </Typography>
              <Select
                theme={theme}
                disablePortal={false}
                items={[
                  { text: "High Priority", value: "high" },
                  { text: "Mid Priority", value: "mid" },
                  { text: "Low Priority", value: "low" },
                ]}
                onChange={handlePriorityChange}
                value={note.priority}
              />
            </Box>

            {/* Sticky Notes Input Section */}
            <Box
              theme={theme}
              className={clsx("add-sticky-notes", { clicked: open === true })}
            >
              <Box theme={theme} className="add-title">
                <Box theme={theme} className="input-title">
                  <Input
                    theme={theme}
                    type="text"
                    placeholder="Add Title"
                    onChange={(e) => handleInputChange("title", e)}
                    value={note.title}
                    fullWidth
                  />
                </Box>

                {/* Select Category */}
                <Box theme={theme} className="custom-dropdown">
                  <Typography
                    theme={theme}
                    className="text-muted"
                    variant="body2"
                    component={"span"}
                    display={"block"}
                  >
                    Select Category:
                  </Typography>
                  <Select
                    theme={theme}
                    disablePortal={false}
                    items={[
                      { text: "Internal Note", value: "internal" },
                      { text: "General Note", value: "general" },
                      { text: "Decision Note", value: "decision" },
                      { text: "Dealer Note", value: "dealer" },
                    ]}
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                  />
                </Box>
              </Box>

              {/* Textarea for Note */}
              <Textarea
                theme={theme}
                type="text"
                rows={2}
                placeholder="Type note here.."
                fullWidth
                onFocus={handleClick}
                onChange={(e) => handleInputChange("comment", e)}
                value={note.comment}
                id={""}
              />
            </Box>

            {/* Sticky Checkbox and Color Selection */}
            <Box theme={theme} display={"flex"} alignItems={"center"} gap={5}>
              <Checkbox
                theme={theme}
                label={"Mark as Sticky Note"}
                checkBoxChecked={note.isSticky}
                onChange={handleStickyChange}
              />

              {/* Color Picker */}
              {note.isSticky && (
                <Box theme={theme} className="select-priority">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component={"span"}
                    className="select fw-sbold"
                  >
                    Select Color:
                  </Typography>
                  <Box theme={theme} className="priority">
                    {["light-yellow", "light-purple", "light-ferozi"].map(
                      (color) => (
                        <Box
                          key={color}
                          theme={theme}
                          className={clsx("clr-picker", color, {
                            selected: note.color === color,
                          })}
                          onClick={() => handleColorChange(color)}
                        />
                      )
                    )}
                  </Box>
                </Box>
              )}
            </Box>

            {/* Save Button */}
            <Button
              theme={theme}
              primary
              text={"Save Note"}
              onClick={handleSaveNote}
            //   disabled={creating}
            >
              {/* {creating ? (
                <CircleLoader theme={theme} size="sm" />
              ) : (
                "Save Note"
              )} */}
            </Button>
          </Stack>
          <Box theme={theme} className="filter-section">
            <Box theme={theme} className="custom-dropdown">
              <Typography theme={theme} className="text-body2 text-muted" component={"symbol"}>
                Category:
              </Typography>
              <Select
                theme={theme}
                disablePortal={false}
                items={[
                  { text: "All", value: "all" },
                ]}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              />
            </Box>

            <Box theme={theme} className="multi-functions">
              <Box theme={theme} className='datepicker'>
                <DateRange
                  theme={theme}
                  onChange={()=>{}}
                  disablePortal={true}
                  placeholder={"Calendar"}
                  endAdornment={<img src={calendarIcon} className="calendar-icon" />}
                  render={<input placeholder="Calendar" readOnly />}
                />
              </Box>
              <Box theme={theme} className="custom-dropdown sort-btn">
                <img src={upAndDownIcon} alt="Sort Icon" className="sort-icon" />
                <Select
                  theme={theme}
                  disablePortal={false}
                  onChange={()=>{}}
                  items={[
                    { text: "Sort By", value: "sort" }
                  ]}
                  value={"sort"}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                />
              </Box>
              <Button
                theme={theme}
                defaultBtn
                iconText={<img src={resetIcon} alt="Reset" />}
                onClick={() => {
                  const resetFilters = {
                    status: "all",
                    sort_order: "sort",
                    created_at_from: null,
                    created_at_to: null,
                  };
                }}
              />
              <Button
                theme={theme}
                defaultBtn
                iconText={<img src={refreshIcon} alt="Refresh" />}
                onClick={() => {}}
              />
              <Box theme={theme} className="search">
                <Button theme={theme} defaultBtn iconText={<img src={searchIcon} alt="Search" />} />
              </Box>
            </Box>
          </Box>
          {/* Notes List with Loading/Error Handling */}
          <Box theme={theme} className="scrollable-container">
            <Box theme={theme} className="notes-listing">
              {loading ? (
                <CircleLoader theme={theme} size="sm" />
              ) : (
                <MyNotesList
                  myNotesData={myNotesData.map((note) => ({
                    ...note,
                    ...extractMetadata(note.comment),
                  }))}
                />
              )}
            </Box>
          </Box>
        </Stack>
      </MyNotesWrap>
    </>
  );
};