import { useTheme } from "@mui/material";
import { ThirdPartyResponseListWrap } from "./third-party-response-list.style";
import { Icon, Box, Typography, Accordion, Label } from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { PdfList, Stack } from "@src/components";
import Experian_Report from "@src/assets/docs/Experian_Report.pdf";
import Transunion_Report from "@src/assets/docs/TransUnion.pdf.pdf";
import { useEffect, useState, useRef } from "react";
import { Button } from "@ntpkunity/controls"

export const ThirdPartyResponseList = ({title, status, onClick}: {title?: string, onClick?: () => void, status?: 'primary' | 'info' | 'warning' | 'success' | 'error'}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ThirdPartyResponseListWrap
        theme={theme}
        className="third-party-response-list-wrap"
      >
        <Box theme={theme} display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
          {title &&
            <Typography theme={theme} variant="body2" component={'span'} className="text-body2">{title}</Typography>
          }
          <Box theme={theme} className="third-party-stack">
            {status &&
              <Label
                theme={theme}
                text="Active"
                state={status}
                textTransform="none"
              />
            }
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="ArrowLinkIcon" />}
              onClick={onClick}
            />
          </Box>
        </Box>
      </ThirdPartyResponseListWrap >
    </>
  );
};
