import { useTheme } from "@mui/material"
import { Box, Icon } from "@ntpkunity/controls";
import { ArrowDownWrap } from "./arrow-down.style";

export const ArrowDown = () => {
    const theme = useTheme();

    return (
        <>
            <ArrowDownWrap theme={theme} className="arrow-down">
                <Icon name="ChevronDown" />
            </ArrowDownWrap>
        </>
    )
}