import { useTheme } from "@mui/material";
import { Box, Grid, Typography, Accordion } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import moment from "moment";
import { useSelector } from "react-redux";

export const CoApplicantList = () => {
  const theme = useTheme();

  const { applicantDetails, loading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  const coapplicantInfo = applicantDetails?.coapplicant_info || {};
  const customerLicense = applicantDetails?.customer_license?.[0] || {};
  const customerAddress = coapplicantInfo?.customer_addresses?.[0] || {};

  const applicantName = `${coapplicantInfo?.first_name ?? "--"} ${coapplicantInfo?.last_name ?? "--"
  }`;

  const formattedDate = (date) =>
    date ? moment(date).format("MM/DD/YYYY") : "--";

  const basicDetailsFields = [
    {
      title: "Applicant Name:",
      textRight: true,
      subtitle: applicantName,
    },
    { title: "ID Type:", textRight: true, subtitle: "Driving License" },
    {
      title: "ID Number:",
      textRight: true,
      subtitle: coapplicantInfo?.customer_license?.[0]?.license_number ?? "--",
    },
    {
      title: "ID Expiry Date:",
      textRight: true,
      subtitle: coapplicantInfo?.customer_license?.[0]?.expiry_date
        ? new Date(
            coapplicantInfo?.customer_license?.[0]?.expiry_date
          ).toLocaleDateString()
        : "--",
    },
    {
      title: "Date of Birth:",
      textRight: true,
      subtitle: coapplicantInfo?.customer_license?.[0]?.expiry_date
        ? new Date(
            coapplicantInfo?.customer_license?.[0]?.date_of_birth
          ).toLocaleDateString()
        : "--",
    },
    { title: "Age:", textRight: true, subtitle: "38 Yrs." },
    { title: "Gender:", textRight: true, subtitle: "Male" },
    { title: "Nationality:", textRight: true, subtitle: "--" },
    { title: "Education:", textRight: true, subtitle: "--" },
    {
      title: "Mobile Number:",
      textRight: true,
      subtitle: coapplicantInfo?.mobile_number ?? "--",
    },
  ];

  const directorDetail = [
    {
      title: "Full Name:",
      textRight: true,
      subtitle: "Sample Name",
    },
    {
      title: "Nationality:",
      textRight: true,
      subtitle: "United Kingdom",
    },
    {
      title: "Current Address:",
      textRight: true,
      subtitle: "Mr John Smith. 132, My Street, Kingston, New York 12401",
    },
    {
      title: "Contact Number",
      textRight: true,
      subtitle: "020 7123 4567",
    },
  ];

  const addressFields = [
    {
      title: "Current Address:",
      textRight: true,
      subtitle:
        coapplicantInfo?.customer_addresses?.[0]?.address_line_1 ?? "--",
    },
    {
      title: "City:",
      textRight: true,
      subtitle: coapplicantInfo?.customer_addresses?.[0]?.city ?? "--",
    },
    {
      title: "State:",
      textRight: true,
      subtitle: coapplicantInfo?.customer_addresses?.[0]?.state_name ?? "--",
    },
    {
      title: "Zip Code:",
      textRight: true,
      subtitle: coapplicantInfo?.customer_addresses?.[0]?.zip_code ?? "--",
    },
    {
      title: "County:",
      textRight: true,
      subtitle: coapplicantInfo?.customer_addresses?.[0]?.county ?? "--",
    },
    {
      title: "Living Since:",
      textRight: true,
      subtitle:
        formattedDate(coapplicantInfo?.customer_addresses?.[0]?.move_in_date) ??
        "--",
    },
  ];

  // const incomeDetailsFields = [
  //   { title: "Average Balance From Bank Statement:", textRight: true, subtitle: "$00,000.00" },
  //   { title: "Net Monthly Income After Tax:", textRight: true, subtitle: "$00,000.00" },
  // ];

  // Dynamic fields for different sections

  // const basicDetailsFields = [
  //   {
  //     title: 'Client Type:',
  //     textRight: true,
  //     subtitle: 'Limited Company'
  //   },
  //   {
  //     title: 'Company Name:',
  //     textRight: true,
  //     subtitle: 'ABC Organisation'
  //   },
  // ]

  return (
    <>
      <Grid
        theme={theme}
        container
        rowSpacing={0}
        columnSpacing={2}
        sx={{ mt: 0 }}
      >
        {/* Basic Details */}
        <Grid item xs={6} theme={theme}>
          <Stack paddingMd={0} paddingXs={0}>
            <Typography
              theme={theme}
              variant="subtitle2"
              component={"span"}
              className="fw-sbold"
              p={1}
            >
              Basic Details
            </Typography>
            <BasicList noWrap options={basicDetailsFields} />
          </Stack>
        </Grid>

        {/* Address Details */}
        <Grid item xs={6} theme={theme}>
          <Stack paddingMd={0} paddingXs={0}>
            <Typography
              theme={theme}
              variant="subtitle2"
              component={"span"}
              className="fw-sbold"
              p={1}
            >
              Address
            </Typography>
            <Box theme={theme}>
              <BasicList noWrap options={addressFields} />
            </Box>
            {/* <Box theme={theme} mt={2}>
              <Typography
                theme={theme}
                variant="subtitle2"
                component={"span"}
                className="fw-sbold"
                p={1}
              >
                Income Details
              </Typography>
              <BasicList options={incomeDetailsFields} />
            </Box> */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}; 