import {
  FETCH_WORKQUEUE_REQUEST,
  FETCH_WORKQUEUE_SUCCESS,
  FETCH_WORKQUEUE_FAILURE,
  RETRIEVE_WORKQUEUE_FAILURE,
  RETRIEVE_WORKQUEUE_REQUEST,
  RETRIEVE_WORKQUEUE_SUCCESS,
  UPDATE_SORT_ORDER,
  UPDATE_PAGINATION,
  UPDATE_RUNNING_WORKFLOW_ID,
  UPDATE_CREDIT_CHECK_SCORE,
  UPDATE_ASSIGNED_ROW_ID,
  UPDATE_DATE_FILTER,
  UPDATE_WORKQUEUE_FILTERS,
  UPDATE_APPLICANT_TYPE,
  UPDATE_API_KEY,
} from "../actions/workQueueActions";

const initialState = {
  loading: false,
  workQueues: [],
  workQueueDetail: null,
  running_workflow_instance_id: null,
  assignedRowId: null,
  apiKey: null,
  credit_check_score: null,
  error: null,
  filters: {},
  readRows: [] as string[],
  applicantType: null,
  sortOrder: { column: "updated_at", order: "desc" },
  dateFilter: { startDate: null, endDate: null },
  pagination: {
    pageNumber: 1,
    pageSize: 50,
    totalRecords: 0,
    totalPages: 1,
  },
};

const workqueueReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WORKQUEUE_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_WORKQUEUE_SUCCESS:
      const { data, pagination } = action.payload;
      return {
        ...state,
        loading: false,
        workQueues: data ?? [],
        pagination: {
          pageNumber: pagination?.page_number ?? state.pagination.pageNumber,
          pageSize: pagination?.records_per_page ?? state.pagination.pageSize,
          totalRecords: pagination?.total_count ?? state.pagination.totalRecords,
          totalPages: pagination?.total_pages ?? state.pagination.totalPages,
        },
      };

    case FETCH_WORKQUEUE_FAILURE:
      return { ...state, loading: false, error: action.payload };


    case RETRIEVE_WORKQUEUE_REQUEST:
      return { ...state, loading: true, error: null };

    case RETRIEVE_WORKQUEUE_SUCCESS:
      return {
        ...state,
        loading: false,
        workQueueDetail: action.payload,
      };

    case RETRIEVE_WORKQUEUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SORT_ORDER:
      return {
        ...state,
        sortOrder: {
          column: action.payload.column,
          order: action.payload.order,
        },
      };

    case UPDATE_WORKQUEUE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case UPDATE_PAGINATION:
      return {
        ...state,
        pagination: {
          pageNumber: action.payload.pageNumber || 1,
          pageSize: action.payload.pageSize || 50,
          totalRecords: action.payload.totalRecords || 0,
          totalPages: action.payload.totalPages || 1,
        },
      };

    case UPDATE_RUNNING_WORKFLOW_ID:
      return { ...state, running_workflow_instance_id: action.payload };

    case UPDATE_ASSIGNED_ROW_ID:
      return { ...state, assignedRowId: action.payload };

    case UPDATE_CREDIT_CHECK_SCORE:
      return { ...state, credit_check_score: action.payload };

    case UPDATE_DATE_FILTER:
      return {
        ...state,
        dateFilter: action.payload,
      };

    case "MARK_ROW_AS_READ":
      const readRows = state.readRows ?? [];

      if (!readRows.includes(action.payload)) {
        return {
          ...state,
          readRows: [...readRows, action.payload],
        };
      }
      return state;

    case UPDATE_API_KEY:
      return {
        ...state,
        apiKey: action.payload,
      };

    case UPDATE_APPLICANT_TYPE:
      return {
        ...state,
        applicantType: action.payload,
      };

    default:
      return state;
  }
};

export default workqueueReducer;