import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const ChatWrap = styled(Box)<Partial<IBoxProps>>(({ theme }) => ({
  "&.chat-wrap": {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    ".cross-btn": {
      display: 'none'
    },
    ".chat-box": {
      padding: "16px",
      borderRadius: 8,
      border: "1px solid",
      borderColor: theme.palette.grey[100],
      backgroundColor: theme.palette.common.white,
      ".u-form-group": {
        marginBottom: 16,
        ".u-form-control": {
          "&.textarea": {
            ".MuiInputBase-root": {
              padding: 0,
              ".MuiInputBase-input": {
                color: '#474A59'
              }
            },
          },
          fieldset: {
            border: "none",
          },
        },
      },

      ".chat-options": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 16,
        ".chat-filters": {
          display: "flex",
          alignItems: "center",
          padding: 4,
          gap: 8,
        },
        ".MuiButtonBase-root": {
          "&.btn.with-icon.btn-default": {
            padding: 0,
          },
        },
      },
    },
    ".help-text": {
      display: 'block'
    },
    ".ai-help-text": {
      display: 'none'
    },
    ".typing-indicator": {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      padding: "10px",
      ".dot": {
        width: "8px",
        height: "8px",
        backgroundColor: theme.palette.grey[500],
        borderRadius: "50%",
        animation: "typing 1.5s infinite ease-in-out",
      },
      ".dot:nth-child(1)": { animationDelay: "0s" },
      ".dot:nth-child(2)": { animationDelay: "0.2s" },
      ".dot:nth-child(3)": { animationDelay: "0.4s" },
    },

    "@keyframes typing": {
      "0%, 80%, 100%": { transform: "translateY(0)" },
      "40%": { transform: "translateY(-5px)" },
    },
  },
}));
