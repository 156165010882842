import { useTheme } from "@mui/material";
import { MyNotesListWrap } from "./my-notes-list.style";
import { Stack } from "@src/components/stack";
import { Box, Label, Typography, CircleLoader } from "@ntpkunity/controls";
import { UserName } from "../../user-name";
import { useDispatch, useSelector } from "react-redux";
import {
  createNoteRequest,
  fetchMyNotesRequest,
  updateNoteRequest,
  updateNoteToSticky,
} from "@src/components/redux/actions/myNotesActions";
import { useParams } from "react-router-dom";

export const MyNotesList = ({ myNotesData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state: any) => state.myNotes);

  const { applicantDetails } = useSelector(
    (state: any) => state.applicantDetails
  );

  const { reference_number } = useParams();


  const handleAddToStickyNotes = (note) => {
    if (note.isSticky) return;

    const updatedComment = `${note.parsedComment} #title=${note.title || "--"
      } #priority=${note.priority || "--"} #color=${note.color || "--"
      } #isSticky=true`.trim();

    dispatch(
      updateNoteRequest({
        identifier: note.identifier,
        comment: updatedComment,
        // reference_id: applicantDetails.customer_info.reference_id
      })
    );

    setTimeout(() => {
      dispatch(fetchMyNotesRequest(reference_number));
    }, 1000);
  };

  const sortedMyNotes = [...myNotesData].sort((a, b) => {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();
    return dateB - dateA;
  });

  return (
    <MyNotesListWrap theme={theme} className="my-notes-list-wrap">
      {loading ? (
        <CircleLoader theme={theme} size="sm" />
      ) : error ? (
        <Typography
          theme={theme}
          variant="body2"
          className="text-error"
          component={"symbol"}
        >
          Error: {error}
        </Typography>
      ) : sortedMyNotes.length === 0 ? (
        <Typography
          theme={theme}
          variant="body2"
          className="text-muted"
          component={"symbol"}
        >
          No notes available for this reference number.
        </Typography>
      ) : (
        sortedMyNotes?.map((note, index) => {
          return (
            <Stack
              paddingMd={0}
              paddingXs={0}
              className="stack-user-header"
              key={index}
            >
              <Box theme={theme} className="sticky-color">
                <Box theme={theme} mb={2}>
                  <Stack paddingMd={1} paddingXs={1} bgLight>
                    <UserName
                      userTitle={note.created_by?.[0]?.toUpperCase() || "AJ"}
                      userName={note.created_by || "Asad"}
                    />
                  </Stack>
                </Box>
                <Box theme={theme} className="notes-content">
                  <Box theme={theme} className="notes-head small-view">
                    <Typography
                      theme={theme}
                      variant="caption"
                      component={"small"}
                      className="fw-medium text-body1"
                    >
                      {note.title}
                    </Typography>
                    <Label
                      theme={theme}
                      text={note.category || "General"}
                      state="primary"
                    />
                  </Box>
                  <Box theme={theme} className="notes-body">
                    <Typography
                      theme={theme}
                      variant="body2"
                      component={"span"}
                      className="text-body1"
                    >
                      {note.parsedComment || "No additional details available."}
                    </Typography>
                    <Box
                      theme={theme}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="caption-text text-muted tag"
                      >
                        #{note.priority}
                      </Typography>
                      <Box theme={theme} className="date-time">
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          className="caption-text text-muted month"
                        >
                          {new Date(note.created_at).toLocaleDateString("en-US", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          })}
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          className="caption-text text-muted"
                        >
                          {new Date(note.created_at).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box theme={theme} className="notes-footer" pb={1} pt={1}>
                    <a
                      href="javascript:void(0)"
                      className={`text-primary ${note.isSticky ? "disabled" : ""
                        }`}
                      onClick={() =>
                        !note.isSticky && handleAddToStickyNotes(note)
                      }
                      style={{
                        cursor: note.isSticky ? "not-allowed" : "pointer",
                        color: note.isSticky ? "grey" : "",
                      }}
                    >
                      Add to Sticky Notes
                    </a>
                  </Box>
                </Box>
              </Box>
            </Stack>
          );
        })
      )}
    </MyNotesListWrap>
  );
};