import { useTheme } from "@mui/material";
import { BusinessRulesAlertsWrap } from "./business-rules.style";
import { Alert } from "@src/components";

export const BusinessRulesAlerts = ({ businessData }) => {
  const theme = useTheme();

  return (
    <>
      <BusinessRulesAlertsWrap
        theme={theme}
        className="business-rules-alerts-wrap"
      >
        {businessData?.business_rules?.map((business, i) => (
          <Alert
            key={i}
            text={business.title}
            description={
              <>
                <a href="" className="text-danger">
                  Wave Alert
                </a>
              </>
            }
            error
            fullWidth
          />
        ))}
      </BusinessRulesAlertsWrap>
    </>
  );
};
