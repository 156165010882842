import { useTheme } from "@mui/material"
import { UserNameWrap } from "./user-name.style"
import { UserNameProps } from "./user-name.props"
import { Avatar, Typography, Box } from "@ntpkunity/controls"

export const UserName = ({ userTitle, userName, subTitle, dayMonthYear, timeStamp }: UserNameProps) => {
    const theme = useTheme();

    return (
        <>
            <UserNameWrap theme={theme} className="user-name-wrap">
                {userTitle &&
                    <Avatar theme={theme}>{userTitle}</Avatar>
                }
                <Box theme={theme}>
                    {userName &&
                        <Typography theme={theme} variant="caption" component={"small"} className="user-name fw-medium">{userName}</Typography>
                    }
                    {subTitle &&
                        <Typography theme={theme} variant="caption" component={"small"} className="text-muted">{subTitle}</Typography>
                    }
                    {dayMonthYear &&
                        <Box theme={theme} className="date-time">
                            <Typography theme={theme} variant="caption" component={'small'} className="text-dark-grey caption-text">{dayMonthYear}</Typography>
                            <Typography theme={theme} variant="caption" component={'small'} className="text-dark-grey caption-text">{timeStamp}</Typography>
                        </Box>
                    }
                </Box>
            </UserNameWrap>
        </>
    )
}