import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AddStipWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-stip-wrap": {
    position: 'relative',
    ".action-area": {
      a: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        svg: {
          width: 16,
          height: 16
        }
      }
    },
    '.scrollable-container': {
      height: 'calc(100vh - 365px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
    },
  },
}));
