import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles"

export const AiDataListingWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.ai-data-listing-wrap": {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    ".cde-card": {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      ".card-head": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 34,
        gap: 8,
        ".status": {
          width: 8,
          height: 8,
          borderRadius: 24,
          "&.success": {
            backgroundColor: theme.palette.success.main
          },
          "&.error": {
            backgroundColor: theme.palette.error.main
          }
        }
      },
      ".card-body": {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 8,
        ".label": {
          backgroundColor: CustomColors.lightGrey,
          padding: '4px 8px',
          borderRadius: 8,
          color: '#474A59'
        },
        ".styled-tooltip": {
          ".MuiTooltip-popper": {
            ".MuiTooltip-tooltip": {
              maxWidth: 200,
              padding: 16,
              fontWeight: theme.typography.body2.fontWeight,
              lineHeight: theme.typography.body2.lineHeight
            }
          }
        },
      }
    }
  }
}));
