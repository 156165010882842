import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { ADD_STIP_REQUEST, addStipSuccess, addStipFailure, FETCH_STIPULATION_REQUEST, fetchStipulationFailure, fetchStipulationSuccess } from "../actions/stipActions";

function* addStipSaga(action) {
  try {
    const response = yield call(axios.post, `/api/stipulation`, action.payload);
    yield put(addStipSuccess(response.data));
    alert("Stipulation added successfully!");
  } catch (error) {
    yield put(addStipFailure(error.response?.data?.message || "Failed to add stipulation."));
    alert("Failed to add stipulation. Please try again.");
  }
}

function* fetchStipSaga(action) {
  try {
    const { company_id, stipulation_type } = action.payload;

    const response = yield call(axios.get, `${process.env.REACT_APP_API_URL}/config/stipulation/filter`, {
      params: {
        stipulation_type,
        page_number: 0,
        page_size: 2000,
      },
      headers: {
        company_id: company_id,
        "X-API-KEY": process.env.REACT_APP_API_KEY
      },
    });

    yield put(fetchStipulationSuccess(response.data.result));
  } catch (error) {
    yield put(fetchStipulationFailure(error.response?.data?.message || "Failed to add stipulation."));
  }
}

export function* watchAddStipSaga() {
  yield takeLatest(ADD_STIP_REQUEST, addStipSaga);
}

export function* watchFetchStipSaga() {
  yield takeLatest(FETCH_STIPULATION_REQUEST, fetchStipSaga);
}
