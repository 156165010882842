import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const DocumentsListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.document-list-wrap": {
    '&.scrollable-container': {
      maxHeight: 'calc(100vh - 255px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      "@media only screen and (min-width: 1599.95px)": {
        maxHeight: 'calc(100vh - 413px)',
      }
    },
    ".documents": {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        marginTop: 16
    }
  }
}));
