import { useEffect, useRef, useState } from "react";

import { useMediaQuery, useTheme } from "@mui/material";
import { Box, Grid, Button, Icon } from "@ntpkunity/controls";
import { ApplicantDetailsStack } from "../applicant-details/applicant-detail-stack/applicant-detail-stack.component";
import { DashboardStackWrap } from "./dashboard-stack.style";
import { AssetDetailsStack } from "../asset-detail/asset-detail-stack/asset-detail-stack.component";
import { RiskAssessmentStack } from "../risk-assessment-metrices/risk-assessment-stack/risk-assessment-stack.component";
import { FinanceStructure } from "@src/components/dashboard/finance-structure/finance-structure/finance-structure.component";
import { BusinessRules } from "@src/components/dashboard/business-rules-stack/business-rules/business-rules.component";
import { ThirdPartyResponse } from "../third-party-response-stack/third-party-response/third-party-response.component";
import { StickyNotesList } from "../sticky-notes-stack/sticky-notes-list/sticky-notes-list.component";
import { ActivitiesAndNotesSlidePopup } from "@src/components/dashboard/activities-and-notes-slide/activities-and-notes-slide-popup/activities-and-notes-slide-popup.component";
import { AddStickyNote } from "../sticky-notes-stack/add-sticky-note/add-sticky-note.component";
import { AiAssistant } from "../ai-assistant-stack/ai-assistant/ai-assistant.component";
import { Documents } from "../documents/document-stack/document-stack.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchExposureDetails } from "@src/services/configureApplicationService";
import { openNotesPopup } from "@src/components/redux/actions/myNotesActions";
import { ArrowDown } from "@src/components";

export const DashboardStacks = ({ businessData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [exposureData, setExposureData] = useState({});

  const { myNotesData } = useSelector(
    (state: any) => state.myNotes
  );

  const stickyNotesData = [...myNotesData]
    .filter((note) => note.comment.includes("#isSticky=true"))
    .sort((a, b) => {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return dateB - dateA;
    });

  const { applicantDetails } = useSelector(
    (state: any) => state.applicantDetails
  );

  const isFetching = useRef(false);

  useEffect(() => {
    const loadBookmarks = async () => {
      if (!applicantDetails?.customer_info || isFetching.current) return;

      isFetching.current = true;

      let customer_id_details: {
        customer_id_type: string;
        customer_id_number: string;
      } = {
        customer_id_type: "",
        customer_id_number: "",
      };

      if (applicantDetails?.customer_info?.ssn) {
        customer_id_details = {
          customer_id_type: "ssn",
          customer_id_number: applicantDetails.customer_info.ssn,
        };
      } else if (
        applicantDetails?.customer_info?.customer_license &&
        applicantDetails.customer_info.customer_license.length > 0 &&
        applicantDetails.customer_info.customer_license[0]?.license_number
      ) {
        customer_id_details = {
          customer_id_type: "license_number",
          customer_id_number:
            applicantDetails.customer_info.customer_license[0].license_number,
        };
      }

      // Extract customer personal details
      let customer_personal_details = {
        customer_name: "",
        customer_dob: "",
        customer_address: "",
      };

      const customer = applicantDetails?.customer_info;
      if (customer) {
        customer_personal_details.customer_name = `${customer.first_name} ${customer.middle_name ? customer.middle_name + " " : ""
          }${customer.last_name}`.trim();
        customer_personal_details.customer_dob =
          applicantDetails?.customer_info.customer_license[0]?.date_of_birth.split(
            "T"
          )[0] || "";

        if (
          customer.customer_addresses &&
          customer.customer_addresses.length > 0
        ) {
          const mailingAddress = customer.customer_addresses.find(
            (addr) => addr.address_type === "Mailing"
          );
          if (mailingAddress) {
            customer_personal_details.customer_address = `${mailingAddress.address_line_1}, ${mailingAddress.city}, ${mailingAddress.county}, ${mailingAddress.state_name}`;
          }
        }
      }

      const payload = {
        customer_id_details,
        customer_personal_details,
      };

      try {
        const data = await fetchExposureDetails(payload);
        setExposureData(data);
        if (data?.error) {
          console.error("Error fetching exposure details:", data.error);
        }
      } catch (error) {
        console.error("Error fetching exposure details:", error);
      }

      isFetching.current = false;
    };

    loadBookmarks();

    return () => {
      isFetching.current = false;
    };
  }, [applicantDetails?.customer_info]);

  const isGridChange = useMediaQuery(theme.breakpoints.down("xl"));

  const handleAddStickyNote = () => {
    dispatch(openNotesPopup());
  };

  return (
    <>
      <DashboardStackWrap theme={theme} className="dashboard-stack-wrap">
        <Grid theme={theme} container item spacing={2}>
          <Grid item xl={10.4} lg={10.3} md={12} theme={theme}>
            <Grid theme={theme} container item spacing={2}>
              <Grid theme={theme} item xs={9}>
                <Box theme={theme} className="scrollable-container" mt={2}>
                  <Grid theme={theme} container item spacing={2}>
                    <Grid theme={theme} item lg={4} md={4} sm={6}>
                      <ApplicantDetailsStack />
                      <Box theme={theme} mt={2}>
                        <AssetDetailsStack />
                      </Box>
                      <Box theme={theme} mt={2}>
                        <BusinessRules businessData={businessData} />
                      </Box>
                    </Grid>
                    <Grid theme={theme} item lg={4} md={4} sm={6}>
                      <RiskAssessmentStack exposureData={exposureData} />
                      <Box theme={theme} mt={2}>
                        <ThirdPartyResponse />
                      </Box>
                    </Grid>
                    <Grid theme={theme} item lg={4} md={4} sm={6}>
                      <FinanceStructure />
                      <Box theme={theme}>
                        <Documents />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid theme={theme} item xs={3}>
                <Grid theme={theme} container item spacing={2}>
                  <Grid theme={theme} item xs={12}>
                    <Box theme={theme} className="scrollable-container" mt={2}>
                      <AiAssistant />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xl={1.6}
            lg={1.7}
            md={12}
            theme={theme}
            position={"relative"}
          >
            <Box theme={theme} className="scrollable-container" mt={2}>
              <Box theme={theme} mt={1}>
                {stickyNotesData.length === 0 && (
                  <AddStickyNote
                    title="Add Sticky Note"
                    handleAddStickyNote={handleAddStickyNote}
                  />
                )}
              </Box>
              <StickyNotesList stickyNotesData={stickyNotesData} />

            </Box>
          </Grid>
        </Grid>
      </DashboardStackWrap>
      <ActivitiesAndNotesSlidePopup />
    </>
  );
};
