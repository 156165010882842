export const FETCH_AUDIT_TRAIL_REQUEST = "FETCH_AUDIT_TRAIL_REQUEST";
export const FETCH_AUDIT_TRAIL_SUCCESS = "FETCH_AUDIT_TRAIL_SUCCESS";
export const FETCH_AUDIT_TRAIL_FAILURE = "FETCH_AUDIT_TRAIL_FAILURE";

export const fetchAuditTrailRequest = () => ({
    type: FETCH_AUDIT_TRAIL_REQUEST,
    // payload: reference_number,
});

export const fetchAuditTrailSuccess = (data) => ({
    type: FETCH_AUDIT_TRAIL_SUCCESS,
    payload: data,
});

export const fetchAuditTrailFailure = (error) => ({
    type: FETCH_AUDIT_TRAIL_FAILURE,
    payload: error,
});
