import { useTheme } from "@mui/material"
import { AiAssistantWrap } from "./ai-assistant.style"
import { Stack } from "@src/components"
import { Box, Typography } from "@ntpkunity/controls"
import img from "@src/assets/icons/askAi.svg"
import { AiAssistantList } from "../ai-assistant-list/ai-assistant-list.component"
import { Chat } from "../chat/chat.component"
import { useState } from "react";

export const AiAssistant = () => {
  const theme = useTheme();
  const [chatInitiated, setChatInitiated] = useState(false);

  const handleChatFocus = () => {
    if (!chatInitiated) setChatInitiated(true);
  };

  const handleCloseChat = () => {
    setChatInitiated(false);
  };

  return (
    <>
      <AiAssistantWrap
        theme={theme}
        className={`ai-assistant-wrap ${chatInitiated ? "chat-initiated" : ""}`}
      >
        <Stack paddingXs={2} paddingMd={2}>
          <Box theme={theme} className="ai-head">
            <Box theme={theme} className="ai-head-text">
              <img src={img} className="img" />
              <Typography
                theme={theme}
                variant="body2"
                component={"span"}
                className=""
              >
                Ask Check AI
              </Typography>
            </Box>
            <Box theme={theme} className="title">
              <Typography
                theme={theme}
                variant="h4"
                component={"h4"}
                className="gradient-text text-h4"
              >
                Hello, Asad
              </Typography>
              <Typography
                theme={theme}
                variant="h4"
                component={"h4"}
                className="title-animation text-h4"
              >
                How Can I Help You?
              </Typography>
            </Box>
          </Box>
          <AiAssistantList />
          <Chat
            onChatFocus={handleChatFocus}
            onCloseChat={handleCloseChat}
            chatInitiated={chatInitiated}
          />
        </Stack>
      </AiAssistantWrap>
    </>
  );
};