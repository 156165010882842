import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Dialog, Icon, Button } from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { FinanceStructureModifyDialogWrap } from "./finance-structure-modify-popup-dialog.style";
import { Stack } from "@src/components/stack";
import { FinanceStructureTable } from "../finance-structure-table/finance-structure-table.component";
import { useDispatch } from "react-redux";
import { deleteFinanceStructure, setSelectedStructure } from "@src/components/redux/actions/financeStructureActions";

export const FinanceStructureModifyDialog = ({
  open,
  closeDialog,
  onSaveStructure,
  selectedStructureKey,
  financeStructures,
  handleAddNewStructure,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [localSelectedStructureKey, setLocalSelectedStructureKey] = useState(selectedStructureKey || "requested");
  useEffect(() => {
    if (selectedStructureKey) {
      setLocalSelectedStructureKey(selectedStructureKey);
    }
  }, [selectedStructureKey, open]);

  const [localFinanceStructures, setLocalFinanceStructures] = useState(financeStructures || []);

  useEffect(() => {
    if (financeStructures) {
      setLocalFinanceStructures(financeStructures);
    }
  }, [financeStructures, open]);

  const handleSaveStructure = () => {
    const selected = localFinanceStructures.find(s => s.key === localSelectedStructureKey);

    if (selected) {
      onSaveStructure(selected);
      dispatch(setSelectedStructure(selected));
      dispatch({
        type: "SET_SELECTED_STRUCTURE_KEY",
        payload: selected.key,
      });
    }
    closeDialog();
  };

  const handleSelectStructure = (key: string) => {
    if (localSelectedStructureKey !== key) {
      setLocalSelectedStructureKey(key); 
      dispatch(setSelectedStructure(financeStructures.find((s) => s.key === key))); 
      dispatch({
        type: "SET_SELECTED_STRUCTURE",
        payload: key,
      });
    }
  };

  const handleRemoveStructure = (key) => {
    dispatch(deleteFinanceStructure(key));

    if (selectedStructureKey === key) {
      dispatch(setSelectedStructure(null));
      dispatch({
        type: "SET_SELECTED_STRUCTURE",
        payload: null,
      });
    }
  };

  return (
    <FinanceStructureModifyDialogWrap theme={theme} className="finance-structure-modify-popup-wrap">
      <Popup>
        <Dialog
          theme={theme}
          size={"md"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Add/Modify Structure"}
          disablePortal
          noFooter
        >
          <Stack
            bgLight
            paddingMd={1.5}
            paddingXs={1.5}
            title={"Finance Structure"}
            actionArea={
              <Box theme={theme} display={"flex"} alignItems={"center"}>
                <Icon name="AddIcon" className="text-primary" />
                <a href="#" className="text-primary" onClick={handleAddNewStructure}>
                  Add New Structure
                </a>
              </Box>
            }
          />
          <Stack paddingMd={2} paddingXs={2}>
            <FinanceStructureTable
              financeStructures={localFinanceStructures}
              onUpdateStructure={(key, updatedData) => {
                setLocalFinanceStructures((prev) =>
                  prev.map((s) => (s.key === key ? { ...s, data: updatedData } : s))
                );
              }}
              onRemoveStructure={handleRemoveStructure}
              selectedStructureKey={localSelectedStructureKey}
              onSelectStructure={handleSelectStructure} 
            />
          </Stack>
          <Box theme={theme} textAlign={"center"} pt={2} pb={2}>
            <Button theme={theme} primary text={"Save Structure"} onClick={handleSaveStructure} />
          </Box>
        </Dialog>
      </Popup>
    </FinanceStructureModifyDialogWrap>
  );
};

export default FinanceStructureModifyDialog;