import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
    FETCH_RISK_ASSESSMENT_REQUEST,
    fetchRiskAssessmentSuccess,
    fetchRiskAssessmentFailure,
} from "../actions/riskAssessmentActions";

function* fetchRiskAssessmentSaga() {
    try {
        const response = yield call(axios.get, "https://dev-api-k8s.netsolapp.io/dms/customer/fraud-check", {
            headers: { "x-api-key": "pk_unity_3692394e-32d7-11ef-8144-4adabd389cec" },
        });
        if (response && response.data) {
            yield put(fetchRiskAssessmentSuccess(response.data));
        } else {
            throw new Error("Empty response from API");
        }
    } catch (error) {
        console.error("API Call Failed:", error);
        yield put(fetchRiskAssessmentFailure(error.response?.data?.message || error.message));
    }
}

export function* watchRiskAssessmentSaga() {
    yield takeLatest(FETCH_RISK_ASSESSMENT_REQUEST, fetchRiskAssessmentSaga);
}
