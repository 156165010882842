import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const OwnershipDocumentWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.ownership-document-wrap": {
    ".stack-wrap": {
        ".stack-header": {
            padding: '0',
            gap: 8,
            ".title": {
                minWidth: 115,
                ".MuiTypography-root": {
                    fontSize: theme.typography.caption.fontSize,
                    fontWeight: theme.typography.fontWeightMedium
                }
            },
            ".action-area": {  
                ".alert-wrap": {
                    padding: 4,
                    ".top-content": {
                        gap: 10
                    }
                }
            }
        }
    },
    ".pdf-card": {
        margin: '4px 0',
        ".pdf-preview": {
            padding: '19px 36px 20px 36px',
            backgroundColor: theme.palette.common.white,
            height: 119,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'strech',
            borderRadius: 8,
            img: {
                width: '100%'
            }
        },
        ".pdf-card-footer": {
            marginTop: 8,
        }
    }
  },
}));
