import { SAVE_COMMENT } from "../actions/complianceChecklistActions";

const initialState = {
  comments: {}, 
};

const complianceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.payload.index]: action.payload.comment, 
        },
      };

    default:
      return state;
  }
};

export default complianceReducer;