import { styled } from "@mui/material/styles";
import zIndex from "@mui/material/styles/zIndex";
import { CustomColors } from "@src/styles";

export const DataTableWrap: any = styled("div")(({ theme }) => ({
  "&.data-table-wrap": {
    ".u-table-wrap": {
      ".scroll": {
        ".scroll-hide": {
          backgroundColor: "#F8F8F9",
          height: 56,
          top: 0,
          right: 0,
          border: "none",
          borderRadius: "0 7px 8px 0",
          "&.spr-border": {
            "&::after": {
              display: "none",
            },
          },
        },
      },
      ".u-table-container": {
        border: "none !important",
        maxHeight: "calc(100vh - 200px)",
        ".u-table-head": {
          tr: {
            "&:nth-child(1)": {
              td: {
                backgroundColor: CustomColors.lightGrey,
                padding: "16px 8px 16px 20px",
                "&:nth-child(1)": {
                  borderBottomLeftRadius: 8,
                },
              },
            },
            td: {
              border: "none",
              fontWeight: theme.typography.fontWeightMedium,
              ".table-order": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 8,
                ".MuiButton-root.btn.with-icon.icon-only": {
                  padding: 0,
                },
              },
            },
            "&.filters-row": {
              td: {
                padding: "8px 8px 8px 20px",
                ".table-filter": {
                  ".u-form-group": {
                    ".u-form-control": {
                      height: 36,
                      borderRadius: 4,
                      "@media only screen and (min-width: 1599px)": {
                        height: 40,
                      }
                    },
                  },
                },
              },
            },
          },
        },
        ".u-table-body": {
          tr: {
            "&:nth-of-type(odd)": {
              td: {
                backgroundColor: CustomColors.lightGrey + " !important",
                "&:nth-child(1)": {
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                },
                "&:last-child": {
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                },
              },
            },
            td: {
              borderTop: "none !important",
              padding: "16px 20px !important",
              maxWidth: "100%",
              color: "#474A59",
              lineHeight: '20px',
              "@media only screen and (min-width: 1599px)": {
                lineHeight: '24px'
              }
            },
            "&:hover": {
              "&:nth-of-type(even)": {
                td: {
                  "&:nth-child(1)": {
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                  },
                  "&:last-child": {
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  },
                },
              },
            },
          },
        },
      },
      "&.has-pagination": {
        ".pagination-wrap": {
          ".MuiTablePagination-root": {
            borderColor: theme.palette.grey[100] + " transparent transparent transparent",
            borderTopStyle: "solid",
            borderTopWidth: 1,
            ".MuiToolbar-root": {
              padding: "4px 12px",
              height: 40,
              "@media only screen and (min-width: 1599px)": {
                padding: "16px 12px",
                height: 'auto',
              }
            },
          },
        },
      },
    },
  },

  "@media only screen and (min-width: 1599px)": {
    ".u-table-wrap .u-table .u-table-head td": {
      fontSize: 16,
      lineHeight: '24px !important'
    },
  },
}));
