import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Typography,
  Grid,
  Dialog,
  Box,
  DuoTab,
  Icon,
  Textarea,
  DataTable,
  Button,
  Input,
  Checkbox,
  Select,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@src/styles/data-table-wrap";
import { AmortisationsTableWrap } from "./amortisations-table.style";
import { useState } from "react";

export const AmortisationsTable = ({ repaymentPlan, incomingPosting }) => {
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState("repayment");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // Common columns for "Repayment Plan"
  const repaymentColumns = [
    { key: "DocumentType", label: "Rental #", className: "w-90 fw-medium" },
    {
      key: "DocumentName",
      label: "Rental Due Date",
      className: "w-150 fw-medium",
    },
    {
      key: "UploadedBy",
      label: "Opening Principal",
      className: "w-150 fw-medium",
    },
    { key: "Timestamp", label: "Rental Amount", className: "w-150 fw-medium" },
    { key: "Timestamp", label: "Principal", className: "w-150 fw-medium" },
    { key: "Timestamp", label: "Interest", className: "w-150 fw-medium" },
    {
      key: "Timestamp",
      label: "Periodic Interest",
      className: "w-150 fw-medium",
    },
    {
      key: "Timestamp",
      label: "Closing Principal",
      className: "w-150 fw-medium",
    },
  ];

  // Columns for "Income Posting"
  const incomeColumns = [
    { key: "sr", label: "Sr. #", className: "w-px-100 fw-medium" },
    { key: "rental", label: "Rental #", className: "w-px-100 fw-medium" },
    { key: "PostingDate", label: "Posting Date", className: "w-150 fw-medium" },
    { key: "days", label: "No. of Days", className: "w-150 fw-medium" },
    { key: "DateFrom", label: "Date From", className: "w-150 fw-medium" },
    { key: "DateTo", label: "Date To", className: "w-150 fw-medium" },
    { key: "Amount", label: "Amount", className: "w-200 fw-medium" },
  ];

  return (
    <>
      <AmortisationsTableWrap theme={theme} className="receivables-table-wrap">
        <Box theme={theme} className="custom-dropdown" pb={3}>
          <Typography
            theme={theme}
            className="text-muted"
            variant="body2"
            component={"span"}
            display={"block"}
          >
            Amortization Type:
          </Typography>
          <Select
            theme={theme}
            disablePortal={false}
            items={[
              { text: "Repayment Plan", value: "repayment" },
              { text: "Income Posting", value: "income" },
              { text: "Expense", value: "expense" },
              { text: "Commission", value: "commission" },
              { text: "Subsidy", value: "subsidy" },
            ]}
            onChange={handleCategoryChange}
            value={selectedCategory}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </Box>

        {/* Render table based on selected category */}
        <DataTableWrap
          theme={theme}
          className="data-table-wrap customize-table"
        >
          {selectedCategory === "repayment" && (
            <DataTable
              theme={theme}
              theadChildren={
                <TableRow>
                  {repaymentColumns.map((column) => (
                    <TableCell key={column.key} className={column.className}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              }
              tbodyChildren={
                <>
                  {repaymentPlan?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="text-dark-grey w-90">
                        {item.rentalNumber}
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        {new Date(item.rentalDueDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        <Box
                          theme={theme}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                          >
                            $
                          </Typography>
                          {item.openingPrincipal.toFixed(2)}
                        </Box>
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        <Box
                          theme={theme}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                          >
                            $
                          </Typography>
                          {item.rentalAmount.toFixed(2)}
                        </Box>
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        <Box
                          theme={theme}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                          >
                            $
                          </Typography>
                          {item.rentalPrincipal.toFixed(2)}
                        </Box>
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        <Box
                          theme={theme}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                          >
                            $
                          </Typography>
                          {item.rentalInterest.toFixed(2)}
                        </Box>
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        <Box
                          theme={theme}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                          >
                            $
                          </Typography>
                          {item.periodicInterest.toFixed(2)}
                        </Box>
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        <Box
                          theme={theme}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                          >
                            $
                          </Typography>
                          {item.closingPrincipal.toFixed(2)}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              }
            />
          )}

          {selectedCategory === "income" && (
            <DataTable
              theme={theme}
              theadChildren={
                <TableRow>
                  {incomeColumns.map((column) => (
                    <TableCell key={column.key} className={column.className}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              }
              tbodyChildren={
                <>
                  {incomingPosting?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="text-dark-grey w-px-100">
                        {item.rentalNumber}
                      </TableCell>
                      <TableCell className="text-dark-grey w-px-100">
                        {item.serialNumber}
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        {new Date(item.postingDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        {item.noOfDays}
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        {new Date(item.fromDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell className="text-dark-grey w-150">
                        {new Date(item.toDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell className="text-dark-grey w-200">
                        <Box
                          theme={theme}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            theme={theme}
                            variant="body2"
                            component={"span"}
                            className="text-dark-grey"
                          >
                            $
                          </Typography>
                          {item.amortizedAmount.toFixed(2)}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              }
            />
          )}

          {/* Add conditions for other categories (expense, commission, subsidy) as needed */}
        </DataTableWrap>
      </AmortisationsTableWrap>
    </>
  );
};
