import { useTheme } from "@mui/material"
import { Stack } from "@src/components"
import { Box, Typography, Tooltip } from "@ntpkunity/controls"
import { AiDataListingWrap } from "./ai-data-listing.style"
import img from "@src/assets/icons/ai-tick-arrow.svg"
import cross from "@src/assets/icons/ai-red-cross.svg"
import down from "@src/assets/icons/ai-down-arrow.svg"
import up from "@src/assets/icons/ai-up-arrow.svg"
import notVerify from "@src/assets/icons/ai-not-verify-icon.svg"
import { AiDataListingProp } from "./ai-data-listing.props"
import clsx from 'clsx'


export const SwotAnalysis = ({ items }: AiDataListingProp) => {
  const theme = useTheme();

  return (
    <>
      <AiDataListingWrap theme={theme} className="ai-data-listing-wrap">
        {items?.map((item, index) => (
          <Stack
            paddingXs={1.5}
            paddingMd={1.5}
            className="cde-card"
            key={index}
          >
            <Box theme={theme} className="card-head">
              <Box
                theme={theme}
                display={"flex"}
                alignItems={"center"}
                gap={"8px"}
              >
                {/* Icons based on item status */}
                {item.status === "strengths" && (
                  <img src={img} className="img" />
                )}
                {item.status === "opportunities" && (
                  <img src={up} className="img" />
                )}
                {item.status === "weaknesses" && (
                  <img src={cross} className="img" />
                )}
                {item.status === "threats" && (
                  <img src={down} className="img" />
                )}

                {/* Display only item.title in tooltip, NOT description */}
                {item.title && (
                  <Tooltip
                    theme={theme}
                    title={item.description || "No additional details"}
                    placement="top"
                  >
                    <Typography
                      theme={theme}
                      variant="caption"
                      component={"small"}
                      className="fw-medium caption-text"
                    >
                      {item.title}
                    </Typography>
                  </Tooltip>
                )}
              </Box>

              {/* Status indicators */}
              {item.success || item.error ? (
                <Box
                  theme={theme}
                  className={clsx({
                    status: true,
                    success: item.success,
                    error: item.error,
                  })}
                ></Box>
              ) : null}

              {/* Show Not Verified Icon */}
              {item.notVerify && <img src={notVerify} className="img" />}
            </Box>

            <Box theme={theme} className="card-body">
              {/* Ensure `description` appears only here */}
              {item.description && (
                <Typography
                  theme={theme}
                  variant="caption"
                  component={"small"}
                  className="text-muted caption-text"
                >
                  {item.description}
                </Typography>
              )}

              {/* Display Recommendation + Action Area */}
              {item.recommendation && (
                <Box
                  theme={theme}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    className="text-muted fw-medium"
                  >
                    Recommendation:
                  </Typography>
                  {item.actionArea}
                </Box>
              )}

              {/* Link Text */}
              {item.linkText && (
                <Box
                  theme={theme}
                  mt={0.4}
                  mb={0.4}
                  width={"100%"}
                  textAlign={"center"}
                >
                  <a
                    href="javascript:void(0)"
                    className="text-primary text-link"
                  >
                    {item.linkText}
                  </a>
                </Box>
              )}

              {/* Listing items (Ensure no duplication) */}
              {item.listing?.map((list, index2) => (
                <Tooltip
                  theme={theme}
                  title={list.description}
                  placement={"bottom"}
                  key={index2}
                >
                  <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    className="fw-medium label caption-text"
                  >
                    {list.title}
                  </Typography>
                </Tooltip>
              ))}
            </Box>
          </Stack>
        ))}
      </AiDataListingWrap>
    </>
  );
};