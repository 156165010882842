import { useTheme } from "@mui/material";
import { Box, Typography } from "@ntpkunity/controls";
import { OrderStatus } from "@src/helpers/enum";
import { clsx } from "clsx";

const DashboardPageHeaderStatus = ({ status }: { status: OrderStatus }) => {
    const theme = useTheme();
  return (
    <>
      <Box theme={theme}
        className={clsx("status-indicator", {
          "ind-danger": [OrderStatus.REJECTED, OrderStatus.EXPIRED].includes(status),
          "ind-success": status === OrderStatus.AUTOAPPROVE,
          "ind-warning": [OrderStatus.APPROVED].includes(status),
          "ind-contract": [OrderStatus.NEW].includes(status),
          // "ind-info": status === OrderStatus.APPROVED,
        })}
      >
        <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">{status}</Typography>
      </Box>
    </>
  );
};

export default DashboardPageHeaderStatus;
