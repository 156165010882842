import { styled } from "@mui/material";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const BasicListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.basic-list-wrap": {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    "&.text-nowrap": {
      ".list": {
        ".MuiTypography-root": {
          "&.subtitle": {
            whiteSpace: "wrap",
          },
        },
      },
    },
    ".list": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 4,
      ".MuiTypography-root": {
        width: "50%",
        "&.title": {
          color: "#474A59",
          width: 164,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "&.subtitle": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "&.text-right": {
            textAlign: "right",
          },
        },

      },
      ".action-area": {
        ".u-form-group": {
          ".u-form-control": {
            backgroundColor: "#F7F7F7",
            height: 36,
            "&.text-right": {
              ".MuiInputBase-input": {
                textAlign: 'right'
              }
            },
            ".adornment-icon": {
              "&.start-icon": {
                color: '#474A59'
              }
            },
            ".MuiInputBase-input": {
              padding: 8,
              maskImage: 'none',
              color: '#474A59',
              fontSize: theme.typography.body2.fontSize
            },
            fieldset: {
              border: 'none'
            }
          }
        },
      }
    },
    "&.input": {
      gap: 8,
      ".list": {
        display: "flex",
        alignItems: "flex-start",
        alignSelf: "stretch",
        flex: "1 0 0",
        gap: 16,
        ".title": {
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          alignSelf: "stretch",
          minWidth: 175,
          maxWidth: 191,
          padding: 8,
          "@media(max-width: 1599.95px)": {
            minWidth: 126,
            maxWidth: 152,
          },
        }
      },
      ".action-area": {
        ".u-form-group": {
          ".u-form-control": {
            color: theme.palette.grey[700],
            fontSize: theme.typography.body1.fontSize,
            "@media(max-width: 1599.95px)": {
              fontSize: theme.typography.body2.fontSize,
            },
            "&.MuiInputBase-adornedStart": {
              paddingLeft: 8,
              ".adornment-icon": {
                "&.start-icon": {
                  marginRight: 0,
                  fontSize: theme.typography.body1.fontSize,
                  fontWeight: theme.typography.body1.fontWeight,
                  lineHeight: theme.typography.body1.lineHeight,
                  color: '#474A59'
                }
              }
            },
            "&.text-right": {
              ".MuiInputBase-input": {
                textAlign: 'right',
                maskImage: 'none'
              }
            },
          }
        },
      }
    }
  },
}));
