import { useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  DuoTab,
  Grid,
  Icon,
  Label,
  ScrollableTabs,
  Select,
  Typography,
} from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { useSelector } from "react-redux";
import { OwnershipDocument } from "../tabs/ownership-documents/ownership-documents.component";
import { ComplianceCheckListTabWrap } from "./compliance-checklist-tabs.style";
import { fetchAllDocuments, fetchCustomerDocuments } from "@src/services/configureApplicationService";
import { ComplianceCheckListTable } from "../compliance-checklist-table/compliance-checklist-table.component";
import { ReceivablesTable } from "../receivables-table/receivables-table.component";
import { PayablesTable } from "../payables-table/payables-table.component";
import { AmortisationsTable } from "../amortisations-table/amortisations-table.component";

export const ComplianceCheckListTabs = ({repaymentPlan, incomingPosting}) => {
  const theme = useTheme();

  const [documentData, setDocumentData] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  const { applicantDetails, dialogLoading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  const { workQueueDetail } = useSelector((state: any) => state.workQueue);

  const isFetching = useRef(false);

  useEffect(() => {
    const loadDocuments = async () => {
      if (!applicantDetails?.reference_number || isFetching.current) return;

      isFetching.current = true;
      try {
        const data = await fetchAllDocuments(
          applicantDetails.reference_number,
          applicantDetails.customer_info.reference_id,
          workQueueDetail?.data?.x_api_key
        );
        const customerData = await fetchCustomerDocuments(
          applicantDetails.customer_info.reference_id,
          workQueueDetail?.data?.x_api_key
        );
        setDocumentData(data[0]?.documents);
        setCustomerData(customerData);
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
      }
      isFetching.current = false;
    };

    loadDocuments();

    return () => {
      isFetching.current = false;
    };
  }, [applicantDetails?.reference_number]);

  return (
    <>
      <ComplianceCheckListTabWrap
        theme={theme}
        className="compliance-checklist-tab-wrap"
      >
        <Box theme={theme} className="scrollable-tabs-wrap">
          <ScrollableTabs
            theme={theme}
            defaultTabIndex={0}
            scrollButtons="auto"
            items={[
              {
                content: (
                  <>
                    <Box theme={theme} className="scrollable-container">
                      <OwnershipDocument
                        documents={documentData}
                        customerData={customerData}
                        title="Funding Contracts"
                      />
                    </Box>
                  </>
                ),
                title: "Documents",
              },
              {
                content: (
                  <>
                    <Box theme={theme} className="scrollable-container">
                      <ComplianceCheckListTable />
                    </Box>
                  </>
                ),
                title: "Compliance Checklist",
              },
              {
                content: (
                  <>
                    <Box theme={theme} className="scrollable-container">
                      <ReceivablesTable />
                    </Box>
                  </>
                ),
                title: "Receivables",
              },
              {
                content: (
                  <>
                    <Box theme={theme} className="scrollable-container">
                      <PayablesTable />
                    </Box>
                  </>
                ),
                title: "Payables",
              },
              {
                content: (
                  <>
                    <Box theme={theme} className="scrollable-container">
                      <AmortisationsTable incomingPosting={incomingPosting} repaymentPlan={repaymentPlan} />
                    </Box>
                  </>
                ),
                title: "Amortizations",
              },
            ]}
          />
        </Box>
      </ComplianceCheckListTabWrap>
    </>
  );
};
