export const FETCH_WORKQUEUE_REQUEST = "FETCH_WORKQUEUE_REQUEST";
export const FETCH_WORKQUEUE_SUCCESS = "FETCH_WORKQUEUE_SUCCESS";
export const FETCH_WORKQUEUE_FAILURE = "FETCH_WORKQUEUE_FAILURE";

export const RETRIEVE_WORKQUEUE_REQUEST = "RETRIEVE_WORKQUEUE_REQUEST";
export const RETRIEVE_WORKQUEUE_SUCCESS = "RETRIEVE_WORKQUEUE_SUCCESS";
export const RETRIEVE_WORKQUEUE_FAILURE = "RETRIEVE_WORKQUEUE_FAILURE";

export const UPDATE_RUNNING_WORKFLOW_ID = "UPDATE_RUNNING_WORKFLOW_ID";
export const UPDATE_CREDIT_CHECK_SCORE = "UPDATE_CREDIT_CHECK_SCORE"; 

export const UPDATE_SORT_ORDER = "UPDATE_SORT_ORDER";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_PAGINATION = "UPDATE_PAGINATION";

export const EDIT_WORKQUEUE_REQUEST = "EDIT_WORKQUEUE_REQUEST";
export const EDIT_WORKQUEUE_SUCCESS = "EDIT_WORKQUEUE_SUCCESS";
export const EDIT_WORKQUEUE_FAILURE = "EDIT_WORKQUEUE_FAILURE";

export const DELETE_WORKQUEUE_REQUEST = "DELETE_WORKQUEUE_REQUEST";
export const DELETE_WORKQUEUE_SUCCESS = "DELETE_WORKQUEUE_SUCCESS";
export const DELETE_WORKQUEUE_FAILURE = "DELETE_WORKQUEUE_FAILURE";

export const UPDATE_ASSIGNED_ROW_ID = "UPDATE_ASSIGNED_ROW_ID";

export const UPDATE_DATE_FILTER = "UPDATE_DATE_FILTER";

export const UPDATE_WORKQUEUE_FILTERS = "UPDATE_WORKQUEUE_FILTERS";

export const UPDATE_API_KEY = "UPDATE_API_KEY";

export const UPDATE_APPLICANT_TYPE = "UPDATE_APPLICANT_TYPE";


// export const fetchWorkqueueRequest = () => ({
//   type: FETCH_WORKQUEUE_REQUEST,
// // payload,
// });

export const fetchWorkqueueRequest = (
  pageNumber: number,
  pageSize: number,
  filters: { [key: string]: any } = {},
  selectedTab?: String,
  apiKey?: string
) => ({
  type: FETCH_WORKQUEUE_REQUEST,
  payload: { pageNumber, pageSize, filters, selectedTab, apiKey },
});


export const fetchWorkqueueSuccess = (data) => ({
  type: FETCH_WORKQUEUE_SUCCESS,
  payload: data,
});

export const fetchWorkqueueFailure = (error) => ({
  type: FETCH_WORKQUEUE_FAILURE,
  payload: error,
});

export const retrieveWorkqueueRequest = (applicantReferenceNum) => ({
  type: RETRIEVE_WORKQUEUE_REQUEST,
  payload: applicantReferenceNum,
});


export const retrieveWorkqueueSuccess = (data) => ({
  type: RETRIEVE_WORKQUEUE_SUCCESS,
  payload: data,
});

export const retrieveWorkqueueFailure = (error) => ({
  type: RETRIEVE_WORKQUEUE_FAILURE,
  payload: error,
});

export const updateRunningWorkflowId = (id) => ({
  type: UPDATE_RUNNING_WORKFLOW_ID,
  payload: id,
});

export const updateAssignedRowId = (rowId) => ({
  type: UPDATE_ASSIGNED_ROW_ID,
  payload: rowId,
});

export const updateCreditCheckScore = (score) => ({
  type: UPDATE_CREDIT_CHECK_SCORE,
  payload: score,
});

export const updateSortOrder = (column, order) => ({
  type: UPDATE_SORT_ORDER,
  payload: { column, order },
});

export const updateFilters = (filters) => ({
  type: UPDATE_FILTERS,
  payload: filters,
});

export const updatePagination = (pageNumber, pageSize) => ({
  type: UPDATE_PAGINATION,
  payload: { pageNumber, pageSize },
});

export const editWorkqueueRequest = (id, updatedData) => ({
  type: EDIT_WORKQUEUE_REQUEST,
  payload: { id, updatedData },
});

export const editWorkqueueSuccess = (updatedRow) => ({
  type: EDIT_WORKQUEUE_SUCCESS,
  payload: updatedRow,
});

export const editWorkqueueFailure = (error) => ({
  type: EDIT_WORKQUEUE_FAILURE,
  payload: error,
});

export const deleteWorkqueueRequest = (id) => ({
  type: DELETE_WORKQUEUE_REQUEST,
  payload: id,
});

export const deleteWorkqueueSuccess = (id) => ({
  type: DELETE_WORKQUEUE_SUCCESS,
  payload: id,
});

export const deleteWorkqueueFailure = (error) => ({
  type: DELETE_WORKQUEUE_FAILURE,
  payload: error,
});


export const updateDateFilter = (startDate, endDate) => ({
  type: "UPDATE_DATE_FILTER",
  payload: { startDate, endDate },
});


export const markRowAsRead = (referenceNumber) => ({
  type: "MARK_ROW_AS_READ",
  payload: referenceNumber,
});

export const updateWorkqueueFilters = (filters) => ({
  type: UPDATE_WORKQUEUE_FILTERS,
  payload: filters,
});

export const updateApiKey = (apiKey) => ({
  type: UPDATE_API_KEY,
  payload: apiKey,
});

export const updateApplicantType = (applicantType) => ({
  type: UPDATE_APPLICANT_TYPE,
  payload: applicantType,
});
