import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AssetDetailDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.asset-detail-dialog-wrap": {
    padding: '0 8px',
    ".basic-list-wrap": {
      ".list": {
        padding: '0 8px',
        ".MuiTypography-root": {
          "&.title": {
            width: '30%',
            minWidth: 135
          },
          "&.subtitle": { 
            width: '70%'
          }
        }
      }
    }
  },
}));
