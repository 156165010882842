import { useTheme } from "@mui/material";
import { Select, Grid, Textarea, Box, Button, Typography } from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { addDecisionRequest, fetchDecisionRequest } from "@src/components/redux/actions/decisionActions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deleteStip } from "@src/components/redux/actions/stipActions";
import { useParams } from "react-router-dom";
import { retrieveWorkqueueRequest } from "@src/components/redux/actions/workQueueActions";
import { NoStipulation } from "../final-decision/no-stipulation.component"
import { FinalDecisionWrap } from "./final-decision.style"
import { AddedStipulation } from "../added-stipulation/added-stipulation.component"

export const FinalDecision = ({ setActiveTab }) => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { reference_number } = useParams();

    const assignedRowId = useSelector((state: any) => state.workQueue.assignedRowId);
    const workQueues = useSelector((state: any) => state.workQueue.workQueues);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        const currentRecord = workQueues.find(
            (item) => item.reference_number === reference_number
        );

        if (currentRecord) {
            const status = currentRecord.status?.toLowerCase();
            if (status === "approved" || status === "declined") {
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
        }
    }, [workQueues, reference_number]);

    const stipulations = useSelector((state: any) => state.stip.stips);

    const [formState, setFormState] = useState({
        credit_decision_status: "",
        commentType: "",
        comments: "",
    });

    const handleInputChange = (key, value) => {
        setFormState((prev) => ({ ...prev, [key]: value }));
    };

    const handleAddDecision = () => {
        if (!formState.credit_decision_status) {
            alert("Please select a decision type and provide a comment.");
            return;
        }

        const payload = {
            status: formState.credit_decision_status,
            comments: formState.comments || "",
            stipulations: stipulations.map((stip) => ({
                stipulation_code: stip.stipulationType,
                stipulation_description: stip.recommendation,
                stipulation_comments: stip.comments || "",
            })),
        };

        dispatch(addDecisionRequest(assignedRowId, payload));
        dispatch(retrieveWorkqueueRequest(reference_number));
        setTimeout(() => setActiveTab(1), 1000);
        setTimeout(() => {
            dispatch(fetchDecisionRequest(assignedRowId));
        }, 1000);

        setTimeout(() => setActiveTab(1), 1500);
    };

    return (
        <>
            <FinalDecisionWrap theme={theme} className="final-decision-wrap">
                <Stack paddingMd={2} paddingXs={2} title="Final Decision">
                    <Box theme={theme} className="scrollable-container">
                        <Grid theme={theme} container rowSpacing={2} columnSpacing={2} mt={0}>
                            <Grid item xs={6} theme={theme}>
                                <Select
                                    theme={theme}
                                    disablePortal={false}
                                    label={"Decision Type"}
                                    items={[
                                        { text: "Approved", value: "Approved" },
                                        { text: "Conditional Approved", value: "Conditional Approved" },
                                        {
                                            text: "Additional Information Required",
                                            value: "Additional Information Required",
                                        },
                                        { text: "Conditioned", value: "Conditioned" },
                                        { text: "Referred", value: "Referred" },
                                        { text: "Declined", value: "Declined" },
                                    ]}
                                    onChange={(e) =>
                                        handleInputChange("credit_decision_status", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} theme={theme}>
                                <Select
                                    theme={theme}
                                    disablePortal={false}
                                    label={"Comment Type"}
                                    items={[
                                        { text: "General Comments", value: "select1" },
                                        { text: "Internal Comments", value: "select2" },
                                        { text: "Dealer Comments", value: "select3" },
                                    ]}
                                    onChange={(e) => handleInputChange("commentType", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} theme={theme}>
                                <Textarea
                                    theme={theme}
                                    id=""
                                    type="text"
                                    fullWidth
                                    textCount={"10"}
                                    totalTextCount={"60"}
                                    rows={3}
                                    label="Comments"
                                    value={formState.comments}
                                    onChange={(e) => handleInputChange("comments", e)}
                                ></Textarea>
                            </Grid>
                            <Grid item xs={12} theme={theme}>
                                <Stack paddingMd={2} paddingXs={2} bgLight className="text-body2" title="Added Stipulations" fullWidth>
                                    <Box theme={theme} height={'96px'}>
                                        <Box theme={theme}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}

                                        >
                                            {/* <Typography theme={theme} variant="caption" component={'small'} className="text-dark-grey">No Stipulation added.</Typography> */}

                                        </Box>
                                        <Grid
                                            theme={theme}
                                            container
                                            rowSpacing={1}
                                            columnSpacing={1}
                                            sx={{ mt: 1 }}
                                        >
                                            <Grid item xs={6} theme={theme}>
                                                <AddedStipulation />
                                            </Grid>
                                            <Grid item xs={6} theme={theme}>
                                                <AddedStipulation />
                                            </Grid>
                                            <Grid item xs={6} theme={theme}>
                                                <AddedStipulation />
                                            </Grid>
                                            <Grid item xs={6} theme={theme}>
                                                <AddedStipulation />
                                            </Grid>
                                        </Grid>
                                        
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box theme={theme} mt={2}>
                        <Button
                            theme={theme}
                            primary
                            text="Send Decision"
                            onClick={handleAddDecision}
                            disabled={isDisabled}
                            style={{
                                pointerEvents: isDisabled ? "none" : "auto",
                                opacity: isDisabled ? 0.5 : 1,
                            }}
                            fullWidth
                        />
                    </Box>
                </Stack>
            </FinalDecisionWrap>
        </>
    );
};