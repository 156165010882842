import { UPDATE_COMMENT, SAVE_RULE, RESET_RULE } from "../actions/buisinessRulesActions";

const initialState = {
  comments: {},
  savedRules: {},
};

const businessRulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.payload.referenceNumber]: action.payload.comment,
        },
      };
    case SAVE_RULE:
      return {
        ...state,
        savedRules: {
          ...state.savedRules,
          [action.payload.referenceNumber]: true,
        },
      };
    case RESET_RULE:
      return {
        ...state,
        savedRules: {
          ...state.savedRules,
          [action.payload.referenceNumber]: false,
        },
      };
    default:
      return state;
  }
};

export default businessRulesReducer;