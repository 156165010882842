import { useTheme } from "@mui/material";
import { Box, Typography, Icon } from "@ntpkunity/controls";
import { BasicStackWrap } from "./basic-stack.style"
import { ReactNode } from "react";
import clsx from "clsx";

export const BasicStack = ({
  text,
  subTitle,
  fullWidth,
  textJustify,
  actionArea,
}: {
  text?: string | ReactNode;
  subTitle?: string | ReactNode;
  fullWidth?: boolean;
  textJustify?: boolean;
  actionArea?: ReactNode;
}) => {
  const theme = useTheme();
  return (
    <BasicStackWrap
      theme={theme}
      className={clsx({
        "basic-stack-wrap": true,
        "w-100": fullWidth,
        "text-justify": textJustify
      })}
    >
      <Box theme={theme} className="text-wrap">
        {text &&
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            className="text-dark-grey"
          >
            {text}
          </Typography>
        }
        {subTitle &&
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
            className="fw-medium"
          >
            {subTitle}
          </Typography>
        }
      </Box>
      {actionArea && (
        <Box theme={theme} className="action-area">
          {actionArea}
        </Box>
      )}
    </BasicStackWrap>
  );
};
