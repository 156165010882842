import { useTheme } from "@mui/material";
import { StickyNotesListWrap } from "./sticky-notes-list.style";
import { StickyNotes } from "../sticky-notes/sticky-notes.component";
import { clsx } from 'clsx'
import { useParams } from "react-router-dom";
import { Stack } from "@src/components/stack";
import { ResponsiveCarousel } from "@src/components";
import { Box } from "@ntpkunity/controls";
import { useState } from "react";

export const StickyNotesList = ({ stickyNotesData }) => {
  const theme = useTheme();
  const { reference_number } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);

  const extractColor = (comment) => {
    const match = comment.match(/#color=([\w-]+)/);
    return match ? match[1] : "light-yellow";
  };

  const extractTitle = (comment) => {
    const match = comment.match(/#priority=([\w\s-]+)/);
    // return match ? "Broker" : match[1];
    return match ? match[1] : "Sticky Note";
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const handleAfterChange = (nextIndex) => {
    // Set the current index to the next slide's index
    setCurrentIndex(nextIndex);
    // Calculate the new transform value based on the index
    const translateX = -220 * nextIndex; // Multiply by 220px for each slide
    const trackElement = document.querySelector(".react-multi-carousel-track") as HTMLElement;

    if (trackElement) {
      trackElement.style.transform = `translate3d(${translateX}px, 0px, 0px)`; // Apply the new transform value
    }
  };

  return (
    <>
      <StickyNotesListWrap theme={theme} className="sticky-notes-list-wrap">
        {stickyNotesData && stickyNotesData.length > 0 && stickyNotesData.map((note, index) => {
          const parsedComment = note.comment.replace(/#\w+=\S+/g, "").trim();
          const color = extractColor(note.comment);
          const title = extractTitle(note.comment);
          const identifier = note.identifier;

          return (
            <StickyNotes
              key={index}
              title={title}
              subTitle={parsedComment}
              color={color}
              identifier={identifier}
            />
          );
        })}
      </StickyNotesListWrap>
    </>
  );
};

