import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_MY_NOTES_REQUEST,
  fetchMyNotesSuccess,
  fetchMyNotesFailure,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  CREATE_NOTE_REQUEST,
  createNoteSuccess,
  fetchMyNotesRequest,
} from "../actions/myNotesActions";

function* fetchMyNotesSaga(action) {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const referenceNumber = action.payload;

    if (!referenceNumber) {
      throw new Error("Missing order reference ID");
    }

    const response = yield call(
      axios.get,
      `${apiUrl}/cde/comments`,
      { headers: { "x-api-key": apiKey } }
    );

    if (response?.data?.result) {
      yield put(fetchMyNotesSuccess(response.data.result));
    } else {
      throw new Error("Empty response from API");
    }
  } catch (error) {
    console.error("API Call Failed:", error);
    yield put(fetchMyNotesFailure(error.response?.data?.message || error.message));
  }
}


function* createNoteSaga(action) {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;

    const response = yield call(axios.post, `${apiUrl}/dms/customer/comment`, action.payload, {
      headers: { "x-api-key": apiKey, "Content-Type": "application/json" },
    });

      yield put(createNoteSuccess(response.data));
      yield put(fetchMyNotesRequest(action.payload.order_reference_id));
  } catch (error) {
    console.error("Create Note API Call Failed:", error);
    yield put({ type: CREATE_NOTE_FAILURE, payload: error.response?.data?.message || "Failed to create note." });
  }
}

export function* watchMyNotesSaga() {
  yield takeLatest(FETCH_MY_NOTES_REQUEST, fetchMyNotesSaga); 
  yield takeLatest(CREATE_NOTE_REQUEST, createNoteSaga);
}
