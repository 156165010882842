import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const MultiFunctionsWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.multi-functions-wrap": {
        ".multi-functions": {
            display: "flex",
            alignItems: "center",
            gap: 4,
            ".datepicker": {
                "&.expanded": {
                    ".date-range": {
                        minWidth: "230px",
                    }
                },
                ".date-range": {
                    padding: "8px 16px",
                    minWidth: 120,
                    maxWidth: "auto",
                    transition: "width 0.3s ease-in-out",
                    borderRadius: 8,
                    width: "120px",

                    "&:hover": {
                        backgroundColor: CustomColors.lightGrey,
                        ".rmdp-container": {
                            input: {
                                backgroundColor: CustomColors.lightGrey,
                            },
                        },
                    },
                    "&.focused": {
                        backgroundColor: CustomColors.lightGrey,
                        ".rmdp-container": {
                            input: {
                                backgroundColor: CustomColors.lightGrey,
                                "&::placeholder": {
                                    color: theme.palette.grey[900],
                                    opacity: 1
                                }
                            },
                        },
                    },
                    ".rmdp-container": {
                        ".ep-arrow": {
                            "+": {
                                div: {
                                    zIndex: "1201 !important",
                                },
                            },
                        },
                        input: {
                            height: "auto",
                            padding: 0,
                            paddingLeft: 24,
                            border: "none",
                            cursor: 'pointer',
                            width: "100%",
                            transition: "width 0.3s ease-in-out",

                            "&::placeholder": {
                                color: theme.palette.grey[600],
                                opacity: 1
                            }
                        },
                        "&:nth-child(3)": {
                            zIndex: 1201,
                        }
                    },
                    ".calendar-icon": {
                        left: 16,
                        top: 8,
                    },
                },
            },
            ".sort-btn": {
                display: "flex",
                alignItems: "center",
                padding: "8px 16px",
                borderRadius: 8,
                "&:hover": {
                    backgroundColor: CustomColors.lightGrey,
                },
                "&:focus": {
                    backgroundColor: CustomColors.lightGrey,
                },
                "&.custom-dropdown": {
                    position: "relative",
                    ".sort-icon": {
                        position: "absolute",
                        left: 16,
                        width: 20,
                        height: 20
                    },
                    ".u-form-group": {
                        ".u-form-control": {
                            "&.u-select": {
                                fontWeight: theme.typography.body2.fontWeight,
                                color: theme.palette.grey[600],
                                ".MuiInputBase-input": {
                                    padding: "0px 0px 0px 32px",
                                    color: theme.palette.grey[600],
                                    fontWeight: theme.typography.body2.fontWeight,
                                    fontSize: theme.typography.body2.fontSize,
                                    lineHeight: theme.typography.body2.lineHeight,
                                },
                                ".MuiSelect-icon": {
                                    display: "none",
                                },
                            },
                        },
                    },
                },
            },
            ".emails": {
                display: "flex",
                alignItems: "center",
                position: 'relative',
                gap: 8,
                padding: '8px 16px',
                ".notify": {
                    display: 'none'
                },
                "&.unread": {
                    ".notify": {
                        display: 'block',
                        position: 'absolute',
                        right: -4,
                        top: -2,
                        width: '12px',
                        height: '12px',
                        backgroundColor: theme.palette.primary.main,
                        border: '3px solid',
                        borderColor: theme.palette.common.white,
                        borderRadius: 24
                    }
                }
            },
            ".search": {
                position: 'relative',
                ".search-input": {
                    position: 'absolute',
                    width: 220,
                    right: 0,
                    top: 0,
                    borderRadius: 8,
                    backgroundColor: theme.palette.common.white,
                }
            }
        },
    }
}));
