import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const RiskAssessmentStackWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.risk-assessment-stack-wrap": {
        ".risk-assessment-alerts": {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            ".basic-stack-wrap": {
                ".text-wrap": {
                    ".MuiTypography-caption": {
                        fontSize: theme.typography.body2.fontSize,
                        fontWeight: theme.typography.body2.fontWeight,
                        lineHeight: theme.typography.body2.lineHeight,
                        color: CustomColors.darkGrey,
                        "@media(min-width: 1800px)": {
                            fontSize: theme.typography.body2.fontSize,
                            fontWeight: theme.typography.body2.fontWeight,
                            lineHeight: theme.typography.body2.lineHeight,
                        }
                    }
                }
            },
            ".top-content": {
                ".alert-text": {
                    ".MuiTypography-caption": {
                        fontSize: theme.typography.body2.fontSize,
                        fontWeight: theme.typography.body2.fontWeight,
                        lineHeight: theme.typography.body2.lineHeight,
                        color: CustomColors.darkGrey,
                        "@media(min-width: 1800px)": {
                            fontSize: theme.typography.body2.fontSize,
                            fontWeight: theme.typography.body2.fontWeight,
                            lineHeight: theme.typography.body2.lineHeight,
                        }
                    }
                }
            }
        }
    },
}));
