
import { useTheme } from "@mui/material";
import { Box, Button, Grid, Icon, Label } from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ComplianceCheckListTabs } from "../compliance-checklist-tabs/compliance-checklist-tabs.component";
import { ComplianceChecklistDialog } from "../compliance-checklist-popup/compliance-checklist-dialog.component";

export const ComplianceCheckList = () => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseDialog = () => {
    setIsOpen(false);
  };
  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Stack
        title={"Documents"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <a href="javascript:void(0)" className="text-primary primary-alert dFlex alignItemsCenter" onClick={() => handleOpenDialog()}>
            Compliance Checklist <Icon name="ArrowLinkIcon" />
          </a>
        }
      >
        <Box theme={theme} mt={2}>
          <ComplianceCheckListTabs />
        </Box>
      </Stack>
      <ComplianceChecklistDialog open={isOpen} closeDialog={handleCloseDialog} />
    </>
  );
};
