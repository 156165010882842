import { useTheme } from "@mui/material";
import { Box, Grid, Typography } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import { Alert } from "@src/components";
import { useSelector } from "react-redux";
import { CircleLoader } from "@ntpkunity/controls";
import formatNumber from "@src/utilities/fomatNumber";

export const AssetDetailsList = () => {
  const theme = useTheme();

  const { applicantDetails, loading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  return (
    <>
      <Grid
        theme={theme}
        container
        rowSpacing={0}
        columnSpacing={2}
        sx={{ mt: 0 }}
      >
        {loading ? (
          <CircleLoader theme={theme} size="sm" />
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <Grid item xs={6} theme={theme}>
            <Stack paddingMd={0} paddingXs={0}>
              <BasicList
                noWrap
                options={[
                  {
                    title: "Make:",
                    subtitle: applicantDetails?.Vehicle_details?.make ?? "N/A",
                  },
                  {
                    title: "Modal:",
                    subtitle: applicantDetails?.Vehicle_details?.model ?? "N/A",
                  },
                  {
                    title: "Year:",
                    subtitle: applicantDetails?.Vehicle_details?.year ?? "N/A",
                  },
                  {
                    title: "Trim:",
                    subtitle:
                      applicantDetails?.Vehicle_details?.trim_description ??
                      "N/A",
                  },
                  {
                    title: "VIN:",
                    subtitle: applicantDetails?.Vehicle_details?.vin ?? "N/A",
                  },
                ]}
              />
            </Stack>
          </Grid>
        )}
        <Grid item xs={6} theme={theme}>
          <Stack paddingMd={0} paddingXs={0}>
            <BasicList
              options={[
                {
                  title: "Condition:",
                  subtitle: applicantDetails?.Vehicle_details?.type ?? "N/A",
                },
                {
                  title: "Price:",
                  subtitle:
                    formatNumber(applicantDetails?.selling_price) ?? "N/A",
                },
                {
                  title: "Exterior Color",
                  subtitle:
                    applicantDetails?.order_asset?.exterior_color_description ??
                    "N/A",
                },
                {
                  title: "Engine:",
                  subtitle:
                    applicantDetails?.order_asset?.engine_description ?? "N/A",
                },
                {
                  title: "Drive Train:",
                  subtitle: applicantDetails?.order_asset?.drive_train ?? "N/A",
                },
              ]}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
