import { useTheme } from "@mui/material";
import { Button, CircleLoader, Grid, Icon } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import { useEffect, useState } from "react";
import { ApplicantDetailDialog } from "../applicant-detail-dialog/applicant-detail-dialog.component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchApplicantDetailsRequest,
} from "@src/components/redux/actions/applicantDetailsActions";
import moment from "moment";

export const ApplicantDetailsStack = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const { applicantDetails, loading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  localStorage.setItem("reference_number", applicantDetails?.status);

  const { reference_number } = useParams();

  useEffect(() => {
    if (reference_number) {
    }
    dispatch(fetchApplicantDetailsRequest(reference_number));
  }, [dispatch, reference_number]);

  const handleOpenDialog = (reference_number) => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  }; 

  const getYearsMonths = (date) => {
    if (!date) return "N/A";

    const startDate = moment(date);
    const now = moment();
    const years = now.diff(startDate, "years");
    const months = now.diff(startDate, "months") % 12;

    return `${years} years ${months} months`;
  };
  const fields = [
    {
      title: "Client Type:",
      key: () => 'Limited Company'
    },
    {
      title: "Company Name:",
      key: () => "HiTech Ltd"
    },
    {
      title: "Company Reg. No.",
      key: () => "445790"
    },
    {
      title: "Trading As:",
      key: () => "Manufacturer"},
    {
      title: "SIC Code:",
      key: () =>  "N/A",
    },
    {
      title: "Nature of Business:",
      key: () => "Spare Parts",
    },
    {
      title: "Business Age:",
      key: () => "15 yrs",
    },
    {
      title: "Address:",
      key: () => "246 E Watkins St, Phoenix",
    },
    // {
    //   title: "Employment Time:",
    //   key: () => formattedDate(applicantDetails?.customer_info?.updated_at),
    // },
  ];

  return (
    <>
      <Stack
        title={"Applicant Details"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={
              <>
                <Icon name="ArrowLinkIcon" />
              </>
            }
            onClick={() => handleOpenDialog(reference_number)}
          />
        }
      >
        {loading ? (
          <CircleLoader theme={theme} size={"sm"} />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <BasicList
            options={fields.map((field) => ({
              title: field.title,
              subtitle: typeof field.key === "function" ? field.key() : "N/A",
            }))}
          />
        )}
      </Stack>
      <ApplicantDetailDialog open={isOpen} closeDialog={handleCloseDialog} />
    </>
  );
};
