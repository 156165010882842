import { useTheme } from "@mui/material"
import { AiAssistantListWrap } from "./ai-assistant-list.style"
import { Accordion, Box, Typography, Input, CircleLoader } from "@ntpkunity/controls";
import { SwotAnalysis } from "./ai-data-listing/ai-data-listing.component"
import swotImg from "@src/assets/icons/ai-swot-analysis.svg"
import applicantIcon from "@src/assets/icons/applicant-icon.svg"
import assetIcon from "@src/assets/icons/asset-icon.svg"
import stipIcon from "@src/assets/icons/stipulation-icon.svg"
import financeIcon from "@src/assets/icons/finance-icon.svg"
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components"
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchModuleData } from "@src/components/api/ai-assistance/aiAssistantList";

export const AiAssistantList = () => {
    const theme = useTheme();

    const { reference_number } = useParams();
  const base_url = process.env.REACT_APP_API_URL;

    const [assetData, setAssetData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);

    
    const [applicantData, setApplicantData] = useState<any>(null);
    const [applicantLoading, setApplicantLoading] = useState(false);
    const [applicantError, setApplicantError] = useState<any>(null);

    const [swotData, setSwotData] = useState<any>(null);
    const [swotLoading, setSwotLoading] = useState<any>(false);
    const [swotError, setSwotError] = useState<any>(null);

    const [financeData, setFinanceData] = useState<any>(null);
    const [financeLoading, setFinanceLoading] = useState<any>(false);
    const [financeError, setFinanceError] = useState<any>(null);

    const [stipsData, setStipsData] = useState<any>(null);
    const [stipsLoading, setStipsLoading] = useState<any>(true);
    const [stipsError, setStipsError] = useState<any>(null);

  const { running_workflow_instance_id } = useSelector(
    (state: any) => state.workQueue
  );

  useEffect(() => {
    const fetchAssetData = async () => {
      setLoading(true);
      setError(null);
      try {
        const asset_researcher = await fetchModuleData(
          running_workflow_instance_id,
          "asset_researcher"
        );
        if (asset_researcher?.data?.topics?.length > 0) {
          const formattedData = asset_researcher.data.topics.map((topic: any) => ({
            title: topic.heading || "No Title",
            listing: [
              {
                title: topic.short_description || "No Description Available",
                description: topic.description,
              },
            ],
          }));
          setAssetData(formattedData);
        } else {
          setAssetData([]);
          setError("No asset insights available.");
        }
      } catch (err) {
        console.error("Error fetching Asset Insights:", err);
        setError("Failed to fetch Asset Insights data. Please try again.");
        setAssetData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAssetData();
  }, [reference_number, running_workflow_instance_id]);

  useEffect(() => {
    const fetchApplicantData = async () => {
      setApplicantLoading(true);
      setApplicantError(null);
      try {
        const applicant_researcher = await fetchModuleData(
          running_workflow_instance_id,
          "applicant_researcher"
        );
        if (applicant_researcher?.data?.topics?.length > 0) {
          const formattedData = applicant_researcher.data.topics.map((topic: any) => ({
            title: topic.heading || "No Title",
            listing: [
              {
                title: topic.short_description || "No Description Available",
                description: topic.description,
              },
            ],
          }));
          setApplicantData(formattedData);
        } else {
          setApplicantData([]);
          setApplicantError("No applicant insights available.");
        }
      } catch (error) {
        console.error("Error fetching applicant insights:", error);
        setApplicantError("Failed to fetch Applicant Insights data. Please try again.");
        setApplicantData([]);
      } finally {
        setApplicantLoading(false);
      }
    };

    if (reference_number) {
      fetchApplicantData();
    }
  }, [reference_number, running_workflow_instance_id]);

  useEffect(() => {
    const fetchSwotData = async () => {
      setSwotLoading(true);
      setSwotError(null);
      try {
        const swot = await fetchModuleData(running_workflow_instance_id, "swot");
        if (
          swot?.data &&
          (swot.data.strengths?.length ||
            swot.data.weaknesses?.length ||
            swot.data.opportunities?.length ||
            swot.data.threats?.length)
        ) {
          const formattedData = [
            {
              title: "Strengths",
              status: "strengths",
              listing: swot.data.strengths?.map((item: any) => ({
                title: item.summary || "",
                description: item.description || "",
              })) || [],
            },
            {
              title: "Weaknesses",
              status: "weaknesses",
              listing: swot.data.weaknesses?.map((item: any) => ({
                title: item.summary || "",
                description: item.description || "",
              })) || [],
            },
            {
              title: "Opportunities",
              status: "opportunities",
              listing: swot.data.opportunities?.map((item: any) => ({
                title: item.summary || "",
                description: item.description || "",
              })) || [],
            },
            {
              title: "Threats",
              status: "threats",
              listing: swot.data.threats?.map((item: any) => ({
                title: item.summary || "",
                description: item.description || "",
              })) || [],
            },
          ];
          setSwotData(formattedData);
        } else {
          setSwotData([]);
          setSwotError("No SWOT analysis available.");
        }
      } catch (error) {
        console.error("Error fetching SWOT analysis:", error);
        setSwotError("Failed to fetch SWOT analysis. Please try again.");
        setSwotData([]);
      } finally {
        setSwotLoading(false);
      }
    };

    if (reference_number) {
      fetchSwotData();
    }
  }, [reference_number, running_workflow_instance_id]);

  useEffect(() => {
    const fetchStips = async () => {
      setStipsLoading(true);
      setStipsError(null);
      try {
        const analyzer = await fetchModuleData(running_workflow_instance_id, "analyzer");
        if (analyzer?.data?.stips?.length > 0) {
          setStipsData(analyzer.data.stips);
        } else {
          setStipsData([]);
          setStipsError("No stipulations available.");
        }
      } catch (error) {
        console.error("Error fetching stipulations:", error);
        setStipsError("Failed to fetch Stipulations data. Please try again.");
        setStipsData([]);
      } finally {
        setStipsLoading(false);
      }
    };

    if (reference_number) {
      fetchStips();
    }
  }, [reference_number, running_workflow_instance_id]);

    // useEffect(() => {
    //     const fetchFinanceData = async () => {
    //         try {
    //             const payload = {
    //                 payload: {
    //                     bmw_internal_exposure: 100,
    //                     corporate_bank_loan_exposure: 2000,
    //                     credit_utilisation_ratio: 0.3,
    //                     debt_of_credit_card: 5000,
    //                     dti_ratio: 0.5,
    //                     lti_ratio: 1.5,
    //                     ltv_ratio: 0.8,
    //                     total_monthly_income_after_tax: 8000,
    //                     total_monthly_instalment: 1500,
    //                 },
    //             };

    //             const response = await fetch("https://dev-api-k8s.netsolapp.io/check/api/v1/analyzer", {
    //                 method: "POST",
    //                 headers: { "Content-Type": "application/json" },
    //                 body: JSON.stringify(payload),
    //             });

    //             const result = await response.json();

    //             if (result?.data?.revised_finance_structure) {
    //                 setFinanceData(result.data.revised_finance_structure);
    //             } else {
    //                 setFinanceData(null);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching finance data:", error);
    //             setFinanceError("Failed to fetch Finance Structure data.");
    //         } finally {
    //             setFinanceLoading(false);
    //         }
    //     };

    //     fetchFinanceData();
    // }, []);

    return (
      <>
        <AiAssistantListWrap theme={theme} className="ai-accordion-wrap">
          <Accordion
            theme={theme}
            items={[
              {
                content: (
                  <>
                    {swotLoading ? (
                      <CircleLoader theme={theme} size="sm" />
                    ) : swotError ? (
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="fw-medium caption-text"
                      >
                        {swotError}
                      </Typography>
                    ) : (
                      <SwotAnalysis
                        items={
                          swotData && swotData.length > 0
                            ? swotData
                            : [
                                {
                                  title: "No Data Available",
                                  listing: [
                                    {
                                      title:
                                        "Please check the API response or connectivity.",
                                    },
                                  ],
                                },
                              ]
                        }
                      />
                    )}
                  </>
                ),
                title: (
                  <>
                    <Box
                      theme={theme}
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <img src={swotImg} />
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="fw-medium caption-text"
                      >
                        SWOT Analysis
                      </Typography>
                    </Box>
                  </>
                ),
                key: "1",
                isExpanded: false,
                id: "1",
                ariaControls: "1",
              },
              {
                content: (
                  <>
                    {applicantLoading ? (
                      <CircleLoader theme={theme} size="sm" />
                    ) : applicantError ? (
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="fw-medium caption-text"
                      >
                        {applicantError}
                      </Typography>
                    ) : (
                      <SwotAnalysis
                        items={
                          applicantData &&
                          Array.isArray(applicantData) &&
                          applicantData.length > 0
                            ? applicantData
                            : [
                                {
                                  title: "No Data Available",
                                  listing: [
                                    {
                                      title:
                                        "Please check the API response or connectivity.",
                                    },
                                  ],
                                },
                              ]
                        }
                      />
                    )}
                  </>
                ),
                title: (
                  <>
                    <Box
                      theme={theme}
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <img src={applicantIcon} alt="Applicant Icon" />
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="fw-medium caption-text"
                      >
                        Applicant Insights
                      </Typography>
                    </Box>
                  </>
                ),
                key: "2",
                isExpanded: false,
                id: "2",
                ariaControls: "2",
              },
              {
                content: (
                  <>
                    {loading ? (
                      <CircleLoader theme={theme} size="sm" />
                    ) : error ? (
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="fw-medium caption-text"
                      >
                        {error}
                      </Typography>
                    ) : (
                      <SwotAnalysis
                        items={
                          assetData &&
                          Array.isArray(assetData) &&
                          assetData.length > 0
                            ? assetData
                            : [
                                {
                                  title: "No Data Available",
                                  listing: [
                                    {
                                      title:
                                        "Please check the API response or connectivity.",
                                    },
                                  ],
                                },
                              ]
                        }
                      />
                    )}
                  </>
                ),
                title: (
                  <>
                    <Box
                      theme={theme}
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <img src={assetIcon} alt="Asset Icon" />
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="fw-medium caption-text"
                      >
                        Asset Insights
                      </Typography>
                    </Box>
                  </>
                ),
                key: "3",
                isExpanded: false,
                id: "3",
                ariaControls: "3",
              },
              {
                content: (
                  <>
                    {stipsLoading ? (
                      <CircleLoader theme={theme} size="sm" />
                    ) : stipsError ? (
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="fw-medium caption-text"
                      >
                        {stipsError}
                      </Typography>
                    ) : (
                      <Box
                        theme={theme}
                        className="accordion-box ai-accordion-child"
                      >
                        <Accordion
                          theme={theme}
                          items={[
                            {
                              content: (
                                <>
                                  <SwotAnalysis
                                    items={
                                      stipsData?.map((stip, index) => {
                                        return {
                                          notVerify: true,
                                          description: stip.problem || "",
                                          recommendation: stip.suggestion || "",
                                          actionArea: stip.message ? (
                                            <Typography
                                              theme={theme}
                                              variant="caption"
                                              component={"small"}
                                              className="fw-medium label caption-text"
                                            >
                                              {stip.suggestion}
                                            </Typography>
                                          ) : null,
                                          linkText: "Create Stipulation",
                                        };
                                      }) || [
                                        {
                                          title: "No Stipulations Available",
                                          notVerify: true,
                                          description:
                                            "No data available from the API response.",
                                          recommendation: "--",
                                        },
                                      ]
                                    }
                                  />
                                </>
                              ),
                              title: "Financial Stipulations",
                              key: "1",
                              isExpanded: false,
                              id: "1",
                              ariaControls: "1",
                            },
                            // {
                            //     content: (
                            //         <>
                            //             <SwotAnalysis
                            //                 items={[
                            //                     {
                            //                         title: "Sample Stipulation",
                            //                         notVerify: true,
                            //                         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                            //                         recommendation: "Recommendation",
                            //                         actionArea: (
                            //                             <Typography
                            //                                 theme={theme}
                            //                                 variant="caption"
                            //                                 component={"small"}
                            //                                 className="fw-medium label caption-text"
                            //                             >
                            //                                 Sample Suggestion
                            //                             </Typography>
                            //                         ),
                            //                         linkText: "Create Stipulation",
                            //                     },
                            //                     {
                            //                         title: "Sample Stipulation",
                            //                         notVerify: true,
                            //                         description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                            //                         recommendation: "Recommendation",
                            //                         actionArea: (
                            //                             <Typography
                            //                                 theme={theme}
                            //                                 variant="caption"
                            //                                 component={"small"}
                            //                                 className="fw-medium label caption-text"
                            //                             >
                            //                                 Sample Suggestion
                            //                             </Typography>
                            //                         ),
                            //                         linkText: "Create Stipulation",
                            //                     },
                            //                 ]}
                            //             />
                            //         </>
                            //     ),
                            //     title: "Non Financial Stipulations",
                            //     key: "2",
                            //     isExpanded: false,
                            //     id: "2",
                            //     ariaControls: "2",
                            // },
                          ]}
                        />
                      </Box>
                    )}
                  </>
                ),
                title: (
                  <>
                    <Box
                      theme={theme}
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <img src={stipIcon} />
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="fw-medium caption-text"
                      >
                        Stipulations Suggestions
                      </Typography>
                    </Box>
                  </>
                ),
                key: "4",
                isExpanded: false,
                id: "4",
                ariaControls: "4",
              },
              // {
              //     content: (
              //         <>
              //             {financeLoading ? (
              //                 <CircleLoader theme={theme} size="sm" />
              //             ) : financeError ? (
              //                 <Typography
              //                     theme={theme}
              //                     variant="caption"
              //                     component={"small"}
              //                     className="fw-medium caption-text"
              //                 >
              //                     {financeError}
              //                 </Typography>
              //             ) : (
              //                 <Stack paddingMd={1.25} paddingXs={1.25} className="recommended-finance-structure">
              //                     <BasicList
              //                         noWrap
              //                         options={[
              //                             {
              //                                 title: "Finance Type",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         value={financeData?.financed_type || "--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Financed Amount",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={financeData?.financed_amount || "--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Deal Duration",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"Months"}
              //                                         value={financeData?.deal_duration || "--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Interest Rate",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"%"}
              //                                         value={financeData?.interest_rate || "--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Upfront Payment",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={financeData?.upfront_payment || "--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Monthly Payment",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={financeData?.monthly_payment || "--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Rebate",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Discount",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Sales Tax",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Deferred Down Payment",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Net Trade-in Value",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Total Vehicle Sales Price",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "GAP",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Warranty",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Insurance",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Backend Fees",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                             {
              //                                 title: "Total Backend Fees",
              //                                 action: (
              //                                     <Input
              //                                         theme={theme}
              //                                         type="text"
              //                                         startAdornment={"$"}
              //                                         value={"--"}
              //                                         readOnly
              //                                     />
              //                                 ),
              //                             },
              //                         ]}
              //                     />
              //                 </Stack>
              //             )}
              //         </>
              //     ),
              //     title: (
              //         <>
              //             <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
              //                 <img src={financeIcon} />
              //                 <Typography
              //                     theme={theme}
              //                     variant="caption"
              //                     component={"small"}
              //                     className="fw-medium caption-text"
              //                 >
              //                     Recommended Finance Structure
              //                 </Typography>
              //             </Box>
              //         </>
              //     ),
              //     key: "5",
              //     isExpanded: false,
              //     id: "5",
              //     ariaControls: "5",
              // }
            ]}
          />
        </AiAssistantListWrap>
      </>
    );
}


