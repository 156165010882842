import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const ThirdPartyResponseListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.third-party-response-list-wrap": {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    alignSelf: 'stretch',
    backgroundColor: CustomColors.lightGrey,
    borderRadius: 8,
    padding: '8px 12px',
    gap: 8,
    '&.third-scrollable-container': {
      height: 'calc(100vh - 477px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      "@media only screen and (min-width: 1599.95px)": {
        height: 'calc(100vh - 577px)',
      }
    },
    ".third-party-stack": {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      ".MuiButtonBase-root": {
        "&.btn.with-icon.btn-default": {
          padding: 0,
        },
        svg: {
          width: 'auto !important',
          height: 'auto !important'
        }
      }
    }
    // ".u-accordian-wrap": {
    //   backgroundColor: CustomColors.lightGrey,
    //   borderRadius: theme.shape.borderRadius,
    //   marginBottom: 8,
    //   "&:last-child": {
    //     marginBottom: 0,
    //   },
    //   ".u-accordion-header": {
    //     borderRadius: theme.shape.borderRadius,
    //     backgroundColor: CustomColors.lightGrey,
    //     padding: 8,
    //     ".MuiAccordionSummary-content": {
    //       margin: "0 0 0 4px",
    //       ".main-title": {
    //         // fontSize: theme.typography.caption.fontSize,
    //         // lineHeight: theme.typography.caption.lineHeight,
    //         fontWeight: theme.typography.fontWeightRegular,
    //         color: '#12131A',
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'space-between',
    //         width: '100%',
    //         "@media only screen and (min-width: 1599.95px)": {
    //           fontSize: theme.typography.body1.fontSize,
    //           lineHeight: theme.typography.body1.lineHeight,
    //         },
    //         ".label-wrap": {
    //           borderRadius: theme.shape.borderRadius,
    //           ".label-bg": {
    //             backgroundColor: "#DADBDE",
    //             ".label-text": {
    //               color: CustomColors.bodyColor
    //             }
    //           }
    //         }
    //       },
    //     },

    //   },
    //   ".u-accordian-body": {
    //     padding: "8px",
    //     border: "none"
    //   },
    //   [theme.breakpoints.down("md")]: {
    //     padding: "24px 16px",
    //   },
    // },
  },
}));
