import {
  ADD_DECISION_REQUEST,
  ADD_DECISION_SUCCESS,
  ADD_DECISION_FAILURE,
  FETCH_DECISION,
  FETCH_DECISION_SUCCESS,
  FETCH_DECISION_FAILURE,
} from "../actions/decisionActions";

const initialState = {
  loading: false,
  success: false,
  data: null,
  error: null,
};

const decisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DECISION_REQUEST:
      return { ...state, loading: true, error: null, success: false };
    case ADD_DECISION_SUCCESS:
      return { ...state, loading: false, success: true, error: null };
    case ADD_DECISION_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case FETCH_DECISION:
      return { ...state, loading: true, error: null, success: false };
    case FETCH_DECISION_SUCCESS:
      return { ...state, loading: false, success: true, data: action.payload };
    case FETCH_DECISION_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        data: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default decisionReducer;
