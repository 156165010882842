export const ADD_DECISION_REQUEST = "ADD_DECISION_REQUEST";
export const ADD_DECISION_SUCCESS = "ADD_DECISION_SUCCESS";
export const ADD_DECISION_FAILURE = "ADD_DECISION_FAILURE";
export const ADD_DECISION_DIALOG_REQUEST = "ADD_DECISION_DIALOG_REQUEST";
export const FETCH_DECISION = "FETCH_DECISION";
export const FETCH_DECISION_SUCCESS = "FETCH_DECISION_SUCCESS";
export const FETCH_DECISION_FAILURE = "FETCH_DECISION_FAILURE";

export const addDecisionRequest = (id, payload) => ({
  type: ADD_DECISION_REQUEST,
  id,
  payload,
});

export const addDecisionSuccess = (data) => ({
  type: ADD_DECISION_SUCCESS,
  payload: data,
});

export const addDecisionFailure = (error) => ({
  type: ADD_DECISION_FAILURE,
  payload: error,
});

export const fetchDecisionRequest = (id) => ({
  type: FETCH_DECISION,
  payload: id,
});

export const fetchDecisionSuccess = (data) => ({
  type: FETCH_DECISION_SUCCESS,
  payload: data,
});

export const fetchDecisionFailure = (error) => ({
  type: FETCH_DECISION_FAILURE,
  payload: error,
});
