
import { useTheme } from "@mui/material";
import { Box, Button, DuoTab, Grid, Icon, Label } from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { useSelector } from "react-redux";
import { OwnershipDocumentWrap } from "./ownership-document.style";
import { Alert, PdfList } from "@src/components";
import pdfSample from "@assets/images/pdf-sample.png"

export const OwnershipDocument = () => {
    const theme = useTheme();


    return (
        <>
            <OwnershipDocumentWrap theme={theme} className="ownership-document-wrap">
                <Grid
                    theme={theme}
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    mt={0}
                >
                    <Grid item xs={4} theme={theme}>
                        <Stack
                            title={"Title Deed"}
                            paddingXs={1}
                            paddingMd={1}
                            bgLight
                            actionArea={
                                <>
                                    <Alert text={"Verified"} success />
                                </>
                            }
                        >
                            <Box theme={theme} className="pdf-card">
                                <Box theme={theme} className="pdf-preview">
                                    <img src={pdfSample} />
                                </Box>
                                <Box theme={theme} className="pdf-card-footer">
                                    <PdfList
                                        title={<a href="https://example.com/TransUnion_Report.pdf" target="_blank" rel="noopener noreferrer">TransUnion Report.pdf</a>}
                                        subTitle={'00-00-0000'}
                                        variant="light"
                                        pdfIcon={true}
                                    />
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} theme={theme}>
                        <Stack
                            title={"Property Appraisal"}
                            paddingXs={1}
                            paddingMd={1}
                            bgLight
                            actionArea={
                                <>
                                    <Alert text={"Not Verified"} notVerified />
                                </>
                            }
                        >
                            <Box theme={theme} className="pdf-card">
                                <Box theme={theme} className="pdf-preview">
                                    <img src={pdfSample} />
                                </Box>
                                <Box theme={theme} className="pdf-card-footer">
                                    <PdfList
                                        title={<a href="https://example.com/TransUnion_Report.pdf" target="_blank" rel="noopener noreferrer">TransUnion Report.pdf</a>}
                                        subTitle={'00-00-0000'}
                                        variant="light"
                                        pdfIcon={true}
                                    />
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} theme={theme}>
                        <Stack
                            title={"Proof of Ownership"}
                            paddingXs={1}
                            paddingMd={1}
                            bgLight
                            actionArea={
                                <>
                                    <Alert text={"Verified"} success />
                                </>
                            }
                        >
                            <Box theme={theme} className="pdf-card">
                                <Box theme={theme} className="pdf-preview">
                                    <img src={pdfSample} />
                                </Box>
                                <Box theme={theme} className="pdf-card-footer">
                                    <PdfList
                                        title={<a href="https://example.com/TransUnion_Report.pdf" target="_blank" rel="noopener noreferrer">TransUnion Report.pdf</a>}
                                        subTitle={'00-00-0000'}
                                        variant="light"
                                        pdfIcon={true}
                                    />
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} theme={theme}>
                        <Stack
                            title={"Mortgage Documents"}
                            paddingXs={1}
                            paddingMd={1}
                            bgLight
                            actionArea={
                                <>
                                    <Alert text={"Verified"} success />
                                </>
                            }
                        >
                            <Box theme={theme} className="pdf-card">
                                <Box theme={theme} className="pdf-preview">
                                    <img src={pdfSample} />
                                </Box>
                                <Box theme={theme} className="pdf-card-footer">
                                    <PdfList
                                        title={<a href="https://example.com/TransUnion_Report.pdf" target="_blank" rel="noopener noreferrer">Sample_report.pdf</a>}
                                        subTitle={'00-00-0000'}
                                        variant="light"
                                        pdfIcon={true}
                                    />
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>

            </OwnershipDocumentWrap>
        </>
    );
};
