export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const SAVE_RULE = "SAVE_RULE";
export const RESET_RULE = "RESET_RULE";

export const updateComment = (referenceNumber, comment) => ({
  type: UPDATE_COMMENT,
  payload: { referenceNumber, comment },
});

export const saveRule = (referenceNumber) => ({
  type: SAVE_RULE,
  payload: { referenceNumber },
});

export const resetRule = (referenceNumber) => ({
  type: RESET_RULE,
  payload: { referenceNumber },
});