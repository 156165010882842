import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const MyNotesWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.my-notes-wrap": {
    ".add-sticky-notes": {
      padding: "12px",
      border: "1px solid",
      borderRadius: 8,
      borderColor: theme.palette.grey[100],
      width: "100%",
      height: 46,
      "&.clicked": {
        height: "auto",
        ".add-title": {
          display: "flex",
          ".input-title": {
            width: '60%',
            ".u-form-group": {
              marginBottom: 0,
              ".u-form-control": {
                height: "auto",
                ".MuiInputBase-input": {
                  padding: 0,
                  maskImage: 'none',
                  "&::placeholder": {
                    color: theme.palette.common.black,
                  },
                },
              },
            },
          },
        },
      },
      ".add-title": {
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 12,
        paddingBottom: 12,
        gap: 10,
        borderBottom: "1px solid",
        borderColor: theme.palette.grey[100],
        display: "none",
      },
      ".u-form-group": {
        ".u-form-control": {
          "&.textarea": {
            ".MuiInputBase-root": {
              padding: 0,
            },
          },
          fieldset: {
            border: "none",
          },
        },
      },
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        margin: 0,
      },
    },
    ".select-priority": {
      display: "flex",
      alignItems: "center",
      ".select": {
        width: 100,
      },
      ".priority": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        ".clr-picker": {
          width: 32,
          height: 32,
          borderRadius: 60,
          cursor: "pointer",
          border: "1px solid transparent",
          "&.selected": {
            border: "1px solid" + CustomColors.bodyColor,
          },
        },
      },
    },
    ".filter-section": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: 16
    },
    ".scrollable-container": {
      maxHeight: "calc(100vh - 350px)",
      overflowX: "hidden",
      overflowY: "auto",
      scrollbarWidth: "none",
      marginTop: 16,
      ".notes-listing": {
        ".my-notes-list-wrap": {
          columns: '2 200px',
          columnGap: '16px',
          ".stack-user-header": {
            marginBottom: 16,
            width: '100%',
            display: 'inline-block',
          }
        },
      },
    },
  },
}));
