import { useTheme } from "@mui/material";
import {
  Typography,
  Grid,
  Dialog,
  Box,
  DuoTab,
  Icon,
  Textarea,
  ScrollableTabs,
} from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { AddDecisionDialogWrap } from "./add-decision-dialog.style";
import { Stack } from "@src/components/stack";
import { Decision } from "../add-decision-popup-data/decision/decision.component";
import { DecisionHistory } from "../add-decision-popup-data/decision-history/decision-history.component";
import { useState } from "react";

export const AddDecisionDialog = ({ open, closeDialog }) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);

  const onChangeTab = (_, value: number) => {
    setActiveTab(value);
  };

  return (
    <>
      <Popup>
        <Dialog
          theme={theme}
          size={"lg"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Send Decision"}
          disablePortal
          noFooter
        >
          <AddDecisionDialogWrap
            theme={theme}
            className="add-decision-dialog-wrap scrollable-tabs-wrap"
          >
            <ScrollableTabs
              theme={theme}
              defaultTabIndex={0}
              tabValue={activeTab}
              scrollButtons="auto"
              items={[
                {
                  content: (
                    <>
                      <Decision setActiveTab={setActiveTab} />
                    </>
                  ),
                  title: "Decision",
                },
                {
                  content: (
                    <>
                      <DecisionHistory />
                    </>
                  ),
                  title: "Decision History",
                },
              ]}
              onChange={onChangeTab}
            />
          </AddDecisionDialogWrap>
        </Dialog>
      </Popup>
    </>
  );
};
