import { useTheme } from "@mui/material";
import { Box, Button, Grid, Icon, Label } from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { DocumentsList } from "../documents-list/document-list.component";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { fetchAllDocuments } from "@src/services/configureApplicationService";

export const Documents = () => {
  const theme = useTheme();

  const [documentData, setDocumentData] = useState([]);

  const { applicantDetails, dialogLoading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  const isFetching = useRef(false);

  useEffect(() => {
    const loadBookmarks = async () => {
      if (!applicantDetails?.reference_number || isFetching.current) return;

      isFetching.current = true;
      try {
        const data = await fetchAllDocuments(
          applicantDetails.reference_number,
          applicantDetails.customer_info.reference_id
        );
        setDocumentData(data[0]?.documents);
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
      }
      isFetching.current = false;
    };

    loadBookmarks();

    return () => {
      isFetching.current = false;
    };
  }, [applicantDetails?.reference_number]);

  return (
    <>
      <Stack title={"Documents"} paddingXs={2} paddingMd={2}>
        <DocumentsList
          documents={documentData}
        />
      </Stack>
    </>
  );
};
