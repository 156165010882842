import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { keyframes } from "@mui/system";

const bounce = keyframes`
   0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

export const ArrowDownWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.arrow-down": {
        position: 'absolute',
        bottom: 16,
        left: '50%',
        transform: 'translateX(-50%)',
        // animation: `${bounce} 1.5s infinite`,
        cursor: 'pointer',
        width: 32,
        height: 32,
        borderRadius: 50,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0 0 40px #00000060',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
