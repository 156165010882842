import { useTheme } from "@mui/material";
import { Stack } from "@src/components/stack";
import { CreateNewBusinesskWrap } from "./create-new-business-rule.style"
import { Grid, Input, Select, Button, Icon, Switch, Box, Typography } from "@ntpkunity/controls"
import { BusinessRulesConditionTable } from "../business-rules-condition-table/business-rules-condition-table.component"
import { Booksmarks } from "../bookmarks/bookmarks.component"


export const CreateNewBusinessRule = () => {
    const theme = useTheme();

    const columns = [
        { key: "business_rules_name", label: "Business Rule Name" },
        { key: "work_flow_activity", label: "Work Flow Activity" },
        { key: "description", label: "Description" },
    ];

    return (
        <CreateNewBusinesskWrap theme={theme} className="basic-stack-wrap">
            <Stack bgLight paddingMd={2} paddingXs={2} marginTopMd={2} marginTopXs={2}
                title="Create New Business Rule"
                actionArea={<>
                    <Button
                        theme={theme}
                        defaultBtn
                        className="no-padding"
                        iconText={<><Icon name="CloseBlack" /></>}
                    />
                </>}
            >
                <Grid
                    theme={theme}
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    sx={{ mt: 0 }}
                >
                    <Grid item xs={3} theme={theme}>
                        <Input
                            type="text"
                            fullWidth={true}
                            theme={theme}
                            placeholder="Type here..."
                            value={'Sample Rule Name'}
                            label="Business Rule Name"
                        />
                    </Grid>
                    <Grid item xs={3} theme={theme}>
                        <Select
                            theme={theme}
                            disablePortal={false}
                            items={[
                                { text: "Activity 1", value: "activity1" },
                                { text: "Activity 2", value: "activity2" },
                                { text: "Activity 3", value: "activity3" },
                            ]}
                            value={"Activity 1"}
                            label="Work Flow Activity"
                        />
                    </Grid>
                </Grid>
                <Stack
                    paddingMd={0}
                    paddingXs={0}
                    bgLight
                    title="Conditions"
                    className="no-padding"
                    actionArea={<>
                        <Box theme={theme} className="switch-btn">
                            <Typography theme={theme} variant="body1" component={'span'}>Booksmarks</Typography>
                            <Switch
                                theme={theme}
                                varient="basic"
                                onChange={()=>{}}
                            />
                        </Box>
                        </>}
                >
                    <Grid
                        theme={theme}
                        container
                        rowSpacing={2}
                        columnSpacing={2}
                        sx={{ mt: -2 }}
                    >
                        <Grid item xs={7} theme={theme}>
                            <BusinessRulesConditionTable />
                        </Grid>
                        <Grid item xs={5} theme={theme}>
                            <Booksmarks />
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </CreateNewBusinesskWrap>

    );
};

export default CreateNewBusinessRule;
