import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu } from "@ntpkunity/controls";
import WorkqueueStatus from "./workqueue-status/workqueue-status.component";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  updateAssignedRowId,
  updateCreditCheckScore,
  updateRunningWorkflowId,
} from "@src/components/redux/actions/workQueueActions";
import moment from "moment";

const formattedDate = (date) =>
  date ? moment(date).format("MM/DD/YYYY hh:mm A") : "N/A";

const WorkqueueTableRow = ({ row, refetchData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  localStorage.setItem("assignedRowId", row.id);

  const navigateToDashboard = () => {
    if (row?.reference_number) {
      if (row.reference_number === "HN-JS-2025-38") {
        navigate(`/cde/dashboard/${row.reference_number}`);
      } else {
        navigate(`/cde/dashboard/${row.reference_number}`);
      }
      dispatch(updateRunningWorkflowId(row.running_workflow_instance_id));
      dispatch(updateAssignedRowId(row.id));
      dispatch(updateCreditCheckScore(row.credit_check_score));
    } else {
      console.error("Row ID is missing for navigation.");
    }
  };

  const handleOptionClick = async (key) => {
    if (key === "view") {
      navigateToDashboard();
    } else if (key === "assigntome") {
      console.log("Assign To Me clicked for row:", row);
      try {
        const assignedUsername = row.assigned_to_username || "Asad";

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/cde/application_queue_by_id/${row.id}`,
          { assigned_to_username: assignedUsername },
          { headers: { "Content-Type": "application/json" } }
        );

        if (response.status === 200) {
          console.log("Successfully assigned:", response.data);
          refetchData();
        } else {
          console.error("Assignment failed:", response);
        }
      } catch (error) {
        console.error(
          "Error assigning row to user:",
          error.response?.data || error
        );
      }
    }
  };

  return (
    <TableRow className="child-tr">
      <TableCell className="text-body1">
        <WorkqueueStatus status={row.status} />
      </TableCell>
      <TableCell className="text-body1">
        <a
          href={`/cde/dashboard/${row.reference_number}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary"
          onClick={(e) => {
            e.preventDefault();
            navigateToDashboard();
          }}
        >
          {row.reference_number ?? "N/A"}
        </a>
      </TableCell>
      <TableCell className="text-body1">
        {row.dealership_name ?? "N/A"}
      </TableCell>
      <TableCell className="text-body1 text-right">
        $
        {row.amount_financed?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) ?? "0.00"}
      </TableCell>
      <TableCell className="text-body1">
        {row.reference_number === "HN-JS-2025-38"
          ? "HiTech Ltd"
          : row.applicant_name ?? "N/A"}
      </TableCell>
      <TableCell className="text-body1">{row.state ?? "N/A"}</TableCell>
      <TableCell className="text-body1">
        {row.reference_number === "HN-JS-2025-38"
          ? "Company"
          : row.business ?? "Individual"}
      </TableCell>
      <TableCell className="text-body1">
        {row.assigned_to_username ?? "N/A"}
      </TableCell>
      <TableCell className="text-body1">
        {formattedDate(row.updated_at ?? "N/A")}
      </TableCell>
      <TableCell className="text-body1">
        {formattedDate(row.updated_at ?? "N/A")}
      </TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={[
            { optionText: "View", optionkey: "view" },
            { optionText: "Assign To Me", optionkey: "assigntome" },
            {
              optionText: "Assign to team member",
              optionkey: "Assign to team member",
            },
          ]}
          handleOptionClick={(event, key) => handleOptionClick(key)}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default WorkqueueTableRow;