import { createBrowserRouter } from "react-router-dom";
import { WorkQueuePage, DashboardPage, DashboardFundingRequestPage } from "@src/pages";
import { DashboardCorporatePage } from "@src/pages/dashboard-corporate/dashboard-corporate-page";
import { BusinessRulesPage } from "@src/pages/business-rules-page/business-rules-page.component"

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <div>404 Page not found.</div>,
  },
  {
    path: "/cde",
    element: <WorkQueuePage />,
    errorElement: <div>404 Page not found.</div>,
  },
  {
    path: "/cde/dashboard/:reference_number",
    element: <DashboardPage />,
  },
  {
    path: "/cde/dashboard/funding_request/:reference_number",
    element: <DashboardFundingRequestPage />,
  },
  {
    path: `/cde/dashboard/corporate/:reference_number`,
    element: <DashboardCorporatePage />,
  },
  {
    path: `/cde/configurations`,
    element: <BusinessRulesPage />,
  },
]);

export default router;
