import { useTheme } from "@mui/material";
import { Box, Checkbox, CircleLoader, Icon, Input } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { fetchFinanceStructureRequest, updateFinanceStructureRequest } from "@src/components/redux/actions/financeStructureActions";
import { Stack } from "@src/components/stack";
import { FinanceStructureWrap } from "./finance-structure.style"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { FinanceStructureModifyDialog } from "../finance-structure-modify-popup/finance-structure-modify-popup-dialog.component"
import { fetchApplicantDetailsRequest } from "@src/components/redux/actions/applicantDetailsActions";
import { ArrowDown } from "@src/components";

export const FinanceStructure = () => {
  const theme = useTheme();
  const [open, setOpened] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  // const { data, updatedData, loading, error } = useSelector((state: any) => state?.financeStructure);
  const { applicantDetails, loading, error } = useSelector(
    (state: any) => state?.applicantDetails
  );
  const [financeStructureState, setFinanceStructureState] = useState<any>({});

  const { reference_number } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (applicantDetails) {
      setFinanceStructureState(applicantDetails);
    }
  }, [applicantDetails]);

  useEffect(() => {
    if (reference_number) {
      dispatch(fetchApplicantDetailsRequest(reference_number));
    }
  }, [dispatch, reference_number]);

  const handleOpenDialog = () => setOpened(true);
  const handleCloseDialog = () => setOpened(false);

  const handleInputChange = (field: string, value: any) => {
    setFinanceStructureState((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // const handleInputBlur = (field: string) => {
  //   const updatedField = { [field]: financeStructureState[field] };
  //   dispatch(updateFinanceStructureRequest({ ...applicantDetails, ...updatedField }));
  // };

  const formatNumber = (value: any) => {
    if (value === null || value === undefined) {
      return (
        <Box theme={theme} className="text-right">
          N/A
        </Box>
      );
    }

    if (!isNaN(value) && value !== "") {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(value));
    }
    return "N/A";
  };

  // const dealerFeesTotal = financeStructureState?.order_fees?.reduce(
  //   (acc: number, fee: any) => acc + (fee.applied_price || 0.0),
  //   0
  // );

  // const insuranceTotal =
  //   financeStructureState?.order_fnI?.reduce(
  //     (acc: number, item: any) => acc + (item.applied_price || 0),
  //     0
  //   ) || 0.0;

  // const tradeInTotal = applicantDetails?.order_tradein?.trade_in_amount;
  // const financeType = applicantDetails?.finance_type || "0.00";

  const gapTotal =
    applicantDetails?.order_options?.reduce(
      (acc: number, option: any) => acc + (option.applied_price || 0.0),
      0
    ) || "N/A";

  const dealerFeesTotal = financeStructureState?.order_fees?.reduce(
    (acc: number, fee: any) => acc + (fee.applied_price || "N/A"),
    0
  );

  const insuranceTotal =
    financeStructureState?.order_fnI?.reduce(
      (acc: number, item: any) => acc + (item.applied_price || 0),
      0
    ) || "N/A";

  const tradeInTotal =
    applicantDetails?.order_tradein?.trade_in_amount || "N/A";
  const financeType = applicantDetails?.finance_type || "N/A";

  const terms =
    applicantDetails?.order_submissions?.[0]?.decision?.terms ?? "N/A";

  const frequency = applicantDetails?.rental_frequency || "N/A";

  const fields = [
    { title: "Finance Type", key: "", value: financeType },
    {
      title: "Financed Amount",
      key: "net_finance_amount",
      startAdornment: "$",
    },
    {
      title: "Periodic Payment",
      key: "estimated_monthly_payment",
      startAdornment: "$",
    },
    { title: "Terms", key: "", value: terms },
    { title: "Frequency", key: "", value: frequency },
    { title: "Asset Cost", key: "selling_price", startAdornment: "$" },
    { title: "Down Payment", key: "down_payment_value", startAdornment: "$" },
    { title: "Tax on Asset Cost", key: "tax_amount", startAdornment: "$" },
    {
      title: "Net Trade-in",
      key: "",
      value: tradeInTotal,
      startAdornment: "$",
    },
    { title: "Charges", key: "charges", startAdornment: "$" },
    {
      title: "Rebate",
      key: "reebate",
      startAdornment: "$",
      value: formatNumber(applicantDetails?.rebate),
    },
    { title: "Discount", key: "discount", startAdornment: "$" },
    { title: "Add-ons", key: "", value: gapTotal, startAdornment: "$" },
    { title: "Insurance", key: "", value: insuranceTotal, startAdornment: "$" },
    { title: "Interest Rate", key: "apr", startAdornment: "%" },
    {
      title: "Dealer Fees",
      key: "",
      value: dealerFeesTotal,
      startAdornment: "$",
    },
  ];

  // const fields = [
  //   { title: "Finance Type", key: "", value: financeType },
  //   {
  //     title: "Financed Amount",
  //     key: "net_finance_amount",
  //     // value: net_finance_amount,
  //     startAdornment: "$",
  //   },
  //   {
  //     title: "Priodic payment",
  //     key: "estimated_monthly_payment",
  //     startAdornment: "$",
  //   },
  //   { title: "Asset Cost", key: "selling_price", startAdornment: "$" },
  //   { title: "Down Payment", key: "down_payment_value", startAdornment: "$" },
  //   { title: "Tax on Asset cost", key: "tax_amount", startAdornment: "$" },
  //   {
  //     title: "Tax Treatment",
  //     key: "order_tradein?.trade_in_amount",
  //   },
  //   {
  //     title: "Net Trade-in Value",
  //     key: "",
  //     value: tradeInTotal,
  //     startAdornment: "$",
  //   },
  //   {
  //     title: "Fees, charge & discount",
  //     key: "Fees, charge & discount",
  //     startAdornment: "$",
  //   },
  //   { title: "Rebate", key: "rebate", startAdornment: "$" },
  //   {
  //     title: "Discount",
  //     key: "estimated_monthly_payment",
  //     startAdornment: "$",
  //   },
  //   { title: "GAP", key: "gap", startAdornment: "$" },
  //   { title: "Insurance", key: "", value: insuranceTotal, startAdornment: "%" },
  //   // { title: "Insurance", key: "backend_fees", startAdornment: "$" },
  //   { title: "Interest Rate", key: "apr", startAdornment: "%" },
  //   {
  //     title: "Dealer Fees",
  //     key: "",
  //     value: dealerFeesTotal,
  //     startAdornment: "$",
  //   },
  // ];

  return (
    <>
      <FinanceStructureWrap theme={theme} className="finance-structure-wrap">
        <Stack
          title={"Finance Structure"}
          paddingXs={2}
          paddingMd={2}
          actionArea={
            <a
              href="javascript:void(0)"
              className="text-primary primary-alert dFlex alignItemsCenter"
              onClick={handleOpenDialog}
            >
              Add/Modify <Icon name="ArrowLinkIcon" />
            </a>
          }
        >
          {loading ? (
            <CircleLoader theme={theme} size="sm" />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <Box theme={theme} className="checkbox-section">
                <Box theme={theme} className="empty"></Box>
                <Checkbox theme={theme} className="checkbox" label={"Active"} />
              </Box>
              <Box theme={theme} className="child-scrollable-container">
                <BasicList
                  input
                  options={fields.map((field) => {
                    let value = field.key
                      ? financeStructureState?.[field.key]
                      : field.value;
                    const isNumber =
                      typeof value === "number" ||
                      (!isNaN(value) && value !== "" && value !== null);

                    const formattedValue = isNumber
                      ? formatNumber(value)
                      : value;

                    return {
                      title: field.title,
                      titleBold: true,
                      textRight: true,
                      action: (
                        <Input
                          theme={theme}
                          type="text"
                          startAdornment={
                            value != null && value !== "" && isNumber
                              ? field.startAdornment || ""
                              : null
                          }
                          value={
                            value != null && value !== ""
                              ? formattedValue
                              : "N/A"
                          }
                          inputClass={isNumber ? "text-right" : ""}
                          disabled
                        />
                      ),
                    };
                  })}
                />
              </Box>
              <ArrowDown />
            </>
          )}
        </Stack>
      </FinanceStructureWrap>
      <FinanceStructureModifyDialog
        open={open}
        closeDialog={handleCloseDialog}
      />
    </>
  );
};