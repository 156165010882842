import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const CreditScoreCounterWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.credit-score-counter-wrap": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    padding: 8,
    gap: 8,
    backgroundColor: "#F8F8F9",
    "@media(min-width: 1800.95px)": {
      padding: 16,
      ".MuiTypography-root": {
        "&.text-body2": {
          fontSize: theme.typography.h4.fontSize,
          fontWeight: theme.customVariables.fontWeightSemiBold
        }
      }
    },
    ".number": {
      color: "#474A59"
    },
    ".label-wrap": {
      ".label-bg": {
        padding: '4px 8px'
      }
    }
  },
}));
