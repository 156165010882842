
import { useTheme } from "@mui/material";
import { Box, Button, DuoTab, Grid, Icon, Label } from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { useSelector } from "react-redux";
import { OwnershipDocument } from "../tabs/ownership-documents/ownership-documents.component";
import { ComplianceCheckListTabWrap } from "./compliance-checklist-tabs.style";

export const ComplianceCheckListTabs = () => {
    const theme = useTheme();


    return (
        <>
            <ComplianceCheckListTabWrap theme={theme} className="compliance-checklist-tab-wrap">
                <DuoTab
                    theme={theme}
                    varient="underline"
                    defaultTabIndex={0}
                    items={[
                        {
                            content: <><OwnershipDocument /></>,
                            title: 'Funding Contracts',
                        },
                        {
                            content: <><OwnershipDocument /></>,
                            title: 'Ownership Documents',
                        },
                        {
                            content: <><OwnershipDocument /></>,
                            title: 'Income & Employement',
                        },
                        {
                            content: <><OwnershipDocument /></>,
                            title: 'Insurance Documents',
                        },
                        {
                            content: <><OwnershipDocument /></>,
                            title: 'Proof of Address',
                        },
                    ]}
                />
            </ComplianceCheckListTabWrap>
        </>
    );
};
