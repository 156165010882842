export const sendChatMessage = async (messages, running_workflow_instance_id, onChunk) => {
  try {
    const base_url = process.env.REACT_APP_API_URL;
      
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY ?? "",
      };

    const body = JSON.stringify({
      user_input: messages,
      workflow_instance_id: running_workflow_instance_id,
      thread_id: running_workflow_instance_id,
      });


    const response = await fetch(`${base_url}/check/api/v1/chat`, {
        method: "POST",
        headers,
        body,
      });

      if (!response.ok) {
        throw new Error(`API Error: ${response.statusText}`);
      }

      if (!response.body) {
        throw new Error("Response body is null.");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      let partialData = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        partialData += decoder.decode(value, { stream: true });

        const chunks = partialData.split("\n");

        for (let i = 0; i < chunks.length - 1; i++) {
          try {
            const json = JSON.parse(chunks[i].replace("data:", "").trim());
            if (json?.content) {
              onChunk(json.content);
            }
          } catch (error) {
            console.error("Error parsing chunk:", error);
          }
        }

        partialData = chunks[chunks.length - 1];
      }
  } catch (error) {
      console.error("Error in sendChatMessage:", error);
  }
};