import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const AddedStipulationsWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.added-stipulations": {
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ".MuiButtonBase-root": {
            "&.btn.with-icon.icon-only": {
                padding: 0
            }
        }
    }
}));
