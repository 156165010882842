import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const AddStickyNoteWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-sticky-note-wrap": {
    background: 'rgba(218, 219, 222, 0.30)',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 8,
    padding: '16px 8px',
    gap: 8,
    ".add-note": {
        textDecoration: 'underline'
    }
  }
}));
