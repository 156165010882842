import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import { Box, Button, CircleLoader, Grid, Icon } from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { AssetDetailDialog } from "../asset-detail-dialog/asset-detail-dialog.component"
import { Stack } from "@src/components/stack";
import { fetchApplicantDetailsRequest } from "@src/components/redux/actions/applicantDetailsActions";

export const AssetDetailsStack = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { reference_number } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const { applicantDetails, loading, error, is } = useSelector((state: any) => state.applicantDetails);
  localStorage.setItem("reference_number", applicantDetails?.reference_number);

  useEffect(() => {
    if (reference_number) {
    }
    dispatch(fetchApplicantDetailsRequest(reference_number))
  }, [dispatch, reference_number]);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const fields = [
    { title: "Make:", key: "make" },
    { title: "Model:", key: "model" },
    { title: "Year:", key: "year" },
    { title: "Trim:", key: "trim_description" },
    { title: "Condition:", key: "type" },
  ];

  return (
    <>
      <Stack
        title={"Asset Details"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={
              <>
                <Icon name="ArrowLinkIcon" />
              </>
            }
            onClick={() => handleOpenDialog()}
          />
        }
      >
        {loading ? (
          <Box theme={theme} mt={2}>
            <CircleLoader theme={theme} size={"sm"} />
          </Box>
        ) : error ? (
          <Box theme={theme} mt={2}>
            {error}
          </Box>
        ) : applicantDetails ? (
          <>
            <Box theme={theme} mt={2}>
              {/* <Alert text="Verified" success fullWidth /> */}
              <Alert
                text={"Not Verified:"}
                // value={ratios.dtiRatio ? `${ratios.dtiRatio}%` : "N/A"}
                error
                fullWidth
              />
            </Box>
            <BasicList
              options={fields.map((field) => ({
                title: field.title,
                subtitle:
                  applicantDetails?.Vehicle_details?.[field.key] ?? "N/A",
              }))}
            />
          </>
        ) : (
          <Box theme={theme} mt={2}>
            No data available
          </Box>
        )}
      </Stack>
      <AssetDetailDialog open={isOpen} closeDialog={handleCloseDialog} />
    </>
  );
};