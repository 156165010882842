import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const ComplianceCheckListTabWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.compliance-checklist-tab-wrap": {
        ".TabsUnstyled-root": {
            "&.TabsUnstyled-horizontal": {
                ".tab-panel-underline": {
                    padding: 0,
                }
            }
        },
        ".u-tab-panel": {
            '.scrollable-container': {
                maxHeight: 'calc(100vh - 233px)',
                overflowX: 'hidden',
                overflowY: 'auto',
                scrollbarWidth: 'none',
            },
        },
        ".data-table-wrap": {
            ".u-table-wrap": {
                ".u-table-container": {
                    maxHeight: 'fit-content'
                }
            }
        }
    },
}));
