import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const ThirdPartyResponseDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.third-party-response-dialog-wrap": {
    ".dialog-wrap": {
      ".u-dialog": {
        "&.u-dialog-xl": {
          ".MuiPaper-root": {
            maxWidth: 1300,
            "@media only screen and (min-width: 1599.95px)": {
              maxWidth: 1550,
            }
          }
        }
      }
    },
    ".content": {
      minHeight: 300,
      ".listing": {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        alignSelf: 'stretch',
        flexWrap: 'wrap',
        gap: 16
      },
      ".label-wrap": {
        ".label-bg": {
          ".MuiTypography-root": {
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.customVariables.fontWeightSemiBold
          }
        }
      }
    },

  },
}));
