import { useTheme } from "@mui/material";
import { Box, Button, Icon, Input, Typography, DateRange, Select } from "@ntpkunity/controls";
import { ButtonGroupProps } from "@components/button-group/button-group.props";
import { MultiFunctionsWrap } from "./multi-functions.style";
import { useState } from "react";
import { MultiFunctionsProps } from "./multi-functions.props"
import clsx from "clsx";
import searchIcon from "@assets/icons/searchIcon.svg";
import refreshIcon from "@assets/icons/refreshIcon.svg";
import resetIcon from "@assets/icons/resetIcon.svg";
import upAndDownIcon from "@assets/icons/upDownArrow.svg";
import calendarIcon from "@assets/icons/calendarIcon.svg";
import email from "@assets/icons/email.svg";

export const MultiFunctions = ({ expandedDateRange, onDateChange, onSorted, sortedItems, sortedValue, onResetClick, onRefreshClick, onInputChange }: MultiFunctionsProps) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);
    const [hasSelectedDate, setHasSelectedDate] = useState(false);

    const handleSearchClick = () => {
        setIsExpanded(!isExpanded);
    };

    const sortOptions = [
        { text: "Sort By", value: "sort", disabled: true },
        { text: "Ascending", value: "asc" },
        { text: "Descending", value: "desc" },
    ];

    return (
        <MultiFunctionsWrap className="multi-functions-wrap" theme={theme}>
            <Box theme={theme} className="multi-functions">
                <Box theme={theme} className="emails unread">
                    <Box theme={theme} position={"relative"} display={"flex"}>
                        <Box theme={theme} className="notify"></Box>
                        <img src={email} alt="email" />
                    </Box>
                    <Typography theme={theme} variant="body2" className="text-muted" component={"symbol"}>
                        Unread
                    </Typography>
                </Box>
                {onDateChange &&
                    <Box theme={theme} className={expandedDateRange}>
                        <DateRange
                            theme={theme}
                            onChange={onDateChange}
                            disablePortal={true}
                            placeholder={"Calendar"}
                            endAdornment={<img src={calendarIcon} className="calendar-icon" />}
                            render={<input placeholder="Calendar" readOnly />}
                        />
                    </Box>
                }
                {onSorted &&
                    <Box theme={theme} className="custom-dropdown sort-btn">
                        <img src={upAndDownIcon} alt="Sort Icon" className="sort-icon" />
                        <Select
                            theme={theme}
                            disablePortal={false}
                            onChange={onSorted}
                            items={sortOptions}
                            value={sortedValue ?? "sort"}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                        />
                    </Box>
                }
                {onResetClick &&
                    <Button
                        theme={theme}
                        defaultBtn
                        iconText={<img src={resetIcon} alt="Reset" />}
                        onClick={onResetClick}
                    />
                }
                {onRefreshClick &&
                    <Button
                        theme={theme}
                        defaultBtn
                        iconText={<img src={refreshIcon} alt="Refresh" />}
                        onClick={onRefreshClick}
                    />
                }
                {onInputChange &&
                    <Box theme={theme} className={`search ${isExpanded ? 'expanded' : ''}`}>
                        <Button
                            theme={theme}
                            defaultBtn
                            iconText={<img src={searchIcon} alt="Search" />}
                            onClick={handleSearchClick}
                        />
                        {isExpanded && (
                            <Box theme={theme} className="search-input">
                                <Input
                                    theme={theme}
                                    type="text"
                                    placeholder="Search..."
                                    endAdornment={<img src={searchIcon} alt="Search" />}
                                    onChange={onInputChange}
                                />
                            </Box>
                        )}
                    </Box>
                }
            </Box>
        </MultiFunctionsWrap>
    );
};
