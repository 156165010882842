import { call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_APPLICANT_DETAILS_REQUEST,
  fetchApplicantDetailsSuccess,
  fetchApplicantDetailsFailure,
} from "../actions/applicantDetailsActions";

const selectApplicantDetails = (state) => state.applicantDetails?.applicantDetails;

const checkDetauils = (state) => state.checkDetauils?.checkDetauils;

function* fetchApplicantDetailsSaga(action) {
  try {
    const applicantReferenceNum = action.payload;
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/dms/order/order_information/${applicantReferenceNum}`,
      {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      }
    );

    yield put(fetchApplicantDetailsSuccess(response.data));
  } catch (error) {
    yield put(fetchApplicantDetailsFailure(error.message));
  }
}

export function* watchApplicantDetailsSaga() {
  yield takeLatest(FETCH_APPLICANT_DETAILS_REQUEST, fetchApplicantDetailsSaga);
}