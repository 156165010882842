import { useTheme } from "@mui/material";
import { DashboardPageHeader } from "@src/components/dashboard";
import { DashboardCorporate } from "@src/components/dashboard/dashboard-corporate/dashboard-corporate/dashboard-corporate.component";
import { DashboardFundingRequest } from "@src/components/dashboard/dashboard-funding-request/dashboard-funding-request.component";
import { DashboardStacks } from "@src/components/dashboard/dashboard-stack/dashboard-stack";
import { LayoutWithSideNavComponent } from "@src/layouts";
import { fetchBookmarks } from "@src/services/configureApplicationService";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

export const DashboardCorporatePage = () => {
  const theme = useTheme();
  const [businessData, setBusinessData] = useState({});

  const { applicantDetails, dialogLoading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  const isFetching = useRef(false);

  useEffect(() => {
    const loadBookmarks = async () => {
      if (!applicantDetails?.reference_number || isFetching.current) return;

      isFetching.current = true;
      try {
        const data = await fetchBookmarks(applicantDetails.reference_number);
        setBusinessData(data?.data);
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
      }
      isFetching.current = false;
    };

    loadBookmarks();

    return () => {
      isFetching.current = false;
    };
  }, [applicantDetails?.reference_number]);

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <DashboardPageHeader businessData={businessData} />
      <DashboardCorporate businessData={businessData} />
    </LayoutWithSideNavComponent>
  );
};
