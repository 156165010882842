import { useTheme } from "@mui/material";
import {
  Box,
  Select,
  Typography,
  DatePicker,
  Button,
  Icon,
  DateRangePicker,
  DateRange,
} from "@ntpkunity/controls";
import { WorkqueueFilterWrap } from "./workqueue-filters.style";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { fetchWorkqueueRequest } from "@src/components/redux/actions/workQueueActions";

export const WorkqueueFilters = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedSort, setSelectedSort] = useState("sort");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const {
    workQueues = [],
    loading,
    error,
    pagination,
  } = useSelector((state: any) => state.workQueue);

  const handleStatusChange = (event) => {
    const status = event.target.value;

    setSelectedStatus(status);
    dispatch(
      fetchWorkqueueRequest(
        1,
        pagination.pageSize,
        status !== "all" ? status : null
      )
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     fetchWorkqueueRequest(
  //       pagination.pageNumber,
  //       pagination.pageSize,
  //       selectedStatus
  //     )
  //   );
  // }, [dispatch, pagination.pageNumber, pagination.pageSize, selectedStatus]);

  const uniqueStatuses = Array.from(
    new Set(workQueues.map((item) => item.status ?? "Unknown"))
  );

  const statusOptions = uniqueStatuses.map((status) => ({
    text: status,
    value: status,
  }));

  const handleDateRangeChange = (range) => {
    setDateRange(range);
    // dispatch(
    //   fetchWorkqueueRequest(1, pagination.pageSize, selectedStatus, range)
    // );
  };

  const handleSortChange = (event) => {
    console.log("event", event);
    const selectedSortOrder = event.target.value;

    const selectedOption = [
      { text: "Sort By", value: "sort", disabled: true },
      { text: "Ascending", value: "asc" },
      { text: "Descending", value: "dsc" },
    ].find((option) => option.value === selectedSortOrder);

    setSelectedSort(selectedOption?.value ?? "sort");
  };

  const handleSearch = () => {
    let filteredData = [...workQueues];

    if (selectedStatus && selectedStatus !== "all") {
      filteredData = filteredData.filter(
        (row) => row.status === selectedStatus
      );
    }
  };

  return (
    <WorkqueueFilterWrap theme={theme} className="table-widget">
      <Box theme={theme} className="custom-dropdown">
        <Typography
          theme={theme}
          className="text-body2 text-muted"
          variant="body2"
          component={"span"}
          display={"block"}
        >
          Select Category:
        </Typography>
        <Select
          theme={theme}
          disablePortal={false}
          items={[{ text: "All Queues", value: "all" }, ...statusOptions]}
          onChange={handleStatusChange}
          value={selectedStatus}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </Box>
      <Box theme={theme} className="multi-functions">
        <Box theme={theme} className="datepicker">
          <DateRange
            theme={theme}
            onChange={handleDateRangeChange}
            disablePortal={true}
            placeholder={"Calendar"}
            endAdornment={
              <Icon name="CalendarIcon" className="calendar-icon" />
            }
            render={<input placeholder="Calendar" readOnly />}
            onFocus={() => {}}
          />
        </Box>
        <Box theme={theme} className="sort-btn">
          <Box theme={theme} className="custom-dropdown">
            <Icon name="AscDescIcon" className="sort-icon" />
            <Select
              theme={theme}
              disablePortal={false}
              onChange={handleSortChange}
              items={[
                {
                  text: "Sort By",
                  value: "sort",
                  disabled: true,
                },
                {
                  text: "Ascending",
                  value: "asc",
                },
                {
                  text: "Decending",
                  value: "dsc",
                },
              ]}
              value={selectedSort ?? "Sort By"}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
          </Box>
        </Box>
        <Button
          theme={theme}
          defaultBtn
          iconText={<Icon name="ResetIcon" />}
          onClick={() => {}}
        />
        <Button
          theme={theme}
          defaultBtn
          iconText={<Icon name="RefreshIcon" />}
          onClick={() => {}}
        />
        <Button
          theme={theme}
          defaultBtn
          iconText={<Icon name="SearchIcon" />}
          onClick={handleSearch}
          // onChange={handleSearch}
        />
      </Box>
    </WorkqueueFilterWrap>
  );
};
