import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const ComplianceCheckListTabWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.compliance-checklist-tab-wrap": {
        ".TabsUnstyled-root": {
            "&.TabsUnstyled-horizontal": {
                ".tab-panel-underline": {
                    padding: 0
                }
            }
        }
    },
}));
