import { useTheme } from "@mui/material";
import { AuditTrailListWrap } from "./audit-trail-list.style";
import { Box, Accordion, CircleLoader } from "@ntpkunity/controls";
import { Activities } from "../../activities/activities.component";
import { Typography } from "@ntpkunity/controls";
import { useSelector } from "react-redux";
import { isSameDay, isWithinInterval, subDays, startOfDay } from "date-fns";

export const AuditTrailList = () => {
  const theme = useTheme();

  // Commenting out API data
  // const { auditTrailData, loading, error } = useSelector((state: any) => state.auditTrail);

  const today = startOfDay(new Date());
  const yesterday = startOfDay(subDays(today, 1));
  const last7DaysStart = startOfDay(subDays(today, 7));

  const parseComment = (comment) => {
    return comment ? comment.replace(/#\w+=\S+/g, "").trim() : "";
  };

  // Static comments instead of API data
  const filteredAuditTrailData = [
    {
      id: 1,
      created_at: new Date().toISOString(),
      comment: "AG User updated the application status.",
    },
    {
      id: 2,
      created_at: new Date().toISOString(),
      comment: "AG Admin assigned a new case to AG agent.",
    },
  ];

  const filterDataByDateRange = (
    data: any[],
    range: "today" | "yesterday" | "last7Days"
  ) => {
    if (!data) return [];
    switch (range) {
      case "today":
        return data.filter((item: any) =>
          isSameDay(startOfDay(new Date(item.created_at)), today)
        );
      case "yesterday":
        return data.filter((item: any) =>
          isSameDay(startOfDay(new Date(item.created_at)), yesterday)
        );
      case "last7Days":
        return data.filter((item: any) =>
          isWithinInterval(startOfDay(new Date(item.created_at)), {
            start: last7DaysStart,
            end: today,
          })
        );
      default:
        return [];
    }
  };

  const dataRanges = [
    {
      key: "today",
      label: "Today",
      data: filterDataByDateRange(filteredAuditTrailData, "today"),
    },
    {
      key: "yesterday",
      label: "Yesterday",
      data: filterDataByDateRange(filteredAuditTrailData, "yesterday"),
    },
    // {
    //   key: "last7Days",
    //   label: "Last 7 Days",
    //   data: filterDataByDateRange(filteredAuditTrailData, "last7Days"),
    // },
  ];

  return (
    <>
      <AuditTrailListWrap theme={theme} className="audit-trail-list-wrap">
        {/* Commented out loading and error conditions */}
        {/* {loading ? (
                    <CircleLoader theme={theme} size="sm" />
                ) : error ? (
                    <Typography
                        theme={theme}
                        variant="body2"
                        color="error"
                        className="text-muted"
                        component={"symbol"}
                    >
                        Error: {error}
                    </Typography>
                ) : ( */}
        <Box theme={theme} className="accordion-box weekdays">
          {dataRanges.map(({ key, label, data }) =>
            data.length > 0 ? (
              <Accordion
                key={key}
                theme={theme}
                items={[
                  {
                    content: <Activities data={data} />,
                    title: (
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="span"
                        className="text-muted"
                      >
                        {label}
                      </Typography>
                    ),
                    key,
                    isExpanded: true,
                    id: key,
                    ariaControls: key,
                  },
                ]}
                shouldCollapse
              />
            ) : null
          )}
        </Box>
        {/* )} */}
      </AuditTrailListWrap>
    </>
  );
};