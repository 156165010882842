import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { keyframes, lineHeight } from "@mui/system";

const bounce = keyframes`
   0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

export const FinanceStructureModifyDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.finance-structure-modify-popup-wrap": {
    padding: 8,
    ".stack-wrap": {
      position: "relative",
      ".stack-header": {
        padding: "0 4px",
      },
      ".sticky-note-container": {
        position: "absolute",
        right: 16,
        top: 60,
        bottom: 0,
        ".add-sticky-note-wrap": {
          height: "96%",
          width: 135,
          ".MuiTypography-root": {
            fontSize: theme.typography.body2.fontSize,
          },
        },
        "@media only screen and (min-width: 1800.95px)": {
          width: 160,
          ".add-sticky-note-wrap": {
            height: "97%",
            width: "100%",
          },
        },
      },
    },
    ".header": {
      display: "flex",
      gap: 16,
      ".empty": {
        minWidth: "190px",
        maxWidth: "211px",
        marginRight: 12,
        "@media only screen and (min-width: 1800.95px)": {
          minWidth: "190px",
        },
      },
      ".header-check": {
        display: "flex",
        gap: 16,
        ".custom-checkbox-wrap": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          ".u-custom-control-label": {
            ".MuiFormControlLabel-label": {
              color: "#474A59",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: 118,
              "@media only screen and (min-width: 1800.95px)": {
                width: 128,
              },
            },
          },
        },
      },
    },

    ".finance-structure-popup-content": {
      position: "relative",
      display: "flex",
      gap: 16,
      "&.scrollable-container": {
        height: "calc(100vh - 370px)",
        overflowX: "hidden",
        overflowY: "auto",
        scrollbarWidth: "none",
      },
      ".left-side": {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        ".list": {
          padding: 8,
          minWidth: "190px",
          maxWidth: "208px",
          minHeight: 40,
          ".left-text": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "block",
          },
        },
      },
      ".right-side": {
        display: "flex",
        gap: 16,
        "@media only screen and (min-width: 1599.95px)": {
          gap: 10,
        },
        ".col-input": {
          width: 149,
          "@media only screen and (min-width: 1800.95px)": {
            width: "163px",
          },
          ".u-form-group": {
            marginBottom: 8,
            ".u-form-control": {
              backgroundColor: "#F7F7F7",
              "&.text-right": {
                ".MuiInputBase-input": {
                  textAlign: "right",
                  maskImage: "none",
                },
              },
              ".MuiInputBase-input": {
                fontSize: theme.typography.body1.fontSize,
                fontWeight: theme.typography.body1.fontWeight,
                lineHeight: theme.typography.body1.lineHeight,
                color: "#474A59",
                padding: 8,
              },
              "&.MuiInputBase-adornedStart": {
                paddingLeft: 8,
                ".adornment-icon": {
                  "&.start-icon": {
                    marginRight: 0,
                    fontSize: theme.typography.body1.fontSize,
                    fontWeight: theme.typography.body1.fontWeight,
                    lineHeight: theme.typography.body1.lineHeight,
                    color: "#474A59",
                  },
                },
              },
              fieldset: {
                border: "none",
              },
              "&.Mui-disabled": {
                ".adornment-icon": {
                  opacity: 0.5,
                },
              },
            },
          },
        },
      },
    },
  },
}));
