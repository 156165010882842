import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const MyNotesListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.my-notes-list-wrap": {
    // display: 'flex',
    // flexDirection: 'column',
    // gap: 16,
    ".stack-user-header": {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 8,
      paddingRight: 8,
      paddingBottom: 8,
      gap: 16,
      ".sticky-color": {
        paddingLeft: 8
      },
      ".notes-content": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0 8px',
        gap: 8,
        ".notes-head": {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          ".label-bg": {
            ".label-text": {
              fontSize: theme.typography.caption.fontSize,
              lineHeight: theme.typography.caption.lineHeight,
              fontWeight: theme.typography.fontWeightMedium,
              textTransform: 'capitalize',
              "@media only screen and (min-width: 1599.5px)": {
                fontSize: theme.typography.body2.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
              },
            },
          },
        },
        ".notes-body": {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: 8,
          ".tag": {
            backgroundColor: '#F5F5F7',
            borderRadius: 8,
            padding: '4px 8px'
          },
          ".date-time": {
            ".month": {
              paddingRight: 8,
              marginRight: 8,
              borderRight: '1px solid',
              borderColor: theme.palette.grey[100]
            }
          }
        },
        ".notes-footer": {
          textAlign: 'right',
          width: '100%'
        }
      }
    },
  },
}));
