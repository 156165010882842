import { useTheme } from "@mui/material";
import { ActivitiesWrap } from "./activities.style";
import { Box, Accordion, Typography, Label, SelectionButton } from "@ntpkunity/controls";
import { UserName } from "../user-name/index";

export const Activities = ({ data }: any) => {
    const theme = useTheme();

    return (
      <ActivitiesWrap theme={theme} className="activities-wrap">
        <Box theme={theme} className="accordion-box child">
          {data && data.length > 0 ? (
            <Accordion
              theme={theme}
              items={data.map((item: any, index: number) => ({
                content: (
                  <Box theme={theme} className="content small-view">
                    <Label
                      theme={theme}
                      text={item.category || "General"}
                      state="primary"
                      textTransform="none"
                    />
                    <SelectionButton
                      theme={theme}
                      // buttonItems={[
                      //   { title: "Sample Content", state: "unavailable" },
                      //   { title: "Sample Content", state: "unavailable" },
                      // ]}
                    />
                  </Box>
                ),
                title: (
                  <UserName
                    userTitle="AG"
                    userName={item.created_by || "Asad"}
                    subTitle={item.comment || "No comment available"}
                  />
                ),
                key: `asset-${index}`,
                isExpanded: index === 0,
                id: `asset-${index}`,
                ariaControls: `asset-${index}`,
              }))}
              shouldCollapse
            />
          ) : (
            <Typography
              theme={theme}
              variant="body2"
              color="textSecondary"
              component={"symbol"}
            >
              No activities available.
            </Typography>
          )}
        </Box>
      </ActivitiesWrap>
    );
};
