import React from "react";
import { IconButton, Divider } from "@mui/material";
import {
  Icon,
  type ISideBarLayoutProps,
  Grid,
  Typography,
  Avatar,
  Box,
  List,
  Switch as CustomizedSwitches,
  Menu,
  Input,
} from "@ntpkunity/controls";

import {
  CustomizedContainer,
  CustomizedDrawer,
  LayoutWrap,
} from "@src/layouts/LayoutWithSideNav/LayoutWithSideNav.style";
import Logo from "@assets/images/verdict-logo.svg";
import { useNavigate } from "react-router-dom";
export const LayoutWithSideNavComponent: React.FC<ISideBarLayoutProps> = ({
  children,
  theme,
}) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleRootOptionsClick = () => setOpen(true);

  const handleOptionClick = (event, key, value) => {};

  const MenuComp = (): JSX.Element => {
    return (
      <Menu
        theme={theme}
        disablePortal={true}
        handleOptionClick={handleOptionClick}
        options={[{ optionText: "Logout", optionkey: "Logout" }]}
        render={(cb) => (
          <>
            <a
              className="header-link-item user-link"
              href="javascript:void(0)"
              onClick={cb}
            >
              <Box theme={theme} className="user-item">
                <Avatar theme={theme}>AJ</Avatar>
                <Box theme={theme} className="user-info">
                  <Typography
                    theme={theme}
                    className="text-black fw-sbold text-subtitle1"
                    variant="caption"
                    component={"small"}
                    display={"block"}
                  >
                    {"Asad Javaid"}
                  </Typography>
                  <Typography
                    theme={theme}
                    className="text-muted text-tiny text-body1"
                    variant="caption"
                    component={"small"}
                    display={"block"}
                  >
                    {"asad.javiad@netsoltech.com"}
                  </Typography>
                </Box>
              </Box>
            </a>
          </>
        )}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    );
  };

  return (
    <LayoutWrap theme={theme} component="div" gap={2}>
      <Box theme={theme} component="aside" className="u-aside-wrap">
        <CustomizedDrawer
          theme={theme}
          variant="permanent"
          open={open}
          className="u-side-nav"
        >
          <Box theme={theme} className="menu-top-wrap">
            <Box
              className="menu-action"
              theme={theme}
              display={{ xs: "block", md: "none" }}
            >
              <IconButton
                onClick={() => setOpen(!open)}
                className="menu-toggle"
              >
                <Box theme={theme} className="menu-icon">
                  <Box theme={theme} className="toggle-bar"></Box>
                </Box>
              </IconButton>
            </Box>
            <div className="logo-wrap">
              <div className="logo-area">
                <img
                  id="unity_logo_icon"
                  src={Logo}
                  alt="logo"
                  className="logo-icon"
                />
                <img
                  id="unity_logo"
                  src={Logo}
                  alt="logo"
                  className="full-logo"
                />
              </div>
            </div>
            <Box
              className="header"
              theme={theme}
              display={{ xs: "flex", md: "none" }}
              gap={2}
            >
              <a
                className="header-link-item item-icon-only link-search"
                href="javascript:void(0)"
              >
                <Icon name="SearchIcon" />
              </a>
              <a
                className="header-link-item item-icon-only link-notification"
                href="javascript:void(0)"
              >
                <Icon name="BellIcon" />
                <span className="notify-icon"></span>
              </a>
            </Box>
          </Box>
          <div className="side-nav-area-wrap">
            <div className="aside-content-wrap content-top">
              <List
                theme={theme}
                options={[
                  {
                    label: "Dashboard",
                    icon: <Icon name="DashboardIcon" />,
                    onClick: () => navigate("/cde/dashboard"),
                  },
                  {
                    label: "Task Queues",
                    icon: <Icon name="OrdersIcon" />,
                    onClick: () => navigate("/cde"),
                  },
                  {
                    label: "Configurations",
                    icon: <Icon name="DealerConfigIcon" />,
                  },
                ]}
              />
            </div>
            <div className="aside-content-wrap">
              <Typography
                theme={theme}
                variant="caption"
                component="small"
                className="nav-group-title"
              >
                Support
              </Typography>
              <List
                theme={theme}
                options={[
                  {
                    icon: <Icon name="DocumentIcon" />,
                    label: "Settings",
                    hideLabel: false,
                    hideIcon: false,
                    onClick: () => {},
                    listItemButtonSx: undefined,
                    listItemIconSx: undefined,
                    listItemTextSx: undefined,
                  },
                ]}
              />
            </div>
          </div>
          <div className="aside-content-wrap action-content">
            <Divider />
            <div className="side-nav-actions">
              <IconButton
                className="toggle-nav-btn"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <Icon name="ChevronRight" />
              </IconButton>
              <Box
                className="user-account"
                theme={theme}
                display={{ xs: "flex", md: "none" }}
              >
                <MenuComp />
                <div className="header-link-item item-theme-switch">
                  <CustomizedSwitches
                    onChange={(e) => {}}
                    theme={theme}
                  ></CustomizedSwitches>
                </div>
              </Box>
            </div>
          </div>
        </CustomizedDrawer>
      </Box>
      <CustomizedContainer theme={theme} open={open} component="main">
        <Box
          theme={theme}
          component="header"
          display={{ xs: "none", md: "flex" }}
        >
          <Grid theme={theme} direction="row" container>
            <Grid theme={theme} item xs={6} md={6}>
              <Box theme={theme} className="header-search" display={"none"}>
                <Input
                  theme={theme}
                  placeholder="What are you looking for?"
                  startAdornment={<Icon name="SearchIcon" />}
                  type={"text"}
                  fullWidth
                />
              </Box>
              <Box theme={theme} display={"flex"} gap={0.5}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component={"span"}
                  className="text-body2 text-muted"
                >
                  Tenant:{" "}
                </Typography>
                <Typography
                  theme={theme}
                  variant="body2"
                  component={"span"}
                  className="text-h4 fw-sbold"
                >
                  Netsol{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid
              theme={theme}
              item
              display="flex"
              justifyContent="right"
              alignItems={"center"}
              gap={"24px"}
              xs={6}
              md={6}
              className="header-nav-right"
            >
              <div className="header-link-item item-theme-switch">
                <CustomizedSwitches
                  onChange={(e) => {}}
                  theme={theme}
                ></CustomizedSwitches>
              </div>
              <a
                className="header-link-item item-icon-only link-notification"
                href="javascript:void(0)"
              >
                <Icon name="BellIcon" />
                <span className="notify-icon"></span>
              </a>
              <MenuComp />
            </Grid>
            <Divider />
          </Grid>
        </Box>
        <Box theme={theme} className="content-container">
          {children}
        </Box>
      </CustomizedContainer>
    </LayoutWrap>
  );
};

export default LayoutWithSideNavComponent;
