import {
  FETCH_WORKQUEUE_REQUEST,
  FETCH_WORKQUEUE_SUCCESS,
  FETCH_WORKQUEUE_FAILURE,
  UPDATE_SORT_ORDER,
  UPDATE_FILTERS,
  UPDATE_PAGINATION,
  EDIT_WORKQUEUE_REQUEST,
  EDIT_WORKQUEUE_SUCCESS,
  EDIT_WORKQUEUE_FAILURE,
  DELETE_WORKQUEUE_REQUEST,
  DELETE_WORKQUEUE_SUCCESS,
  DELETE_WORKQUEUE_FAILURE,
  UPDATE_RUNNING_WORKFLOW_ID,
  UPDATE_CREDIT_CHECK_SCORE,
  UPDATE_ASSIGNED_ROW_ID,
} from "../actions/workQueueActions";

const initialState = {
  loading: false,
  workQueues: [],
  running_workflow_instance_id: null,
  assignedRowId: null,
  credit_check_score: null,
  error: null,
  filters: {},
  sortOrder: { column: null, order: null },
  pagination: {
    pageNumber: 1,
    pageSize: 50,
    totalRecords: 0,
    totalPages: 1,
  },
};

const workqueueReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WORKQUEUE_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_WORKQUEUE_SUCCESS:
      const { data, pagination } = action.payload;
      return {
        ...state,
        loading: false,
        workQueues: data ?? [],
        pagination: {
          pageNumber: pagination?.page_number ?? state.pagination.pageNumber,
          pageSize: pagination?.records_per_page ?? state.pagination.pageSize,
          totalRecords: pagination?.total_count ?? state.pagination.totalRecords,
          totalPages: pagination?.total_pages ?? state.pagination.totalPages,
        },
      };

    case FETCH_WORKQUEUE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case UPDATE_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.payload,
      };

    case UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case UPDATE_PAGINATION:
      return {
        ...state,
        pagination: {
          pageNumber: action.payload.pageNumber || state.pagination.pageNumber,
          pageSize: action.payload.pageSize || state.pagination.pageSize,
          totalRecords: state.pagination.totalRecords,
          totalPages: state.pagination.totalPages,
        },
      };

    case UPDATE_RUNNING_WORKFLOW_ID:
      return { ...state, running_workflow_instance_id: action.payload };

    case UPDATE_ASSIGNED_ROW_ID:
      return { ...state, assignedRowId: action.payload };

    case UPDATE_CREDIT_CHECK_SCORE:
      return { ...state, credit_check_score: action.payload };

    default:
      return state;
  }
};

export default workqueueReducer;