import { useTheme } from "@mui/material";
import { Accordion, Box, Button, Dialog, DuoTab } from "@ntpkunity/controls";
import { ApplicantDetailDialogWrap } from "./applicant-detail-dialog.style";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { ApplicantDetailsList } from "../applicant-detail-list/applicant-detail-list.component";
import { CoApplicantList } from "../co-applicant-list/co-applicant-list.component"
import { Popup } from "@src/components/Dialog/dialog.component";
import { useSelector } from "react-redux";

export const ApplicantDetailDialog = ({ open, closeDialog }) => {
  const theme = useTheme();

  const { applicantDetails } = useSelector(
    (state: any) => state.applicantDetails
  );
  const hasCoApplicant = !!applicantDetails?.coapplicant_info;

  return (
    <>
      <Popup>
        <Dialog
          theme={theme}
          size={"md"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Applicant Details"}
          disablePortal
          noFooter
        >
          <ApplicantDetailDialogWrap
            theme={theme}
            className="applicant-detail-dialog-wrap collapse-wrap"
          >
            <DuoTab
              theme={theme}
              varient="underline"
              defaultTabIndex={0}
              items={[
                {
                  content: <ApplicantDetailsList />,
                  title: "Primary Applicant",
                },
                ...(hasCoApplicant
                  ? [
                      {
                        content: <CoApplicantList />,
                        title: "Co-Applicant",
                      },
                    ]
                  : []),
              ]}
            />
          </ApplicantDetailDialogWrap>
        </Dialog>
      </Popup>
    </>
  );
};
