import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FinanceStructureWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.finance-structure-wrap": {
    ".stack-wrap": {
      ".basic-list-wrap": {
        paddingTop: 0,
        ".list": {
          ".title": {
            width: "50%",
            minWidth: "50%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline",
            "@media only screen and (min-width: 1800px)": {
              minWidth: "60%",
            },
          },
          ".action-area": {
            width: "50%",
            ".u-form-group": {
              ".adornment-icon": {
                opacity: 0.5,
              },
            },
            "@media only screen and (min-width: 1800px)": {
              width: "40%",
            },
          },
        },
      },
      ".checkbox-section": {
        paddingBottom: 8,
        paddingTop: 16,
        display: "flex",
        gap: 16,
        ".empty": {
          minWidth: "50%",
          "@media only screen and (min-width: 1800px)": {
            minWidth: "60%",
          },
        },
        ".custom-checkbox-wrap": {
          overflow: "hidden",
          ".u-custom-control-label": {
            width: "100%",
            margin: 0,
            ".MuiTypography-root": {
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
          },
        },
      },
    },
  },
}));
