import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AddDecisionDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-decision-dialog-wrap": {
    padding: '0 8px',
    height: 'auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    ".stack-wrap": {
      "&.header": {
        padding: '16px 0'
      },
      ".stack-header": {
        ".title": {
          ".MuiTypography-root": {
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.customVariables.fontWeightSemiBold,
            lineHeight: theme.typography.body2.lineHeight
          }
        },
        ".action-area": {
          a: {
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight
          }
        }
      }
    },
    ".TabsUnstyled-root": {
      "&.TabsUnstyled-horizontal": {
        ".tab-panel-underline": {
          padding: '16px 0 0 0'
        }
      }
    },
    ".u-form-group": {
      ".u-help-text": {
        "&.text-count": {
          textAlign: 'right'
        }
      }
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        margin: 0
      }
    },
  },
}));
