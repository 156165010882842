import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PayablesTableWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.compliance-checklist-table-wrap": {
        ".data-table-wrap": {
            
        }
    },
}));
