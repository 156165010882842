import {
  ADD_STIP_REQUEST,
  ADD_STIP_SUCCESS,
  ADD_STIP_FAILURE,
  DELETE_STIP,
  ADD_ALL_STIPS,
} from "../actions/stipActions";

const initialState: {
  loading: boolean;
  success: boolean;
  error: any;
  stips: any[];
} = {
  loading: false,
  success: false,
  error: null,
  stips: [],
};

const stipReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STIP_REQUEST:
      return { ...state, loading: true, error: null, success: false };

    case ADD_STIP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        stips: [...state.stips, action.payload],
      };

    case ADD_STIP_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case DELETE_STIP:
      return {
        ...state,
        loading: false,
        success: false,
        stips: state.stips.filter(
          (stip) => stip.recommendation != action.payload
        ),
      };

    case ADD_ALL_STIPS:
      return {
        ...state,
        loading: false,
        success: false,
        stips: action.payload,
      };

    default:
      return state;
  }
};

export default stipReducer;
