import { useTheme } from "@mui/material";
import { Stack } from "@src/components/stack";
import { BookmarksWrap } from "./bookmarks.style"
import { Box, Typography, Button, Icon, Select } from "@ntpkunity/controls"
import { MultiFunctions } from "@src/components/multi-functions/multi-functions.component"
import { useState } from "react";


export const Booksmarks = () => {
    const theme = useTheme();
    const [selectedCategory, setSelectedCategory] = useState("all");

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const columns = [
        { key: "business_rules_name", label: "Business Rule Name" },
        { key: "work_flow_activity", label: "Work Flow Activity" },
        { key: "description", label: "Description" },
    ];

    return (
        <BookmarksWrap theme={theme} className="bookmarks-wrap">
            <Box theme={theme} className="bookmarks-header">
                <Typography theme={theme} variant="body1" component={'span'} className="fw-sbold">Bookmarks</Typography>
                <Box theme={theme} display={'flex'} gap={'10px'}>
                    <Button
                        theme={theme}
                        defaultBtn
                        className="no-stroke no-padding"
                        iconText={<><Icon name="PinUnpin" /></>}
                    />
                    <Button
                        theme={theme}
                        defaultBtn
                        className="no-padding"
                        iconText={<><Icon name="CloseBlack" /></>}
                    />
                </Box>
            </Box>
            <Box theme={theme} className="bookmarks-filters">
                <Box theme={theme} className="custom-dropdown">
                    <Typography
                        theme={theme}
                        className="text-muted"
                        variant="body2"
                        component={"span"}
                        display={"block"}
                    >
                        Category:
                    </Typography>
                    <Select
                        theme={theme}
                        disablePortal={false}
                        items={[
                            { text: "All", value: "all" },
                        ]}
                        onChange={handleCategoryChange}
                        value={selectedCategory}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    />
                </Box>
                <MultiFunctions
                    onSorted={() => { }}
                    sortedItems={''}
                    sortedValue={''}
                    onInputChange={() => { }}
                />
            </Box>
            <Box theme={theme} className="bookmarks-content">
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Co_Borrower_First_Name</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Co_Borrower_Last_Name</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Co_Borrower_Mailing_Address</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Asset_Cost</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Finance_Amount</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Down_Payment</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Interest_Rate</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Commission_Percentage</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Commission_Amount</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Contract_Duration</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Customer_Mailing_Address</Typography>
                <Typography theme={theme} variant="caption" component={'small'} className="fw-medium bookmark-list">Customer_Last_Name</Typography>
            </Box>
        </BookmarksWrap>

    );
};

export default Booksmarks;
