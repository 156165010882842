import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const BookmarksWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.bookmarks-wrap": {
    padding: 8,
    border: '1px solid',
    borderColor: '#EAEBEE',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    gap: 16,
    ".bookmarks-header": {
      backgroundColor: theme.palette.common.white,
      padding: '12px 16px',
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'stretch',
      gap: 10,
      ".no-stroke": {
        "&.btn.with-icon.icon-only": {
          padding: 0
        },
        svg: {
          path: {
            stroke: 'none !important',
            fill: '#808080'
          }
        }
      }
    },
    ".bookmarks-filters": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 16px'
    },
    ".bookmarks-content": {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: '0 16px',
      marginBottom: 8,
      gap: 16,
      ".bookmark-list": {
        padding: '8px 16px',
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        display: 'block'
      }
    }
  },
}));
