import { ADD_MESSAGE } from "../actions/chatActions";

const initialState = {
  messages: {}, // Initialize as an empty object
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      const { workflowInstanceId, message } = action.payload;
      return {
        ...state,
        messages: {
          ...state.messages,
          [workflowInstanceId]: [
            ...(state.messages[workflowInstanceId] || []), // Ensure it's an array
            message,
          ],
        },
      };
    default:
      return state;
  }
};

export default chatReducer;