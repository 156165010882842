import { useTheme } from "@mui/material";
import { Box, Button, Grid, Icon, Label } from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { DocumentsList } from "../documents-list/document-list.component";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { fetchCustomerDocuments } from "@src/services/configureApplicationService";

export const Documents = () => {
  const theme = useTheme();

  const [documentData, setDocumentData] = useState([]);

  const { applicantDetails } = useSelector(
    (state: any) => state.applicantDetails
  );

  const { workQueueDetail } = useSelector((state: any) => state.workQueue);

  const isFetching = useRef(false);

  useEffect(() => {
    const loadBookmarks = async () => {
      if (!applicantDetails?.reference_number || isFetching.current) return;
      isFetching.current = true;
      try {
        const data = await fetchCustomerDocuments(
          applicantDetails.customer_info.reference_id,
          workQueueDetail?.data?.x_api_key
        );
        setDocumentData(data);
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
      }
      isFetching.current = false;
    };
    setDocumentData([]);
    loadBookmarks();

    return () => {
      isFetching.current = false;
    };
  }, [applicantDetails?.reference_number]);

  return (
    <>
      <Stack
        title={"Documents"}
        paddingXs={2}
        paddingMd={2}
      >
        <DocumentsList documents={documentData} />
      </Stack>
    </>
  );
};
