import { all } from "redux-saga/effects";
import { watchWorkQueuesSaga } from "./workQueueSaga";
import { watchApplicantDetailsSaga } from "./applicantDetailsSaga";
import { watchRiskAssessmentSaga } from "./riskAssessmentSaga";
import { watchFinanceStructureSaga } from "./financeStrcuctureSaga";
import { watchAuditTrailSaga } from "./auditTrailSaga";
import { watchMyNotesSaga } from "./myNotesSaga";
import { watchAddDecisionSaga, watchfetchDecisionSaga } from "./decisionSaga";
import { watchFundingRequestsSaga } from "./fundingSaga";
import { watchFetchStipSaga } from "./stipSaga";
// import { watchAddStipSaga } from "./stipSaga";

export default function* rootSaga() {
  yield all([
    watchWorkQueuesSaga(),
    watchApplicantDetailsSaga(),
    watchRiskAssessmentSaga(),
    watchFinanceStructureSaga(),
    watchAuditTrailSaga(),
    watchMyNotesSaga(),
    watchAddDecisionSaga(),
    watchfetchDecisionSaga(),
    watchFundingRequestsSaga(),
    // watchAddStipSaga(),
    watchFetchStipSaga(),
  ]);
}
