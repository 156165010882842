import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_FUNDING_REQUEST,
  fetchFundingFailure,
  fetchFundingSuccess,
  RETRIEVE_FUNDING_REQUEST,
  retrieveFundingFailure,
  retrieveFundingSuccess,
} from "../actions/fundingActions";

function* fetchFundingRequestsSaga(action) {
  try {
    const { pageNumber, pageSize, status } = action.payload;
    const apiUrl = `${
      process.env.REACT_APP_API_URL
    }/cde/funding-requests?page_number=${pageNumber}&records_per_page=${pageSize}${
      status ? `&status=${status}` : ""
    }`;
    const response = yield call(axios.get, apiUrl, {
      headers: { "x-api-key": `${process.env.REACT_APP_API_KEY}` },
    });

    if (response.status === 200) {
      const { data, pagination } = response.data;
      yield put(fetchFundingSuccess({ data, pagination }));
    } else {
      throw new Error(`API Error: ${response.status}`);
    }
  } catch (error) {
    console.error("FundingRequests Saga Error:", error);
    yield put(
      fetchFundingFailure(error.response?.data?.message || "API Request Failed")
    );
  }
}

function* retrieveFundingSaga(action) {
  try {
    const applicantReferenceNum = action.payload;

    const apiUrl = `${process.env.REACT_APP_API_URL}/cde/funding_application_queue?application_number=${applicantReferenceNum}`;

    const response = yield call(axios.get, apiUrl, {
      headers: { "x-api-key": `${process.env.REACT_APP_API_KEY}` },
    });

    if (response.status === 200) {
      const { data } = response.data;
      if (Array.isArray(data) && data.length > 0) {
        yield put(retrieveFundingSuccess({ data: data[0] }));
      } else {
        throw new Error("API Error: Data is empty or not an array");
      }
    } else {
      throw new Error(`API Error: ${response.status}`);
    }
  } catch (error) {
    console.error("Workqueues Saga Error:", error);
    yield put(
      retrieveFundingFailure(
        error.response?.data?.message || "API Request Failed"
      )
    );
  }
}

export function* watchFundingRequestsSaga() {
  yield takeLatest(FETCH_FUNDING_REQUEST, fetchFundingRequestsSaga);
  yield takeLatest(RETRIEVE_FUNDING_REQUEST, retrieveFundingSaga);
}
