import { TableCell, TableRow, useTheme } from "@mui/material";
import { Icon, Box, Typography } from "@ntpkunity/controls";
import { useState } from "react";
import { PayableChildRows } from "../payables-table-child-row/payables-table-child-row.component"; // Ensure this is correct
import React from 'react';

export const PayableTableRow = () => {
    const theme = useTheme();
    const [openIndex, setOpenIndex] = useState(null); // Track which row is expanded

    const rows = [
        {
            key: "payable",
            label: "Total Payable Asset Cost",
            amount: "44,700.00",
            partnerName: "Honda of Lincoln",
            partnerRole: "Dealer",
            accordion: true,
        },
        {
            key: "commission",
            label: "Commission",
            amount: "2,500.00",
            partnerName: "Honda of Lincoln",
            partnerRole: "Dealer",
            accordion: false,
        },
        {
            key: "advanceRentals",
            label: "Total Fees",
            amount: "150.00",
            partnerName: "Honda of Lincoln",
            partnerRole: "Dealer",
            accordion: true,
        },
        {
            key: "addOnes",
            label: "Total Add-Ons",  // Accordion row
            amount: "350.00",
            partnerName: "Honda of Lincoln",
            partnerRole: "Dealer",
            accordion: true,
        },
        {
            key: "F&I",
            label: "Total F&I Product",
            amount: "825.00",
            partnerName: "Honda of Lincoln",
            partnerRole: "Dealer",
            accordion: true,
        },
        {
            key: "maintenance",
            label: "Total Maintenance & Service Products",
            amount: "500.00",
            partnerName: "Honda of Lincoln",
            partnerRole: "Dealer",
            accordion: true,
        },
    ];

    // Toggle open index when an accordion row is clicked
    const handleAccordionToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle the open state for a specific row
    };

    return (
        <>
            {rows.map((row, index) => (
                <React.Fragment key={index}>
                    <TableRow className={row.accordion ? 'accordion-row' : ''}>
                        <TableCell className='w-300 fw-medium white-space-normal'>
                            <Box theme={theme} display={'flex'}>
                                {row.accordion ? (
                                    <>
                                        {row.label}
                                        <span>&nbsp;</span> {/* Spacer */}
                                        <Icon
                                            name={openIndex === index ? "ChevronDown" : "ChevronDown"}
                                            className={`toggle-btn ${openIndex === index ? 'ch-down' : ''}`}
                                            onClick={() => handleAccordionToggle(index)} // Toggle open/close
                                        />
                                    </>
                                ) : (
                                    row.label
                                )}
                            </Box>
                        </TableCell>
                        <TableCell className="w-180 fw-medium">
                            <Box theme={theme} display={'flex'} justifyContent={'space-between'}>
                                <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">$</Typography>
                                {row.amount}
                            </Box>
                        </TableCell>
                        <TableCell className="w-250 text-dark-grey">{row.partnerName}</TableCell>
                        <TableCell className="w-250 text-dark-grey">{row.partnerRole}</TableCell>
                    </TableRow>

                    {/* Render child rows below the accordion row when it's open */}
                    {row.accordion && openIndex === index && (
                        <PayableChildRows open={true} index={index} /> // Only show if openIndex matches
                    )}
                </React.Fragment>
            ))}
        </>
    );
};
