import { useTheme } from "@mui/material";
import { Dialog} from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { ComplianceCheckListTable } from "../compliance-checklist-table/compliance-checklist-table.component";

export const ComplianceChecklistDialog = ({ open, closeDialog }) => {
  const theme = useTheme();

  return (
    <>
      <Popup>
        <Dialog
          theme={theme}
          size={"xl"}
          onCloseDialog={closeDialog}
          open={open}
          title={"Compliance Checklist"}
          disablePortal
          noFooter
        >
            <ComplianceCheckListTable />
        </Dialog>
      </Popup>
    </>
  );
};
