import {
  FETCH_FUNDING_FAILURE,
  FETCH_FUNDING_REQUEST,
  FETCH_FUNDING_SUCCESS,
  RETRIEVE_FUNDING_FAILURE,
  RETRIEVE_FUNDING_REQUEST,
  RETRIEVE_FUNDING_SUCCESS,
} from "../actions/fundingActions";

const initialState = {
  loading: false,
  fundingRequests: [],
  fundingRequestDetail: null,
  assignedRowId: null,
  error: null,
  pagination: {
    pageNumber: 1,
    pageSize: 50,
    totalRecords: 0,
    totalPages: 1,
  },
};

const fundingRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FUNDING_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_FUNDING_SUCCESS:
      const { data, pagination } = action.payload;
      return {
        ...state,
        loading: false,
        fundingRequests: data ?? [],
        pagination: {
          pageNumber: pagination?.page_number ?? state.pagination.pageNumber,
          pageSize: pagination?.records_per_page ?? state.pagination.pageSize,
          totalRecords:
            pagination?.total_count ?? state.pagination.totalRecords,
          totalPages: pagination?.total_pages ?? state.pagination.totalPages,
        },
      };

    case FETCH_FUNDING_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case RETRIEVE_FUNDING_REQUEST:
      return { ...state, loading: true, error: null };

    case RETRIEVE_FUNDING_SUCCESS:
      return {
        ...state,
        loading: false,
        fundingRequestDetail: action.payload,
      };

    case RETRIEVE_FUNDING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default fundingRequestReducer;
