import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-content": {
    ".stack-wrap": {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      padding: 24,
      paddingBottom: 0,
      "@media only screen and (max-width: 1599.95px)": {
        padding: 16,
      }
    },
    ".data-table-wrap": {
      ".u-table-wrap": {
        ".u-table-container": {
          maxHeight: "calc(100vh - 246px)",
          "@media only screen and (min-width: 1599.95px)": {
            maxHeight: "calc(100vh - 270px)",
          },
        },
      },
    },
  },
}));
