import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_WORKQUEUE_REQUEST,
  fetchWorkqueueSuccess,
  fetchWorkqueueFailure,
} from "../actions/workQueueActions";


function* fetchWorkQueuesSaga(action) {
  try {
    const { pageNumber, pageSize, status } = action.payload;
    const apiUrl = `${process.env.REACT_APP_API_URL}/cde/requests?page_number=${pageNumber}&records_per_page=${pageSize}${status ? `&status=${status}` : ""}`;
    const response = yield call(axios.get, apiUrl, {
      headers: { "x-api-key": `${process.env.REACT_APP_API_KEY}` },
    });

    if (response.status === 200) {
      const { data, pagination } = response.data;
      yield put(fetchWorkqueueSuccess({ data, pagination }));
    } else {
      throw new Error(`API Error: ${response.status}`);
    }
  } catch (error) {
    console.error("Workqueues Saga Error:", error);
    yield put(fetchWorkqueueFailure(error.response?.data?.message || "API Request Failed"));
  }
}


export function* watchWorkQueuesSaga() {
  yield takeLatest(FETCH_WORKQUEUE_REQUEST, fetchWorkQueuesSaga);
}