import { useTheme } from "@mui/material";
import { Box, Typography, Icon } from "@ntpkunity/controls";
import { ReactNode } from "react";
import clsx from "clsx";
import { AlertWrap } from "./alert.style";
import frame from "@assets/icons/expandedFrame.svg"
import successGraphIcon from "@assets/icons/successGraph.svg"
import errorGraphIcon from "@assets/icons/errorGraph.svg"

export const Alert = ({
  text,
  error,
  notVerified,
  success,
  description,
  fullWidth,
  value,
  expandedFrameSuccess,
  expandedFrameError,
  successGraph,
  errorGraph,
  accordionSuccess,
  accordionError
}: {
  text?: string | ReactNode;
  error?: boolean;
  notVerified?: boolean
  success?: boolean;
  description?: ReactNode;
  fullWidth?: boolean;
  value?: ReactNode;
  expandedFrameSuccess?: boolean;
  expandedFrameError?: boolean;
  successGraph?: boolean;
  errorGraph?: boolean
  accordionSuccess?: boolean
  accordionError?: boolean
}) => {
  const theme = useTheme();
  return (
    <AlertWrap
      theme={theme}
      className={clsx({
        "alert-wrap": true,
        "w-100": fullWidth,
        "error-alert": error || notVerified || expandedFrameError || accordionError,
        "success-alert": success || expandedFrameSuccess || accordionSuccess,
      })}
    >
      <Box theme={theme} className="top-content">
        {text && (
          <Box theme={theme} className="alert-text">
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
              className="fw-medium"
            >
              {text}
            </Typography>
            {value && (
              <Typography
                theme={theme}
                component={"h4"}
                variant="h4"
                className={clsx({
                  "text-danger": error || expandedFrameError || notVerified || accordionError,
                  "text-success": success || expandedFrameSuccess || accordionSuccess,
                })}
              >
                {value}
              </Typography>
            )}
            <Box theme={theme} ml={0.2}>
              {successGraph && <img src={successGraphIcon}/>}
              {errorGraph && <img src={errorGraphIcon}/>}
            </Box>
          </Box>
        )}
        <Box theme={theme} className="alert-icon">
          {success && <Icon name="GreenCheckCircleIcon" />}
          {error && <Icon name="ErrorTriangleOutline" />}
          {notVerified && <Icon name="NotVerifiedIcon"/>}
          {expandedFrameSuccess && <img src={frame}/>}
          {expandedFrameError && <img src={frame}/>}
        </Box>
      </Box>
      {description && (
        <Box theme={theme} className="bottom-content">
          {description}
        </Box>
      )}
    </AlertWrap>
  );
};
