import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const StickyNotesListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.sticky-notes-list-wrap": {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    ".react-multi-carousel-list": {
      paddingLeft: 60,
      margin: '12px -16px 20px -16px',
      ".react-multi-carousel-track": {
        gap: 10,
        ".react-multi-carousel-item": {
          width: '220px !important'
        }
      }
    },
    ".react-multi-carousel-dot-list": {
      bottom: 10,
      ".react-multi-carousel-dot": {
        button: {
          width: 4,
          height: 4,
          marginRight: 4,
          borderColor: theme.palette.grey[100]
        },
        "&.react-multi-carousel-dot--active": {
          button: {
            width: 12,
            borderRadius: 3,
            borderColor: theme.palette.primary.main
          }
        }
      }
    },
    ".carousel-button-group": {
      ".carousel-control": {
        width: 40,
        border: 'none',
        borderRadius: 8,
        top: '77%',
        "&.prev-btn": {
          left: 16
        },
        "&.next-btn": {
          right: 16
        }
      }
    }
  },
}));
