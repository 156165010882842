import {
    FETCH_FINANCE_STRUCTURE_REQUEST,
    FETCH_FINANCE_STRUCTURE_SUCCESS,
    FETCH_FINANCE_STRUCTURE_FAILURE,
    UPDATE_FINANCE_STRUCTURE_REQUEST,
    UPDATE_FINANCE_STRUCTURE_SUCCESS,
    UPDATE_FINANCE_STRUCTURE_FAILURE,
    SET_SELECTED_STRUCTURE,
    UPDATE_FINANCE_STRUCTURES,
    DELETE_FINANCE_STRUCTURE,
} from "../actions/financeStructureActions";

const initialState = {
    data: {},
    updatedData: {},
    financeStructureRefId: null,
    loading: false,
    error: null,
    financeStructures: [] as Array<{ key: string; label: string; data: any }>,
    selectedStructure: null, 
    selectedStructureKey: null,
    credit_check_score: "--",
};

const financeStructureReducer = (state = initialState, action): any => {
    switch (action.type) {
        case FETCH_FINANCE_STRUCTURE_REQUEST:
            return { ...state, loading: true, error: null, financeStructureRefId: action.payload };

        case FETCH_FINANCE_STRUCTURE_SUCCESS:
            return { ...state, loading: false, data: action.payload };

        case FETCH_FINANCE_STRUCTURE_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case UPDATE_FINANCE_STRUCTURE_REQUEST:
            return { ...state, loading: true, error: null };

        case UPDATE_FINANCE_STRUCTURES:
            const newSelection = action.payload.find(s => s.key === state.selectedStructureKey) || null;

            return {
                ...state,
                financeStructures: action.payload,
                selectedStructure: newSelection,
                selectedStructureKey: newSelection ? newSelection.key : null,
            };

        case UPDATE_FINANCE_STRUCTURE_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case SET_SELECTED_STRUCTURE:
            return {
                ...state,
                selectedStructure: action.payload?.data ? action.payload : null,
                selectedStructureKey: action.payload?.data ? action.payload.key : null,
            };  

        case SET_SELECTED_STRUCTURE:
            if (!action.payload || !action.payload.key) {
                return state; // Prevent null updates
            }

            const updatedStructure = state.financeStructures.find(s => s.key === action.payload.key) || action.payload;

            return {
                ...state,
                selectedStructure: { ...updatedStructure, data: { ...updatedStructure.data } },
                selectedStructureKey: updatedStructure.key,
            };

        case DELETE_FINANCE_STRUCTURE:
            const updatedFinanceStructures = state.financeStructures.filter(
                (s: { key: string }) => s.key !== action.payload
            );

            const newSelectedStructure = updatedFinanceStructures.length > 0 ? updatedFinanceStructures[0] : null;

            return {
                ...state,
                financeStructures: updatedFinanceStructures,
                selectedStructure: newSelectedStructure,
                selectedStructureKey: newSelectedStructure ? newSelectedStructure.key : null,
            };

        default:
            return state;
    }
};

export default financeStructureReducer;