import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { keyframes, lineHeight } from "@mui/system";

const gradientShift = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const AiAssistantWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.ai-assistant-wrap": {
    position: "relative",
    "&.chat-initiated": {
      ".stack-wrap": {
        height: "calc(100vh - 128px)",
        ".start-chat": {
          backgroundColor: theme.palette.common.white,
          borderRadius: 8,
          padding: 8,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          marginTop: 0,
          ".cross-btn": {
            display: "flex",
            justifyContent: "right",
            ".MuiButtonBase-root": {
              "&.icon-only": {
                padding: 0,
              },
            },
          },
          ".help-text": {
            display: "none",
          },
          ".ai-help-text": {
            display: "block",
          },
          ".chat-box-history": {
            "&.center": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              height: "calc(100vh - 180px)",
            },
            ".chat-history": {
              minHeight: "120px",
              overflowY: "auto",
              marginBottom: "16px",
              display: "flex",
              flexDirection: "column",
              scrollbarWidth: "none",
              gap: "8px",
              ".user-message": {
                alignSelf: "flex-end",
                backgroundColor: "rgb(35 43 246 / 0.05)",
                color: theme.palette.grey[600],
                fontSize: theme.typography.body2.fontSize,
                fontWeight: theme.typography.body2.fontWeight,
                lineHeight: theme.typography.body2.lineHeight,
                padding: 16,
                borderRadius: "12px 12px 0px 12px",
                maxWidth: "75%",
              },
              ".ai-message": {
                alignSelf: "flex-start",
                backgroundColor: "rgb(13 13 13 / 0.05)",
                color: "#4D4D4D",
                padding: 16,
                borderRadius: "12px 12px 12px 0px",
                maxWidth: "75%",
              },
            },
            ".chat-box": {
              borderColor: "#F8F8F9",
              backgroundColor: "#F5F5F7",
            },
          },
        },
      },
    },
    ".ai-head": {
      display: "flex",
      alignItems: "start",
      flexDirection: "column",
      gap: 16,
      ".ai-head-text": {
        display: "flex",
        alignItems: "center",
        gap: 8,
      },
    },
    ".title": {
      display: "flex",
      alignItems: "start",
      flexDirection: "column",
      gap: 4,
      ".title-animation": {
        background: "linear-gradient(90deg, #DADBDE, #FFFFFF)",
        "-webkit-background-clip": "text",
        backgroundSize: "200% 100%",
        color: "transparent",
        animation: `${gradientShift} 3s linear infinite`,
        width: "fit-content",
        height: "auto",
      },
    },
  },
}));


