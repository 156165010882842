import {
    FETCH_RISK_ASSESSMENT_REQUEST,
    FETCH_RISK_ASSESSMENT_SUCCESS,
    FETCH_RISK_ASSESSMENT_FAILURE,
    OPEN_RISK_DIALOG,
    CLOSE_RISK_DIALOG,
    FETCH_FRAUD_SCORE,
    UPDATE_RATIOS,
} from "../actions/riskAssessmentActions";

const initialState = {
    loading: false,
    riskAssessmentData: null,
    error: null,
    isDialogOpen: false,
    credit_check_score: null,
    ratios: {
        dtiRatio: "0.00",
        ltiRatio: "0.00",
        dbrRatio: "0.00",
        creditUtilization: "0.00",
      },
};

const parseRiskAssessmentData = (data) => {
    return {
        primaryApplicant: {
            riskTier: data?.risk_tier ?? "--",
            creditRating: data?.credit_rating ?? "--",
            monthlyIncome: data?.estimated_monthly_payment ?? "--",
            dueAtSigning: data?.due_at_signing ?? "--",
            sellingPrice: data?.selling_price ?? "--",
            loanDetails: {
                financeAmount: data?.finance_amount ?? "--",
                downPayment: data?.down_payment ?? "--",
            },
        },
        coApplicant: {
            riskTier: "2",
            creditRating: "Satisfactory",
            monthlyIncome: "--",
            sellingPrice: "--",
        },
    };
};

const riskAssessmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_RATIOS:
      return {
        ...state,
        ratios: action.payload,
      };
        case FETCH_RISK_ASSESSMENT_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_RISK_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                riskAssessmentData: parseRiskAssessmentData(action.payload),
                // riskAssessmentData: action.Payload
            };
        case FETCH_RISK_ASSESSMENT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case OPEN_RISK_DIALOG:
            return { ...state, isDialogOpen: true };
        case CLOSE_RISK_DIALOG:
            return { ...state, isDialogOpen: false };
        case FETCH_FRAUD_SCORE:
        return {
          ...state,
          fraudScore: action.payload.data?.fraud?.scores?.[0]?.score ?? null,
        };
        case "SET_CREDIT_SCORE":
            return {
                ...state,
                credit_check_score: action.payload,
            };
        default:
            return state;
    }
};


export default riskAssessmentReducer;
