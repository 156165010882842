import { useTheme } from "@mui/material";
import {
  Accordion,
  Box,
  Button,
  CircleLoader,
  Grid,
  Icon,
  Label,
} from "@ntpkunity/controls";
import { Alert, ArrowDown } from "@src/components";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { RiskAssessmentGraph } from "../risk-assessment-graph/risk-assessment-graph";
import { AlertStatusText, BasicStack } from "@src/components/dashboard";
import { Stack } from "@src/components/stack";
import { useEffect, useState, useRef } from "react";
import { RiskAssessmentDialog } from "../risk-assessment-dialog/risk-assessment-dialog.component";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchWorkflowDetails } from "@src/services/configureApplicationService";
import { RiskAssessmentStackWrap } from "./risk-assessment-stack.style";
import { CreditScoreCounter } from "../credit-score-counter/credit-score-counter.component";
import graphImage from "@assets/images/graphImage.png"

export const RiskAssessmentStack = ({ exposureData }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const [fraudScores, setFraudScores] = useState([]) as any;
  const [ratios, setRatios] = useState({
    dtiRatio: null,
    ltvRatio: null,
    creditUtilization: null,
  }) as any;


  const dispatch = useDispatch();


  const { riskAssessmentData, error } = useSelector(
    (state: any) => state.riskAssessment
  );

  const { applicantDetails, loading } = useSelector(
    (state: any) => state?.applicantDetails
  );

  const creditCheckScore = useSelector(
    (state: any) => state.workQueue.credit_check_score
  );
  const { running_workflow_instance_id } = useSelector(
    (state: any) => state.workQueue
  );

  const { data } = useSelector((state: any) => state?.financeStructure);


  useEffect(() => {
    const fetchData = async (activityName: string) => {
      try {
        const base_url = process.env.REACT_APP_API_URL;
        const response = await axios.get(
          `${base_url}/cde/get_activity_workflow_data`,
          {
            params: {
              workflowInstanceId: running_workflow_instance_id,
              activity_name: activityName,
            },
          }
        );
        return response.data;
      } catch (err) {
        console.error(`Error fetching data for ${activityName}:`, err);
        return null;
      }
    };

    const fetchAllData = async () => {
      if (running_workflow_instance_id) {
        const fraudData = await fetchData("FraudCheck_Response");
        setFraudScores(fraudData?.fraud?.scores || []);

        const dtiData = await fetchData("LTI");
        const ltvData = await fetchData("LTV");
        const creditUtilizationData = await fetchData(
          "CreditUtilization_Response"
        );

        setRatios({
          dtiRatio: dtiData?.ratio ?? null,
          ltvRatio: ltvData?.ratio ?? null,
          creditUtilization: creditUtilizationData?.ratio ?? null,
        });
      }
    };

    fetchAllData();
  }, [running_workflow_instance_id]);


  const isFetching = useRef(false);

  useEffect(() => {
    const loadWorkflow = async () => {
      if (!running_workflow_instance_id || isFetching.current) return;

      isFetching.current = true;
      try {
        const data = await fetchWorkflowDetails(running_workflow_instance_id);
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
      }
      isFetching.current = false;
    };

    loadWorkflow();

    return () => {
      isFetching.current = false;
    };
  }, [running_workflow_instance_id]);

  // useEffect(() => {
  //   if (!riskAssessmentData) {
  //     dispatch(fetchRiskAssessmentRequest(customerReferenceId));
  //   }
  // }, [dispatch, riskAssessmentData]);

  // const {
  //   credit_rating,
  //   net_finance_amount,
  //   estimated_monthly_payment,
  //   due_at_signing,
  // } = riskAssessmentData?.primaryApplicant ?? {};

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Stack
        title={"Risk Assessment Metrices"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={<Icon name="ArrowLinkIcon" />}
            onClick={handleOpenDialog}
          />
        }
      >
        <RiskAssessmentStackWrap
          theme={theme}
          className="risk-assessment-stack-wrap"
        >
          {loading ? (
            <CircleLoader theme={theme} size="sm" />
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            <>
              <Box theme={theme} className="scrollable-container">
                <Box theme={theme} mb={2}>
                  <Label
                    theme={theme}
                    text="Risk Tier 2"
                    state="primary"
                    textTransform="none"
                  />
                </Box>
                <Box theme={theme} mb={2}>
                  <CreditScoreCounter />
                </Box>
                <Box theme={theme} className="risk-assessment-alerts">
                  <AlertStatusText
                    text={"Fraud Check"}
                    statusText={"Approved"}
                    success
                    fullWidth
                  />
                  <BasicStack
                    text={"Monthly Income:"}
                    subTitle={'$ 65,310.00'}
                    fullWidth
                    textJustify
                  />
                  <BasicStack
                    text={"Monthly Debt Payment"}
                    subTitle={'$ 8,500.00'}
                    fullWidth
                    textJustify
                  />
                  <BasicStack
                    text={"Current Exposure:"}
                    subTitle={'$ 52,4990.00'}
                    fullWidth
                    textJustify
                  />
                  <Alert
                    text={"Credit Utilisation:"}
                    value={"30%"}
                    success
                    fullWidth
                  />
                  <Alert
                    text={"Debt-to-Equity Ratio:"}
                    value={'1x'}
                    success
                    fullWidth
                  />
                  <Alert
                    text={"Debt-to-EBITDA Ratio:"}
                    value={'5x'}
                    notVerified
                    fullWidth
                  />
                  <Alert
                    text={"Return on Assets Ratio:"}
                    value={'5%'}
                    notVerified
                    fullWidth
                  />
                  <Alert
                    text={"Net Profit Ratio:"}
                    value={"15%"}
                    success
                    fullWidth
                  />
                  <Box theme={theme} className="accordion-graph-wrap">
                    <Accordion
                      theme={theme}
                      items={[
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box
                                theme={theme}
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                              >
                                <Alert
                                  text={"Long-Term Liabilities:"}
                                  value={"$220M"}
                                  fullWidth
                                  errorGraph
                                  accordionError
                                />
                              </Box>
                            </>
                          ),
                          key: "1",
                          isExpanded: false,
                          id: "1",
                          ariaControls: "1",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box
                                theme={theme}
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                              >
                                <Alert
                                  text={"Short-Term Liabilities:"}
                                  value={"$70M"}
                                  fullWidth
                                  errorGraph
                                  accordionError
                                />
                              </Box>
                            </>
                          ),
                          key: "2",
                          isExpanded: false,
                          id: "2",
                          ariaControls: "2",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box
                                theme={theme}
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                              >
                                <Alert
                                  text={"Net Profit:"}
                                  value={"$60M"}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "3",
                          isExpanded: false,
                          id: "3",
                          ariaControls: "3",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box
                                theme={theme}
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                              >
                                <Alert
                                  text={"EBIDTA:"}
                                  value={"$120M"}
                                  fullWidth
                                  errorGraph
                                  accordionError
                                />
                              </Box>
                            </>
                          ),
                          key: "4",
                          isExpanded: false,
                          id: "4",
                          ariaControls: "4",
                        },
                      ]}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
          <ArrowDown />
        </RiskAssessmentStackWrap>
      </Stack>

      <RiskAssessmentDialog
        open={isOpen}
        closeDialog={handleCloseDialog}
        applicantDetails={applicantDetails}
        exposureData={exposureData}
      />
    </>
  );
};
