import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Typography,
  Grid,
  Dialog,
  Box,
  DuoTab,
  Icon,
  Textarea,
  DataTable,
  Button,
  Input,
  Checkbox,
  Select,
  Accordion,
} from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { Stack } from "@src/components/stack";
import { DataTableWrap } from "@src/styles/data-table-wrap";
import { ReceivablesTableWrap } from "./receivables-table.style";
import { ReceivablesTableRow } from "./receivables-table-row/receivable-table-row.component"
import { useState } from "react";

export const ReceivablesTable = () => {
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const columns = [
    {
      key: "DocumentType",
      label: "",
      className: "w-300 fw-medium",
    },
    {
      key: "DocumentName",
      label: "Amount",
      className: "w-180 fw-medium",
    },
    {
      key: "UploadedBy",
      label: "Business Partner Name",
      className: "w-250 fw-medium",
    },
    {
      key: "Timestamp",
      label: "Business Partner Role",
      className: "w-250 fw-medium",
    },
  ];

  return (
    <>
      <ReceivablesTableWrap
        theme={theme}
        className="receivables-table-wrap"
      >
        <Box theme={theme} className="custom-dropdown" pb={3}>
          <Typography
            theme={theme}
            className="text-muted"
            variant="body2"
            component={"span"}
            display={"block"}
          >
            Filter by:
          </Typography>
          <Select
            theme={theme}
            disablePortal={false}
            items={[
              { text: "All Assets", value: "all" },
              { text: "Asset 1", value: "Asset1" },
              { text: "Asset 2", value: "Asset2" },
              { text: "Asset 3", value: "Asset3" },
            ]}
            onChange={handleCategoryChange}
            value={selectedCategory}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </Box>
        <DataTableWrap theme={theme} className="data-table-wrap customize-table">
          <DataTable
            theme={theme}
            theadChildren={
              <>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.key} className={column.className}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </>
            }
            tbodyChildren={
              <ReceivablesTableRow />
            }
          />
        </DataTableWrap>
      </ReceivablesTableWrap>
    </>
  );
};
