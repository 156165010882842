import { combineReducers } from "redux";
import workQueueReducer from "./workQueueReducer";
import applicantDetailsReducer from "./applicantDetailsReducer";
import riskAssessmentReducer from "./riskAssessmentReducer";
import financeStructureReducer from "../reducers/financeStructureReducer";
import auditTrailReducer from "../reducers/auditTrailReducer";
import myNotesReducer from "../reducers/myNotesReducer";
import decisionReducer from "./decisionReducer";
import stipReducer from "./stipReducer";

export interface RootState {
  workQueue: ReturnType<typeof workQueueReducer>;
  applicantDetails: ReturnType<typeof applicantDetailsReducer>;
  riskAssessment: ReturnType<typeof riskAssessmentReducer>;
  financeStructure: ReturnType<typeof financeStructureReducer>;
  auditTrail: ReturnType<typeof auditTrailReducer>,
  myNotes: ReturnType<typeof myNotesReducer>,
  decision: ReturnType<typeof decisionReducer>,
  stip: ReturnType<typeof stipReducer>,
}

const rootReducer = combineReducers({
  workQueue: workQueueReducer,
  applicantDetails: applicantDetailsReducer,
  riskAssessment: riskAssessmentReducer,
  financeStructure: financeStructureReducer,
  auditTrail: auditTrailReducer,
  myNotes: myNotesReducer,
  decision: decisionReducer,
  stip: stipReducer,
});

export default rootReducer;
