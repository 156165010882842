import { useTheme } from "@mui/material";
import {
  Icon,
  Grid,
  Typography,
  Textarea,
  Box,
  Button,
  Select,
} from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { AddStipWrap } from "./add-stip.style";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStipSuccess, fetchStipulationRequest } from "@src/components/redux/actions/stipActions";
import { useParams } from "react-router-dom";

const FORM_INITIAL_STATE = {
  stipulationType: "",
  recommendation: "",
  comments: "",
};

export const AddStip = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { filteredStips, loading, error } = useSelector(
    (state: any) => state?.stip
  );


  const { reference_number } = useParams()

  const assignedRowId = useSelector((state: any) => state.workQueue.assignedRowId);
  const workQueues = useSelector((state: any) => state.workQueue.workQueues);

  const [isToggled, setIsToggled] = useState(false);
  const [formState, setFormState] = useState(FORM_INITIAL_STATE);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const currentRecord = workQueues.find(
      (item) => item.reference_number === reference_number
    );

    if (currentRecord) {
      const status = currentRecord.status?.toLowerCase();
      if (status === "approved" || status === "declined") {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [workQueues, reference_number]);

  const handleClick = () => {
    if (!isDisabled) {
      setIsToggled(!isToggled);
    }
  };

  const handleInputChange = (key, value) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
    if (key === 'stipulationType') {
      dispatch(fetchStipulationRequest({ company_id: "15863", stipulation_type: value }));
    }
  };

  const handleAddStipulation = () => {
    if (
      !formState.stipulationType ||
      !formState.recommendation
    ) {
      return;
    }

    const payload = {
      stipulationType: formState.stipulationType,
      recommendation: formState.recommendation,
      comments: formState.comments,
    };

    dispatch(addStipSuccess(payload));
  };

  const filteredStipulations =
    formState.stipulationType === "Financial"
      ? filteredStips.filter((s) => s.stipulation_type === "Financial")
      : filteredStips;
  return (
    <>
      <AddStipWrap
        theme={theme}
        className="add-stip-wrap"
      >
        <Stack
          paddingMd={2}
          paddingXs={2}
          title={"Add Stipulations"}
          bgLight
        >
          <Box theme={theme} className="scrollable-container">
            <Grid theme={theme} container rowSpacing={2} columnSpacing={2} mt={0}>
              <Grid item xs={12} theme={theme}>
                <Select
                  theme={theme}
                  disablePortal={false}
                  label={"Stipulation Type"}
                  items={[
                    { text: "Financial", value: "Financial" },
                    { text: "Non Financial", value: "Non Financial" },
                  ]}
                  onChange={(e) =>
                    handleInputChange("stipulationType", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} theme={theme}>
                <Select
                  theme={theme}
                  disablePortal={false}
                  label={"Recommendation"}
                  items={filteredStipulations.map((s) => ({
                    text: s.parameter,
                    value: s.parameter,
                  }))}
                  className={`${filteredStipulations.length === 0 ? "disabled" : ""
                    }`}
                  onChange={(e) =>
                    handleInputChange("recommendation", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} theme={theme}>
                <Textarea
                  theme={theme}
                  id=""
                  type="text"
                  fullWidth
                  textCount={"10"}
                  totalTextCount={"60"}
                  rows={3}
                  label="Comments"
                  onChange={(e) => handleInputChange("comments", e)}
                  value={formState.comments}
                ></Textarea>
              </Grid>
            </Grid>
          </Box>
          <Box theme={theme} mt={2}>
            <Button
              theme={theme}
              secondary
              text="Add & Save"
              onClick={handleAddStipulation}
              fullWidth
            />
          </Box>
        </Stack>
      </AddStipWrap>
    </>
  );
};
