import { useTheme } from "@mui/material"
import { ActivitiesAndNotesSlidePopupWrap, SlideOutWrap } from "./activities-and-notes-slide-popup.style"
import { ActivitiesAndNotesSlidePopupProps } from "./activities-and-notes-slide-popup.props"
import { Grid } from "@ntpkunity/controls"
import { AuditTrail } from "../audit-trail-stack/audit-trail/audit-trail.component"
import { MyNotes } from "../my-notes-stack/my-notes/my-note.component"
import { Box, Button, Icon } from "@ntpkunity/controls"
import { useDispatch, useSelector } from "react-redux";
import { fetchAuditTrailRequest } from "../../../redux/actions/auditTrailActions"
import { useEffect, useState } from "react";
import { closeNotesPopup, fetchMyNotesRequest, openNotesPopup } from "@src/components/redux/actions/myNotesActions"
import { useParams } from "react-router-dom";

export const ActivitiesAndNotesSlidePopup = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.myNotes.isNotesPopupOpen);

  const { reference_number } = useParams();

  const handleClick = () => {
    if (!open) {
      dispatch(fetchAuditTrailRequest());
      dispatch(fetchMyNotesRequest(reference_number));
      dispatch(openNotesPopup());
    } else {
      dispatch(closeNotesPopup());
    }
  };

  return (
    <>
      <ActivitiesAndNotesSlidePopupWrap
        theme={theme}
        className={`activities-and-notes-slide-popup-wrap ${
          open ? "open" : ""
        }`}
      >
        <Box theme={theme} className="activities-and-notes">
          <Grid
            theme={theme}
            container
            rowSpacing={0}
            columnSpacing={2}
            sx={{ mt: 0 }}
          >
            <Grid item xs={4} theme={theme}>
              <AuditTrail />
            </Grid>
            <Grid item xs={8} theme={theme}>
              <MyNotes />
            </Grid>
          </Grid>
          <Button
            theme={theme}
            defaultBtn
            className={`btn-dark-anim ${!open ? "hide" : ""}`}
            iconText={
              <>
                <Icon name="AnimatedBtnDark" />
              </>
            }
            onClick={handleClick}
          />
        </Box>
        <Button
          theme={theme}
          defaultBtn
          className={`btn-light-anim ${open ? "show" : ""}`}
          iconText={<Icon name="AnimatedBtnLight" />}
          onClick={handleClick}
        />
      </ActivitiesAndNotesSlidePopupWrap>

      <SlideOutWrap
        theme={theme}
        className={`slide-out ${open ? "show" : ""}`}
      ></SlideOutWrap>
    </>
  );
};