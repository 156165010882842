import { legacy_createStore as createStore, applyMiddleware, compose  } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'; 
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers/rootReducer";
import rootSaga from "../sagas/rootSaga";
 
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["workQueue", "myNotes", "fundingRequests", "filteredStips", "financeStructure", "decision", "compliance", "businessRules", "chat"],
};
 
const persistedReducer = persistReducer(persistConfig, rootReducer);
 
const sagaMiddleware = createSagaMiddleware();

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

const persistor = persistStore(store);
 
sagaMiddleware.run(rootSaga);
 
export { store, persistor };