import { useTheme } from "@mui/material";
import { PageHeader, Stack } from "@src/components";
import { ButtonGroup } from "@src/components/button-group";
import { WorkqueueFilters } from "@src/components/work-queue";
import { LayoutWithSideNavComponent } from "@src/layouts";
import { PageContent } from "./work-queue-page.style";
import { WorkqueueTable } from "@src/components/work-queue/workqueue-table/workqueue-table.component";
import { useState } from "react";

export const WorkQueuePage = () => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState("Credit Requests");

  const handleButtonClick = (title) => {
    setSelectedTab(title);
  };

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <PageContent theme={theme} className="page-content">
        <Stack paddingXs={2} paddingMd={2}>
          <PageHeader
            title="Task Queues"
            actionArea={
              <>
                <ButtonGroup
                  items={[
                    {
                      title: "Credit Requests",
                      selected: selectedTab === "Credit Requests",
                      onClick: () => handleButtonClick("Credit Requests"),
                    },
                    {
                      title: "Funding Requests",
                      selected: selectedTab === "Funding Requests",
                      onClick: () => handleButtonClick("Funding Requests"),
                    },
                  ]}
                />
              </>
            }
          />
          <WorkqueueFilters />
          <WorkqueueTable selectedTab={selectedTab} />
        </Stack>
      </PageContent>
    </LayoutWithSideNavComponent>
  );
};