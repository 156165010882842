import {
  styled,
  TableCell,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";
import { Box, Button, DataTable, Icon, Skeleton } from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import WorkqueueTableRow from "./workqueue-table-row/workqueue-table-row.component";
import { WorkqueueFilter } from "./workqueue-filter/workqueue-filter.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkqueueRequest } from "@src/components/redux/actions/workQueueActions";
import { useEffect, useState } from "react";

const PaginationWrap = styled("div")(({ theme }) => ({
  "&.pagination-wrap": {
    ".MuiTablePagination-root": {
      marginTop: "-4px",
      paddingTop: "4px",
      borderColor: theme.palette.grey[100],
      color: theme.palette.grey[600],
      fontSize: theme.typography.htmlFontSize,
      fontFamily: theme.typography.fontFamily,
    },
    ".MuiTablePagination-select:focus": {
      backgroundColor: "transparent",
    },
    ".MuiTablePagination-selectIcon": {
      top: 2,
      left: 32,
    },
  },
}));

export const WorkqueueTable = ({ selectedTab }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [sortConfig, setSortConfig] = useState({
    key: "updated_at",
    order: "desc",
  });
  const [sortedData, setSortedData] = useState<any>([]);

  const updateFilter = (columnKey, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnKey]: value ? String(value).toLowerCase() : "",
    }));
  };

  const {
    workQueues = [],
    loading,
    error,
    pagination = { pageNumber: 0, pageSize: 10 },
  } = useSelector((state: any) => state.workQueue);

  const refetchData = () => {
    dispatch(fetchWorkqueueRequest(pagination.pageNumber, pagination.pageSize));
  };

  useEffect(() => {
    refetchData();
  }, [dispatch, pagination.pageNumber, pagination.pageSize, selectedTab]);

  const columns = [
    { key: "status", label: "Status" },
    { key: "reference_number", label: "Application Number" },
    { key: "dealership_name", label: "Dealership Name" },
    { key: "amount_financed", label: "Amount Financed" },
    { key: "applicant_name", label: "Applicant Name" },
    { key: "state", label: "State" },
    { key: "business", label: "Business" },
    { key: "assigned_to_username", label: "Assignee" },
    { key: "created_at", label: "Request Time" },
    { key: "updated_at", label: "Last Updated" },
  ];

  let filteredWorkQueues = workQueues.filter((row) =>
    Object.entries(filters).every(([key, value]) => {
      if (!value) return true;

      if (["created_at", "updated_at"].includes(key)) {
        const formattedDate = new Date(row[key]).toISOString().split("T")[0];
        return formattedDate.includes(value);
      }

      return row[key]?.toString().toLowerCase().includes(value);
    })
  );

  useEffect(() => {
    const sorted = [...filteredWorkQueues].sort((a, b) => {
      const { key, order } = sortConfig;
      const aValue = a[key] ?? "";
      const bValue = b[key] ?? "";

      if (typeof aValue === "number" && typeof bValue === "number") {
        return order === "asc" ? aValue - bValue : bValue - aValue;
      }

      if (key === "updated_at" || key === "created_at") {
        return order === "asc"
          ? new Date(aValue).getTime() - new Date(bValue).getTime()
          : new Date(bValue).getTime() - new Date(aValue).getTime();
      }

      return order === "asc"
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    setSortedData(sorted);
  }, [filteredWorkQueues, sortConfig]);

  const applySorting = (columnKey) => {
    let newOrder = "asc";
    if (sortConfig.key === columnKey && sortConfig.order === "asc") {
      newOrder = "desc";
    }
    setSortConfig({ key: columnKey, order: newOrder });
  };

  const handlePageChange = (_event, newPage) => {
    dispatch(fetchWorkqueueRequest(newPage + 1, pagination.pageSize));
  };

  const handlePageSizeChange = (event) => {
    dispatch(fetchWorkqueueRequest(1, parseInt(event.target.value, 10)));
  };

  return (
    <DataTableWrap theme={theme} className="data-table-wrap">
      <DataTable
        theme={theme}
        hasPagination={
          <PaginationWrap theme={theme} className="pagination-wrap">
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={pagination.totalRecords}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNumber - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePageSizeChange}
            />
          </PaginationWrap>
        }
        theadChildren={
          <>
            <TableRow>
              {columns.map(({ key, label }) => (
                <TableCell key={key} className="text-body1 fw-medium">
                  <Box theme={theme} className="table-order">
                    {label}
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={
                        sortConfig.key === key && sortConfig.order === "asc" ? (
                          <Icon name="AscendingIcon" />
                        ) : sortConfig.key === key &&
                          sortConfig.order === "desc" ? (
                          <Icon name="DescendingIcon" />
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => applySorting(key)}
                    />
                  </Box>
                </TableCell>
              ))}
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              {columns.map(({ key }) => (
                <TableCell key={key}>
                  <WorkqueueFilter
                    columnKey={key}
                    updateFilter={updateFilter}
                    value={filters[key] || ""}
                    applySorting={() => applySorting(key)}
                  />
                </TableCell>
              ))}
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          loading ? (
            <>
              {Array.from({ length: 12 }).map((_, index) => (
                <TableRow key={index}>
                  {columns.map((_, idx) => (
                    <TableCell key={idx}>
                      <Skeleton theme={theme} width={"90%"} height={"40px"} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ) : error ? (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>Error: {error}</TableCell>
            </TableRow>
          ) : sortedData.length > 0 ? (
            sortedData.map((row, index) => (
              <WorkqueueTableRow
                key={index}
                row={row}
                refetchData={refetchData}
              />
            ))
          ) : null
        }
      />
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={pagination.totalRecords}
        rowsPerPage={pagination.pageSize}
        page={pagination.pageNumber - 1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePageSizeChange}
      /> */}
    </DataTableWrap>
  );
};

export default WorkqueueTable;
