import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const ApplicantDetailDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.applicant-detail-dialog-wrap": {
    padding: '0 8px',
    height: 'calc(100vh - 200px)',
    "@media(min-width: 1800.95px)": {
      height: 'calc(100vh - 340px)',
    },
    ".stack-wrap": {
      ".basic-list-wrap": {
        ".list": {
          padding: '0 8px'
        }
      }
    }
  },
}));
