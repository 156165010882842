import {
    styled,
    TableCell,
    TablePagination,
    TableRow,
    useTheme,
  } from "@mui/material";
  import { Box, Button, DataTable, Icon, Skeleton } from "@ntpkunity/controls";
  import { DataTableWrap } from "@styles/data-table-wrap";
  import dragIcon from "@assets/icons/dragIcon.svg"
  
  
  export const BusinessRulesTable = () => {
    const theme = useTheme();
  
    return (
      <DataTableWrap theme={theme} className="data-table-wrap table-border">
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow>
                <TableCell className="action-cell fixed-cell w-42 bg-white"></TableCell>
                <TableCell className="w-376 text-body2 fw-sbold bg-white">Business Rule Name</TableCell>
                <TableCell className="w-376 text-body2 fw-sbold bg-white">Work Flow Activity</TableCell>
                <TableCell className="text-body2 fw-sbold bg-white">Description</TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
                {Array.from({ length: 8 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell className="action-cell fixed-cell w-42">
                        <Button theme={theme} defaultBtn iconText={<><img src={dragIcon} /></>} />
                    </TableCell>
                    <TableCell className="w-376">Sample Rule Name</TableCell>
                    <TableCell className="w-376">Sample Work Flow Activity</TableCell>
                    <TableCell>(IF) Credit Score ≥ 700, (AND) Income ≥ $50,000, (OR) Existing Debt ≤ $10,000</TableCell>
                  </TableRow>
                ))}
              </>
          }
        />
      </DataTableWrap>
    );
  };
  
  export default BusinessRulesTable;
  