import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
    FETCH_AUDIT_TRAIL_REQUEST,
    fetchAuditTrailSuccess,
    fetchAuditTrailFailure,
} from "../actions/auditTrailActions";

function* fetchAuditTrailSaga() {
    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const apiKey = process.env.AUDIT_TRAIL_API_KEY;

        const response = yield call(axios.get, `${apiUrl}/cde/comments`, {
            headers: { "x-api-key": apiKey },
        });

        if (response?.data?.result) {
            yield put(fetchAuditTrailSuccess(response.data.result));
        } else { 
            throw new Error("Empty response from API");
        }
    } catch (error) {
        console.error("API Call Failed:", error);

        yield put(fetchAuditTrailFailure(error.response?.data?.message || error.message));
    }
}

export function* watchAuditTrailSaga() {
    // yield takeLatest(FETCH_AUDIT_TRAIL_REQUEST, fetchAuditTrailSaga);
}
