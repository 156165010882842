import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const BusinessRulesAlertsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.business-rules-alerts-wrap": {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '&.scrollable-container': {
      maxHeight: 'calc(100vh - 568px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      "@media only screen and (min-width: 1599.95px)": {
        maxHeight: 'calc(100vh - 684px)',
      }
    },
  },
}));
