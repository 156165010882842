import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
    FETCH_FINANCE_STRUCTURE_REQUEST,
    fetchFinanceStructureSuccess,
    fetchFinanceStructureFailure,
} from "../actions/financeStructureActions";

function* fetchFinanceStructureSaga(action) {
    const financeStructureRefId = action.payload;
    try {
        const response = yield call(
            axios.get,
            `${process.env.REACT_APP_API_URL}/dms/order/order_information/${financeStructureRefId}`,
            { headers: { "x-api-key": process.env.REACT_APP_API_KEY } }
        );
        yield put(fetchFinanceStructureSuccess(response.data));
    } catch (error) {
        console.error("Saga: Fetch error:", error.message);
        yield put(fetchFinanceStructureFailure(error.message));
    }
}

export function* watchFinanceStructureSaga() {
    yield takeLatest(FETCH_FINANCE_STRUCTURE_REQUEST, fetchFinanceStructureSaga);
}
