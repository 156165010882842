import { useTheme } from "@mui/material";
import { Box, Typography, Menu, Button, Icon } from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { AddedStipulationsWrap } from './added-stipulation.style'

export const AddedStipulation = () => {
    const theme = useTheme();

    return (
        <>
            <AddedStipulationsWrap theme={theme} className="added-stipulations">
                <Box theme={theme} display={'flex'} gap={0.5}>
                    <Typography theme={theme} variant="caption" component={'small'} className="fw-medium">1.</Typography>
                    <Typography theme={theme} variant="caption" component={'small'} className="fw-medium">Add Co-Borrower</Typography>
                </Box>
                <Box theme={theme}>
                    <Menu
                        theme={theme}
                        options={[
                            { optionText: "Edit", optionkey: "edit" },
                            { optionText: "Delete", optionkey: "delete" },
                        ]}
                        handleOptionClick={(event, key) => { }}
                        render={(cb) => (
                            <Button
                                defaultBtn
                                theme={theme}
                                iconText={<Icon name="MoreIcon" />}
                                onClick={cb}
                            ></Button>
                        )}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    />
                </Box>
            </AddedStipulationsWrap>
        </>
    );
};