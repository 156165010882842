export const FETCH_FUNDING_REQUEST = "FETCH_FUNDING_REQUEST";
export const FETCH_FUNDING_SUCCESS = "FETCH_FUNDING_SUCCESS";
export const FETCH_FUNDING_FAILURE = "FETCH_FUNDING_FAILURE";

export const RETRIEVE_FUNDING_REQUEST = "RETRIEVE_FUNDING_REQUEST";
export const RETRIEVE_FUNDING_SUCCESS = "RETRIEVE_FUNDING_SUCCESS";
export const RETRIEVE_FUNDING_FAILURE = "RETRIEVE_FUNDING_FAILURE";

export const fetchFundingRequest = (
  pageNumber = 1,
  pageSize = 50,
  status = null
) => ({
  type: FETCH_FUNDING_REQUEST,
  payload: { pageNumber, pageSize, status },
});

export const fetchFundingSuccess = (data) => ({
  type: FETCH_FUNDING_SUCCESS,
  payload: data,
});

export const fetchFundingFailure = (error) => ({
  type: FETCH_FUNDING_FAILURE,
  payload: error,
});

export const retrieveFundingRequest = (applicantReferenceNum) => ({
  type: RETRIEVE_FUNDING_REQUEST,
  payload: applicantReferenceNum,
});

export const retrieveFundingSuccess = (data) => ({
  type: RETRIEVE_FUNDING_SUCCESS,
  payload: data,
});

export const retrieveFundingFailure = (error) => ({
  type: RETRIEVE_FUNDING_FAILURE,
  payload: error,
});
