import { transform } from "@babel/core";
import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";
import tooltipBottomIcon from "../../../../assets/icons/tooltip_bottom_arrow.svg"

export const BusinessRulesAlertsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.business-rules-alerts-wrap": {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '&.scrollable-container': {
      maxHeight: 'calc(100vh - 568px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      "@media only screen and (min-width: 1599.95px)": {
        maxHeight: 'calc(100vh - 684px)',
      }
    },
  },
}));

export const CustomizedTooltipWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.customized-tooltip-wrap": {
    ".styled-tooltip": {
      ".MuiTooltip-popper": {
        ".MuiTooltip-tooltip": {
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.16)',
          padding: '8px 12px',
          width: '289px',
          "@media(min-width: 1599.95px)": {
            width: '372px',
            maxWidth: '372px'
          },
          ".MuiTooltip-arrow": {
            display: 'block',
            transform: 'translate(8px, 0px) !important',
            backgroundImage: `url(${tooltipBottomIcon})`,
          }
        }
      }
    },
    ".disabled-link": {
      opacity: 0.2,
      cursor: "not-allowed"
    }
  },
}));
