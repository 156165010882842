import axios from "axios";

const base_url = process.env.REACT_APP_API_URL;
const activityCache = {};

export const fetchActivityWorkflowData = async (workflowInstanceId, activityNames) => {
    try {
        const requests = activityNames.map((activityName) => {
            const cacheKey = `${workflowInstanceId}_${activityName}`;

            if (activityCache[cacheKey]) {
                return Promise.resolve(activityCache[cacheKey]);
            }

            return axios
                .get(`${base_url}/cde/get_activity_workflow_data`, {
                    params: {
                        workflowInstanceId,
                        activity_name: activityName,
                    },
                })
                .then((response) => {
                    activityCache[cacheKey] = response.data;
                    return response.data;
                });
        });

        const responses = await Promise.all(requests);
        const data = {};

        activityNames.forEach((name, index) => {
            data[name] = responses[index] || null;
        });

        return data;
    } catch (err) {
        console.error("Error fetching workflow data:", err);
        throw err;
    }
};
