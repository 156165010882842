import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material";
import {
  Box,
  Dialog,
  Icon,
  Typography,
  Checkbox,
  Input,
  Button,
} from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { FinanceStructureModifyDialogWrap } from "./finance-structure-modify-popup-dialog.style";
import { Stack } from "@src/components/stack";
import { useSelector } from "react-redux";
import { AddStickyNote } from "../../sticky-notes-stack/add-sticky-note/add-sticky-note.component";

export const FinanceStructureModifyDialog = ({ open, closeDialog }) => {
  const theme = useTheme();
  const { applicantDetails, aiGeneratedData } = useSelector(
    (state: any) => state?.applicantDetails
  );

  const [showAIRecommendedColumn, setShowAIRecommendedColumn] = useState(false);
  const [aiRecommendedValues, setAIRecommendedValues] = useState({});
  const [selectedCheckbox, setSelectedCheckbox] = useState("Requested");

  // const dealerFeesTotal =
  //   applicantDetails?.order_fees?.reduce(
  //     (acc: number, fee: any) => acc + (fee.applied_price || 0),
  //     0
  //   ) || 0;

  const formatNumberWithCommas = (number) => {
    if (typeof number === "number") {
      return new Intl.NumberFormat("en-US").format(number);
    }
    return number;
  };

  const formatNumber = (value: any) => {
    if (value === null || value === undefined || value === "") {
      return (
        <Box theme={theme} className="text-right">
          N/A
        </Box>
      );
    }
    if (!isNaN(value) && value !== "" && value !== null) {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(value));
    }
    return "N/A";
  };

  const financeType = applicantDetails?.finance_type || "N/A";
  const financedAmount = applicantDetails?.net_finance_amount || "N/A";
  const periodicPayment = applicantDetails?.estimated_monthly_payment || "N/A";
  const assetCost = applicantDetails?.selling_price || "N/A";
  const downPayment = applicantDetails?.down_payment_value || "N/A";
  const taxOnAssetCost = applicantDetails?.tax_amount || "N/A";
  const tradeInTotal =
    applicantDetails?.order_tradein?.trade_in_amount || "N/A";
  const rebate = applicantDetails?.rebate || "N/A";
  const discount = applicantDetails?.estimated_monthly_payment || "N/A";
  const interestRate = applicantDetails?.apr || "N/A";
  const dealerFeesTotal =
    applicantDetails?.order_fees?.reduce(
      (acc, fee) => acc + (fee.applied_price || 0),
      0
    ) || "N/A";

  const insuranceTotal =
    applicantDetails?.order_fnI?.reduce(
      (acc: number, item: any) => acc + (item.applied_price || 0),
      0
    ) || "N/A";

  const gapTotal =
    applicantDetails?.order_options?.reduce(
      (acc, option) => acc + (option.applied_price || 0.0),
      0
    ) || "N/A";
  const terms =
    applicantDetails?.order_submissions?.[0]?.decision?.terms ?? "N/A";
  const frequency = applicantDetails?.rental_frequency || "N/A";
  const charges = applicantDetails?.charges || "N/A";

  const fields = [
    {
      label: "Finance Type",
      key: "",
      value: financeType,
      textLeft: true,
    },
    {
      label: "Financed Amount",
      key: "",
      value: formatNumberWithCommas(financedAmount),
      startAdornment: "$",
    },
    {
      label: "Periodic Payment",
      key: "",
      value: formatNumberWithCommas(periodicPayment),
      startAdornment: "$",
    },
    { label: "Terms", key: "terms", value: terms },
    {
      label: "Frequency",
      key: "rental_frequency",
      value: frequency,
      textLeft: true,
    },
    {
      label: "Asset Cost",
      key: "",
      value: formatNumberWithCommas(assetCost),
      startAdornment: "$",
    },
    {
      label: "Down Payment",
      key: "down_payment_value",
      value: formatNumberWithCommas(downPayment),
      startAdornment: "$",
    },
    {
      label: "Tax on Asset Cost",
      key: "",
      value: formatNumberWithCommas(taxOnAssetCost),
      startAdornment: "$",
    },
    {
      label: "Net Trade-in",
      key: "trade_in_value",
      value: formatNumberWithCommas(tradeInTotal),
      startAdornment: "$",
    },
    {
      label: "Charges",
      key: "charges",
      // value: formatNumberWithCommas(charges),
      startAdornment: "$",
    },
    {
      label: "Rebate",
      key: "",
      value: formatNumberWithCommas(rebate),
      startAdornment: "$",
    },
    {
      label: "Discount",
      key: "",
      value: formatNumberWithCommas(discount),
      startAdornment: "$",
    },
    {
      label: "Add-ons",
      key: "",
      value: formatNumberWithCommas(gapTotal),
      startAdornment: "$",
    },
    {
      label: "Insurance",
      key: "",
      value: formatNumberWithCommas(insuranceTotal),
      startAdornment: "$",
    },
    {
      label: "Interest Rate",
      key: "interest_rate",
      value: formatNumberWithCommas(interestRate),
      startAdornment: "%",
    },
    {
      label: "Dealer Fees",
      key: "",
      value: formatNumberWithCommas(dealerFeesTotal),
      startAdornment: "$",
    },
  ];

  const editableFields = new Set([
    "down_payment_value",
    "trade_in_value",
    "interest_rate",
    "terms",
    "rental_frequency",
    "charges",
  ]);

  // Prefill AI recommended values from Redux
  const handleAddStickyNote = () => {
    setShowAIRecommendedColumn(true);
    setAIRecommendedValues(aiGeneratedData || {});
  };

  const handleCheckboxChange = (checkboxName) => {
    setSelectedCheckbox(checkboxName);
  };

  return (
    <Popup>
      <Dialog
        theme={theme}
        size={"md"}
        onCloseDialog={closeDialog}
        open={open}
        title={"Add/Modify Structure"}
        disablePortal
        noFooter
      >
        <FinanceStructureModifyDialogWrap
          theme={theme}
          className="finance-structure-modify-popup-wrap"
        >
          <Stack
            bgLight
            paddingMd={1.5}
            paddingXs={1.5}
            title={"Finance Structure"}
            actionArea={
              <Box theme={theme} display={"flex"} alignItems={"center"}>
                <Icon name="AddIcon" className="text-primary" />
                <a href="#" className="text-primary">
                Add/Modify
                </a>
              </Box>
            }
          ></Stack>
          <Stack paddingMd={2} paddingXs={2}>
            <Box theme={theme} className="header">
              <Box theme={theme} className="empty"></Box>
              <Box theme={theme} className="header-check" mb={1}>
                <Checkbox
                  theme={theme}
                  label={"Requested"}
                  checkBoxChecked={selectedCheckbox === "Requested"}
                  onChange={() => handleCheckboxChange("Requested")}
                />
                <Checkbox
                  theme={theme}
                  label={"Underwriter"}
                  checkBoxChecked={selectedCheckbox === "Underwriter"}
                  onChange={() => handleCheckboxChange("Underwriter")}
                />
                {showAIRecommendedColumn && (
                  <Checkbox
                    theme={theme}
                    label={"AI Recommended"}
                    checkBoxChecked={selectedCheckbox === "AI Recommended"}
                    onChange={() => handleCheckboxChange("AI Recommended")}
                  />
                )}
              </Box>
            </Box>

            <Box
              theme={theme}
              className="finance-structure-popup-content scrollable-container"
            >
              <Box theme={theme} className="left-side">
                {fields.map((field, index) => (
                  <Box theme={theme} className="list" key={index}>
                    <Typography
                      theme={theme}
                      variant="body2"
                      component={"span"}
                      className="left-text text-body2 fw-medium"
                    >
                      {field.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box theme={theme} className="right-side">
                {/* Requested Column (Disabled Fields) */}
                <Box theme={theme} className="right-side">
                  <Box theme={theme} className="col-input">
                    {fields.map((field, index) => (
                      <Input
                        key={index}
                        theme={theme}
                        type="text"
                        value={
                          field.value !== undefined
                            ? formatNumberWithCommas(field.value)
                            : "0.00"
                        }
                        startAdornment={field.startAdornment || ""}
                        inputClass={field.textLeft ? "text-left" : "text-right"}
                        disabled
                      />
                    ))}
                  </Box>
                </Box>

                {/* Underwriter Column (Disabled Fields) */}
                <Box theme={theme} className="col-input">
                  {fields.map((field, index) => {
                    const isEditable = editableFields.has(field.key);
                    return (
                      <Input
                        key={index}
                        theme={theme}
                        type="text"
                        value={field.value !== undefined ? field.value : "0.00"}
                        startAdornment={field.startAdornment || ""}
                        onChange={(e) =>
                          isEditable &&
                          setAIRecommendedValues((prev) => ({
                            ...prev,
                            [field.key]: e.target.value,
                          }))
                        }
                        inputClass={field.textLeft ? "text-left" : "text-right"}
                        disabled={!isEditable}
                      />
                    );
                  })}
                </Box>

                {/* AI Recommended Column (Editable Fields) */}
                {showAIRecommendedColumn && (
                  <Box theme={theme} className="col-input">
                    {fields.map((field, index) => {
                      const isEditable = editableFields.has(field.key);
                      return (
                        <Input
                          key={index}
                          theme={theme}
                          type="text"
                          value={
                            field.value !== undefined ? field.value : "0.00"
                          }
                          startAdornment={field.startAdornment || ""}
                          onChange={(e) =>
                            isEditable &&
                            setAIRecommendedValues((prev) => ({
                              ...prev,
                              [field.key]: e.target.value,
                            }))
                          }
                          inputClass={
                            field.textLeft ? "text-left" : "text-right"
                          }
                          disabled={!isEditable}
                        />
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>
            {!showAIRecommendedColumn && (
              <Box theme={theme} className="sticky-note-container">
                <AddStickyNote
                  title="Add Structure"
                  handleAddStickyNote={handleAddStickyNote}
                />
              </Box>
            )}
          </Stack>

          <Box theme={theme} textAlign={"center"} pt={2}>
            <Button theme={theme} primary text={"Add Finance Structure"} />
          </Box>
        </FinanceStructureModifyDialogWrap>
      </Dialog>
    </Popup>
  );
};

export default FinanceStructureModifyDialog;