import { useTheme } from "@mui/material";
import { Box, Button, Icon, Input } from "@ntpkunity/controls";
import { useState } from "react";
// import { useWorkQueueContext } from "@src/context/WorkQueueContext";

export const WorkqueueFilter = ({
  columnKey,
  updateFilter,
  value,
  applySorting,
}) => {
  const theme = useTheme();
  const [sortOrder, setSortOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState(value);

  // const handleChange = (event: React.ChangeEvent) => {
  //   if (event !== undefined) {
  //     // updateFilter(columnKey, event);
  //   } else {
  //     console.error("Input event structure is invalid:", event);
  //   }
  // };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    updateFilter(columnKey, event);
  };

  const handleSort = () => {
    let newSortOrder;
    if (sortOrder === null) {
      newSortOrder = "asc";
    } else if (sortOrder === "asc") {
      newSortOrder = "desc";
    } else {
      newSortOrder = null;
    }

    setSortOrder(newSortOrder);
    applySorting(columnKey, newSortOrder);
  };

  return (
    <>
      <Box theme={theme} className="table-filter">
        <Input
          theme={theme}
          fullWidth={true}
          type={"text"}
          startAdornment=""
          endAdornment=""
          id=""
          placeholder={"Search..."}
          onChange={(event) => handleChange(event)}
          // value={value}
          value={searchTerm}
        />
      </Box>
    </>
  );
};
