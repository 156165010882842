import {
  styled,
  TableCell,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";
import { Box, Button, DataTable, Icon, Skeleton } from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import WorkqueueTableRow from "./workqueue-table-row/workqueue-table-row.component";
import { WorkqueueFilter } from "./workqueue-filter/workqueue-filter.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkqueueRequest, updatePagination } from "@src/components/redux/actions/workQueueActions";
import { useCallback, useEffect, useState } from "react";
import { fetchFundingRequest } from "@src/components/redux/actions/fundingActions";
import { AssignToMemberDialog } from "../dialog/assign-member-dialog/assign-member-dialog.component"

const PaginationWrap = styled("div")(({ theme }) => ({
  "&.pagination-wrap": {
    ".MuiTablePagination-root": {
      marginTop: "-4px",
      paddingTop: "4px",
      borderColor: theme.palette.grey[100],
      color: theme.palette.grey[600],
      fontSize: theme.typography.htmlFontSize,
      fontFamily: theme.typography.fontFamily,
    },
    ".MuiTablePagination-select:focus": {
      backgroundColor: "transparent",
    },
    ".MuiTablePagination-selectIcon": {
      top: 2,
      left: 32,
    },
  },
}));

export const WorkqueueTable = ({ selectedTab }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [sortConfig, setSortConfig] = useState({
    key: "",
    order: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const handleAssignToTeamMember = (row) => {
    if (!row) return;
    setSelectedRow(row);
    setDialogOpen(true);
  };


  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };

  const updateFilter = useCallback((columnKey, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnKey]: value ? String(value).toLowerCase() : "",
    }));
  }, []);

  const {
    workQueues = [],
    loading,
    error,
    pagination = { pageNumber: 0, pageSize: 10 },
  } = useSelector((state: any) => state.workQueue);

  const [sortedData, setSortedData] = useState<any>([...workQueues]);

  const {
    fundingRequests = [],
    loading: fundLoading,
    error: fundError,
    pagination: fundPagination = { pageNumber: 0, pageSize: 10 },
  } = useSelector((state: any) => state.fundingRequests);

  const refetchData = () => {
    dispatch(fetchWorkqueueRequest(pagination.pageNumber || 1, pagination.pageSize || 10, filters, selectedTab));
  };

  useEffect(() => {
    refetchData();
  }, [pagination.pageNumber, pagination.pageSize, selectedTab, filters]);

  const columns = [
    { key: "status", label: "Status" },
    { key: "reference_number", label: "Application Number" },
    { key: "dealership_name", label: "Dealership Name" },
    { key: "amount_financed", label: "Amount Financed" },
    { key: "applicant_name", label: "Applicant Name" },
    // { key: "state", label: "State" },
    { key: "application_type", label: "Applicant Type" },
    { key: "assigned_to_username", label: "Assignee" },
    { key: "created_at", label: "Request Time" },
    { key: "updated_at", label: "Last Updated" },
  ];

  const applySorting = (columnKey) => {
    let newOrder = "asc";
    if (sortConfig.key === columnKey && sortConfig.order === "asc") {
      newOrder = "desc";
    }
    setSortConfig({ key: columnKey, order: newOrder });
  };

  useEffect(() => {
    let dataToSort = [...workQueues];

    if (filters && Object.keys(filters).length > 0) {
      dataToSort = dataToSort.filter((row) =>
        Object.entries(filters).every(([key, value]) => {
          if (!value) return true;

          if (["created_at", "updated_at"].includes(key)) {
            const formattedDate = new Date(row[key]).toISOString().split("T")[0];
            return formattedDate.includes(value);
          }

          return row[key]?.toString().toLowerCase().includes(value);
        })
      );
    }
    if (sortConfig.key) {
      dataToSort.sort((a, b) => {
        const aValue = a[sortConfig.key] ?? "";
        const bValue = b[sortConfig.key] ?? "";

        if (["created_at", "updated_at"].includes(sortConfig.key)) {
          return sortConfig.order === "asc"
            ? new Date(aValue).getTime() - new Date(bValue).getTime()
            : new Date(bValue).getTime() - new Date(aValue).getTime();
        }

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.order === "asc" ? aValue - bValue : bValue - aValue;
        }

        return sortConfig.order === "asc"
          ? aValue.toString().localeCompare(bValue.toString())
          : bValue.toString().localeCompare(aValue.toString());
      });
    }

    setSortedData(dataToSort);
  }, [workQueues, fundingRequests, filters, sortConfig, selectedTab]);



  const handlePageChange = (_event, newPage) => {

    if (selectedTab === "Credit Requests") {
      dispatch(fetchWorkqueueRequest(newPage + 1, pagination.pageSize, filters, selectedTab));
    } else if (selectedTab === "Funding Requests") {
      dispatch(fetchFundingRequest(newPage + 1, fundPagination.pageSize));
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);

    dispatch(updatePagination(1, newSize));

    if (selectedTab === "Credit Requests") {
      dispatch(fetchWorkqueueRequest(1, newSize, filters, selectedTab));
    } else if (selectedTab === "Funding Requests") {
      dispatch(fetchFundingRequest(1, newSize));
    }
  };

  const currentPagination =
    selectedTab === "Credit Requests" ? pagination : fundPagination;

  if (!currentPagination) {
    return <div>Loading...</div>;
  }

  return (
    <DataTableWrap theme={theme} className="data-table-wrap">
      <DataTable
        theme={theme}
        hasPagination={
          <PaginationWrap theme={theme} className="pagination-wrap">
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={currentPagination.totalRecords}
              rowsPerPage={currentPagination.pageSize}
              page={currentPagination.pageNumber - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePageSizeChange}
            />
          </PaginationWrap>
        }
        theadChildren={
          <>
            <TableRow>
              <TableCell className="action-cell fixed-cell"></TableCell>
              {columns?.map(({ key, label }) => (
                <TableCell key={key} className="text-body1 fw-medium">
                  <Box theme={theme} className="table-order">
                    {label}
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={
                        sortConfig?.key === key && sortConfig.order === "asc" ? (
                          <Icon name="AscendingIcon" />
                        ) : sortConfig?.key === key &&
                          sortConfig?.order === "desc" ? (
                          <Icon name="DescendingIcon" />
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => applySorting(key)}
                    />
                  </Box>
                </TableCell>
              ))}
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell className="action-cell fixed-cell"></TableCell>
              {columns.map(({ key }) => (
                <TableCell key={key}>
                  <WorkqueueFilter
                    columnKey={key}
                    updateFilter={updateFilter}
                    value={filters[key] || ""}
                    // applySorting={() => applySorting(key)}
                  />
                </TableCell>
              ))}
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          loading || fundLoading ? (
            <>
              {Array.from({ length: 12 }).map((_, index) => (
                <TableRow key={index}>
                  {columns.map((_, idx) => (
                    <TableCell key={idx}>
                      <Skeleton theme={theme} width={"90%"} height={"40px"} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ) : error || fundError ? (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>Error: {error}</TableCell>
            </TableRow>
          ) : sortedData.length > 0 ? (
            sortedData.map((row, index) => (
              <WorkqueueTableRow
                key={index}
                row={row}
                refetchData={refetchData}
                selectedTab={selectedTab}
                onAssignToTeamMember={handleAssignToTeamMember}
              />
            ))
          ) : null
        }
      />
      {dialogOpen && (
        <AssignToMemberDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
        />
      )}


    </DataTableWrap>
  );
};

export default WorkqueueTable;
