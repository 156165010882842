import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const BusinessRulesTableStackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.basic-stack-wrap": {
    ".stack-wrap": {
        ".stack-header": {
            ".title": {
                padding: '8px 0'
            }
        }
    }
  },
}));
