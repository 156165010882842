import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const VersionResponseStackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.version-response-stack-wrap": {
    minWidth: 284,
    maxWidth: '100%',
    ".stack-wrap": {
        padding: '12px 16px',
        svg: {
            width: 20,
            height: 20
        },
        ".label-wrap": {
          ".label-bg": {
            ".MuiTypography-root": {
              fontSize: theme.typography.caption.fontSize,
              fontWeight: theme.customVariables.fontWeightSemiBold
            }
          }
        }
    }
  },
}));
