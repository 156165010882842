import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import { Box, Button, CircleLoader, Icon } from "@ntpkunity/controls";
import { Alert } from "@src/components";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { AssetDetailDialog } from "../asset-detail-dialog/asset-detail-dialog.component";
import { Stack } from "@src/components/stack";
import axios from "axios";

export const AssetDetailsStack = () => {

  const theme = useTheme();
  const base_url = process.env.REACT_APP_API_URL
  const xApiKey = process.env.API_KEY

  const [isOpen, setIsOpen] = useState(false);
  const [assetTypes, setAssetTypes] = useState<any>([]);
  const [assetSubTypes, setAssetSubTypes] = useState<any>([]);
  const [assetCategories, setAssetCategories] = useState<any>([]);

  const { applicantDetails, loading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  const companyInfo = applicantDetails?.customer_info || {};
  const companyAddress = companyInfo?.customer_addresses?.[0] || {};
  const assetCategory = applicantDetails?.order_asset?.category
  const assetCode = applicantDetails?.order_asset?.asset_type;
  const assetSubType = applicantDetails?.order_asset?.asset_sub_type;

  const assetCondition = applicantDetails?.Vehicle_details?.type;
  const orderAsset = applicantDetails?.order_asset;

  useEffect(() => {
    const fetchAssetConfigs = async () => {
      try {
        const headers = {
          accept: "application/json",
          "x-api-key": xApiKey,
          company_id: 17274,
        };

        const [typeRes, subTypeRes, categoryRes] = await Promise.all([
          axios.get(`${base_url}/config/asset_type`, { headers }),
          axios.get(`${base_url}/config/asset_sub_type`, { headers }),
          axios.get(`${base_url}/config/asset_category`, { headers }),
        ]);

        setAssetTypes(typeRes.data || []);
        setAssetSubTypes(subTypeRes.data || []);
        setAssetCategories(categoryRes.data || []);

      } catch (err) {
        console.error("Failed to fetch asset config data", err);
      }
    };

    if (xApiKey && 17274) fetchAssetConfigs();
  }, [xApiKey]);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const fields = [
    {
      title: "Asset Category:",
      key: () => {
        const match = assetCategories.find(item => item.code === assetCategory);
        return match?.description || applicantDetails?.order_asset?.category || null;
      },
    },
    {
      title: "Asset Type:",
      key: () => {
        const match = assetTypes.find(item => item.code === assetCode);
        return match?.name || applicantDetails?.order_asset?.asset_type || null;
      },
    },
    {
      title: "Asset Sub-Type:",
      key: () => {
        const match = assetSubTypes.find(item => item.code === assetSubType);
        return match?.name || applicantDetails?.order_asset?.asset_sub_type || null;
      },
    },
    {
      title: "Asset Description:",
      key: () => orderAsset?.description || null,
    },
    { title: "Make:", key: "make" },
    { title: "Model:", key: "model" },
    { title: "Year:", key: "year" },
    { title: "Trim Type:", key: "trim_description" },
    { title: "Condition:", key: "type" },
  ];


  const data = fields.filter((field) => {
    const value =
      typeof field.key === "function"
        ? field.key()
        : applicantDetails?.Vehicle_details?.[field.key];
    return value !== null && value !== undefined && value !== "";
  });

  return (
    <>
      <Stack
        title={"Asset Details"}
        paddingXs={2}
        paddingMd={2}
        actionArea={
          <Button
            theme={theme}
            defaultBtn
            iconText={<Icon name="ArrowLinkIcon" />}
            onClick={handleOpenDialog}
          />
        }
      >
        {loading ? (
          <Box theme={theme} mt={2}>
            <CircleLoader theme={theme} size={"sm"} />
          </Box>
        ) : error ? (
          <Box theme={theme} mt={2}>
            {error}
          </Box>
        ) : applicantDetails ? (
          <>
            <Box theme={theme} mt={2}>
                  {assetCondition !== "New" && (
                    <Alert
                      text={"Not Verified:"}
                      error
                      fullWidth
                    />
                  )}
            </Box>
            <BasicList
                  options={data.map((d) => ({
                    title: d.title,
                    subtitle:
                      typeof d.key === "function"
                        ? d.key()
                        : applicantDetails?.Vehicle_details?.[d.key],
                  }))}
                />
          </>
        ) : (
          <Box theme={theme} mt={2}>
            No data available
          </Box>
        )}
      </Stack>
      <AssetDetailDialog
        open={isOpen}
        closeDialog={handleCloseDialog}
        assetCondition={assetCondition}
      />
    </>
  );
};
