const initialState = {
    isNotesPopupOpen: false,
  myNotesData: [],
  stickyNotes: [],
  loading: false,
  error: null,
};

const myNotesReducer = (state = initialState, action) => {
  switch (action.type) {
      case "FETCH_MY_NOTES_REQUEST":
          return { ...state, loading: true, error: null };

      case "FETCH_MY_NOTES_SUCCESS":
          return { ...state, loading: false, myNotesData: action.payload };

      case "FETCH_MY_NOTES_FAILURE":
          return { ...state, loading: false, error: action.payload };

      case "CREATE_NOTE_REQUEST":
          return { ...state, loading: true };

      case "CREATE_NOTE_SUCCESS":
          return {
              ...state,
              loading: false,   
              myNotesData: [...state.myNotesData, action.payload], 
          };

          case "UPDATE_NOTE_TO_STICKY":
            const updatedNotes = state.myNotesData.map((note: any) =>
                note.id === action.payload.noteId
                    ? { ...note, comment: note.comment.includes("#isSticky=true") ? note.comment : `${note.comment} #isSticky=true` }
                    : note
            );
        
            const updatedStickyNote = updatedNotes.find((note) => note.id === action.payload.noteId);
        
            return {
                ...state,
                myNotesData: updatedNotes,
                stickyNotes: updatedStickyNote
                    ? [...state.stickyNotes.filter((note: any) => note.id !== updatedStickyNote.id), updatedStickyNote]
                    : state.stickyNotes, 
            };

      case "OPEN_NOTES_POPUP":
          return { ...state, isNotesPopupOpen: true };

      case "CLOSE_NOTES_POPUP":
          return { ...state, isNotesPopupOpen: false };

      default:
          return state;
  }
};

export default myNotesReducer;
