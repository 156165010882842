import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const FinanceStructureWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.finance-structure-wrap": {
    ".stack-wrap": {
      ".basic-list-wrap": {
        paddingTop: 0,
        ".list": {
          ".title": {
            width: "50%",
            minWidth: "50%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline",
            color: CustomColors.darkGrey,
            padding: '8px 0',
            "@media only screen and (min-width: 1800px)": {
              minWidth: "60%",
            },
          },
          ".action-area": {
            width: 95,
            ".u-form-group": {
              ".u-form-control": {
                height: 'auto',
                paddingLeft: 0,
                backgroundColor: 'transparent',
                "&.MuiInputBase-readOnly": {
                  color: 'rgba(27, 29, 43, 1)',
                  ".MuiInputBase-readOnly": {
                    color: 'rgba(27, 29, 43, 1)',
                    '-webkit-text-fill-color': 'rgba(27, 29, 43, 1)'
                  }
                },
                ".adornment-icon": {
                  paddingRight: 8,
                  color: 'rgba(27, 29, 43, 1)',
                  fontSize: theme.typography.body2.fontSize,
                  lineHeight: theme.typography.body2.lineHeight,
                  fontWeight: theme.typography.fontWeightMedium 
                },
                ".MuiInputBase-input": {
                  padding: '8px 0',
                  color: 'rgba(27, 29, 43, 1)',
                  fontWeight: theme.typography.fontWeightMedium
                }
              },
            },
            "@media only screen and (min-width: 1800px)": {
              width: "40%",
            },
          },
        },
      },
      ".checkbox-section": {
        margin: '16px 0px',
        display: "flex",
        gap: 4,
        "@media only screen and (min-width: 1599.95px)": {
          margin: '16px 0px 16px 0',
          gap: 8
        },
        ".custom-dropdown": {
          padding: 0,
          "@media only screen and (min-width: 1599.95px)": {
            padding: '8px 0px',
          },
        },

        ".custom-checkbox-wrap": {
          overflow: "hidden",
          ".u-custom-control-label": {
            width: "100%",
            margin: 0,
            ".MuiTypography-root": {
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
          },
        },
      },
    },
  },
}));
