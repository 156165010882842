import { BlurCircular } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { Box, Grid, Typography, CircleLoader } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import formatNumber from "@src/utilities/fomatNumber";
import moment from "moment";
import { useSelector } from "react-redux";

export const ApplicantDetailsList = () => {
  const theme = useTheme();
  const { applicantDetails, dialogLoading, error } = useSelector(
    (state: any) => state.applicantDetails
  );

  const customerInfo = applicantDetails?.customer_info || {};
  const customerLicense = applicantDetails?.customer_license?.[0] || {};
  const customerAddress = customerInfo?.customer_addresses?.[0] || {};

  const applicantName = `${
    applicantDetails?.customer_info?.first_name ?? "--"
    } ${applicantDetails?.customer_info?.last_name ?? "--"}`;

  const dateOfBirth = customerLicense?.date_of_birth
    ? new Date(customerLicense?.date_of_birth).toLocaleDateString()
    : "--";

  const formattedDate = (date) =>
    date ? moment(date).format("MM/DD/YYYY") : "--";

  const formatCurrency = (value) => {
    if (value === null || value === undefined || isNaN(value)) return "--";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(value);
  };

  const livingSince = applicantDetails?.customer_info?.customer_addresses?.[0]
    ?.move_in_date
    ? `${Math.floor(
        moment().diff(
          moment(
            applicantDetails.customer_info.customer_addresses[0].move_in_date
          ),
          "years"
        )
      )} Yrs.`
    : "--";

  const basicDetailsFields = [
    {
      title: "Applicant Name:",
      textRight: true,
      subtitle: applicantName,
    },
    { title: "ID Type:", textRight: true, subtitle: "Driving License" },
    {
      title: "ID Number:",
      textRight: true,
      subtitle:
        applicantDetails?.customer_info?.customer_license?.[0]
          ?.license_number ?? "--",
    },
    {
      title: "ID Expiry Date:",
      textRight: true,
      subtitle: applicantDetails?.customer_info?.customer_license?.[0]
        ?.expiry_date
        ? new Date(
            applicantDetails?.customer_info?.customer_license?.[0]?.expiry_date
          ).toLocaleDateString()
        : "--",
    },
    {
      title: "Date of Birth:",
      textRight: true,
      subtitle: applicantDetails?.customer_info?.customer_license?.[0]
        ?.date_of_birth
        ? new Date(
            applicantDetails?.customer_info?.customer_license?.[0]?.date_of_birth
          ).toLocaleDateString()
        : "--",
    },
    { title: "Age:", textRight: true, subtitle: formatNumber(38) + " Yrs." },
    { title: "Gender:", textRight: true, subtitle: "Male" },
    { title: "Nationality:", textRight: true, subtitle: "--" },
    { title: "Education:", textRight: true, subtitle: "--" },
    {
      title: "Mobile Number:",
      textRight: true,
      subtitle: applicantDetails?.customer_info?.mobile_number ?? "--",
    },
  ];

  const addressFields = [
    {
      title: "Current Address:",
      textRight: true,
      subtitle:
        applicantDetails?.customer_info?.customer_addresses?.[0]
          ?.address_line_1 ?? "--",
    },
    {
      title: "City:",
      textRight: true,
      subtitle:
        applicantDetails?.customer_info?.customer_addresses?.[0]?.city ?? "--",
    },
    {
      title: "State:",
      textRight: true,
      subtitle:
        applicantDetails?.customer_info?.customer_addresses?.[0]?.state_name ??
        "--",
    },
    {
      title: "Zip Code:",
      textRight: true,
      subtitle: formatNumber(
        applicantDetails?.customer_info?.customer_addresses?.[0]?.zip_code
      ),
    },
    {
      title: "County:",
      textRight: true,
      subtitle:
        applicantDetails?.customer_info?.customer_addresses?.[0]?.county ??
        "--",
    },
    {
      title: "Living Since:",
      textRight: true,
      subtitle: livingSince,
    },
  ];

  const incomeDetailsFields = [
    {
      title: "Average Balance From Bank Statement:",
      textRight: true,
      subtitle: formatNumber(
        applicantDetails?.customer_info?.employment_info?.bank_statement_balance
      ),
    },
    {
      title: "Net Monthly Income After Tax:",
      textRight: true,
      subtitle: applicantDetails?.customer_info?.employment_info
        ?.annual_employment_income
        ? `$${formatNumber(
            applicantDetails.customer_info.employment_info
              .annual_employment_income / 12
          )}`
        : "--",
    },
  ];

  if (dialogLoading) {
    return <CircleLoader theme={theme} size="sm" />;
  }

  if (error) {
    return (
      <Box theme={theme} textAlign="center" mt={2}>
        <Typography theme={theme} variant="body2" component="p" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid
      theme={theme}
      container
      rowSpacing={0}
      columnSpacing={2}
      sx={{ mt: 0 }}
    >
      {/* Basic Details */}
      <Grid item xs={6} theme={theme}>
        <Stack paddingMd={0} paddingXs={0}>
          <Typography
            theme={theme}
            variant="subtitle2"
            component={"span"}
            className="fw-sbold"
            p={1}
          >
            Basic Details
          </Typography>
          <BasicList noWrap options={basicDetailsFields} />
        </Stack>
      </Grid>

      {/* Address Details */}
      <Grid item xs={6} theme={theme}>
        <Stack paddingMd={0} paddingXs={0}>
          <Typography
            theme={theme}
            variant="subtitle2"
            component={"span"}
            className="fw-sbold"
            p={1}
          >
            Address
          </Typography>
          <Box theme={theme}>
            <BasicList noWrap options={addressFields} />
          </Box>
          <Box theme={theme} mt={2}>
            <Typography
              theme={theme}
              variant="subtitle2"
              component={"span"}
              className="fw-sbold"
              p={1}
            >
              Income Details
            </Typography>
            <BasicList options={incomeDetailsFields} />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
