import { useTheme } from "@mui/material";
import { Box, Grid, Typography, Label, Accordion } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { Stack } from "@src/components/stack";
import { CreditScoreCounter } from "../credit-score-counter/credit-score-counter.component";
import { RiskAssessmentDialogDataWrap } from "./risk-assessment-dialog-data.style";
import { useSelector } from "react-redux";
import { RiskAssessmentGraph } from "../risk-assessment-graph/risk-assessment-graph";
import { Alert } from "@src/components";
import graphImage from "@assets/images/graphImage.png"

export const RiskAssessmentDialogData = () => {
  const theme = useTheme();

  return (
    <>
      <RiskAssessmentDialogDataWrap
        theme={theme}
        className="risk-assesment-dialog-data-wrap"
      >

        <Grid
          theme={theme}
          container
          rowSpacing={0}
          columnSpacing={2}
          sx={{ mt: 0 }}
        >
          <Grid item xs={3} theme={theme}>
            <RiskAssessmentGraph />
            <Box theme={theme}>
              <Stack paddingMd={0} paddingXs={0}>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component={"span"}
                  className="fw-sbold text-body2"
                  display={"block"}
                  mt={2}
                  p={1}
                >
                  Total Exposure
                </Typography>
                <BasicList options={[
                  {
                    title: "Applications:",
                    subtitle: '08',
                    textRight: true
                  },
                  {
                    title: "Contracts:",
                    subtitle: '05',
                    textRight: true
                  },
                  {
                    title: "Active Exposure:",
                    subtitle: '321110',
                    textRight: true
                  },
                  {
                    title: "Pipeline Exposure:",
                    subtitle: '203880',
                    textRight: true
                  },
                  {
                    title: "Active + Pipeline Exposure :",
                    subtitle: '524990',
                    textRight: true
                  },
                  {
                    title: "Periodic Payment:",
                    subtitle: '$11,300.00',
                    textRight: true
                  },
                ]}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={9} theme={theme}>
            <Grid
              theme={theme}
              container
              rowSpacing={2}
              columnSpacing={2}
              sx={{ mt: 0 }}
            >
              <Grid item xs={4} theme={theme}>
                <Stack paddingMd={0} paddingXs={0} title="Trends">
                  <Box theme={theme} className="accordion-graph-wrap" mt={1.5}>
                    <Accordion
                      theme={theme}
                      items={[
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Loan Term Liabilities"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "1",
                          isExpanded: false,
                          id: "1",
                          ariaControls: "1",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Short Term Liabilities"}
                                  value={'$60M'}
                                  fullWidth
                                  errorGraph
                                  accordionError
                                />
                              </Box>
                            </>
                          ),
                          key: "2",
                          isExpanded: false,
                          id: "2",
                          ariaControls: "2",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Net Profit:"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "3",
                          isExpanded: false,
                          id: "3",
                          ariaControls: "3",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"EBIDTA"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "4",
                          isExpanded: false,
                          id: "4",
                          ariaControls: "4",
                        },
                      ]}
                    />
                  </Box>
                </Stack>
                <Stack paddingMd={0} paddingXs={0} title="Cash Flow Ratio" marginTopMd={1.5} marginTopXs={1.5}>
                  <Box theme={theme} className="accordion-graph-wrap" mt={1.5}>
                    <Accordion
                      theme={theme}
                      items={[
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"OCF/TD Ratio"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "1",
                          isExpanded: false,
                          id: "1",
                          ariaControls: "1",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"FCFF Ratio"}
                                  value={'$60M'}
                                  fullWidth
                                  errorGraph
                                  accordionError
                                />
                              </Box>
                            </>
                          ),
                          key: "2",
                          isExpanded: false,
                          id: "2",
                          ariaControls: "2",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"OCF/Total Liabilities"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "3",
                          isExpanded: false,
                          id: "3",
                          ariaControls: "3",
                        },
                      ]}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={4} theme={theme}>
                <Stack paddingMd={0} paddingXs={0} title="Leverage Ratio">
                  <Box theme={theme} className="accordion-graph-wrap" mt={1.5}>
                    <Accordion
                      theme={theme}
                      items={[
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Debt-to-Equity Ratio"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "1",
                          isExpanded: false,
                          id: "1",
                          ariaControls: "1",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Debt-to-EBIDTA Ratio"}
                                  value={'$60M'}
                                  fullWidth
                                  errorGraph
                                  accordionError
                                />
                              </Box>
                            </>
                          ),
                          key: "2",
                          isExpanded: false,
                          id: "2",
                          ariaControls: "2",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Debt-to-Asset Ratio"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "3",
                          isExpanded: false,
                          id: "3",
                          ariaControls: "3",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"ICR"}
                                  value={'$60M'}
                                  fullWidth
                                  errorGraph
                                  accordionError
                                />
                              </Box>
                            </>
                          ),
                          key: "4",
                          isExpanded: false,
                          id: "4",
                          ariaControls: "4",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"DSCR"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "5",
                          isExpanded: false,
                          id: "5",
                          ariaControls: "5",
                        },
                      ]}
                    />
                  </Box>
                </Stack>
                <Stack paddingMd={0} paddingXs={0} title="Liquidity Ratio" marginTopMd={1.5} marginTopXs={1.5}>
                  <Box theme={theme} className="accordion-graph-wrap" mt={1.5}>
                    <Accordion
                      theme={theme}
                      items={[
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Current Ratio"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "1",
                          isExpanded: false,
                          id: "1",
                          ariaControls: "1",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Quick Ratio"}
                                  value={'$60M'}
                                  fullWidth
                                  errorGraph
                                  accordionError
                                />
                              </Box>
                            </>
                          ),
                          key: "2",
                          isExpanded: false,
                          id: "2",
                          ariaControls: "2",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Cash Ratio"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "3",
                          isExpanded: false,
                          id: "3",
                          ariaControls: "3",
                        },
                      ]}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={4} theme={theme}>
                <Stack paddingMd={0} paddingXs={0} title="Profitability Ratio">
                  <Box theme={theme} className="accordion-graph-wrap" mt={1.5}>
                    <Accordion
                      theme={theme}
                      items={[
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Net Profit Margin"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "1",
                          isExpanded: false,
                          id: "1",
                          ariaControls: "1",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Return on Asset"}
                                  value={'$60M'}
                                  fullWidth
                                  errorGraph
                                  accordionError
                                />
                              </Box>
                            </>
                          ),
                          key: "2",
                          isExpanded: false,
                          id: "2",
                          ariaControls: "2",
                        },
                        {
                          content: (
                            <>
                              <Box theme={theme}>
                                <img src={graphImage} />
                              </Box>
                            </>
                          ),
                          title: (
                            <>
                              <Box theme={theme} display={"flex"} alignItems={"center"} gap={1}>
                                <Alert
                                  text={"Return on Equity"}
                                  value={'$60M'}
                                  fullWidth
                                  successGraph
                                  accordionSuccess
                                />
                              </Box>
                            </>
                          ),
                          key: "3",
                          isExpanded: false,
                          id: "3",
                          ariaControls: "3",
                        },
                      ]}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </RiskAssessmentDialogDataWrap>
    </>
  );
};
