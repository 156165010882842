import { useTheme } from "@mui/material";
import { Stack } from "@src/components/stack";
import { BusinessRulesTable } from "../business-rules-table/business-rules-table.component";
import { BusinessRulesTableStackWrap } from "./business-rules-table-stack.style"


export const BusinessRulesTableStack = () => {
    const theme = useTheme();

    const columns = [
        { key: "business_rules_name", label: "Business Rule Name" },
        { key: "work_flow_activity", label: "Work Flow Activity" },
        { key: "description", label: "Description" },
    ];

    return (
        <BusinessRulesTableStackWrap theme={theme} className="basic-stack-wrap">
            <Stack paddingMd={2} paddingXs={2} marginTopMd={2} marginTopXs={2} title="List of Business Rules">
                <BusinessRulesTable />
            </Stack>
        </BusinessRulesTableStackWrap>

    );
};

export default BusinessRulesTableStack;
