import "./root-style.css";
import { createTheme } from "@mui/material";
import { unityTheme } from "@ntpkunity/controls";

export const CustomColors = {
  AppPrimary: "#232BF6",
  bodyColor: "#1B1D2B",
  greyIcon: "#999999",
  lightGrey: "#F8F8F9",
  darkGrey: '#474A59',
  PrimaryLight: "rgba(35, 43, 246, 0.08)",
  yellow: "#FBF0B1",
  yellowLight: "#FDF7D8",
  ferozi: "#ACFAE2",
  feroziLight: "#D5FCF0",
  purple: "#D9C2F3",
  purpleLight: "#ECE0F9",
  pinkLight: "#FFEBEA",
  greenLight: "#EAF9EE"

};

export const CDE_APP_THEME = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: {
      main: CustomColors.AppPrimary,
      light: CustomColors.PrimaryLight,
    },
    grey: { ...unityTheme.palette.grey, 900: CustomColors.bodyColor },
  },
});

export { CDE_THEME_WRAP } from "./global.styles";
