import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const SuggestionListWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.suggestion-list-wrap": {
        li: {
            ".suggestion-list": {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                gap: 8,
                ".title": {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    ".custom-checkbox-wrap": {
                        ".u-custom-control-label": {
                            margin: 0,
                            ".custom-checkbox": {
                                margin: 0
                            }
                        }
                    },
                    ".u-avator": {
                        width: 20,
                        height: 20,
                        background: 'transparent'
                    }
                }
            }
        }
    },
}));
