import { useTheme } from "@mui/material";
import {
  Icon,
  Typography,
  Box,
  Button,
  Accordion,
  CircleLoader,
} from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { DecisionHistoryWrap } from "./decision-history.style";
import { useEffect } from "react";
import { fetchDecisionRequest } from "@src/components/redux/actions/decisionActions";
import { useDispatch, useSelector } from "react-redux";

export const DecisionHistory = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const assignedRowId = useSelector(
    (state: any) => state.workQueue.assignedRowId
  );

  const decision = useSelector((state: any) => state.decision);

  useEffect(() => {
    dispatch(fetchDecisionRequest(assignedRowId));
  }, []);

  if (decision.loading) {
    return <CircleLoader theme={theme} />;
  }

  const stipulations = decision.data?.stipulations;

  const financialStipulations = stipulations?.filter(
    (stip) => stip.stipulationType === "financial"
  );
  const nonFinancialStipulations = stipulations?.filter(
    (stip) => stip.stipulationType === "nonFinancial"
  );

  return (
    <>
      <DecisionHistoryWrap theme={theme} className="decision-history-wrap">
        <Stack paddingMd={2} paddingXs={2}>
          <Box
            theme={theme}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              className="fw-sbold"
            >
              Previous Decision
            </Typography>
            <Button
              theme={theme}
              endIcon={
                <>
                  <Icon name="InfoIcon" />
                </>
              }
              text={decision.data?.status}
              className="state-unavailable"
            />
          </Box>
        </Stack>
        <Stack paddingMd={2} paddingXs={2}>
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            className="fw-sbold"
          >
            Previous Decision
          </Typography>
          <Box theme={theme} className="accordian-mini-wrap">
            <Accordion
              theme={theme}
              items={[
                {
                  content: (
                    <>
                      <Box
                        theme={theme}
                        display={"flex"}
                        alignItems={"center"}
                        gap={2}
                      >
                        {financialStipulations?.map((stip, index) => {
                          return (
                            <Button
                              key={`${stip.stipulationType}-${index}`}
                              theme={theme}
                              endIcon={
                                <>
                                  <Icon name="InfoIcon" />
                                </>
                              }
                              text={stip.recommendation}
                              className="pink-light"
                            />
                          );
                        })}
                      </Box>
                    </>
                  ),
                  title: "Financial Stips",
                  key: "history-1",
                  isExpanded: true,
                  id: "history-1",
                  ariaControls: "history-1",
                },
                {
                  content: (
                    <>
                      <Box
                        theme={theme}
                        display={"flex"}
                        alignItems={"center"}
                        gap={2}
                      >
                        {nonFinancialStipulations?.map((stip, index) => {
                          return (
                            <Button
                              key={`${stip.stipulationType}-${index}`}
                              theme={theme}
                              endIcon={
                                <>
                                  <Icon name="InfoIcon" />
                                </>
                              }
                              text={stip.recommendation}
                              className="pink-light"
                            />
                          );
                        })}
                      </Box>
                    </>
                  ),
                  title: "Non-Financial Stips",
                  key: "non-history-1",
                  isExpanded: true,
                  id: "non-history-1",
                  ariaControls: "non-history-1",
                },
              ]}
            ></Accordion>
          </Box>
        </Stack>
      </DecisionHistoryWrap>
    </>
  );
};
