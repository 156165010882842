import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const DashboardStackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.dashboard-stack-wrap": {
    ".stack-wrap": {
      ".stack-header": {
        padding: "8px 0",
        "@media only screen and (max-width: 1599.95px)": {
          padding: "4px 0",
        },
        ".title": {
          minWidth: 127,
          ".MuiTypography-root": {
            fontWeight: theme.customVariables.fontWeightSemiBold,
            "@media(max-width: 1599.95px)": {
              fontSize: theme.typography.subtitle1.fontSize,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          },
        },
      },
      ".basic-list-wrap": {
        paddingTop: 16,
        marginBottom: 8,
        "@media only screen and (max-width: 1599.95px)": {
          marginBottom: 4,
        },
      },
    },
    '.scrollable-container': {
      maxHeight: 'calc(100vh - 184px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      "@media only screen and (min-width: 1599.95px)": {
        maxHeight: 'calc(100vh - 200px)',
      }
    },
    '.child-scrollable-container': {
      maxHeight: 'calc(100vh - 364px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      "@media only screen and (min-width: 1800.95px)": {
        maxHeight: 'calc(100vh - 381px)',
      }
    },
  },
}));
