import { useTheme } from "@mui/material"
import { Icon, Box, Typography } from "@ntpkunity/controls"
import { PdfListProps } from "./pdf-list.props"
import { PdfListWrap } from "./pdf-list.style"
import imgIcon from "@src/assets/icons/jpgIcon.svg"
import clsx from "clsx"


export const PdfList = ({title, subTitle, variant, onClick, pdfIcon, jpgIcon}: PdfListProps) => {
    const theme = useTheme()

    return(
        <>
            <PdfListWrap theme={theme} className={clsx({"pdf-list-wrap": true, "light": variant === "light"})}>
                {pdfIcon && <Icon name="PdfViewIcon" />}
                {jpgIcon && <img src={imgIcon} />}
                {title &&
                    <Box theme={theme} className="doc-list">
                        <Typography theme={theme} variant="caption" component={"small"} className="fw-medium" onClick={onClick}>{title}</Typography>
                        {subTitle &&
                            <Typography theme={theme} variant="caption" component={"small"} className="text-muted">{subTitle}</Typography>
                        }
                    </Box>
                }
            </PdfListWrap>
        </>
    )
}