import { useTheme } from "@mui/material"
import { CreditScoreCounterWrap } from "./credit-score-counter.style"
import { Typography, Box, Label } from "@ntpkunity/controls"
import { useSelector } from "react-redux";



export const CreditScoreCounter = () => {
    const theme = useTheme();

const creditCheckScore = useSelector((state: any) => state.workQueue.credit_check_score);

    return(
        <>
            <CreditScoreCounterWrap theme={theme} className="credit-score-counter-wrap">
                <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    className="text-body2"
                >
                    Credit Score
                </Typography>
                <Box theme={theme} display={'flex'} alignItems={'center'} gap={1}>
                    <Typography
                        theme={theme}
                        variant="body2"
                        component={"span"}
                        className="fw-bold text-body2 text-primary"
                    >
                        {creditCheckScore ? creditCheckScore : "N/A"}<span className="number">/900</span>
                    </Typography>
                    <Label 
                        theme={theme} 
                        text="Excellent"
                        state="primary"
                        textTransform="none" 
                    />
                </Box>
            </CreditScoreCounterWrap>
        </>
    )
}