import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const BasicStackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.basic-stack-wrap": {
    padding: "12px 16px",
    backgroundColor: CustomColors.lightGrey,
    borderRadius: 8,
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    "&.text-justify": {
      ".text-wrap": {
        justifyContent: 'space-between'
      }
    },
    "&.w-100": {
      ".text-wrap": {
        width: '100%'
      }
    },
    "@media only screen and (max-width: 1599.95px)": {
      padding: 8
    },
    ".text-wrap": {
        display: 'flex',
        alignSelf: 'stretch',
        alignItems: 'center',
        gap: 8,
    },
    ".action-area": {
        display: "flex",
        alignItems: "center",
        gap: 16,
        flexShrink: 0,
        ".btn": {
          "&.with-icon": {
            "&.icon-only": {
              padding: 0,
              svg: {
                width: "auto",
                height: "auto",
              },
            },
          },
        },
      },
  },
}));
