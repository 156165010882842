import { useTheme } from "@mui/material";
import { Accordion, Box, DuoTab, Dialog, Typography, Label, Grid, CircleLoader } from "@ntpkunity/controls";
import { Popup } from "@src/components/Dialog/dialog.component";
import { ThirdPartyResponseDialogWrap } from "./third-party-response-dialog.style";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Stack } from "@src/components";
import { VersionResponseStack } from "./../version-response-stack/version-response-stack.component";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

export const ThirdPartyResponseDialog = ({ open, closeDialog }) => {
  const theme = useTheme();
  const { reference_number } = useParams();

  const apiKey = useSelector((state: any) => state.workQueue.apiKey);
  const { workQueues } = useSelector((state: any) => state.workQueue);

  const [socureData, setSocureData] = useState<any>(null);
  const [reasonCodes, setReasonCodes] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchSocureData = async () => {
      if (!open) return;

      setLoading(true);
      setError(null);

      try {
        const base_url = process.env.REACT_APP_API_URL;

        let workflowId = "34dd9920192fcdb5";

        if (reference_number !== "BU-SD-2025-1638") {
          const filteredQueue = workQueues.find((item) => item.reference_number === reference_number);

          if (!filteredQueue) {
            setLoading(false);
            return;
          }

          workflowId = filteredQueue.running_workflow_instance_id;
        }


        const response = await axios.get(`${base_url}/cde/get_activity_workflow_data`, {
          params: {
            workflowInstanceId: workflowId,
            activity_name: "FraudCheck_Response",
          },
        });

        setSocureData(response.data);
      } catch (err) {
        console.error("Error fetching Socure data:", err);
        setError("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchSocureData();
  }, [open, workQueues, reference_number]);

  useEffect(() => {
    const fetchReasonCodes = async () => {
      if (open) {
        setLoading(true);
        setError(null);

        try {
          const response = await axios.get(
            "https://dev-api-k8s.netsolapp.io/hubex/fraud-identification/reason-codes",
            {
              headers: {
                "x-service-provider": "Socure",
                "x-api-key": apiKey,
              },
            }
          );
          setReasonCodes(response.data.reason_codes);
        } catch (err) {
          console.error("Error fetching reason codes:", err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchReasonCodes();
  }, [open]);

  const getDescription = (code) => {
    const match = reasonCodes.find((item) => item.code === code);
    return match ? match.description : "Description not found";
  };

  return (
    <>
      <ThirdPartyResponseDialogWrap theme={theme} className="third-party-response-dialog-wrap">
        <Popup>
          <Dialog
            theme={theme}
            size={"xl"}
            onCloseDialog={closeDialog}
            open={open}
            title={"Socure Response"}
            disablePortal
            noFooter
          >
            {loading ? <CircleLoader theme={theme} size="sm" /> : <DuoTab
              theme={theme}
              varient="underline"
              defaultTabIndex={0}
              items={[
                {
                  content: (
                    <>
                      <Box theme={theme} className="content">
                        <Box theme={theme} pt={1} pb={1} display={'flex'} alignItems={'center'} gap={2}>
                          <Box theme={theme} display={'flex'} gap={0.5}>
                            <Typography theme={theme} variant="body2" component={'span'} className="text-muted">Model Name:</Typography>
                            <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">{socureData?.reason_codes?.code || "--"}</Typography>
                          </Box>
                          <Label
                            theme={theme}
                            text={socureData?.decision?.modelVersion || "--"}
                            state="primary"
                          />
                        </Box>
                        <Box theme={theme} className="listing" mt={2}>
                          <VersionResponseStack
                            title='Synthetic:'
                            subTitle={socureData?.decision?.details?.synthetic?.decision || "--"}
                            version={socureData?.decision?.modelVersion || "--"}
                            variant={socureData?.decision?.details?.synthetic?.decision === "accept" ? "success" : "success"} 
                          />
                          <VersionResponseStack
                            title='Name Phone Correlation:'
                            subTitle={socureData?.decision?.details?.namephonecorrelation?.decision || "--"}
                            version={socureData?.decision?.modelVersion || "--"}
                            variant={socureData?.decision?.details?.namephonecorrelation?.decision === "accept" ? "success" : "success"} 
                          />
                          <VersionResponseStack
                            title='Fraud:'
                            subTitle={socureData?.decision?.details?.fraud?.decision || "--"}
                            version={socureData?.fraud?.scores?.[0]?.version || "--"}
                            variant={socureData?.decision?.details?.fraud?.decision === "accept" ? "success" : "success"} 
                          />
                          <VersionResponseStack
                            title='Address Risk:'
                            subTitle={socureData?.decision?.details?.addressrisk?.decision || "--"}
                            version={socureData?.decision?.modelVersion || "--"}
                            variant={socureData?.decision?.details?.addressrisk?.decision === "accept" ? "success" : "success"} 
                          />
                          <VersionResponseStack
                            title='Phone Risk:'
                            subTitle={socureData?.decision?.details?.phonerisk?.decision || "--"}
                            version={socureData?.decision?.modelVersion || "--"}
                            variant={socureData?.decision?.details?.phonerisk?.decision === "accept" ? "success" : "success"} 
                          />
                          <VersionResponseStack
                            title='Email Risk:'
                            subTitle={socureData?.decision?.details?.emailrisk?.decision || "--"}
                            version={socureData?.decision?.modelVersion || "--"}
                            variant={socureData?.decision?.details?.emailrisk?.decision === "accept" ? "success" : "success"} 
                          />
                          <VersionResponseStack
                            title='Name Email Correlation Risk:'
                            subTitle={socureData?.decision?.details?.nameemailcorrelation?.decision || "--"}
                            version={socureData?.decision?.modelVersion || "--"}
                            variant={socureData?.decision?.details?.nameemailcorrelation?.decision === "accept" ? "success" : "success"} 
                          />
                          <VersionResponseStack
                            title='Name Address Correlation Risk:'
                            subTitle={socureData?.decision?.details?.nameaddresscorrelation?.decision || "--"}
                            version={socureData?.decision?.modelVersion || "--"}
                            variant={socureData?.decision?.details?.nameaddresscorrelation?.decision === "accept" ? "success" : "success"} 
                          />
                          <VersionResponseStack
                            title='KYC:'
                            subTitle={socureData?.decision?.details?.kyc?.decision || "--"}
                            version={socureData?.decision?.modelVersion || "--"}
                            variant={socureData?.decision?.details?.kyc?.decision === "accept" ? "success" : "success"} 
                          />
                        </Box>
                      </Box>
                    </>
                  ),
                  title: "Decision",
                },
                {
                  content: (
                    <>
                      <Box theme={theme} className="content">
                        <Box theme={theme} pt={1} pb={1} display={'flex'} alignItems={'center'} gap={2}>
                          <Box theme={theme} display={'flex'} gap={0.5}>
                            <Typography theme={theme} variant="body2" component={'span'} className="text-muted">Score:</Typography>
                            <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">
                              {socureData?.nameAddressCorrelation?.score || "--"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box theme={theme} mt={2}>
                          <Box theme={theme} pt={0.5} pb={0.5}>
                            <Typography theme={theme} variant="caption" component={'span'} className="fw-medium">Informational Codes</Typography>
                          </Box>
                          <Box theme={theme} className="listing" mt={0.5}>
                            {socureData?.nameAddressCorrelation?.reasonCodes?.map((code, index) => {
                              const matchedReason = socureData?.reason_codes?.find(item => item.code === code);
                              const description = matchedReason ? matchedReason.description : "No description available";

                              return (
                                <VersionResponseStack
                                  key={index}
                                  title={
                                    <>
                                      <Typography theme={theme} variant="caption" component={'span'} className="line-height18 text-black text-nowrap">
                                        {getDescription(code)}
                                      </Typography>
                                    </>
                                  }
                                  actionArea={
                                    <>
                                      <Label
                                        theme={theme}
                                        text={code}
                                        state="primary"
                                      />
                                    </>
                                  }
                                />
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ),
                  title: "Name Address Correlations",
                },
                {
                  content: (
                    <>
                      <Box theme={theme} className="content">
                        <Box theme={theme} pt={1} pb={1} display={'flex'} alignItems={'center'} gap={2}>
                          <Box theme={theme} display={'flex'} gap={0.5}>
                            <Typography theme={theme} variant="body2" component={'span'} className="text-muted">Score:</Typography>
                            <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">
                              {socureData?.nameEmailCorrelation?.score || "--"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box theme={theme} mt={2}>
                          <Box theme={theme} pt={0.5} pb={0.5}>
                            <Typography theme={theme} variant="caption" component={'span'} className="fw-medium">Informational Codes</Typography>
                          </Box>
                          <Box theme={theme} className="listing" mt={0.5}>
                            {socureData?.nameEmailCorrelation?.reasonCodes?.map((code, index) => {
                              const matchedReason = socureData?.reason_codes?.find(item => item.code === code);
                              const description = matchedReason ? matchedReason.description : "No description available";

                              return (
                                <VersionResponseStack
                                  key={index}
                                  title={
                                    <>
                                      <Typography theme={theme} variant="caption" component={'span'} className="line-height18 text-black text-nowrap">
                                        {getDescription(code)}
                                      </Typography>
                                    </>
                                  }
                                  actionArea={
                                    <>
                                      <Label
                                        theme={theme}
                                        text={code}
                                        state="primary"
                                      />
                                    </>
                                  }
                                />
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ),
                  title: "Name Email Correlations",
                },                
                {
                  content: (
                    <>
                      <Box theme={theme} className="content">
                        <Box theme={theme} pt={1} pb={1} display={'flex'} alignItems={'center'} gap={2}>
                          <Box theme={theme} display={'flex'} gap={0.5}>
                            <Typography theme={theme} variant="body2" component={'span'} className="text-muted">Score:</Typography>
                            <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">
                              {socureData?.namePhoneCorrelation?.score || "--"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box theme={theme} mt={2}>
                          <Box theme={theme} pt={0.5} pb={0.5}>
                            <Typography theme={theme} variant="caption" component={'span'} className="fw-medium">Informational Codes</Typography>
                          </Box>
                          <Box theme={theme} className="listing" mt={0.5}>
                            {socureData?.namePhoneCorrelation?.reasonCodes?.map((code, index) => {
                              const matchedReason = socureData?.reason_codes?.find(item => item.code === code);
                              const description = matchedReason ? matchedReason.description : "No description available";

                              return (
                                <VersionResponseStack
                                  key={index}
                                  title={
                                    <>
                                      <Typography theme={theme} variant="caption" component={'span'} className="line-height18 text-black text-nowrap">
                                        {getDescription(code)}
                                      </Typography>
                                    </>
                                  }
                                  actionArea={
                                    <>
                                      <Label
                                        theme={theme}
                                        text={code}
                                        state="primary"
                                      />
                                    </>
                                  }
                                />
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ),
                  title: "Name Phone Correlations",
                },                
                {
                  content: (
                    <>
                      <Box theme={theme} className="content">
                        <Box theme={theme} pt={1} pb={1} display={'flex'} alignItems={'center'} gap={2}>
                          <Box theme={theme} display={'flex'} gap={0.5}>
                            <Typography theme={theme} variant="body2" component={'span'} className="text-muted">Score:</Typography>
                            <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">
                              {socureData?.fraud?.scores?.[0]?.score || "--"}
                            </Typography>
                          </Box>
                          <Label
                            theme={theme}
                            text={socureData?.fraud?.scores?.[0]?.name || "--"}
                            state="primary"
                          />
                        </Box>
                        <Box theme={theme} mt={2}>
                          <Box theme={theme} pt={0.5} pb={0.5}>
                            <Typography theme={theme} variant="caption" component={'span'} className="fw-medium">Informational Codes</Typography>
                          </Box>
                          <Box theme={theme} className="listing" mt={0.5}>
                            {socureData?.fraud?.reasonCodes?.map((code, index) => {
                              const matchedReason = socureData?.reason_codes?.find(item => item.code === code);
                              const description = matchedReason ? matchedReason.description : "No description available";

                              return (
                                <VersionResponseStack
                                  key={index}
                                  title={
                                    <>
                                      <Typography theme={theme} variant="caption" component={'span'} className="line-height18 text-black text-nowrap">
                                        {getDescription(code)}
                                      </Typography>
                                    </>
                                  }
                                  actionArea={
                                    <>
                                      <Label
                                        theme={theme}
                                        text={code}
                                        state="primary"
                                      />
                                    </>
                                  }
                                />
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ),
                  title: "Fraud",
                },                
                {
                  content: (
                    <>
                      <Box theme={theme} className="content">
                        <Box theme={theme} pt={1} pb={1} display={'flex'} alignItems={'center'} gap={2}>
                          <Box theme={theme} display={'flex'} gap={0.5}>
                            <Typography theme={theme} variant="body2" component={'span'} className="text-muted">Score:</Typography>
                            <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">
                              {socureData?.kyc?.fieldValidations?.firstName || "--"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box theme={theme} mt={2}>
                          <Box theme={theme} pt={0.5} pb={0.5}>
                            <Typography theme={theme} variant="caption" component={'span'} className="fw-medium">Informational Codes</Typography>
                          </Box>
                          <Box theme={theme} className="listing" mt={0.5}>
                            {socureData?.kyc?.reasonCodes?.map((code, index) => {
                              const matchedReason = socureData?.reason_codes?.find(item => item.code === code);
                              const description = matchedReason ? matchedReason.description : "No description available";

                              return (
                                <VersionResponseStack
                                  key={index}
                                  title={
                                    <>
                                      <Typography theme={theme} variant="caption" component={'span'} className="line-height18 text-black text-nowrap">
                                        {getDescription(code)}
                                      </Typography>
                                    </>
                                  }
                                  actionArea={
                                    <>
                                      <Label
                                        theme={theme}
                                        text={code}
                                        state="primary"
                                      />
                                    </>
                                  }
                                />
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ),
                  title: "KYC",
                },                
                {
                  content: (
                    <>
                      <Box theme={theme} className="content">
                        <Box theme={theme} pt={1} pb={1} display={'flex'} alignItems={'center'} gap={2}>
                          <Box theme={theme} display={'flex'} gap={0.5}>
                            <Typography theme={theme} variant="body2" component={'span'} className="text-muted">Score:</Typography>
                            <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">
                              {socureData?.addressRisk?.score || "--"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box theme={theme} mt={2}>
                          <Box theme={theme} pt={0.5} pb={0.5}>
                            <Typography theme={theme} variant="caption" component={'span'} className="fw-medium">Informational Codes</Typography>
                          </Box>
                          <Box theme={theme} className="listing" mt={0.5}>
                            {socureData?.addressRisk?.reasonCodes?.map((code, index) => {
                              const matchedReason = socureData?.reason_codes?.find(item => item.code === code);
                              const description = matchedReason ? matchedReason.description : "No description available";

                              return (
                                <VersionResponseStack
                                  key={index}
                                  title={
                                    <>
                                      <Typography theme={theme} variant="caption" component={'span'} className="line-height18 text-black text-nowrap">
                                        {getDescription(code)}
                                      </Typography>
                                    </>
                                  }
                                  actionArea={
                                    <>
                                      <Label
                                        theme={theme}
                                        text={code}
                                        state="primary"
                                      />
                                    </>
                                  }
                                />
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ),
                  title: "Address Risk",
                },
                {
                  content: (
                    <>
                      <Box theme={theme} className="content">
                        <Box theme={theme} pt={1} pb={1} display={'flex'} alignItems={'center'} gap={2}>
                          <Box theme={theme} display={'flex'} gap={0.5}>
                            <Typography theme={theme} variant="body2" component={'span'} className="text-muted">Score:</Typography>
                            <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">
                              {socureData?.emailRisk?.score || "--"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box theme={theme} mt={2}>
                          <Box theme={theme} pt={0.5} pb={0.5}>
                            <Typography theme={theme} variant="caption" component={'span'} className="fw-medium">Informational Codes</Typography>
                          </Box>
                          <Box theme={theme} className="listing" mt={0.5}>
                            {socureData?.emailRisk?.reasonCodes?.map((code, index) => {
                              const matchedReason = socureData?.reason_codes?.find(item => item.code === code);
                              const description = matchedReason ? matchedReason.description : "No description available";

                              return (
                                <VersionResponseStack
                                  key={index}
                                  title={
                                    <>
                                      <Typography theme={theme} variant="caption" component={'span'} className="line-height18 text-black text-nowrap">
                                        {getDescription(code)}
                                      </Typography>
                                    </>
                                  }
                                  actionArea={
                                    <>
                                      <Label
                                        theme={theme}
                                        text={code}
                                        state="primary"
                                      />
                                    </>
                                  }
                                />
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ),
                  title: "Email Risk",
                },
                {
                  content: (
                    <>
                      <Box theme={theme} className="content">
                        <Box theme={theme} pt={1} pb={1} display={'flex'} alignItems={'center'} gap={2}>
                          <Box theme={theme} display={'flex'} gap={0.5}>
                            <Typography theme={theme} variant="body2" component={'span'} className="text-muted">Score:</Typography>
                            <Typography theme={theme} variant="body2" component={'span'} className="fw-medium">
                              {socureData?.phoneRisk?.score || "--"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box theme={theme} mt={2}>
                          <Box theme={theme} pt={0.5} pb={0.5}>
                            <Typography theme={theme} variant="caption" component={'span'} className="fw-medium">Informational Codes</Typography>
                          </Box>
                          <Box theme={theme} className="listing" mt={0.5}>
                            {socureData?.phoneRisk?.reasonCodes?.map((code, index) => {
                              const matchedReason = socureData?.reason_codes?.find(item => item.code === code);
                              const description = matchedReason ? matchedReason.description : "No description available";

                              return (
                                <VersionResponseStack
                                  key={index}
                                  title={
                                    <>
                                      <Typography theme={theme} variant="caption" component={'span'} className="line-height18 text-black text-nowrap">
                                        {getDescription(code)}
                                      </Typography>
                                    </>
                                  }
                                  actionArea={
                                    <>
                                      <Label
                                        theme={theme}
                                        text={code}
                                        state="primary"
                                      />
                                    </>
                                  }
                                />
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ),
                  title: "Phone Risk",
                },                
                {
                  content: (
                    <>
                      <Box theme={theme} className="content" display="flex" alignItems="center" justifyContent="center">
                        <Typography theme={theme} variant="caption" component="span" className="text-muted fw-medium">
                          {socureData?.alertList?.length > 0 ? "Alerts Found." : "No Alert Found."}
                        </Typography>
                      </Box>
                    </>
                  ),
                  title: "Alert List",
                },                
                {
                  content: (
                    <>
                      <Box theme={theme} className="content" display="flex" alignItems="center" justifyContent="center">
                        <Typography theme={theme} variant="caption" component="span" className="text-muted fw-medium">
                          {socureData?.globalWatchList?.length > 0 ? "Watch List Found." : "No Watch List Found."}
                        </Typography>
                      </Box>
                    </>
                  ),
                  title: "Global Watch List",
                },                
                {
                  content: (
                    <>
                      <Box theme={theme} className="content">
                        <Box theme={theme} pt={1} pb={1} display="flex" alignItems="center" gap={2}>
                          <Box theme={theme} display="flex" gap={0.5}>
                            <Typography theme={theme} variant="body2" component="span" className="text-muted">
                              Score:
                            </Typography>
                            <Typography theme={theme} variant="body2" component="span" className="fw-medium">
                              {socureData?.synthetic?.score ?? "--"}
                            </Typography>
                          </Box>
                          {socureData?.synthetic?.name && (
                            <Label theme={theme} text={socureData.synthetic.name} state="primary" />
                          )}
                        </Box>

                        {socureData?.synthetic?.reasonCodes?.length > 0 && (
                          <Box theme={theme} mt={2}>
                            <Box theme={theme} pt={0.5} pb={0.5}>
                              <Typography theme={theme} variant="caption" component="span" className="fw-medium">
                                Risk Codes
                              </Typography>
                            </Box>
                            <Box theme={theme} className="listing" mt={0.5}>
                              {socureData.synthetic.reasonCodes.map((code, index) => (
                                <VersionResponseStack
                                  key={index}
                                  title={
                                    <Typography theme={theme} variant="caption" component="span" className="line-height18 text-black text-nowrap">
                                      {code}
                                    </Typography>
                                  }
                                  actionArea={<Label theme={theme} text={code} state="error" />}
                                />
                              ))}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </>
                  ),
                  title: "Synthetic",
                },                
                {
                  content: (
                    <>
                      <Box theme={theme} className="content" display="flex" alignItems="center" justifyContent="center">
                        <Typography theme={theme} variant="caption" component="span" className="text-muted fw-medium">
                          {socureData?.deviceRisk?.length > 0 ? "Device Risk Found" : "No Device Risk Found"}
                        </Typography>
                      </Box>
                    </>
                  ),
                  title: "Device Risk",
                },                
              ]}
            />}
          </Dialog>
        </Popup>
      </ThirdPartyResponseDialogWrap>
    </>
  );
};