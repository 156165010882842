import { useTheme } from "@mui/material";
import {
  Icon,
  Grid,
  Typography,
  Textarea,
  Box,
  Button,
  Select,
} from "@ntpkunity/controls";
import { Stack } from "@src/components/stack";
import { AddStipWrap } from "./add-stip.style";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStipSuccess } from "@src/components/redux/actions/stipActions";

const FORM_INITIAL_STATE = {
  stipulationType: "",
  recommendation: "",
  comments: "",
};

export const AddStip = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const assignedRowId = useSelector(
    (state: any) => state.workQueue.assignedRowId
  );

  const [isToggled, setIsToggled] = useState(false);
  const [formState, setFormState] = useState(FORM_INITIAL_STATE);

  const handleClick = () => {
    setIsToggled(!isToggled);
  };

  const handleInputChange = (key, value) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  const handleAddStipulation = () => {
    if (
      !formState.stipulationType ||
      !formState.recommendation ||
      !formState.comments
    ) {
      return;
    }

    const payload = {
      stipulationType: formState.stipulationType,
      recommendation: formState.recommendation,
      comments: formState.comments,
    };

    dispatch(addStipSuccess(payload));
  };

  return (
    <>
      <AddStipWrap
        theme={theme}
        className={`add-stip-wrap ${isToggled ? "toggled" : ""}`}
      >
        <Stack
          paddingMd={2}
          paddingXs={2}
          title={"Stipulation"}
          actionArea={
            <>
              {isToggled === false ? (
                <>
                  <a
                    href="javascript:void(0)"
                    className="text-primary primary-alert dFlex alignItemsCenter"
                    onClick={handleClick}
                  >
                    Add Stip <Icon name="AddIcon" />
                  </a>
                </>
              ) : (
                <Button
                  theme={theme}
                  defaultBtn
                  endIcon={<Icon name="CloseBlack" />}
                  onClick={() => setIsToggled(false)}
                />
              )}
            </>
          }
        >
          <Grid theme={theme} container rowSpacing={2} columnSpacing={2} mt={0}>
            <Grid item xs={6} theme={theme}>
              <Select
                theme={theme}
                disablePortal={false}
                label={"Stipulation Type"}
                items={[
                  { text: "Financial", value: "financial" },
                  { text: "Non Financial", value: "nonFinancial" },
                ]}
                onChange={(e) =>
                  handleInputChange("stipulationType", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} theme={theme}>
              <Select
                theme={theme}
                disablePortal={false}
                label={"Stipulation"}
                items={[
                  {
                    text: "Increase Down Payment",
                    value: "Increase Down Payment",
                  },
                  { text: "Increase Terms", value: "Increase Terms" },
                  {
                    text: "Add Employment Documents",
                    value: "Add Employment Documents",
                  },
                ]}
                onChange={(e) =>
                  handleInputChange("recommendation", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} theme={theme}>
              <Textarea
                theme={theme}
                id=""
                type="text"
                fullWidth
                textCount={"10"}
                totalTextCount={"60"}
                rows={4}
                label="Comments"
                onChange={(e) => handleInputChange("comments", e)}
                value={formState.comments}
              ></Textarea>
            </Grid>
            <Grid item xs={12} theme={theme}>
              <Box theme={theme} display={"flex"} justifyContent={"center"}>
                <Button
                  theme={theme}
                  primary
                  text="Add Stipulation"
                  onClick={handleAddStipulation}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </AddStipWrap>
    </>
  );
};
