export const ADD_STIP_REQUEST = "ADD_STIP_REQUEST";
export const ADD_STIP_SUCCESS = "ADD_STIP_SUCCESS";
export const ADD_STIP_FAILURE = "ADD_STIP_FAILURE";
export const FETCH_STIPULATION_REQUEST = "FETCH_STIPULATION_REQUEST";
export const FETCH_STIPULATION_SUCCESS = "FETCH_STIPULATION_SUCCESS";
export const FETCH_STIPULATION_FAILURE = "FETCH_STIPULATION_FAILURE";
export const DELETE_STIP = "DELETE_STIP";
export const ADD_ALL_STIPS = "ADD_ALL_STIPS";

export const addStipRequest = (id, payload) => ({
  type: ADD_STIP_REQUEST,
  id,
  payload,
});

export const addStipSuccess = (data) => ({
  type: ADD_STIP_SUCCESS,
  payload: data,
});

export const addStipFailure = (error) => ({
  type: ADD_STIP_FAILURE,
  payload: error,
});

export const deleteStip = (recommendation) => ({
  type: DELETE_STIP,
  payload: recommendation,
});

export const addAllStips = (payload) => ({
  type: ADD_ALL_STIPS,
  payload: payload,
});


export const fetchStipulationRequest = (payload) => ({
  type: FETCH_STIPULATION_REQUEST,
  payload,
});

export const fetchStipulationSuccess = (data) => ({
  type: FETCH_STIPULATION_SUCCESS,
  payload: data,
});

export const fetchStipulationFailure = (error) => ({
  type: FETCH_STIPULATION_FAILURE,
  payload: error,
});