import { useTheme } from "@mui/material";
import { Box, CircleLoader, Icon, Input, Select, Typography } from "@ntpkunity/controls";
import { BasicList } from "@src/components/basic-list/basic-list.component";
import { fetchApplicantDetailsRequest } from "@src/components/redux/actions/applicantDetailsActions";
import { Stack } from "@src/components/stack";
import { FinanceStructureWrap } from "./finance-structure.style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FinanceStructureModifyDialog } from "../finance-structure-modify-popup/finance-structure-modify-popup-dialog.component";
import { ArrowDown } from "@src/components";
import { setSelectedStructure } from "@src/components/redux/actions/financeStructureActions";
import axios from "axios";

export const FinanceStructure = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { reference_number } = useParams();

  const [open, setOpened] = useState(false);
  const [selectedStructureKey, setSelectedStructureKey] = useState<string | null>(null);
  const [financeStructures, setFinanceStructures] = useState<any[]>([]);
  const [currentStructure, setCurrentStructure] = useState<any>(null);
  const [financeType, setFinanceType] = useState<any>([]);

  const { applicantDetails, loading, error } = useSelector((state: any) => state?.applicantDetails);
  const { selectedStructure } = useSelector((state: any) => state?.financeStructure); 
  const apiKey = useSelector((state: any) => state.workQueue.apiKey);

  const base_url = process.env.REACT_APP_API_URL
  const xApiKey = process.env.API_KEY

  useEffect(() => {
    const fetchCustomerTypes = async () => {
      try {
        const res = await axios.get(
          `${base_url}/config/finance_type`,
          {
            headers: {
              accept: "application/json",
              "x-api-key": xApiKey,
              company_id: 17274,
            },
          }
        );
        setFinanceType(res.data);
      } catch (err) {
        console.error("Failed to fetch customer types", err);
      }
    };

    if (apiKey && 17274) fetchCustomerTypes();
  }, [apiKey, 17274]);

  useEffect(() => {
    if (reference_number) {
      dispatch(fetchApplicantDetailsRequest(reference_number, apiKey));
    }
  }, [dispatch, reference_number]);

  const normalizeStructureData = (data: any) => {
    const rentalAmount = data?.order_repayment_plan?.[0]?.rental_amount;
    const estimatedPayment = data?.estimated_monthly_payment;

    const matchedType = financeType?.find((item: any) => item.code === data?.finance_type);
    const financeTypeName = matchedType?.name || "Hire Purchase";

    return {
      finance_type: financeTypeName || "Hire Purchase",
      finance_amount: data?.net_finance_amount ?? data?.finance_amount ?? "--",
      rental_amount: rentalAmount ?? estimatedPayment ?? "--",
      terms: data?.order_submissions?.[0]?.decision?.terms ?? data?.no_of_regular_payments ?? "--",
      frequency: data?.rental_frequency || "--",
      asset_cost: data?.selling_price || "--",
      down_payment_value: data?.down_payment_value ?? data?.down_payment ?? data?.down_payment ?? "--",
      tax_amount: data?.tax_amount || "--",
      net_trade_in: data?.order_tradein?.trade_in_amount || "--",
      charges: data?.charges || "--",
      rebate: data?.rebate || "--",
      discount: data?.discount || "--",
      add_ons: data?.order_options?.reduce((acc: number, option: any) => acc + (option.applied_price || 0), 0) || "--",
      insurance: data?.order_fnI?.reduce((acc: number, item: any) => acc + (item.applied_price || 0), 0) || "--",
      interest_rate: data?.apr ?? data?.meta_data?.apr ?? "--",
      dealer_fees: data?.order_fees?.reduce((acc: number, fee: any) => acc + (fee.applied_price || 0), 0) || "--",
    };
  };

  const persistedStructures = useSelector((state: any) => state?.financeStructure?.financeStructures) || [];

  useEffect(() => {
    if (applicantDetails) {
      const baseData = normalizeStructureData(applicantDetails);
      const defaultStructure = {
        key: "requested",
        label: "Requested",
        data: baseData,
      };

      const aiRecommendation = {
        key: "ai_recommendation",
        label: "AI Recommendation",
        data: baseData,
      };

      const initialStructures = persistedStructures.length ? persistedStructures : [defaultStructure, aiRecommendation];

      setFinanceStructures(initialStructures);

      const initialStructure = persistedStructures?.find((s) => s.key === selectedStructureKey) || selectedStructure || defaultStructure;
      setCurrentStructure(initialStructure);
      setSelectedStructureKey(initialStructure.key);
      dispatch(setSelectedStructure(initialStructure));
    }
  }, [applicantDetails, persistedStructures, selectedStructure, dispatch]);


  const handleSaveStructure = (structure: any) => {
    const normalizedData = structure.key.startsWith("Underwriter")
      ? normalizeStructureData(structure.data)
      : structure.data;

    const normalizedStructure = { ...structure, data: normalizedData };
    setCurrentStructure(normalizedStructure);
    setSelectedStructureKey(normalizedStructure.key);
    dispatch(setSelectedStructure(normalizedStructure));
    dispatch({
      type: "SET_SELECTED_STRUCTURE_KEY",
      payload: normalizedStructure.key,
    });
  };

  const handleAddNewStructure = () => {
    const underwriterCount = financeStructures.filter((s) => s.key.startsWith("Underwriter")).length;
    const newStructureKey = `Underwriter${underwriterCount + 1}`;

    const newStructure = {
      key: newStructureKey,
      label: `Underwriter ${underwriterCount + 1}`,
      data: normalizeStructureData(applicantDetails),
    };

    const updatedStructures = [...financeStructures, newStructure];
    setFinanceStructures(updatedStructures);

    dispatch({
      type: "UPDATE_FINANCE_STRUCTURES",
      payload: updatedStructures,
    });

  };

  const handleOpenDialog = () => setOpened(true);
  const handleCloseDialog = () => setOpened(false);

  const formatNumber = (value: any): string | number => {
    if (value === null || value === undefined) return "--";
    if (typeof value === "number" && !isNaN(value)) {
      return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    }
    return typeof value === "string" ? value : "--";
  };

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => {
      if (Array.isArray(acc) && part.includes('[')) {
        const [arrKey, index] = part.split(/\[|\]/).filter(Boolean);
        return acc?.[arrKey]?.[Number(index)];
      }
      return acc?.[part];
    }, obj);
  };

  const fields = [
    { label: "Finance Type", key: "finance_type" },
    { label: "Financed Amount", key: (data: any) => data?.net_finance_amount ?? data?.finance_amount, startAdornment: "$" },
    {
      label: "Periodic Payment",
      key: (data: any) => data?.estimated_monthly_payment ?? data?.order_repayment_plan?.[0]?.rental_amount,
      startAdornment: "$",
    },
    {
      label: "Terms",
      key: (data: any) => data?.order_submissions?.[0]?.decision?.terms ?? data?.no_of_regular_payments ?? "--",
    },    
    { label: "Frequency", key: "frequency" },
    { label: "Asset Cost", key: "asset_cost", startAdornment: "$" },
    {
      label: "Down Payment",
      key: (data: any) => data?.down_payment_value,
      startAdornment: "$",
    },    
    { label: "Tax on Asset Cost", key: "tax_amount", startAdornment: "$" },
    { label: "Net Trade-in", key: "net_trade_in", startAdornment: "$" },
    { label: "Net Trade-in", key: "net_trade_in", startAdornment: "$" },
    {
      label: "Charges",
      key: (data: any) =>
        data?.order_fees?.reduce((acc: number, fee: any) => acc + (fee?.applied_price || 0), 0) ?? "--",
      startAdornment: "$"
    },
    { label: "Rebate", key: "rebate", startAdornment: "$" },
    { label: "Discount", key: "discount", startAdornment: "$" },
    { label: "Add-ons", key: "add_ons", startAdornment: "$" },
    { label: "Insurance", key: "insurance", startAdornment: "$" },
    {
      label: "Interest Rate",
      key: (data: any) =>
        data?.margin ??
        data?.apr ??
        data?.meta_data?.apr ??
        "--",
      startAdornment: "%"
    },    
    { label: "Dealer Fees", key: "dealer_fees", startAdornment: "$" },
  ];

  return (
    <>
      <FinanceStructureWrap theme={theme} className="finance-structure-wrap">
        <Stack
          title={"Finance Structure"}
          paddingXs={2}
          paddingMd={2}
          actionArea={
            <a href="javascript:void(0)" className="text-primary primary-alert dFlex alignItemsCenter" onClick={handleOpenDialog}>
              Add/Modify <Icon name="ArrowLinkIcon" />
            </a>
          }
        >
          {loading ? (
            <CircleLoader theme={theme} size="sm" />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <Box theme={theme} className="checkbox-section">
                    <Box theme={theme} className="custom-dropdown">
                      <Typography theme={theme} className="text-muted" variant="body2" component={"span"} display={"block"}>
                        Structure Name:
                      </Typography>
                      <Typography theme={theme} variant="body2" component={"span"} className="fw-medium">
                        {financeStructures.find(s => s.key === selectedStructureKey)?.label || "--"}
                      </Typography>
                    </Box>
                  </Box>

              <Box theme={theme} className="child-scrollable-container">
                    <BasicList
                      input
                      options={fields.map((field) => {
                        // const normalized = currentStructure?.data || {};
                        const normalized =
                          selectedStructureKey === "requested" || !selectedStructureKey
                            ? normalizeStructureData(applicantDetails)
                            : currentStructure?.data || {};

                        const value =
                          typeof field.key === "function"
                            ? field.key(applicantDetails)
                            : normalized?.[field.key];

                        return {
                          title: field.label,
                          textRight: true,
                          action: (
                            <Input
                              theme={theme}
                              type="text"
                              startAdornment={field.startAdornment || ""}
                              value={formatNumber(value)}
                              inputClass="text-right"
                              readOnly
                            />
                          ),
                        };
                      })}
                    />
              </Box>
            </>
          )}
        </Stack>
        <ArrowDown />
      </FinanceStructureWrap>

      <FinanceStructureModifyDialog
        open={open}
        closeDialog={handleCloseDialog}
        onSaveStructure={handleSaveStructure}
        selectedStructureKey={selectedStructureKey}
        financeStructures={financeStructures}
        handleAddNewStructure={handleAddNewStructure}
      />
    </>
  );
};