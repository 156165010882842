import { useTheme } from "@mui/material";
import { MyNotesListWrap } from "./my-notes-list.style";
import { Stack } from "@src/components/stack";
import { Box, Label, Typography, CircleLoader } from "@ntpkunity/controls";
import { UserName } from "../../user-name";
import { useDispatch, useSelector } from "react-redux";
import {
  createNoteRequest,
  fetchMyNotesRequest,
} from "@src/components/redux/actions/myNotesActions";
import { useParams } from "react-router-dom";

export const MyNotesList = ({ myNotesData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state: any) => state.myNotes);

  const { reference_number } = useParams();

  const handleAddToStickyNotes = (note) => {
    const updatedComment = `${note.comment} #title=${
      note.title || "N/A"
    } #priority=${note.priority || "N/A"} #color=${
      note.color || "N/A"
    } #isSticky=true`.trim();

    dispatch(
      createNoteRequest({
        comment: updatedComment,
        category: "General",
        order_reference_id: reference_number,
      })
    );

    setTimeout(() => {
      dispatch(fetchMyNotesRequest(reference_number));
    }, 1000);
  };

  return (
    <>
      <MyNotesListWrap theme={theme} className="my-notes-list-wrap">
        {loading ? (
          <CircleLoader theme={theme} size="sm" />
        ) : error ? (
          <Typography
            theme={theme}
            variant="body2"
            className="text-error"
            component={"symbol"}
          >
            Error: {error}
          </Typography>
        ) : (
          myNotesData?.map((note, index) => (
            <Stack
              paddingMd={1}
              paddingXs={1}
              className="stack-user-header"
              key={index}
            >
              <Stack paddingMd={1} paddingXs={1} bgLight>
                <UserName
                  userTitle={note.created_by?.[0]?.toUpperCase() || "NA"}
                  userName={note.created_by || "Asad"}
                  subTitle={note.comment || "No comment available."}
                />
              </Stack>
              <Box theme={theme} className="notes-content">
                <Box theme={theme} className="notes-head small-view">
                  {/* Static Credit History Text */}
                  <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    className="fw-sbold text-body1"
                  >
                    Credit History
                  </Typography>
                  <Label
                    theme={theme}
                    text={note.category || "General"}
                    state="primary"
                  />
                </Box>
                <Box theme={theme} className="notes-body">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component={"span"}
                    className="text-body1"
                  >
                    {note.comment || "No additional details available."}
                  </Typography>
                  <Box
                    theme={theme}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Typography
                      theme={theme}
                      variant="caption"
                      component={"small"}
                      className="caption-text text-muted tag"
                    >
                      #High_Priority
                    </Typography>
                    <Box theme={theme} className="date-time">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="caption-text text-muted month"
                      >
                        {new Date(note.created_at).toLocaleDateString("en-US", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="caption-text text-muted"
                      >
                        {new Date(note.created_at).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box theme={theme} className="notes-footer" pb={1}>
                  <a
                    href="javascript:void(0)"
                    className="text-primary"
                    onClick={() => handleAddToStickyNotes(note)}
                  >
                    Add to Sticky Notes
                  </a>
                </Box>
              </Box>
            </Stack>
          ))
        )}
      </MyNotesListWrap>
    </>
  );
};
