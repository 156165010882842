import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@src/styles";

export const CreateNewBusinesskWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.basic-stack-wrap": {
    ".stack-wrap": {
      "&.no-padding": {
        padding: '0px !important'
      },
      ".stack-header": {
        position: 'relative',
        ".title": {
          padding: '8px 0',
          ".MuiTypography-root": {
            fontSize: theme.typography.subtitle1.fontSize,
            fontWeight: theme.customVariables.fontWeightSemiBold
          }
        },
        ".action-area": {
          ".no-padding": {
            position: 'absolute',
            right: 0,
            top: '-16px'
          },
          ".switch-btn": {
            display: 'flex',
            alignItems: 'center',
            gap: 8
          }
        }
      }
    },
    ".chat-box": {
      padding: 8,
      borderRadius: 8,
      backgroundColor: 'transparent',
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: 'relative',
      "&::before": {
        aspectRatio: 1,
        content: '""',
        position: 'absolute',
        inset: 0,
        padding: 1,
        background: 'linear-gradient(100deg, #232BF6 -4%, #FF3B30 120%)',
        borderRadius: 8,
        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
        height: '-webkit-fill-available',
        width: '100%',
      },
      ".u-form-group": {
        marginBottom: 0,
        width: '100%',
        ".u-form-control": {
          "&.textarea": {
            ".MuiInputBase-root": {
              padding: 0,
              ".MuiInputBase-input": {
                color: '#474A59',
                padding: 4
              }
            },
          },
          fieldset: {
            border: "none",
          },
        },
      },

      ".chat-options": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 16,
        ".chat-filters": {
          display: "flex",
          alignItems: "center",
          gap: 16,
        },
        ".MuiButtonBase-root": {
          "&.btn.with-icon.btn-default": {
            padding: 0,
          },
        },
      },
    },
  },
}));
